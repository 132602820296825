import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

import Spinner from 'react-bootstrap/Spinner'
import { connect } from 'react-redux'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import * as com from '../Common'
import { Input, Space, Table, Tag, ConfigProvider, Tooltip, Spin } from 'antd';
import { DownloadOutlined, DeleteOutlined } from "@ant-design/icons";
import "./Documents.css"

const mapStateToProps = (state) => {
    return {
        application: state.application,
    }
}

const mapDispatchToProps = (dispatch) => ({
});
class DocumentManager extends Component {

    dateSortFunction = (a, b, order, dataField) => {
        if (a === '') {
            a = '01/01/1000'
        }
        if (b === '') {
            b = '01/01/1000'
        }
        if (a === '01/01/1000' && b === '01/01/1000') {
            b = '01/02/1000'
        }
        let at = new Date(a)
        at = at.getTime()
        let bt = new Date(b)
        bt = bt.getTime()


        if (order === 'asc') {
            return (at - bt) < 0 ? -1 : 1
        } else {
            return (at - bt) < 0 ? 1 : -1
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            documents: [],
            url: "",
            download: "",
            show: false,
            modalTitle: "",
            index: 0,
            id: 0,
            loadingIds: [],
        }
        this.aref = React.createRef();
        this.download = this.download.bind(this);
        this.columns = [
            {
                dataField: 'progress',
                text: '',
            },
            {
                dataField: 'document',
                text: 'Document:',
                sort: true,
                classes: 'divlink',
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        this.download(row.id, row.index)
                    },
                }
            },
            {
                dataField: 'comment',
                text: 'Comment:',
                sort: true
            }, {
                dataField: 'forwhom',
                text: 'For Whom:',
                sort: true
            }, {
                dataField: 'created',
                text: 'Created',
                sortFunc: this.dateSortFunction,
                sort: true
            }, {
                /*
                dataField: 'delete',
                text: 'Delete',
                classes: 'divlink',
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {

                        this.onShow(row.index)
                    },
                }
            }, { */
                dataField: 'id',
                text: 'id',
                hidden: true,
            }, {
                dataField: 'index',
                text: 'index',
                hidden: true,
            },
        ];
    }
    componentDidMount() {
        this.reload()
    }

    forWhomDecoded = (_forwhom) => {
        let forwhom = ""
        if (_forwhom === "joint" && this.props.application.hascoborrower === "alone") {
            _forwhom = "borrower"
        }
        try {
            switch (_forwhom) {
                case 'borrower':
                    forwhom = this.props.application.borrower.firstname + " " +
                        this.props.application.borrower.lastname
                    break
                case 'coborrower':
                    forwhom = this.props.application.coborrower.firstname + " " +
                        this.props.application.coborrower.lastname
                    break;
                case 'joint':
                    forwhom = this.props.application.borrower.firstname + " " +
                        this.props.application.borrower.lastname + " and " + this.props.application.coborrower.firstname + " " +
                        this.props.application.coborrower.lastname
                    break;
                default:
                    forwhom = _forwhom
                    break;
            }
        } catch (x) {
            forwhom = _forwhom
        }
        return forwhom
    }

    documentsJSON = (document, index) => {
        return {
            progress: <Spinner className="position-relative" size="sm" variant="success" animation={((document.progress > 0) && (document.progress < 100)) ? "border" : ""} />,
            document: document.name,
            comment: document.comment,
            forwhom: this.forWhomDecoded(document.forwhom),
            created: document.created.toLocaleString(),
            delete: 'Delete',
            index: index,
            id: document.id,
        }
    }
    reload = () => {
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/docs/documents', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    window.document.dispatchEvent(new Event('reauthenticate'), "");

                    return;
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                        window.document.dispatchEvent(new Event('reauthenticate'), "");

                    } else {
                        let docs = js.Docs.map(s => {

                            let dt = new Date(com.timeGoToJS(s.Uploaded))

                            return { rand: Math.random(), id: s.ID, name: s.FileName, comment: s.Comment, mime: s.Mime, forwhom: s.ForWhom, created: dt.toLocaleString("en-US"), progress: 0 }
                        })
                        this.setState({ documents: docs })
                    }

                });
            }
        )
            .catch(function (err) {
                console.log('Fetch Error :', err);
            });
    }
    delete = (id) => {
        let token = sessionStorage.getItem("ZeitroA")

        fetch('/docs/deletedoc/' + id, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    window.document.dispatchEvent(new Event('reauthenticate'), "");
                    this.setState({ show: false })
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        window.document.dispatchEvent(new Event('reauthenticate'), "");
                        console.log("Error: " + js.Text)
                    } else {
                    }
                    this.setState({ show: false })
                    this.reload()
                });
            }
        )
            .catch(function (err) {
                this.setState({ show: false })
                console.log('Fetch Error :', err);
            });
    }
    download = (id, index) => {
        let token = sessionStorage.getItem("ZeitroA")

        let disposition
        let docs = this.state.documents
        docs[index] = { ...docs[index], rand: Math.random(), progress: 1 }
        this.setState({ documents: Array.from(docs)})
        this.setState((prevState) => ({
            loadingIds: [...prevState.loadingIds, id],
        }))
        fetch('/docs/downloaddoc/' + id, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(response => {
            if (response.status !== 200) {
                this.setState((prevState) => ({
                    loadingIds: prevState.loadingIds.filter((loadingId) => loadingId !== id),
                }))
                alert('Looks like there was a problem. Please try again later.' )
                return
            }

            disposition = response.headers.get("Content-Disposition").split('=')

            return response.body
        })
            .then(body => {
                if (!body) {
                    throw new Error(`HTTP error! `);
                }

                const reader = body.getReader();

                return new ReadableStream({
                    start(controller) {
                        return pump();

                        function pump() {
                            return reader.read().then(({ done, value }) => {
                                // When no more data needs to be consumed, close the stream
                                if (done) {
                                    controller.close();
                                    return;
                                }

                                // Enqueue the next data chunk into our target stream
                                controller.enqueue(value);
                                return pump();
                            });
                        }
                    }
                })
            })
            .then(stream => new Response(stream))
            .then(r => r.blob())
            .then(blob => {

                let docs = this.state.documents
                docs[index] = { ...docs[index], rand: Math.random(), progress: 0 }
                this.setState({ documents: docs })

                let download = disposition.slice(1).join().replace(/"/g, '')

                const url = window.URL.createObjectURL(blob);
                this.setState({ url: url, download: download})
                this.setState((prevState) => ({
                    loadingIds: prevState.loadingIds.filter((loadingId) => loadingId !== id),
                }))
                this.aref.current.click()
                this.forceUpdate();
            })
            .catch(err=> {
                console.log('Fetch Error :', err);
                let docs = this.state.documents
                docs[index] = { ...docs[index], rand: Math.random(), progress: 0 }
                this.setState({ documents: docs})
                this.setState((prevState) => ({
                    loadingIds: prevState.loadingIds.filter((loadingId) => loadingId !== id),
                }))

            });
    }
    deleteSelected = () => {
        this.delete(this.state.id)
    }
    onHide = () => {
        this.setState({ show: false })
    }
    onShow = (index) => {
        this.setState({
            show: true,
            modalTitle: this.state.documents[index].comment,
            index: index,
            id: this.state.documents[index].id,
        })
        // TODO: SOME CALCULATIONS!
        // this.setState({redirect: <Redirect to={url} />})
    }
    render() {
        const columns = [
            {
                title: "FILE NAME",
                dataIndex: 'document',
                key: 'document',
                width: 400,
                ellipsis: {
                    showTitle: false,
                },
                render: (text) => (
                    <Tooltip placement="topLeft" title={text}>
                        <a style={{ fontWeight: 500 }}>{text}</a>
                    </Tooltip>
                )
            },
            {
                title: 'COMMENT',
                dataIndex: 'comment',
                key: 'comment',
                ellipsis: {
                    showTitle: false,
                },
                render: (text) => (
                    <Tooltip placement="topLeft" title={text}>
                        <a>{text}</a>
                    </Tooltip>
                ),
            },
            {
                title: 'DATE UPLOADED',
                dataIndex: 'created',
                key: 'created',
                width: 200,
                sorter: (a, b) => new Date(a.created) - new Date(b.created),
                defaultSortOrder: 'descend', 
            },
            {
                title: 'FOR WHOM',
                dataIndex: 'forwhom',
                key: 'forwhom',
                width: 150,
                render: (_, { forwhom }) => (
                    <>
                        {this.forWhomDecoded(forwhom)}
                    </>
                ),
            },
            {
                title: ' ',
                dataIndex: 'action',
                key: 'action',
                fixed: 'right',
                width: 60,
                render: (_, row, index) => (
                    <div className="d-flex">
                        <a>
                            {this.state.loadingIds.includes(row.id) ? <Spin /> : <DownloadOutlined onClick={() => this.download(row.id, row.index)} style={{ fontSize: 25 }} />}
                        </a>
                    </div>
                ),
            },
        ]
        return (
            <div className="text-left mt-3 mx-3 whitebackground">
                <Modal show={this.state.show} onHide={this.onHide} >
                    <Modal.Header closeButton>
                        <Modal.Title className="capitalize">Do you really want to delete {this.state.modalTitle}?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        This operation is irreversible. The document will be deleted.
                    </Modal.Body>
                    <Modal.Footer className="text-center d-block" >
                        <Row className="text-center">
                            <Col>
                                <Button variant="success" onClick={this.onHide}>Keep it</Button>
                            </Col>
                            <Col>
                                <Button variant="danger" onClick={this.deleteSelected}>Delete the document</Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal>
                <a hidden ref={this.aref} href={this.state.url} download={this.state.download}>&nbsp;</a>
                <div className="py-3 px-5 currentTasksWrap">
                    <Table className="myUpload-table" columns={columns} scroll={{ x: 810 }} 
                    showSorterTooltip={false}
                    dataSource={this.state.documents.map(this.documentsJSON)} pagination={{
                        pageSize: 10,
                        hideOnSinglePage: true
                    }} />
                </div>

            </div>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DocumentManager)
