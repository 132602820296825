import React, {useState, useEffect} from "react";
import {Form, Input, Row, Col, Radio, InputNumber, Button, Steps, message, Spin, Space, Checkbox} from "antd";
import {useParams} from "react-router-dom";
import {AddressComponent} from "../../Common/AddressAutocomplete";
import {DollarInput} from "../../Common/components/DollarInput";
import {connect} from "react-redux";
import * as act from "../../Store/actions";
import * as com from "../../Common.js";
import "./NonQMBorrowerQualifier.css";
import NonQMResult from "./NonQMResult";
import {PrequalHeader} from "../../Landing/LandingMenu";
import ContactInformation from "./ContactInformation";

const mapStateToProps = (state) => {
  return {
    application: state.application,
    progress: state.progress,
    property: state.application.property,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateProduct: (v, verb) => {
    dispatch(act.UpdateProduct(v, verb));
  },
  updateSelectField: (input, verb) => {
    dispatch(act.UpdateSelectField(input, verb));
  },
  changeMainPropertyAttribute: (t, verb) => {
    dispatch(act.ChangeMainPropertyAttribute(t, verb));
  },
  changeBorrowerInfo: (event, who, verb) => {
    dispatch(act.ChangeBorrowerInfo(event, who, verb));
  },
  updateSelectField: (input, verb) => {
    dispatch(act.UpdateSelectField(input, verb));
  },
  changeNonQMInfo: (event, verb) => {
    dispatch(act.ChangeNonQMInfo(event, "borrower", verb));
  },
  updateApplicationAttribute: (input, verb) => {
    dispatch(act.UpdateApplicationAttribute(input, verb));
  },
  changeDeclaration: (val, verb) => {
    dispatch(act.ChangeDeclaration(val, "borrower", verb));
  },
});

const NonQMBorrowerQualifier = (props) => {
  const {customerid} = useParams();

  const [form] = Form.useForm();
  const [loID, setLoID] = useState("");
  const [loName, setLoName] = useState("");
  const [loPhone, setLoPhone] = useState("");
  const [loEmail, setLoEmail] = useState("");
  const [currentStep, setCurrentStep] = useState(() => {
    const savedStep = sessionStorage.getItem("nonQMCurrentStep");
    return savedStep ? parseInt(savedStep, 10) : 0;
  });
  const [creditScore, setCreditScore] = useState(null);
  const [loading, setLoading] = useState(false);
  const [qualifiedProducts, setQualifiedProducts] = useState([]);
  const [monthlyDebt, setMonthlyDebt] = useState("");
  const [incomeDuration, setIncomeDuration] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const [verified, setVerified] = useState(null);
  const [alreadySignedUp, setAlreadySignedUp] = useState(false);
  const [pastLoans, setPastLoans] = useState([]);

  const steps = [
    {key: "step0", title: "Property information"},
    {key: "step1", title: "Loan criteria"},
    {key: "step2", title: "Credit profile"},
    {key: "step3", title: "Contact information"},
  ];

  useEffect(() => {
    sessionStorage.setItem("nonQMCurrentStep", currentStep.toString());
  }, [currentStep]);

  useEffect(() => {
    if (creditScore) {
      switch (creditScore) {
        case "below600":
          props.updateApplicationAttribute(600, "scre");
          break;
        case "600to649":
          props.updateApplicationAttribute(649, "scre");
          break;
        case "650to699":
          props.updateApplicationAttribute(699, "scre");
          break;
        case "above700":
          props.updateApplicationAttribute(800, "scre");
          break;
      }
    }
  }, [creditScore]);

  const onFinish = async () => {
    if (currentStep < steps.length - 1) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      setCurrentStep(currentStep + 1);
    } else {
      qualify();
    }
  };

  const getLTV = () => {
    let salesprice = com.safeParseInt(props.application.property.salesprice);
    let loansize = com.safeParseInt(props.application.selection.loansize);
    let downpayment = com.safeParseInt(props.application.selection.downpayment);
    let paydown = com.safeParseInt(props.application.selection.paydown);
    let cashout = com.safeParseInt(props.application.selection.cashout);

    if (props.application.property.purpose === "purchase") {
      return Math.round(((salesprice - downpayment) / salesprice) * 100);
    } else if (props.application.property.purpose === "refinance") {
      return Math.round(((loansize - paydown) / salesprice) * 100);
    } else if (props.application.property.purpose === "cashoutrefi") {
      return Math.round(((loansize + cashout) / salesprice) * 100);
    }
  };

  const getDTI = () => {
    return Math.round(
      (com.safeParseInt(monthlyDebt) /
        com.safeParseInt(props.application.borrower.nonqm.nonqmincomeamount)) *
        100
    );
  };

  const getDSCR = () => {
    if (com.safeParseInt(monthlyDebt) !== 0) {
      return parseFloat(
        (
          com.safeParseInt(props.application.borrower.nonqm.nonqmincomeamount) /
          com.safeParseInt(monthlyDebt)
        ).toFixed(2)
      );
    }
    return 100;
  };

  const getEstimatedRate = (rate) => {
    return (rate - 1.5) + "% - " + (rate + 1.5) + "%";
  };

  const getQualifiedProducts = (result) => {
    let prds = [];
    let productMap = {}

    for (let prd of result) {
      for (let p of prd.ProductRatesCosts) {
        let prdName = p.product.Name;
        let incomeDocTypes = p.product.IncomeDocTypes.sort();

        let hash = incomeDocTypes.join(',')

        if (p.rateCosts.length === 0) {
          continue;
        }

        let rate = Infinity;
        let closingCosts = Infinity;
        let minDiff = Infinity;
        let monthlyPayment = Infinity;
        for (let r of p.rateCosts) {
          if (r.lockDay !== 30) {
            continue;
          }
          let diff = Math.abs(r.closing_cost - 4000);
          if (diff < minDiff) {
            rate = r.base_rate;
            closingCosts = r.closing_cost;
            minDiff = diff;
            monthlyPayment = r.monthly_payment;
          }
        }
        if (rate === Infinity) {
          continue;
        }

        

        if (productMap[hash] === undefined || (rate < productMap[hash].rate && closingCosts < productMap[hash].closingCosts)) {
          let toAdd =  {
            prdName: prdName,
            rate: getEstimatedRate(rate),
            closingCosts: closingCosts,
            monthlyPayment: monthlyPayment,
            incomeDocTypes: incomeDocTypes,
          }
          productMap[hash] = toAdd
          prds.push(toAdd)
        } 
      }
    }
    setQualifiedProducts(prds);
  };

  const getLoInfo = () => {
    fetch("/data/nonqm/getloanofficerinfo/" + customerid, {
      method: "GET",
      headers: {
        Cache: "no-cache",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          return;
        }
        response.json().then((data) => {
          setLoID(data.id);
          sessionStorage.setItem("loid", data.id);
          setLoName(data.first_name + " " + data.last_name);
          setLoPhone(data.phone);
          setLoEmail(data.email);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    props.updateProduct("nonqm", "mortgageapplied");
    sessionStorage.setItem("cid", customerid);
    getLoInfo();
  }, []);

  const qualify = () => {
    let body = {
      state: props.application.property.state,
      county: props.application.property.county,
      purpose: com.Purpose2Calculator(props.application.property.purpose),
      loanAmount: com.safeParseInt(props.application.selection.loansize),
      units: 1,
      LTV: getLTV(),
      CLTV: getLTV(),
      HCLTV: getLTV(),
      FICO: props.application.scre,
      occupancy: com.getOccupancy(props.application.property.occupancy),
      property: com.getPropertyType(props.application.property.propertytype),
      point: 0,
      estimatedClosingCosts: 3000,
      pmi: 0,
      getFiles: false,
      monthlyIncome: com.safeParseFloat(props.application.borrower.nonqm.nonqmincomeamount),
      monthlyDebt: com.safeParseFloat(monthlyDebt),
      firsttimehomebuyer: false,
      selfemployed: props.application.borrower.occupation.selfemployed,
      citizenship: "",
      visaType: "",
      numberOfOwnedProperties: 0,
      escrowWaiver: false,
      appraisalWaiver: false,
      currentJobInMonths: 0,
      rsuAsIncome: 0,
      giftFundUsed: false,
      familyRelatedOrSmallBusiness: false,
      borrowerPaidCompensationAmount: 0,
      dti: getDTI(),
      incomeDocumentationType: "",
      DSCR: getDSCR(),
      nonQMDocTypes: props.application.borrower.nonqm.nonqmincometype,
    };
    setLoading(true);
    fetch("/data/nonqmcalculator/" + customerid, {
      method: "POST",
      headers: {
        Cache: "no-cache",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status !== 200) {
          setLoading(false);
          messageApi.open({
            type: "warning",
            content: "Something went wrong, please try later.",
          });
          return;
        }
        response.json().then((prd) => {
          let products = prd.result;
          if (products !== undefined) {
            getQualifiedProducts(products);
          }

          setLoading(false);
          setShowResult(true);
        });
      })
      .catch((err) => {
        messageApi.open({
          type: "warning",
          content: "Something went wrong, please try later.",
        });
        setLoading(false);
      });
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const renderStepContent = () => {
    let b = props.application.borrower;
    let updateBorrower = (key, value) => {
      props.changeBorrowerInfo(value, "borrower", key);
    };
    let p = props.application.property;
    let updateProperty = (key, value) => {
      props.changeMainPropertyAttribute(value, key);
    };
    let updateNonQMIncome = (e, key) => {
      props.changeNonQMInfo("monthly", "nonqmincomeperiod");
      props.changeNonQMInfo(e, "nonqmincomeamount");
    };

    switch (currentStep) {
      case 0:
        return (
          <div
            key="step0"
            className="mt-4">
            <AddressComponent
              id="borrowerQualifierAddress"
              label="What is the address of the property?"
              address={p.address}
              state={p.state}
              county={p.county}
              city={p.city}
              zip={p.zipcode}
              onAddressLineChange={(value) => updateProperty("address", value)}
              onStateChange={(value) => updateProperty("state", value)}
              onCountyChange={(value) => updateProperty("county", value)}
              onCityChange={(value) => updateProperty("city", value)}
              onZipChange={(value) => updateProperty("zipcode", value)}
              placeholder="Address line (Optional)"
              cityPlaceholder="City (Optional)"
              zipPlaceholder="Zip code (Optional)"
            />

            <div className="formLabel mt-5">
              What type of property are you looking to purchase or refinance?
            </div>
            <Radio.Group
              onChange={(e) => updateProperty("propertytype", e.target.value)}
              className="d-flex flex-column"
              value={p.propertytype}>
              <Radio value="singlefamilydetached">Single-family home</Radio>
              <Radio value="twotofourfamily">2-4 family</Radio>
              <Radio value="condominium">Condo</Radio>
              <Radio value="condominiumdetached">Town house</Radio>
              <Radio value="pudattached">PUD (Planned Unit Development)</Radio>
            </Radio.Group>

            <div className="formLabel mt-5">What's the estimated property value?</div>
            <DollarInput
              value={p.salesprice}
              onChange={(e) => updateProperty("salesprice", e)}
              placeholder="Enter the estimated property value"
            />

            <div className="formLabel mt-5">What's the intended use of the property?</div>
            <Radio.Group
              onChange={(e) => updateProperty("occupancy", e.target.value)}
              value={p.occupancy}
              className="d-flex flex-column">
              <Radio value="principal">Primary residence (the home you will live in)</Radio>
              <Radio value="investment">Investment property (rental or income-generating)</Radio>
              <Radio value="secondhome">Second home (vacation or secondary residence)</Radio>
            </Radio.Group>
          </div>
        );

      case 1:
        return (
          <div key="step1">
            
            <div className="formLabel mt-4">What is your loan purpose?</div>
            <Radio.Group
              onChange={(e) => updateProperty("purpose", e.target.value)}
              value={p.purpose}
              className="d-flex flex-column">
              <Radio value="purchase">Purchase</Radio>
              <Radio value="refinance">Refinance</Radio>
              <Radio value="cashoutrefinance">Cash out refinance</Radio>
            </Radio.Group>

            {(props.application.property.purpose === "refinance" || props.application.property.purpose === "cashoutrefinance" )&& (
              <div>
                <div className="formLabel mt-5">What is your remaining loan balance?</div>
                <DollarInput
                  value={props.application.selection.loansize}
                  onChange={(e) => props.updateSelectField(com.safeParseInt(e), "loansize")}
                />
              </div>
            )}

            {props.application.property.purpose &&<> <div className="formLabel mt-5">
              {p.purpose === "purchase"
                ? "What is the estimated amount you have for the down payment?"
                : p.purpose === "refinance"
                ? "What is the amount of your principal paydown?"
                : "What is your cash out amount?"}
            </div>
            <DollarInput
              value={
                p.purpose === "purchase"
                  ? props.application.selection.downpayment
                  : p.purpose === "refinance"
                  ? props.application.selection.paydown
                  : props.application.selection.cashout
              }
              onChange={(e) => {
                props.updateSelectField(
                  com.safeParseInt(e),
                  p.purpose === "purchase" ? "downpayment" : p.purpose === "refinance" ? "paydown" : "cashout"
                );
                if (p.purpose === "purchase") {
                  let salesprice = com.safeParseInt(props.property.salesprice);
                  let downpayment = com.safeParseInt(e);
                  props.updateSelectField(salesprice - downpayment, "loansize");
                }
              }}></DollarInput>
              </>
            }



            <div className="formLabel mt-5">What are your primary source(s) of income documentation? Select all that apply.</div>
            <Checkbox.Group
              onChange={(values) => props.changeNonQMInfo(values, "nonqmincometype")}
              value={props.application.borrower.nonqm.nonqmincometype}
              className="d-flex flex-column">
              <Checkbox value="VOE">W-2 Employee (e.g., pay stubs or employer verification of salary)</Checkbox>
              <Checkbox value="PersonalBankStatement24Mo">Self-Employed (e.g., bank statements showing business income deposits)</Checkbox>
              <Checkbox value="Form109924Mo">1099 Contractor (e.g., 1099 forms showing contractor income)</Checkbox>
              <Checkbox value="PL24">Profit & Loss Statement (e.g., a 1-year or 2-year statement showing business income and expenses)</Checkbox>
              <Checkbox value="AssetDepletion">Savings and Investments (e.g., statements from savings accounts, stocks, or other investments)</Checkbox>
              <Checkbox value="DebtServiceCoverageRatio">Rental property's cash flow</Checkbox>
            </Checkbox.Group>

            <div className="formLabel mt-4">What is your monthly income?</div>
            <DollarInput
              value={b.nonqm.nonqmincomeamount}
              onChange={updateNonQMIncome}
              placeholder="Enter your monthly income"
            />

            <div className="formLabel mt-5">What is your monthly debt?</div>
            <DollarInput
              value={monthlyDebt}
              onChange={(e) => setMonthlyDebt(e)}
              placeholder="Enter your monthly debt"
            />

          </div>
        );
      case 2:
        return (
          <div
            key="step2"
            className="mt-4">
            <div className="formLabel mt-4">What is your estimated credit score?</div>
            <Radio.Group
              onChange={(e) => setCreditScore(e.target.value)}
              value={creditScore}
              className="d-flex flex-column">
              <Radio value="below600">Below 600</Radio>
              <Radio value="600to649">600-649</Radio>
              <Radio value="650to699">650-699</Radio>
              <Radio value="above700">700 and above</Radio>
            </Radio.Group>

            <div className="formLabel mt-4">
              Do you have any bankruptcies, foreclosures, or short sales in your credit history in the last 7
              years?
            </div>
            <Radio.Group
              onChange={(e) => props.changeDeclaration(e.target.value, "bancrupt")}
              value={b.declarations.bancrupt}
              className="d-flex flex-column">
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
            </Radio.Group>
          </div>
        );
    case 3:
        return (
            <ContactInformation verified={verified} setVerified={setVerified} setAlreadySignedUp={setAlreadySignedUp} setPastLoans={setPastLoans} />
        )
    }
  };

  const nextDisabled = () => {
    let b = props.application.borrower;
    let p = props.application.property;
    if (currentStep === 0) {
      return !p.state || !p.county || !p.salesprice || !p.occupancy;
    }
    if (currentStep === 1) {
      return (
        !b.nonqm.nonqmincometype.length>0 ||
        !b.nonqm.nonqmincomeamount ||
        !p.purpose ||
        (p.purpose === "purchase" && !props.application.selection.downpayment) ||
        (p.purpose === "refinance" && !props.application.selection.paydown) ||
        (p.purpose === "cashoutrefinance" && !props.application.selection.cashout) ||
        !monthlyDebt ||
        !props.application.selection.loansize
      );
    }
    if (currentStep === 2) {
      return !b.declarations.bancrupt || !creditScore;
    }

    if (currentStep === 3) {
      return !props.application.borrower.email || !props.application.borrower.homephone || !props.application.borrower.firstname || !props.application.borrower.lastname || !verified
    }

    return false;
  };

  const onApplicationFinish = () => {
    fetch("/data/nonqm/sendnewborrowerinfotolo", {
      method: "POST",
      body: JSON.stringify({
        LoID: loID,
        CustomerID: customerid,
        LoanID: "",
        CustomerFirstName: props.application.borrower.firstname,
        CustomerLastName: props.application.borrower.lastname,
        CustomerEmail: props.application.borrower.email,
        QualifiedLoans: qualifiedProducts.map((p) => {
          return {
            Name: p.prdName,
            InterestRate: p.rate + "%",
            ClosingCosts: "$" + com.commaize(p.closingCosts),
            MonthlyPayment: "$" + com.commaize(p.monthlyPayment),
          };
        }),
        PropertyAddress:
          props.application.property.address +
          ", " +
          props.application.property.city +
          ", " +
          props.application.property.state +
          " " +
          props.application.property.zipcode,
        IncomeDocumentation: props.application.borrower.nonqm.nonqmincometype,
        Purpose: props.application.property.purpose,
        Occupany: props.application.property.occupancy,
        EstimatedPropertyValue: "$" + com.commaize(props.application.property.salesprice),
        MonthlyIncome: "$" + com.commaize(props.application.borrower.nonqm.nonqmincomeamount),
        FICO: creditScore,
      }),
    });
  };

  if (showResult) {
    return (
      <div>
        <PrequalHeader customerid={customerid}/>
        <div className="nonqm-qualifier">
          <NonQMResult
            qualifiedProducts={qualifiedProducts}
            loName={loName}
            loPhone={loPhone}
            loEmail={loEmail}
            onFinish={onApplicationFinish}
            alreadySignedUp={alreadySignedUp}
            pastLoans={pastLoans}
          />
        </div>
      </div>
    );
  }

  return (
    <div>
      <PrequalHeader customerid={customerid}/>
      <div className="nonqm-qualifier">
        {contextHolder}
        <div className="header-1 text-center mb-2">Non-QM Borrower Qualifier</div>
        <div className="title-5 text-center mb-4">Discover personalized program options and rates by answering a few questions.</div>
        <Steps
          size="small"
          current={currentStep}
          items={steps}
          className="px-5 py-3"
        />

        {renderStepContent()}

        <div className="d-flex mt-4">
          {currentStep > 0 && (
            <Button
              size="large"
              type="outline"
              className="mr-2"
              style={{minWidth: 150}}
              onClick={() => prevStep()}>
              Previous
            </Button>
          )}
          <Button
            size="large"
            disabled={nextDisabled()}
            type="primary"
            style={{minWidth: 150}}
            onClick={onFinish}>
            {loading ? <Spin /> : currentStep < steps.length - 1 ? "Next" : "Qualify me"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NonQMBorrowerQualifier);
