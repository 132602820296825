import React, { Component } from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Spinner from 'react-bootstrap/Spinner'
import * as com from './Common'
import { Redirect } from 'react-router-dom'
import { withRouter } from "react-router-dom";

class Validate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect:  <Spinner className="text-center" 
            animation="border" role="status" />

        }
        this.hex = this.props.match.params["hex"] 
        this.schema = this.props.match.params["schema"] 
        this.validate()
    }


    validate = () => {
        let token = sessionStorage.getItem("ZeitroA")

        fetch('/borrower/validated/' + this.hex + "/" + this.schema, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    alert('Looks like there was a problem. Status Code: ' +
                        response.status);
                        this.setState({redirect: <Redirect to={"/services/signin"}/>})    
                    return;
                    
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        alert("Error: " + js.Text)
                        window.document.dispatchEvent(new Event('reauthenticate'), "");
                    } else {

                        window.location.href = js.Path;
                    }

            
            });
            }
        ).catch( (err) => {
            alert('Fetch Error :', err);
        });
    }

    onSigned = () => {
        this.markClosed()
    }
    render() {
        return (
        <div className="mt-5 mb-5 text-center">
            {this.state.redirect}
        </div>
        )
    }
}

export default withRouter(Validate)
