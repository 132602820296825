import { Col, Row, Typography } from "antd";
import { USDollarCurrencyLocale } from "./utils";
import moment from "moment";

import "./ClickablePrequalLetterDisplay.css";

const { Text } = Typography;

interface ClickablePrequalLetterDisplayProps {
  purchasePrice: number;
  expirationDate: string;
  active: boolean;
  onClick: () => void;
}

const ClickablePrequalLetterDisplay = (
  props: ClickablePrequalLetterDisplayProps
) => {
  return (
    <Row
      className={
        props.active
          ? "display-container display-container-active"
          : "display-container"
      }
      gutter={[24, 16]}
      onClick={props.onClick}
    >
      <Col span={3} className="pdf-icon-container">
        <img src="/images/pdf-icon-blue.svg" alt="Prequal Letter" />
      </Col>
      <Col span={21} className="text-container">
        <Row className="main-text-container">
          <Text>Pre-qual Letter</Text>
          <Text>{" • "}</Text>
          <Text>{USDollarCurrencyLocale.format(props.purchasePrice)}</Text>
        </Row>
        <Row className="sub-text-container">
          <Text>
            Expires on {moment(props.expirationDate).format("MMM D, YYYY")}
          </Text>
        </Row>
      </Col>
    </Row>
  );
};

export default ClickablePrequalLetterDisplay;
