import { useState, useRef, useEffect } from 'react';
import {
    Input
  } from 'antd';
import "./GoogleAutocomplete.css";
export default function GoogleAutocomplete({
    placeholder, value, onChange, getPlace, disabled
}) {;
    const autoCompleteRef = useRef();
    const inputRef = useRef();


    const googleOptions = {
        componentRestrictions: { country: "us"},
        fields: ["address_components", "formatted_address"]
    };
    useEffect(() => {
        if(window.google == null)
            return
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
            inputRef.current,
            googleOptions
        );
        autoCompleteRef.current.addListener("place_changed", async function () {
            const place = await autoCompleteRef.current.getPlace();
            onChange(inputRef.current.value)
            getPlace(place)
        //   onChange(place.formatted_address)
        //   form.setFieldsValue({
        //     address: place.formatted_address,
        //     city: place.formatted_address.split(',')[1],
        //     state: place.address_components.find(item =>item.types.includes("administrative_area_level_1")).short_name,
        //     zipCode: place.address_components.find(item => item.types.includes("postal_code")).long_name
        //   });
        });
    }, []);
    return (
        <div>
            <input
                className='google-autocomplete'
                onChange={() =>{onChange(inputRef.current.value)}}
                value={value}
                ref={inputRef}
                placeholder={placeholder}
                disabled={disabled}
            />
        </div>
    );
}
