import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { withRouter } from "react-router-dom";
import {UpdateChangeProgressType} from "./Store/progress"
import { connect } from 'react-redux'

const mapStateToProps = (state) => {
  return {
    application: state.application,
    progress: state.progress,
    hidden: false,
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateChangeProgressType: (step, stage) => {
      dispatch(UpdateChangeProgressType(step, stage))
  },      
});

export default class Glossary extends Component {

    componentDidMount() {
        this.props.updateChangeProgressType("glossary")
    }
    render = () => {
        return (
            <div className="px-0 mx-0 py-0 my-0">
                <h2 className="spaced my-3">LOAN DICTIONARY</h2>

                <h3>
                    <a className="blocklink" href="#19">1-9</a>&nbsp;&nbsp;&nbsp;
            <a className="blocklink" href="#AC">A-C</a>&nbsp;&nbsp;&nbsp;
            <a className="blocklink" href="#DF">D-F</a>&nbsp;&nbsp;&nbsp;
            <a className="blocklink" href="#GI">G-I</a>&nbsp;&nbsp;&nbsp;
            <a className="blocklink" href="#JL">J-L</a>&nbsp;&nbsp;&nbsp;
            <a className="blocklink" href="#MO">M-O</a>&nbsp;&nbsp;&nbsp;
            <a className="blocklink" href="#PR">P-R</a>&nbsp;&nbsp;&nbsp;
            <a className="blocklink" href="#ST">S-T</a>&nbsp;&nbsp;&nbsp;
            <a className="blocklink" href="#UZ">U-Z</a>
                </h3>
                <div id="landing1" className="container my-5 text-left" >

                    <h4 id="19">1-9</h4>
                    <div className="my-3">
                        <b>10/15/30-Year Fixed Mortgage</b> – A fixed-rate home loan with terms lasting 10, 15, or 30 years, depending on which option you choose. The most common loan term is a 30-year fixed rate mortgage.
                </div>
                    <div className="my-3">
                        <b>203k Loan</b> – An FHA loan where financing goes toward buying a house and needed home improvements.
                </div>
                    <div className="my-3">
                        <b>3/1 ARM</b> – An adjustable-rate mortgage where the mortgage rate is fixed for the first three years of the loan. Mortgage rates adjust annually after the third year.
                </div>
                    <div className="my-3">
                        <b>5/1 ARM</b> – An adjustable-rate mortgage that has a fixed mortgage rate for the first five years of the loan term. After, the mortgage rate adjusts annually for the remainder of the loan.
                </div>
                    <div className="my-3">
                        <b>80/10/10, 80/15/5</b> – See Piggyback Loan.
                </div>
                    <div className="my-3">
                        <b>100% Loan</b> – A loan where no down payment is required. VA and USDA loans are examples of 100% loans.
                </div>

                    <h4 id="AC">A-C</h4>

                    <div className="my-3">
                        <b>Abstract of title</b> –
A written history of all the transactions related to the title for a specific tract of land. An abstract of title covers the period from the original source of title (often the original land grant from the United States government to an individual) to the present time and summarizes all subsequent documents that have been recorded against that tract.
</div>

                    <div className="my-3">
                        <b>Adjustable-Rate Mortgage</b> – Also known as an ARM, adjustable-rate mortgages have a variable interest rate. This means that the mortgage rate will be fixed for a set amount of time, then adjust on a regular basis for the remainder of the loan.
                </div>
                    <div className="my-3">
                        <b>Alt-A Mortgage</b> – A loan that falls somewhere in between “prime” and “subprime” in terms of risk. Alt-A homebuyers generally have higher loan-to-value (LTV) ratios, less income documentation, average credit scores, and additional properties.
                </div>
                    <div className="my-3">
                        <b>Amortization</b> – The way a loan is paid off one month at a time. With a mortgage, the principal amount paid each month increases, while the interest paid decreases. In other words, at the beginning of the loan, most of your payment is interest. At the end, the payment is mostly principal.
                </div>

                    <div className="my-3">
                        <b>Annual Percentage Rate (APR)</b> – This is the rate that shows the true cost of borrowing. It factors in the interest rate of your loan, plus all the costs associated with obtaining the loan. Comparing APRs from various lenders is a useful way to see whose offer is best.
                </div>
                    <div className="my-3"><b>Appraisal</b> – An evaluation of a home to determine its value.
                </div>
                    <div className="my-3"><b>Assumption</b> – When one person or party takes on (assumes) an existing mortgage.
                </div>
                    <div className="my-3"><b>Balloon Mortgage</b> – A mortgage loan with lower monthly payments and a large sum due at the end of the loan period, as opposed to higher monthly payments with no money due at the end of the loan term.
                </div>
                    <div className="my-3"><b>Blanket Mortgage</b> – A mortgage used to purchase more than one property. These are commonly used for buying multiple pieces of land to develop, or for purchasing existing properties with the intent to rent.
                </div>
                    <div className="my-3"><b>Bridge Loan</b> – A temporary loan used to “bridge” the time gap when financing is needed but funds are not yet available. These are commonly used when a homeowner wants to buy a new property but first needs to sell their current property.
                </div>
                    <div className="my-3"><b>Buy-Down</b> – A buy-down is when you pay your lender a premium in order to lower your mortgage rate.
                </div>
                    <div className="my-3"><b>Caps</b> – With ARMs, a cap is a limit to how much your mortgage rate can change. This protects homeowners from experiencing a sudden spike in mortgage payments.
                </div>
                    <div className="my-3"><b>Cash-Out Refinance</b> – A refinance loan where the homeowner takes equity out of their home in the form of cash.
                </div>

                    <div className="my-3"><b>Certificate of Reasonable Value (CRV)</b> – After the VA home appraisal, the VA determines the value of the home with a CRV. This value affects the size of the loan a VA homebuyer will be able to secure.
                </div>

                    <div className="my-3"><b>Closing</b> – The last step of the homebuying process. This is where you sign your loan documents, release funds from escrow, and receive the keys to your new house.
                </div>

                    <div className="my-3"><b>Closing Costs</b> – All the fees associated with the loan and purchasing the property. For instance, loan origination, title, escrow, appraisal, recording, and upfront mortgage insurance are all closing costs. Most closing costs are paid out-of-pocket at closing unless the seller has agreed to pay them for you.
                </div>

                    <div className="my-3"><b>Conforming Loan</b> – A mortgage that conforms to the standards set by Fannie Mae and Freddie Mac, sometimes referred to as a conventional loan.
                </div>

                    <div className="my-3"><b>Construction Loan</b> – A short-term loan used to secure funding to build a home. These generally have higher interest rates and are often refinanced once the home is completed.
                </div>

                    <div className="my-3"><b>Contingency</b> – This is basically an “out” that is written into your purchase contract. If conditions of the contingency are not met, you can back out of the transaction and keep your earnest money.
                Example: An inspection contingency is common. If the inspection is not to your standards, then you can cancel your offer with no penalty.
                </div>

                    <div className="my-3"><b>Conventional Mortgage</b> – A mortgage loan that isn’t guaranteed or insured by the government. Also, referred to as a conforming loan.
                </div>

                    <div className="my-3"><b>Credit Report </b>– A summary of all your debts. Your creditors, balances, available credit, any late payments, and a number of other factors are listed on your credit report. Lenders review your credit report when considering you for a loan. You are eligible for one free credit report a year from AnnualCreditReport.com by law.
                </div>

                    <div className="my-3"><b>Credit Score </b>– Your credit history and risk assessment expressed by a number. The three major credit bureaus — Experian, Equifax, and Transunion — have proprietary algorithms to determine your number. The lenders uses your middle score for qualification.
                Example: Your scores from each bureau are 680, 690, and 700. The lender will use 690.
                </div>
                    <h4 id="DF"> D-F</h4>

                    <div className="my-3"><b>Debt-to-Income (DTI) Ratio </b>– The percentage of your expenses compared to your gross income. The front-end ratio (or front ratio) is your proposed housing payment compared to your income. Your back-end ratio (or back ratio) is all your monthly debt payments, plus proposed housing payment compared to your income.
                 Example: Your income before taxes is $5,000 per month, and your future house payment is $1,600 per month. You have a student loan payment of $100, a car payment of $200, and a credit card payment of $50. Your DTI is 39%. Your front-end ratio is 32% ($1,600 / $5,000) and your back-end ratio is 39% ($1,950 / $5,000). Another way of expressing your DTI is 32/39.
                </div>
                    <div className="my-3"><b>Deed-in-Lieu of Foreclosure </b>– The property is deeded to the lender in order to avoid foreclosure proceedings.
                </div>

                    <div className="my-3"><b>Deed Of Trust </b>– The deed of trust acts as your loan’s security and is stored in public records. The deed of trust is often signed at closing.
                </div>

                    <div className="my-3"><b>Delinquency </b>– When you don’t make a monthly payment on your mortgage. Continued delinquency can potentially lead to foreclosure.
                </div>

                    <div className="my-3"><b>Dependent</b>– Who is a Dependent? A dependent is a person whom the borrower supports financially. In most cases, the dependent is a member of the mortgage applicant's household, with certain exceptions, such as a college student living on campus but being fully supported by his parents. In many cases, an applicant can use the number of dependents listed on the most recent tax return as a guideline when filling out a mortgage application.
                When you’re claiming a dependent who is a child, there are further requirements:
                <ul>
                            <li>The child has to have lived with you for at least half of the year.</li>
                            <li>The child has to be related to you as a son, daughter, stepchild, foster child, brother, sister, stepbrother, stepsister, or a descendant of any of those.</li>
                            <li>The child must be 18 or younger at the end of the year, or under 24 if a student. To be a student, the child must have attended school full-time during at least five months of the year. The five months don’t have to be in a row.</li>
                            <li>The child must be younger than you (or your spouse, if married filing jointly), unless the child is disabled.</li>
                        </ul>
                    </div>
                    <div className="my-3"><b>Discount Point </b>– This is similar to an origination point, but is only allowed to be charged if it’s used to lower your interest rate.
                </div>

                    <div className="my-3"><b>Down Payment </b>– The amount you pay toward the purchase price of your home.
                </div>

                    <div className="my-3"><b>Earnest Money </b>– This is the money you pay upfront to show the seller you are serious, or “earnest” about buying their home. The money is held by the escrow company and is applied toward down payment and/or closing costs when the transaction closes. Usually earnest money is between 1% and 3% of the purchase price. You can lose your earnest money, but only by backing out of the purchase contract without the appropriate contingency.
                </div>

                    <div className="my-3"><b>Escrow </b>– The company that holds and accepts money from all parties involved in the transaction, then distributes it appropriately at closing. You often sign final loan paperwork at the escrow office.
                </div>

                    <div className="my-3"><b>Fannie Mae </b>– The Federal National Mortgage Association, often called Fannie Mae, is a federal corporation that backs mortgages such as Conventional 97 and HomeReady.
                </div>

                    <div className="my-3"><b>Freddie Mac </b>– The Federal Home Loan Mortgage Corporation, or Freddie Mac, is a government enterprise that backs mortgages, like the Home Possible Advantage.
                </div>

                    <div className="my-3"><b>FHA Loan </b>– A mortgage backed by the Federal Housing Administration. FHA loans were created to help lower income homebuyers afford homes.
                </div>

                    <div className="my-3"><b>First Mortgage </b>– The primary mortgage on a home. Usually this term is only used when a borrower also has a second mortgage.
                </div>

                    <div className="my-3"><b>Fixed-rate Mortgage </b>– A loan where the interest rate remains the same for the life of the mortgage.
                </div>

                    <div className="my-3"><b>Float </b>– What happens before you lock your interest rate. When you float your interest rate, you actively watch interest rate fluctuations and wait for a time to lock. If you float your rate, your future monthly mortgage payment could go up if rates go up. Also see Lock.
                </div>

                    <div className="my-3"><b>Foreclosure </b>– When someone fails to make mortgage payments, banks sell the property elsewhere. This legal process from start to finish is called foreclosure.</div>


                    <h4 id="GI"> G-I</h4>

                    <div className="my-3"><b>Gift Money </b>– The money you receive from an eligible source (family member, non-profit, etc.) to apply to use as a down payment. You can use gift money on any type of loan, but each loan has its own restrictions.
                </div>

                    <div className="my-3"><b>Good Faith Estimate (GFE) </b>– This is a document you should receive within 3 days of applying for a loan. It details the fees associated with the mortgage. Take this document with you at loan closing to make sure the fees didn’t change dramatically. For a sample GFE, see our downloadable mortgage forms page.
                </div>


                    <div className="my-3"><b>HARP Loan </b>– A refinance program that helps homeowners with negative equity in their home. The program expired Dec. 31, 2018.
                </div>



                    <div className="my-3"><b>Hazard Insurance </b>– See Homeowner’s Insurance.
                </div>



                    <div className="my-3"><b>Homeowners Association (HOA) </b>– The organization that creates and maintains the bylaws and rules for a specific housing development, such as a Planned Unit Development (PUD) or condominium project. Homeowners within the community must pay dues to the HOA, usually monthly or yearly.
                </div>



                    <div className="my-3"><b>Homeowners Insurance </b>– The insurance policy that insures the home being purchased in the case of fire or other hazards.
                </div>



                    <div className="my-3"><b>HOA/HOA Dues </b>– See Homeowners Association.
                </div>



                    <div className="my-3"><b>Home Equity </b>– The total value of the property minus the amount currently owed. A $400,000 home with $300,000 remaining on the mortgage has earned $100,000 in home equity.
                </div>



                    <div className="my-3"><b>Home Equity Line of Credit (HELOC) </b>– A mortgage loan that is backed by the current equity in your home. Essentially, you can take money out of your home equity, but will need to repay it plus interest.
                </div>



                    <div className="my-3"><b>HUD/HUD1 </b>– You receive this document when you sign final loan paperwork at escrow. It breaks down all the fees associated with the loan and tells you the final dollar amount you need to pay to close the loan.
                </div>



                    <div className="my-3"><b>Impound Account </b>– An account set up by a mortgage company to hold your tax and insurance payments.



</div>



                    <div className="my-3"><b>Index </b>– Rate averages that determine the interest rate of an ARM after the initial fixed period. See also ARM and Margin.



</div>



                    <div className="my-3"><b>Interest-Only Mortgage </b>– A mortgage where the homebuyer only pays interest and not principal for a fixed term (usually around 5 years). When paying the principal is added, the monthly payment will increase, sometimes significantly.



</div>



                    <div className="my-3"><b>Investment Property </b>– Property you own but don’t occupy, instead renting it out to generate income or preparing it to sell.

</div>

                    <h4 id="JL"> J-L </h4>




                    <div className="my-3"><b>Jumbo Loan </b>– A loan that exceeds the conforming limits set by Freddie Mac and Fannie Mae, so not backed by the government. Rather, private banks issue and approve jumbo loans.



</div>



                    <div className="my-3"><b>Lender Credit </b>– A credit given to the homebuyer from the lender in exchange for accepting a higher mortgage rate.



</div>



                    <div className="my-3"><b>Lender-Paid Mortgage Insurance </b>– Mortgage insurance that is covered by the lender in exchange for a higher mortgage rate.



</div>



                    <div className="my-3"><b>Letter of Explanation </b>– A document that provides the mortgage underwriter with extra information they may need for the mortgage to get approved.



</div>



                    <div className="my-3"><b>Lien </b>– The bank’s claim on a property until the debt (aka the mortgage loan) is paid off.



</div>



                    <div className="my-3"><b>Loan Officer </b>– Act as the liaison between the homebuyer and the bank to ensure completion of the loan.



</div>



                    <div className="my-3"><b>Loan Originator </b>– Another term for a loan officer.



</div>



                    <div className="my-3"><b>Loan Processor </b>– The person who prepares and organizes the necessary files for the mortgage.



</div>



                    <div className="my-3"><b>Loan-to-Value (LTV) Ratio </b>– The percentage of your loan amount compared to the home’s value and/or purchase price.



                Example: If you put 5% down on a $200,000 home, then your loan amount is $190,000 and your LTV is 95%.

</div>



                    <div className="my-3"><b>Lock </b>– The act of securing an interest rate on a loan. After a lock, future rate fluctuations in the market won’t affect the interest rate on your loan. See also Float.


</div>
                    <h4 id="MO"> M-O </h4>




                    <div className="my-3"><b>Margin </b>– The amount of extra interest the lender charges when an ARM starts adjusting. The margin and the index are added to determine the rate you pay when the ARM’s fixed period ends.



</div>



                    <div className="my-3"><b>Mortgage </b>– A loan on a property for which the payments will retire the debt at the end of the loan term.



</div>



                    <div className="my-3"><b>Mortgage Banker/Mortgage Lender </b>– Unlike a mortgage broker, this company actually lends the money for a mortgage loan, as well as takes the borrower’s application and processes the loan. See Mortgage Broker.



</div>



                    <div className="my-3"><b>Mortgage Broker </b>– A company or individual that takes a loan application then selects a bank to fund the loan. The broker sends the loan to a bank, and the bank lends the money. The bank pays the mortgage broker for sending the loan.



</div>



                    <div className="my-3"><b>Mortgage Discount Points </b>– Homebuyers can lower their mortgage rate at closing by paying mortgage discount points.



</div>



                    <div className="my-3"><b>Mortgage Insurance </b>– Loan insurance is required on most mortgages that don’t have a large enough down payment. VA loans do not require mortgage insurance, since they are backed by the VA. In some cases, mortgage insurance can be removed from the loan once a specific equity is earned.



</div>



                    <div className="my-3"><b>Mortgage Payment </b>– The monthly payment you make on your home loan, which includes principal, interest, taxes, and insurance. See PITI.



</div>



                    <div className="my-3"><b>Mortgage Principal </b>– The total amount to be paid on your home, not including interest.



</div>



                    <div className="my-3"><b>Mortgage Rate </b>– The interest rate for your home loan.



</div>



                    <div className="my-3"><b>Mortgage Rate Lock </b>– Choosing a mortgage rate and locking it in so it won’t change. See Lock.



</div>



                    <div className="my-3"><b>Mortgage Term </b>– The length, or life, of the mortgage. Common loan terms are 15 or 30 years.



</div>



                    <div className="my-3"><b>Mortgage Underwriter </b>– Someone who works for the lender that decides whether your home loan is approved.



</div>



                    <div className="my-3"><b>Origination Point </b>– The fee you pay the lender for approving the loan. A point is equal to 1% of the loan amount and is paid at closing with your other closing costs. It may also be paid by the seller if it’s negotiated into the purchase contract.
</div>


                    <h4 id="PR"> P-R</h4>




                    <div className="my-3"><b>Pre-approval </b>– This occurs after you submit a mortgage application and lets you know exactly how large a loan you qualify for.



</div>



                    <div className="my-3"><b>Pre-qualification </b>– The first step in the mortgage process where you supply information on your income, credit score, and debt to get a general idea of how large a loan you could qualify for.



</div>



                    <div className="my-3"><b>P&amp;I </b>– The principle and interest payment on a home loan not including taxes, insurance, or HOA dues (if applicable).



</div>



                    <div className="my-3"><b>Piggyback Loan </b>– When a borrower opens a first and second mortgage simultaneously when buying a home, usually to avoid private mortgage insurance. See 80/10/10.



Example: You buy a home for $200,000. You open a first mortgage for $160,000 and a second mortgage for $20,000, and put $20,000 down. Since your first mortgage is at 80% loan-to-value, you don’t need mortgage insurance.

                </div>



                    <div className="my-3"><b>PITI </b>– This represents the homebuyer’s entire monthly payment. It stands for principal, interest, taxes, and insurance.



</div>



                    <div className="my-3"><b>Prepayment Penalty </b>– The penalty a borrower must pay if the loan is paid off within a short period of time. FHA, VA, USDA, and conventional mortgages typically do not have a prepayment penalty.



</div>



                    <div className="my-3"><b>Private Mortgage Insurance (PMI) </b>– The insurance policy that covers your mortgage lender in case you default on the loan. You pay for this policy monthly whenever you put less than 20% down on a conventional mortgage.



</div>



                    <div className="my-3"><b>Processing </b>– The loan processor puts your loan file together so that it is complete prior to the underwriting process. See Underwriting.



</div>



                    <div className="my-3"><b>Reverse Mortgage </b>– A loan where you can tap into your home equity without adding to monthly payments. You must be 62 years old or older to be eligible.


</div>
                    <h4 id="ST"> S-T
</h4>




                    <div className="my-3"><b>Second Mortgage </b>– A lien that is second priority to a first mortgage on a home. Second mortgages usually have higher interest rates, since they are higher-risk loans. They are often used to help the borrower avoid private mortgage insurance. See Piggyback Loan.



</div>



                    <div className="my-3"><b>Short Sale </b>– In lieu of a foreclosure the homeowner sells their home for less than the amount left on the mortgage.



</div>



                    <div className="my-3"><b>Stated Income Mortgage </b>– A mortgage loan where you don’t have to supply your income information.



</div>



                    <div className="my-3"><b>Streamline Refinance </b>– A refinance that requires less documentation than other refinance options. You cannot take out cash with a streamline refinance. See Cash-out Refinance.



</div>



                    <div className="my-3"><b>Term </b>– The length of the loan, expressed in years or months.



</div>



                    <div className="my-3"><b>Title &amp; Title Insurance </b>– The title is the document that shows ownership history, the outstanding debts that are tied to the property, and current ownership. Title insurance protects you and the lender against any previous owner who claims rights to the property.
</div>



                    <div className="my-3"><b>Truth in Lending (TIL) </b>– You should receive this document within 3 days of applying for the loan. It tells you the loan’s APR, whether it has a prepayment penalty, and the loan terms, among others. For a sample TIL, see our downloadable mortgage forms page.
</div>


                    <h4 id="UZ"> U-Z </h4>




                    <div className="my-3"><b>Underwriting </b>– The process of verifying that the borrower’s documentation adheres to a specific loan program’s guidelines.
</div>



                    <div className="my-3"><b>USDA Loan </b>– The US Department of Agriculture (USDA) loan, or Rural Housing Mortgage, is a loan with no down payment requirement. Eligible homes are restricted to rural areas and income limits.
</div>



                    <div className="my-3"><b>VA Mortgage </b>– The Veterans Affairs mortgage is a no-money down, low interest rate loan available only to veterans and active-duty military members. The loan is backed by the VA and does not require mortgage insurance.



</div>



                    <div className="my-3"><b>Zero-down Mortgage </b>– A mortgage that doesn’t require a down payment at closing.

            </div>
                </div>
            </div>

        )
    }
}

Glossary = withRouter(connect(mapStateToProps, mapDispatchToProps)(Glossary))