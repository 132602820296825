import {
  Point,
  Selection,
  IncomingDataInterface,
  ProcessedRateDataInterface,
  EpochInterface,
  RateDataInterface,
} from './ChartTypes'

const KEY_MAP = {
  Fixed15: 'Fixed 15 Year',
  Fixed30: 'Fixed 30 Year',
  ARM106: 'ARM 10 Year',
  ARM76: 'ARM 7 Year',
  ARM56: 'ARM 5 Year',
} as const

const EPOCH_INDEX_MAP = {
  '1M': [0],
  '3M': [1, 0],
  '6M': [2, 1, 0],
  '1Y': [3, 2, 1, 0],
} as const

class ProcessedRateData implements ProcessedRateDataInterface {
  'ARM 10 Year': Point[]
  'ARM 7 Year': Point[]
  'ARM 5 Year': Point[]
  'Fixed 15 Year': Point[]
  'Fixed 30 Year': Point[]

  constructor() {
    this['Fixed 15 Year'] = new Array<Point>()
    this['Fixed 30 Year'] = new Array<Point>()
    this['ARM 10 Year'] = new Array<Point>()
    this['ARM 7 Year'] = new Array<Point>()
    this['ARM 5 Year'] = new Array<Point>()
  }

  push(group: RateDataInterface, time: Date) {
    for (let key of Object.keys(group))
      if (group[key] > 0) {
        this[KEY_MAP[key]].push({ x: time, y: group[key] })
      }
  }
}

class Epoch implements EpochInterface {
  Conforming: ProcessedRateData
  Super: ProcessedRateData
  Jumbo: ProcessedRateData
  Threshold: Date
  static Count: number = 0
  static Months: number[] = [0, 1, 3, 6]
  constructor() {
    this.Conforming = new ProcessedRateData()
    this.Super = new ProcessedRateData()
    this.Jumbo = new ProcessedRateData()
    this.Threshold = new Date()
    this.Threshold.setMonth(
      this.Threshold.getMonth() - Epoch.Months[Epoch.Count]
    )
    Epoch.Count += 1
  }

  push(
    groups: {
      Conforming: RateDataInterface
      Super: RateDataInterface
      Jumbo: RateDataInterface
    },
    TimeStamp: Date
  ) {
    this.Conforming.push(groups.Conforming, TimeStamp)
    this.Super.push(groups.Super, TimeStamp)
    this.Jumbo.push(groups.Jumbo, TimeStamp)
  }

  contains(date: Date) {
    if (date <= this.Threshold) return true
    return false
  }
}

class Rates {
  Epochs: Epoch[]
  length: number
  constructor() {
    this.Epochs = [new Epoch(), new Epoch(), new Epoch(), new Epoch()]
    this.length = 0
  }

  push(data: IncomingDataInterface[]) {
    data.forEach(({ Timestamp, ...Groups }: IncomingDataInterface) => {
      const date = new Date(Timestamp)
      for (let i = this.Epochs.length - 1; i >= 0; i--) {
        if (this.Epochs[i].contains(date)) {
          this.Epochs[i].push(Groups, date)
          break
        }
      }
    })
    this.length = data.length
  }

  merge = (TimeFrame: string, Loan: string, product: string) => {
    let temp = []
    for (let epoch_index of EPOCH_INDEX_MAP[TimeFrame]) 
      temp.push(...this.Epochs[epoch_index][Loan][product])
    return temp
  }

  filter(selection: Selection) {
    function compare(x, y) {
      return x.x.getTime() - y.x.getTime() > 0 ? 1 : -1
    }    
    let filteredData = {}
    selection.Products.forEach((product: string) => {
      filteredData[product] = this.merge(
        selection.TimeFrame,
        selection.Loan,
        product
      )
    })
    let keys = Object.keys(filteredData)

    keys.forEach( x => {
      filteredData[x] = filteredData[x].sort(compare)
    })

    return filteredData
  }
}

export default Rates
