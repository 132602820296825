import React, { Component } from "react"
import { InputNumber, Select } from "antd"
import 'bootstrap/dist/css/bootstrap.min.css'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import * as com from "../Common.js"
import { Tips } from "../Common/Tooltip"
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import CheckGroup from '../Common/components/CheckGroup'

const mapStateToProps = (state) => {
    return {
        application: state.application,
        borrower: state.application.borrower.declarations,
        coborrower: state.application.coborrower.declarations,
        hascoborrower: state.application.hascoborrower,
    }
}

const mapDispatchToProps = (dispatch) => ({
    changeDeclaration: (event, who, verb) => {
        dispatch(act.ChangeDeclaration(event.target.value, who, verb))
    },
    changeBorrowerInfo: (event, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(event, who, verb))
    },
})

class Declarations extends Component {
    constructor(props) {
        super(props)

        this.form = React.createRef()

        this.state = {
            validated: false
        }

        this.isValidated = this.isValidated.bind(this)
        this.form = React.createRef()
    }

    componentDidMount() { }

    componentWillUnmount() { }

    onFinish = () => {
        const { who } = this.props
        const { completedsteps } = this.props.application[who] || {}

        if (!completedsteps?.propertymoney) {
            this.props.changeBorrowerInfo(
                { ...completedsteps, propertymoney: true },
                who,
                'completedsteps'
            )
        }
    }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        }
        return true
    }

    render = () => {
        const achangeDeclaration = (verb, who) => {
            return ((event) => {
                const e = {
                    target: {
                        value: event
                    }
                }
                com.touch()
                this.props.changeDeclaration(e, who, verb)
            }
            )
        }

        const { who, borrower, coborrower } = this.props
        const { purpose } = this.props.application.property

        const occupyAsPrimary = who === com.borrower ? borrower.occupyasprimary : coborrower.occupyasprimary
        const ownershipInterest = who === com.borrower ? borrower.ownershipinterest : coborrower.ownershipinterest
        const propertyOwned = who === com.borrower ? borrower.propertyowned : coborrower.propertyowned
        const familyRelationship = who === com.borrower ? borrower.familyrelationship : coborrower.familyrelationship
        const titleHeld = who === com.borrower ? borrower.titleheld : coborrower.titleheld
        const downPaymentBorrowed = who === com.borrower ? borrower.downpaymentborrowed : coborrower.downpaymentborrowed
        const moneyBorrowed = who === com.borrower ? borrower.moneyborrowed : coborrower.moneyborrowed
        const applyingOnAnother = who === com.borrower ? borrower.applyingonanother : coborrower.applyingonanother
        const applyingNewCredit = who === com.borrower ? borrower.applyingnewcredit : coborrower.applyingnewcredit
        const cleanEnergyLien = who === com.borrower ? borrower.cleanenergylien : coborrower.cleanenergylien

        const yesnooptions = [
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' },
        ]

        return (
            <div className="text-left newInterviewPaneProperty" style={{ padding: '0 5px 15px 0' }}>
                <div className="page-header">
                    <span className="page-header-primary">Declarations</span>
                    <span className="page-header-separator">/</span>
                    <span className="page-header-secondary">Property and your money for this loan</span>
                </div>

                <Row>
                    <Col className="mt-4 px-0" md={12} xs={12}>
                        <Form.Group className="inputSection">
                            <Form.Label>
                                Will you occupy the property as your primary residence?
                            </Form.Label>
                            <Row>
                                <Col className="pl-0" md={6} xs={12}>
                                    <Form.Group className="inputSection">
                                        <CheckGroup
                                            data={yesnooptions}
                                            value={occupyAsPrimary}
                                            onChangeSelect={(event) => {
                                                const e = { target: { value: event } }
                                                if (event === 'no') {
                                                    this.props.changeDeclaration(e, who, 'ownershipinterest')
                                                }
                                                this.props.changeDeclaration(e, who, 'occupyasprimary')
                                            }}
                                            type="radio"
                                            direction="horizontal"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>
                {occupyAsPrimary !== 'yes' ? '' :
                    <Row>
                        <Col className="mt-4 px-0" md={12} xs={12}>
                            <Form.Group className="inputSection">
                                <Form.Label>
                                    Have you had an ownership interest in another property in the last three years?
                                </Form.Label>
                                <Row>
                                    <Col className="pl-0" md={6} xs={12}>
                                        <Form.Group className="inputSection">
                                            <CheckGroup
                                                data={yesnooptions}
                                                value={ownershipInterest}
                                                onChangeSelect={(event) => {
                                                    if (occupyAsPrimary === 'no') return
                                                    const e = { target: { value: event } }
                                                    com.touch()
                                                    this.props.changeDeclaration(e, who, 'ownershipinterest')
                                                }}
                                                type="radio"
                                                direction="horizontal"
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>
                    </Row>
                }
                {occupyAsPrimary === 'yes' &&
                    <Row>
                        <Col className="mt-4 px-0" md={6} xs={12}>
                            <div hidden={!(ownershipInterest !== "no")}>
                                <Row>
                                    <Col md={6} className="pl-0">
                                        <Form.Group controlId="propertyowned" className="text-left">
                                            <Form.Label className="d-flex align-items-center">
                                                What type of property did you own?
                                                <Tips
                                                    content={<div>
                                                        Select FHA Second Home only if your mortgage is an FHA one, and your property was qualified as FHA secondary for this mortgage
                                                    </div>}
                                                />
                                            </Form.Label>
                                            <div>
                                                <Select
                                                    style={{ width: '100%', height: 44 }}
                                                    value={propertyOwned}
                                                    onChange={achangeDeclaration('propertyowned', who)}
                                                >
                                                    <option value="principal">Primary Residence</option>
                                                    <option value="secondhome">Second Home</option>
                                                    <option value="fhasecond">FHA Second residence</option>
                                                    <option value="investment">Investment property</option>
                                                </Select>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="pl-0">
                                        <Form.Group controlId="titleheld" className="text-left">
                                            <Form.Label>How did you hold title to the home?</Form.Label>
                                            <div>
                                                <Select
                                                    style={{ width: '100%', height: 44 }}
                                                    value={titleHeld}
                                                    onChange={achangeDeclaration('titleheld', who)}
                                                >
                                                    <option value="yourself">By Yourself</option>
                                                    <option value="jointwithspouse">Jointly with your spouse</option>
                                                    <option value="jointwithother">Jointly with Another Person</option>
                                                </Select>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                }
                {purpose === "purchase" &&
                    <Row>
                        <Col className="mt-4 px-0" md={12} xs={12}>
                            <Form.Group className="inputSection">
                                <Form.Label>
                                    Do you have a family relationship or business affiliation with the seller of the property?
                                </Form.Label>
                                <Row>
                                    <Col className="pl-0" md={6} xs={12}>
                                        <Form.Group className="inputSection">
                                            <CheckGroup
                                                data={yesnooptions}
                                                value={familyRelationship}
                                                onChangeSelect={(event) => {
                                                    const e = { target: { value: event } }
                                                    this.props.changeDeclaration(e, who, 'familyrelationship')
                                                }}
                                                type="radio"
                                                direction="horizontal"
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col className="mt-4 px-0" md={12} xs={12}>
                        <Form.Group className="inputSection">
                            <Form.Label>
                                Are you borrowing any money for this real estate transaction?
                                <Tips
                                    content={
                                        <div>
                                            (e.g., money for your closing costs or down payment) or
                                            obtaining any money from another party, such as the seller or realtor, that you have not disclosed on this loan application?
                                        </div>
                                    }
                                />
                            </Form.Label>
                            <Row>
                                <Col className="pl-0" md={6} xs={12}>
                                    <Form.Group className="inputSection">
                                        <CheckGroup
                                            data={yesnooptions}
                                            value={downPaymentBorrowed}
                                            onChangeSelect={achangeDeclaration('downpaymentborrowed', who)}
                                            type="radio"
                                            direction="horizontal"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>
                {downPaymentBorrowed === 'yes' &&
                    <Row>
                        <Col className="mt-4 px-0" md={6} xs={12}>
                            <Form.Group controlId="moneyborrowed" className="m-0 p-0 text-left" >
                                <Form.Label>
                                    What is the amount of this money?
                                </Form.Label>
                                <div>
                                    <InputNumber
                                        prefix="$"
                                        size='large'
                                        style={{ width: "100%" }}
                                        value={moneyBorrowed}
                                        onChange={achangeDeclaration("moneyborrowed", who)}
                                    />
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col className="mt-4 px-0" md={12} xs={12}>
                        <Form.Group className="inputSection">
                            <Form.Label>
                                Have you or will you be applying for a mortgage loan on another property (not the property securing this loan) on or before closing this transaction that is not disclosed on this loan application?
                            </Form.Label>
                            <Row>
                                <Col className="pl-0" md={6} xs={12}>
                                    <Form.Group className="inputSection">
                                        <CheckGroup
                                            data={yesnooptions}
                                            value={applyingOnAnother}
                                            onChangeSelect={achangeDeclaration("applyingonanother", who)}
                                            type="radio"
                                            direction="horizontal"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className="mt-4 px-0" md={12} xs={12}>
                        <Form.Group className="inputSection">
                            <Form.Label>
                                Have you or will you be applying for any new credit on or before closing this loan that is not disclosed on this application?
                                <Tips content={"(E.g., installment loan, credit card, etc.)"} />
                            </Form.Label>
                            <Row>
                                <Col className="pl-0" md={6} xs={12}>
                                    <Form.Group className="inputSection">
                                        <CheckGroup
                                            data={yesnooptions}
                                            value={applyingNewCredit}
                                            onChangeSelect={achangeDeclaration("applyingnewcredit", who)}
                                            type="radio"
                                            direction="horizontal"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className="mt-4 px-0" md={12} xs={12}>
                        <Form.Group className="inputSection">
                            <Form.Label>
                                Will this property be subject to a lien that could take priority over the first mortgage lien?
                                <Tips content={"Such as a clean energy lien paid through your property taxes (e.g., the Property Assessed Clean Energy Program)"} />
                            </Form.Label>
                            <Row>
                                <Col className="pl-0" md={6} xs={12}>
                                    <Form.Group className="inputSection">
                                        <CheckGroup
                                            data={yesnooptions}
                                            value={cleanEnergyLien}
                                            onChangeSelect={(event) => {
                                                const e = { target: { value: event } }
                                                this.props.changeDeclaration(e, who, "cleanenergylien")
                                            }}
                                            type="radio"
                                            direction="horizontal"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Declarations)
