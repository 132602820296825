import { isEmpty } from 'lodash'
import { isNumericOrNumericString } from './String'
import * as com from '../Common'
import * as st from '../State'

/**
 * Get loan address from loan object
 * @param {object} loan - The loan object
 * @returns {string} The loan address
 */
export const getLoanAddress = (loan) => {
    const { address, county, state } = loan.state.application.property

    // Return TBD if both address and county are empty
    if (isEmpty(address) && isEmpty(county)) {
        return 'TBD'
    }

    // Return address if it exists, otherwise format county and state
    return isEmpty(address)
        ? `${com.removeCountySuffix(county)}, ${com.convertStateToAbbr(state)}`
        : address
}

/**
 * Skip the loan
 * @param {object} l - The loan object
 * @returns {boolean} Whether to skip the loan
 */
export function skipLoan(l) {
    if (l.correction !== 'none' && l.correction !== '') return true

    if (
        !(
            l.creditloantype.toLowerCase().indexOf('mortgage') >= 0 ||
            l.creditloantype.toLowerCase().indexOf('homeequity') >= 0 ||
            l.creditloantype.toLowerCase().indexOf('realestate') >= 0
        )
    )
        return true

    return false
}

/**
 * Count the subject mortgages
 * @param {string} purpose - The purpose of the loan
 * @param {array} loans - The loans
 * @param {array} otherliens - The other liens
 * @returns {number} The number of subject mortgages
 */
export function countSubjectMortgages(purpose, loans, otherliens) {
    if (purpose === st.POL_Purchase) return 0

    let counter = 0

    if (loans === null) {
        return 0
    }

    loans.forEach(l => {
        if (skipLoan(l)) return
        if (l.forsubjectproperty) {
            counter += 1
        }
    })

    if (!isEmpty(otherliens)) {
        otherliens.forEach(l => {
            counter += 1
        })
    }

    return counter
}

/**
 * Check loan is mortgage or not
 * @param {*} loan
 * @returns
 */
export function isMortgageLoan(loan) {
    if (!loan.creditloantype) return false
    const creditLoanType = loan.creditloantype.toLowerCase()
    return creditLoanType.indexOf('mortgage') >= 0 || creditLoanType.indexOf('homeequity') >= 0 || creditLoanType.indexOf('realestate') >= 0
}

/**
 * Calculate the balances and payments of the loans
 * @param {string} purpose - The purpose of the loan
 * @param {array} loans - The loans
 * @param {array} otherliens - The other liens
 * @returns {array} The balances and payments
 */
export function calculateBalancesAndPayments(purpose, loans, otherliens) {
    let firstmortgage = 0
    let firstmortgagebalance = 0
    let othermortgage = 0
    let otherbalance = 0
    let cashout = 0
    let loanmount = 0

    const subjectmortgages = countSubjectMortgages(purpose, loans, otherliens)

    if (isEmpty(loans)) {
        return [
            firstmortgage,
            firstmortgagebalance,
            othermortgage,
            otherbalance,
            cashout,
            loanmount,
        ]
    }

    const len = loans.length
    if (subjectmortgages === 1 && purpose !== st.POL_Purchase) {
        // mark the only mortgage as primary
        for (let index = 0; index < len; index++) {
            const loan = loans[index]
            if (loan.forsubjectproperty === true) {
                loan.isprimary = true
            }
        }
        if (otherliens != null) {
            for (let index = 0; index < otherliens.length; index++) {
                const loan = otherliens[index]
                loan.isprimary = true
                loan.satisfiedupon = 'withproceeds'
            }
        }
    }

    for (let index = 0; index < len; index++) {
        const loan = loans[index]
        if (com.isHomeloan(loan)) {
            if (loan.correction !== 'none' && loan.correction !== '') continue

            const mopayment = parseInt(loan.monthlypayment)
            const rembalance = parseInt(loan.remainingbalance)
            if (purpose !== st.POL_Purchase && loan.forsubjectproperty) {
                if (loan.satisfiedupon === 'withproceeds') {
                    if (loan.isprimary) {
                        firstmortgage += mopayment
                        firstmortgagebalance += rembalance
                    } else {
                        if (
                            purpose === st.POL_Refinance &&
                            loan.partoforiginalpurchase === true
                        ) {
                            othermortgage += mopayment
                            otherbalance += rembalance
                        } else {
                            othermortgage += mopayment
                            otherbalance += rembalance
                            cashout += rembalance
                        }
                    }
                }
            }

            if (purpose === st.POL_Purchase && loan.forprimaryresidence) {
                if (firstmortgagebalance === 0) {
                    firstmortgage += mopayment
                    firstmortgagebalance += rembalance
                } else {
                    othermortgage += mopayment
                    otherbalance += rembalance
                    cashout += rembalance
                }
            }
        }
    }

    if (!isEmpty(otherliens)) {
        for (let i = 0; i < otherliens.length; i++) {
            const lien = otherliens[i]
            if ('' !== lien.balance) {
                if (lien.isHELOC) {
                    if (lien.satisfiedupon !== 'withproceeds') continue
                }
                firstmortgagebalance += parseInt(lien.balance)
                firstmortgage += parseInt(lien.monthly)
            }
        }
    }

    switch (purpose) {
        case st.POL_Refinance:
            loanmount = firstmortgagebalance + otherbalance
            break
        case st.POL_Cashoutrefinance:
            loanmount = firstmortgagebalance + cashout
            break
        default:
            break
    }

    return [
        firstmortgage,
        firstmortgagebalance,
        othermortgage,
        otherbalance,
        cashout,
        loanmount,
    ]
}

/**
 * Get the loan amount
 * @param {string} purpose - The purpose of the loan
 * @param {string} mortgageapplied - The mortgage applied
 * @param {number} salesprice - The sales price
 * @param {number} downpayment - The down payment
 * @param {number} initialloanamount - The initial loan amount
 * @param {number} refinanceloanamount - The refinance loan amount
 * @param {number} loansize - The loan size
 * @returns {string} The loan amount
 */
export function getLoanAmount(
    purpose,
    mortgageapplied,
    salesprice,
    downpayment,
    initialloanamount,
    refinanceloanamount,
    loansize,
    hascontract,
    appraisal
) {
    if (mortgageapplied === st.TOM_Hardmoneyloan) {
        if (purpose === st.POL_Purchaserehab) {
            return isNumericOrNumericString(initialloanamount) ? `$ ${com.commaize(initialloanamount)}` : '$--'
        }
        if (purpose === st.POL_Refinance) {
            return isNumericOrNumericString(refinanceloanamount) ? `$ ${com.commaize(refinanceloanamount)}` : '$--'
        }
        if (purpose === st.POL_Cashoutrefinance) {
            return isNumericOrNumericString(refinanceloanamount) ? `$ ${com.commaize(refinanceloanamount)}` : '$--'
        }
        return '$--'
    } else {
        if (purpose === st.POL_Purchase) {
            const price = hascontract ? salesprice : appraisal
            const salesPrice = isNumericOrNumericString(price) ? Number(price) : 0
            const downPayment = isNumericOrNumericString(downpayment) ? Number(downpayment) : 0
            return `$ ${com.commaize(salesPrice - downPayment)}`
        }
        return isNumericOrNumericString(loansize) ? `$ ${com.commaize(loansize)}` : '$--'
    }
}
