import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { connect } from 'react-redux'
//import Modal from 'react-bootstrap/Modal'

import * as com from "./Common.js"

import {UpdateChangeProgressType} from "./Store/progress"
const mapStateToProps = (state) => {
    return {
        progress: state.progress,
        application: state.application,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateChangeProgressType: (step, stage) => {
        dispatch(UpdateChangeProgressType(step, stage))
    },      
});
/* not sure if we will need this

function DeleteProfile(props) {
    const nukeTheProfile = a => {
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/auth/deleteprofile', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
            body: ''
        }).then(
            response => {
                if (response.status !== 200) {
                    alert('Looks like there was a problem. Status Code: ' +
                        response.status);
                    window.document.dispatchEvent(new Event('reauthenticate'), "");

                    return;
                }
                // Examine the text in the response
                response.json().then(js => {
                    if (js.Status !== "OK") {
                        alert("Error: " + js.Text)
                        window.document.dispatchEvent(new Event('reauthenticate'), "");
                    } else {
                        sessionStorage.removeItem("ZeitroA")
                        sessionStorage.removeItem("state")
                        window.location.href="/"
                    }
                });
            }
        ).catch( (err) => {
            alert('Fetch Error :', err);
        });
    }

    return (
        <Modal      {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h4>Do you really want to delete your account?</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="py-5">
                <p>
                    We are sad to see you go! We hope you will find the right loan for yourself.
                </p>
                <p>
                    Upon the deletion of your profile all the data will be lost, including the 1003 form, and all the supplemental documentation.
          </p>
                <p>
                    This operation is irreversible. Are you sure you want to do this?
                </p>
                <Row className="mt-5">
                    <Col className="text-center">
                        <Button variant="success" onClick={props.handleClose}>No, I'll keep working on my loan</Button>
                    </Col>
                    <Col className="text-center">
                        <Button variant="danger" onClick={nukeTheProfile}>Yes, delete my data, and the account</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}
*/
class Profile extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.form1 = React.createRef();
        this.email = React.createRef();
        this.voice = React.createRef();
        this.sms = React.createRef();
        this.password = React.createRef();
        this.password1 = React.createRef();
        this.control = React.createRef();
        this.state = {
            validated: false,
            validated1: false,
            lastName: "",
            firstName: "",
            middleName: "",
            email: "",
            phone: "",
            byphone: false,
            byemail: false,
            bysms: false,
            password: "",
            password1: "",
            required: true,
            delete: false
        }
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/auth/getprofile', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                        window.document.dispatchEvent(new Event('reauthenticate'), "");
                        return;
                }
                // Examine the text in the response
                response.json().then(js => {
                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                        window.document.dispatchEvent(new Event('reauthenticate'), "");

                    } else {
                        js.Method = parseInt(js.Method)
                        if(js.Method === 0)
                            js.Method = 2; 
                        this.setState({ firstName: js.FirstName, middleName: js.MiddleName, lastName: js.LastName, phone: js.Phone, email: js.Email, auth: js.Auth })

                        this.setState({ byemail: !!(parseInt(js.Method) & 1), bysms: !!(parseInt(js.Method) & 2), byvoice: !!(parseInt(js.Method) & 4) })

                        this.setState({ required: (!this.state.byemail && !this.state.bysms && !this.state.byvoice) })
                        this.forceUpdate()
                    }
                });
            }
        ).catch( (err) => {
            console.log('Fetch Error :', err);
        });
    }
    componentDidMount() {
        this.props.updateChangeProgressType("profile")
    }
    sendProfile = () => {
        let tosend = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email.toLowerCase(),
            phone: this.state.phone,
            contact: (this.state.byemail ? 1 : 0) | (this.state.bysms ? 2 : 0) | (this.state.byvoice ? 4 : 0)
        }
        let token = sessionStorage.getItem("ZeitroA")

        fetch('/auth/updateprofile', {
            method: 'POST',
            body: JSON.stringify(tosend),
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + token,
            },

        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                        window.document.dispatchEvent(new Event('reauthenticate'), "");

                    return;
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                        window.document.dispatchEvent(new Event('reauthenticate'), "");

                    } else {
                        alert(js.Text)

                    }
                });
            }
        ).catch( (err) => {
            console.log('Fetch Error :', err);
        });
    }
    sendPassword = () => {
        let tosend = {
            auth: "password",
            password: this.state.password,
        }
        let token = sessionStorage.getItem("ZeitroA")

        fetch('/auth/updatepassword', {
            method: 'POST',
            body: JSON.stringify(tosend),
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + token,
            },
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        alert("Error: " + js.Text)

                    } else {
                        alert(js.Text)

                    }
                    this.password.current.value = ""
                    this.password1.current.value = ""
                });
            }
        ).catch( (err) => {
            console.log('Fetch Error :', err);
        });
    }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true;
    }
    isValidated1() {
        if (!this.form1.current.reportValidity()) {
            this.setState({ validated1: true })
            return false
        };
        return true;
    }
    handleSubmit = (event) => {

        if (this.form.current.reportValidity() === false) {

            event.preventDefault();
            this.setState({ validated: true })
            return false
        }
        event.preventDefault();
        this.setState({ validated: false })
        event.stopPropagation();

        this.sendProfile()
        return false
    }
    checkMethodValidity = () => {

        if (!this.state.byemail && !this.state.bysms && !this.state.byvoice) {
            this.setState({ validated: true })
            this.setState({ required: true })
            // this.control.current.setCustomValidity("need to select one")

            this.email.current.setCustomValidity("need to select one")
            this.sms.current.setCustomValidity("need to select one")
            this.voice.current.setCustomValidity("need to select one")

        } else {
            this.setState({ required: false })
            // this.control.current.setCustomValidity("")

            this.sms.current.setCustomValidity("")
            this.voice.current.setCustomValidity("")
            this.email.current.setCustomValidity("")
        }
    }

    handleSubmit1 = (event) => {
        this.checkPasswordValidity()
        if (this.form1.current.reportValidity() === false) {
            event.preventDefault();
            this.setState({ validated1: true })
            return false
        }
        event.preventDefault();
        this.setState({ validated1: false })
        event.stopPropagation();
        this.sendPassword()
        return false
    }
    checkPasswordValidity = () => {
        if (this.state.password !== this.state.password1) {
            this.password1.current.setCustomValidity("Passwords need to match")
        } else {
            this.password1.current.setCustomValidity("")
        }
    }
    onpassword = e => {

        this.setState({ password: e.target.value })
        // this is intentional!
        this["state"].password = e.target.value
        this.checkPasswordValidity()
    }
    onpassword1 = e => {
        this.setState({ password1: e.target.value })
         // this is intentional!
        this["state"].password1 = e.target.value
        this.checkPasswordValidity()
    }
    pass() {
        return (
            <div>
                <Row className="text-left mt-3 mb-1">
                    <Col className="text-left">
                        <Form.Group controlId="newpassword" className="text-left" >
                            <Form.Label className="text-left" >New Password</Form.Label>
                            <Form.Control isValid={false} size="sm"
                                required
                                ref={this.password}
                                name="newpassword"
                                type="password"
                                defaultValue=""
                                onChange={this.onpassword}
                                pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$"
                            />
                            
                            <Form.Control.Feedback type="invalid" >
                                Password should be 8-20 characters long with a number, mix of small and large letters.
                </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="text-left">
                        <Form.Group controlId="newpassword1" className="text-left" >
                            <Form.Label className="text-left" >Retype New Password</Form.Label>
                            <Form.Control isValid={false} size="sm"
                                required
                                ref={this.password1}
                                name="newpassword1"
                                type="password"
                                defaultValue=""
                                onChange={this.onpassword1}
                                pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$"
                            />
                            
                            <Form.Control.Feedback type="invalid" >
                                Please provide your password.
                </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="text-center pt-3 pb-3  ">

                    <Col className="text-center" xs="auto">
                        <Button variant="primary"  type="submit" className="text-center ml-3"  >&nbsp;&nbsp;Update&nbsp;&nbsp;</Button>
                    </Col>

                </Row>
            </div>
        )
    }
    changePhone = (e) => {
        let phone = com.fixPhoneInput(e.target.value)
        this.setState({ phone: phone })
    }
    changeEmail = (e) => {
        this.setState({ email: e.target.value })
    }
    changeFirstName = (e) => {
        this.setState({ firstName: e.target.value })
    }
    changeLastName = (e) => {
        this.setState({ lastName: e.target.value })
    }
    onbyemail = (e) => {
        this.setState({ byemail: e.target.checked })
         // this is intentional!
        this["state"].byemail = e.target.checked // that's a hack
        this.checkMethodValidity()
    }
    onbyvoice = (e) => {
        this.setState({ byvoice: e.target.checked })
         // this is intentional!
        this["state"].byvoice = e.target.checked
        this.checkMethodValidity()
    }
    onbysms = (e) => {
        this.setState({ bysms: e.target.checked })
         // this is intentional!
        this["state"].bysms = e.target.checked
        this.checkMethodValidity()
    }

    render() {
        /*
        let showDelete = e => {
            this.setState({ delete: true })
        }
        let hideDelete = e => {
            this.setState({ delete: false })
        }
        */

        return (
            <div className="text-center mt-0 px-5 mx-5">
                <Form ref={this.form} id="personalform" noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                    <Row >
                        <Col className="text-left">
                            <h4>Profile information</h4>
                        </Col>
                    </Row>
                    <Row className="mt-5 pt-2 border-top border-dark">
                        <Col className="text-left">
                            <Form.Group controlId="firstname" className="text-left" >
                                <Form.Label className="text-left" >First Name:</Form.Label>
                                <Form.Control isValid={false} size="sm"
                                    required
                                    name="firstname"
                                    type="text"
                                    defaultValue={this.state.firstName}
                                    onChange={this.changeFirstName}
                                    pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                />
                                
                                <Form.Control.Feedback type="invalid" >
                                    Please provide your first name.
                            </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col className="text-left">
                            <Form.Group controlId="lastname" className="text-left" >
                                <Form.Label className="text-left" >Last Name:</Form.Label>
                                <Form.Control isValid={false} size="sm"
                                    required
                                    name="lastname"
                                    type="text"
                                    defaultValue={this.state.lastName}
                                    onChange={this.changeLastName}
                                    pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                />
                                
                                <Form.Control.Feedback type="invalid" >
                                    Please provide your last name.
                            </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="text-center ">
                        <Col className="text-left">
                            <Form.Group controlId="email" className="text-left" >
                                <Form.Label className="text-left" >Email:</Form.Label>
                                <Form.Control isValid={false} size="sm"
                                    required
                                    name="email"
                                    type="text"
                                    defaultValue={this.state.email}
                                    onChange={this.changeEmail}
                                    pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$"
                                />
                                
                                <Form.Control.Feedback type="invalid" >
                                    Please provide your email.
                            </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col className="text-left">
                            <Form.Group controlId="contactphone" className="text-left" >
                                <Form.Label className="text-left" >Contact Phone</Form.Label>
                                <Form.Control isValid={false} size="sm"
                                    required
                                    name="contactphone"
                                    type="text"
                                    value={this.state.phone}
                                    placeholder="(###)-###-####"
                                    onChange={this.changePhone}
                                    pattern="^\([1-9][0-9]{2,2}\)-[0-9]{3,3}-[0-9]{4,4}$"
                                />
                                
                                <Form.Control.Feedback type="invalid" >
                                    Please provide your contact phone.
                            </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="text-center mt-1 pt-3 border-top border-dark">

                        <Col xs className="text-left">
                            <Form.Group controlId="contactmethod" className="text-left d-block text-nowrap " >
                                <Form.Label className="text-left" >Contact Method:</Form.Label>

                                
                                <Form.Control.Feedback type="invalid" >
                                    Please provide at least one contact method.
                            </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs className="text-left">

                            <Form.Group controlId="emailmethod" className="text-left d-block text-nowrap " >
                                <Form.Check type="checkbox" className="mx-1 px-0"
                                    defaultChecked={this.state.byemail}
                                    required={this.state.required}

                                    key={Math.random()}

                                    name="emailmethod"
                                    ref={this.email}
                                    onChange={this.onbyemail}
                                    label="Email:"></Form.Check>
                            </Form.Group>
                        </Col>

                        <Col xs className="text-left">

                            <Form.Group  controlId="smsmethod" className="text-left" >
                                <Form.Check type="checkbox" className="mx-1 px-0"
                                    defaultChecked={this.state.bysms}
                                    required={this.state.required}
                                    key={Math.random()}

                                    name="smsmethod"
                                    ref={this.sms}
                                    onChange={this.onbysms}
                                    label="SMS:"></Form.Check>
                            </Form.Group>


                        </Col>
                        <Col xs hidden className="text-left">

                            <Form.Group controlId="voicemethod" className="text-left d-block text-nowrap " >
                                <Form.Check type="checkbox" className="mx-1 px-0"
                                    defaultChecked={this.state.byvoice}
                                    required={this.state.required}
                                    key={Math.random()}
                                    ref={this.voice}
                                    name="voicemethod"

                                    onChange={this.onbyvoice}
                                    label="Voice Message"></Form.Check>
                            </Form.Group>
                        </Col>

                        <Col xs="auto" ></Col>

                    </Row>
                    <Row className="text-center pt-3 pb-3  border-bottom border-dark">
                        <Col className="text-center pl-3" xs="auto">
                            <Button type="submit" variant="primary" className="text-center ml-5"  >&nbsp;&nbsp;Update Profile&nbsp;&nbsp;</Button>
                        </Col>
                        <Col className="text-right " xs={2} >
                            {/* don't delete
                            <Button variant="danger" onClick={showDelete} className="text-center ml-3 r-5"  >&nbsp;&nbsp;Delete&nbsp;Account&nbsp;&nbsp;</Button>
                            <DeleteProfile
                                show={this.state.delete}
                                onHide={hideDelete}
                                handleClose={() => { this.setState({ delete: false }) }}
                            />
                        */}
                        </Col>

                    </Row>
                </Form>

                <Form ref={this.form1} id="asswordpform" noValidate validated={this.state.validated1} onSubmit={this.handleSubmit1}>

                    <Row className="text-center  mt-1 pt-3 ">
                        <Col className="text-left" ls="2">
                            Your authentication method: <b>{this.state.auth}</b>
                            {this.state.auth !== "password" ? <div><Button variant="primary"  className="ml-3">Change to password</Button> </div> : ""}
                        </Col>
                    </Row>

                    {(this.state.auth === "password" ? this.pass() : "")}



                </Form>

            </div>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Profile)
