import React, { Component } from "react"
import '../App.css'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import * as com from "../Common.js"
import * as st from "../State"
import { InputNumber, Select } from "antd"
import { DollarInput } from "../Common/components/DollarInput"

const mapStateToProps = (state) => {
    return {
        property: state.application.property,
        application: state.application,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateSameProperty: (event) => {
        dispatch(act.UpdateSameProperty(event.target.checked))
    },
    changeMainProperty: (value, verb) => {
        dispatch(act.ChangeMainProperty(value, verb))
    },
    changeMainPropertyAddress: (event) => {
        dispatch(act.ChangeMainPropertyAddress(event.target.value))
    },
    changeMainPropertySalesPrice: (event) => {
        console.log(event)
        dispatch(act.ChangeMainPropertySalesPrice(event))
    },
    changeMainPropertyAppraisal: (event) => {
        dispatch(act.ChangeMainPropertyAppraisal(event))
    },
})


export class PrequalProperty extends Component {
    constructor(props) {
        super(props)

        this.form = React.createRef()

        this.state = {
            loanLimits: {},
        }

        fetch("/data/loanlimits")
            .then((res) => {
                if (!res.ok) {
                    console.warn('Looks like there was a problem. Status Code: ', res.status);
                    return
                }
                return res.json()
            })
            .then((res) => {
                this.setState({ loanLimits: res })
            })
            .catch((err) => {
                console.error(err)
            })

        this.lastcompletion = 0
    }

    isoccupancyHidden = () => {
        return this.props.application.financingsameproperty && (this.props.application.property.purpose !== st.POL_Purchase)
    }

    lookupAddress = (providedAddress) => {
        const now = Date.now()

        if (now - this.lastcompletion < 500)
            return

        let addr = this.props.application.property.address
        if (typeof providedAddress !== "undefined") {
            addr = providedAddress
        }

        if (this.isoccupancyHidden()) {
            addr = this.sameaddress()
            this.props.changeMainPropertyAddress({ target: { value: addr } })

        }

        if ("" === addr)
            return

        const GOOGLE_MAPS_API_KEY = 'AIzaSyBQFO5uQtyZ5EsbTsNEK7HOhEA00KS3le8'
        const GEOCODE_API_URL = 'https://maps.googleapis.com/maps/api/geocode/json'
        const target = encodeURI(`${GEOCODE_API_URL}?address=${addr}&key=${GOOGLE_MAPS_API_KEY}`)

        fetch(target, {
            method: 'GET',
        }).then(
            response => {
                if (response.status !== 200) {
                    console.warn(`Looks like there was a problem. Status Code: ${response.status}`)
                    return
                }
                // Examine the text in the response
                response.json().then(js => {
                    if (js.status === "OK") {
                        this.onStreetAddressCompletion(js.results[0].address_components)
                    }
                })
            }
        ).catch((err) => {
            console.warn('Fetch Error :', err)
        })
    }

    onStreetAddressCompletion = address => {
        this.lastcompletion = Date.now()

        if ("undefined" !== typeof this.timer)
            clearTimeout(this.timer)

        const num = com.getSubaddress(address, "street_number", "long_name")
        const city = com.getSubaddress(address, "locality", "long_name")
        const zip = com.getSubaddress(address, "postal_code", "long_name")
        const street = com.getSubaddress(address, "route", "short_name")
        const county = com.getSubaddress(address, "administrative_area_level_2", "long_name")
        const longstate = com.getSubaddress(address, "administrative_area_level_1", "long_name")

        if (county !== "")
            this.props.changeMainProperty(county, "county")

        this.props.changeMainProperty(longstate, "state")
        this.props.changeMainProperty(city, "city")
        this.props.changeMainProperty(zip, "zipcode")

        const addr = [
            num,
            street,
            city,
            com.getSubaddress(address, "administrative_area_level_1", "short_name"),
            zip
        ].join(" ").replace(" ,", ",")

        if (num !== "")
            this.props.changeMainPropertyAddress({ target: { value: addr } })

        let sfx = ""
        let short = com.getSubaddress(address, "route", "short_name")
        let shorts = short.split(' ')

        if (com.streetsuffixes.includes(shorts[shorts.length - 1].toUpperCase())) {
            sfx = shorts.pop()

            if (typeof com.streetTypeAbbreviations[sfx.toLowerCase()] !== "undefined")
                sfx = com.streetTypeAbbreviations[sfx.toLowerCase()].toUpperCase()

            short = shorts.join(' ')
        }

        const s = shorts[0].toUpperCase()

        if (com.prefixes.includes(s)) {
            shorts = short.split(' ')
            short = shorts.slice(1, shorts.length).join(' ')
        }

        this.props.changeMainPropertyAddress({ target: { value: addr } })
    }

    updateRefinanceAddressWithResidencyAddress = () => {
        const { borrower, property } = this.props.application
        if (property.address === "") {
            this.props.changeMainProperty(borrower.presentaddressstreet, "address")
            this.lookupAddress(borrower.presentaddressstreet)
        }
    }

    lookupProperty = (num, prefix, short, sfx, city, zip) => {
        const body = {
            number: num,
            prefix: prefix,
            street: short,
            suffix: sfx,
            city: city,
            zip: zip
        }

        const token = sessionStorage.getItem("ZeitroA")
        this.props.changeMainProperty(false, "lookupsuccessful")
        fetch('/borrower/findproperty', {
            method: 'POST',
            headers: {
                Cache: "no-cache",
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json',
                "X-Borrower": this.props.borrowerid,
            },
            body: JSON.stringify(body)
        }).then(
            response => {
                if (response.status !== 200) {
                    alert('Looks like there was a problem. Status Code: ', response.status)
                    window.document.dispatchEvent(new Event('reauthenticate'), "")
                    return
                }

                // Examine the text in the response
                response.json().then(js => {
                    const { yearbuilt, lookupsuccessful } = this.props.property
                    if (yearbuilt === "" && js.Yearbuilt !== "" && !lookupsuccessful) {
                        this.props.changeMainPropertyYearBuilt({ target: { value: js.Yearbuilt } })
                        this.props.changeMainPropertyUnits({ target: { value: js.Units } })
                        this.props.changeMainProperty(true, "lookupsuccessful")
                        if (this.props.application.property.purpose !== st.POL_Purchase) {
                            const dt = js.Lastsale
                            const dat = dt.split('T')[0].split('-')
                            const d = parseInt(dat[0])
                            this.props.changeMainPropertyRefinanceYearAcquired({ target: { value: d } })
                            const cost = js.Lastsaleprice.toString()
                            this.props.changeMainPropertyRefinanceOriginalCost({ target: { value: cost } })
                        }
                    }
                })
            }
        ).catch((err) => {
            console.error('Fetch Error :', err)
        })
    }

    componentDidMount() {
        if (this.props.application.financingsameproperty === null) {
            this.props.updateSameProperty({ target: { checked: true } })
            this.updateRefinanceAddressWithResidencyAddress()
        }
        this.completion('property')
    }

    componentDidUpdate() {
        this.completion('property')
    }

    componentWillUnmount() {}

    completion = (stateKey) => {
        const keysToFilter = ['state', 'county']
        const { propertytype, occupancy } = this.props[stateKey]
        if (propertytype === "twotofourfamily") {
            keysToFilter.push('units')
        }
        if (occupancy === "investment" || (occupancy === "principal" && this.props.application[stateKey].propertytype === "twotofourfamily")) {
            keysToFilter.push('purchase.expectedgrossmonthlyrentalincome')
        }

        const filteredObj = keysToFilter.reduce((obj, key) => {
            if (key.includes('.')) {
                const nestedKeys = key.split('.')
                let value = this.props[stateKey]

                for (const nestedKey of nestedKeys) {
                    if (value.hasOwnProperty(nestedKey)) {
                        value = value[nestedKey]
                    } else {
                        value = undefined
                        break
                    }
                }

                if (value !== undefined) {
                    obj[nestedKeys[nestedKeys.length - 1]] = value
                }
            } else if (this.props[stateKey].hasOwnProperty(key)) {
                obj[key] = this.props[stateKey][key]
            }

            return obj
        }, {})

        const emptyNum = Object.keys(filteredObj).filter((key) => {
            const value = filteredObj[key]
            return value === '' || value === null || value === undefined
        }).length
        const progressValue = Math.round(((keysToFilter.length - emptyNum) / keysToFilter.length) * 100)
        sessionStorage.setItem("progressValue", progressValue)
    }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        }
        return true
    }

    render() {
        const changeMainPropertyExpectedRentalIncome = e => {
            com.touch()
            this.props.changeMainProperty(e, "purchase|expectedgrossmonthlyrentalincome")
        }

        const achangeMainPropertyState = e => {
            // this.stateref.current.setCustomValidity("")
            this.props.changeMainProperty(e, "state")
        }

        const changeMainPropertyCounty = e => {
            // this.countyref.current.setCustomValidity("")
            this.props.changeMainProperty(e, "county")
        }

        const getStates = () => {
            const options = []
            const keys = Object.keys(this.state.loanLimits)
            options.push(<option value=""></option>)
            keys.forEach((x) => {
                options.push(<option value={com.capitalize(x)}> {com.capitalize(x)}</option>)
            })
            return options
        }

        const getCounties = () => {
            const options = []
            options.push(<option value=""></option>)
            if (this.state.loanLimits[this.props.property.state.toUpperCase()]) {
                const keys = Object.keys(this.state.loanLimits[this.props.property.state.toUpperCase()])
                keys.forEach((x) => {
                    options.push(<option value={com.capitalize(x)}> {com.capitalize(x)}</option>)
                })
            }
            return options
        }

        const onBudget = e => {
            com.touch()
            this.props.changeMainPropertySalesPrice(e)
            this.props.changeMainPropertyAppraisal(e)
        }

        const { state, county, salesprice, occupancy, purchase } = this.props.property
        const { propertytype } = this.props.application.property

        return (
            <div className="text-left newInterviewPaneEmployment">
                <Row className="pt-3">
                    <Col md={4} className="p-0" >
                        <Form.Group controlId="units" className="text-left" >
                            <Form.Label className="text-left" >State</Form.Label>
                            <div>
                                <Select
                                    style={{ width: "100%", height: 44 }}
                                    onChange={(e) => achangeMainPropertyState(e)}
                                    value={state}>
                                    {getStates()}
                                </Select>
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={4} className="p-0" >
                        <Form.Group controlId="units" className="text-left" >
                            <Form.Label className="text-left" >County</Form.Label>
                            <div>
                                <Select
                                    style={{ width: "100%", height: 44 }}
                                    onChange={(e) => changeMainPropertyCounty(e)}
                                    value={county}>
                                    {getCounties()}
                                </Select>
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={4} className="p-0" >
                        <Form.Group controlId="units" className="text-left" >
                            <Form.Label>What is your maximum purchase price?</Form.Label>
                            <div>
                                <DollarInput
                                    className="w-100"
                                    value={salesprice === "" ? 500000 : parseInt(salesprice)}
                                    onChange={(e) => { onBudget(e) }}
                                ></DollarInput>
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={4} className="p-0" >
                    {(occupancy === "investment" || (occupancy === "principal" && propertytype === "twotofourfamily")) ?
                        <div style={{  }}>
                            <Form.Group controlId="expectedgrossmonthlyrentalincome" className="text-left" >
                                <Form.Label className="text-left" >Expected monthly gross rental income/month</Form.Label>
                                <div>
                                    <InputNumber
                                        style={{ width: "100%" }}
                                        size="large"
                                        prefix='$'
                                        onChange={(e) => changeMainPropertyExpectedRentalIncome(e)}
                                        value={purchase.expectedgrossmonthlyrentalincome}
                                    />
                                </div>
                                <Form.Control.Feedback type="invalid" >
                                    Please provide monthly gross rental income.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        : ""}
                    </Col>
                </Row>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PrequalProperty)
