import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
export default function Copyright() {
    return (
        <div>
            <hr />
            <div className="text-center py-2">© 2021 Zeitro. All rights reserved. </div>
        </div>
    )
};