import React, { Component } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { ResizeObserver } from '@juggle/resize-observer';
import {CustomerHeader} from './Landing/LandingMenu'
import Helmet from 'react-helmet'
import Modal from 'react-bootstrap/Modal'
import { Spinner } from "react-bootstrap";

export default class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();

        this.state = {
            email: "",
            modaltitle: "",
            show: false,
            message: "",
            validated: false,
            loading: false,
        };
        this.pattern = "^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$"
        if (this.state.signin)
            this.pattern = "^.+$"

        this.myObserver = new ResizeObserver(entries => {
            if (entries.length > 0) {
                let bod = entries[0]
    
                let _width = bod.contentRect.width
                let _height = bod.contentRect.height

                let height = Math.max( _height, document.body.clientHeight)
                let width = Math.max( _width, document.body.clientWidth)

                window.parent.postMessage({ source: "tools", payload: [width,  height] }, '*')
            
            }
        });            
        this.ref = React.createRef()
    }

    componentDidMount() {
        if (this.props.view === "bare" ) {            
            this.myObserver.observe( this.ref.current );
            if(window.drift) {            
                window.drift.hide()
                window.drift = null;
            }
        }
    }
    validateForm() {
        if (this.state.signin)
            return this.state.email.length > 0 && this.state.password.length > 8;
        else
            return this.state.firstName.length > 0 && this.state.lastName.length > 0 && this.state.email.length > 0 && this.state.password.length > 8
                && this.state.password === this.state.password1;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleClose = () => { this.setState({ show: false }) }


    reset = (event, data) => {
        
        let host = window.location.host
        let params = new URLSearchParams(window.location.search);
        let customerid = params.get("customerid")
   
        let tosend =
            JSON.stringify({ email: data.get("email").toLowerCase(),  customerid })

        this.setState({loading: true})

        fetch('/services/reset', {
            method: 'POST',
            body: tosend,
            headers: { 'Content-Type': 'application/json' },
        }).then(
            response => {
                if (response.status !== 200) {
                    this.setState({
                        message: 'Looks like there was a problem. Status Code: ' +
                            response.status, show: true
                    })
                    window.document.dispatchEvent(new Event('reauthenticate'), "");
                    this.setState({loading: false})
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {
                    this.setState({ message: js.Text, show: true, loading: false })
                });
            }
        ).catch((err) => {
            this.setState({loading: false})
            console.log('Fetch Error at /services/reset:', err);
        });
    }
    handleSubmit = event => {
        if (this.form.current.reportValidity() === false) {
            event.preventDefault();
            this.setState({ validated: true })
            return false
        }
        event.preventDefault();
        this.setState({ validated: false })
        event.stopPropagation();
        const data = new FormData(event.target);
        this.reset(event, data)
    }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true;
    }
    header = () => {

        return (

            <div className="ml-3 pt-3 nav-link text-center">
                <h5 className="mb-3">Recover Password</h5>
                Please enter your email, and click on the button.
                In a few minutes you will receive a password reset email <br />
                Click on the link in the email, and follow instructions in the resulting web page.
            </div>
        )

    }
    render() {
        return (
            <div ref={this.ref} className="Login m-0 p-0" >
                {this.props.view !== 'bare' ?
                    <CustomerHeader /> : "" }
                <Helmet>
                    <title>Zeitro Forgot Password</title>
                </Helmet>
                <Modal show={this.state.show} onHide={this.handleClose} onShow={this.onShow}>
                    <Modal.Header closeButton>
                        <Modal.Title>Password recovery</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.message}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>Close</Button>
                    </Modal.Footer>
                </Modal>
                <div className={this.props.view !== 'bare' ? "text-center mb-5 mt-5" : "text-center" } >
                    <div className="loginbox">
                    <div id="landing1" className=" container text-center my-4 pb-5" >
                        <div className="row">
                            <div className="col-sm mt-3  text-center">
                                { this.props.view !== 'bare' ? this.header() : ""}
                                <Form style={{fontSize:'0.8em'}}  ref={this.form} onSubmit={this.handleSubmit} noValidate validated={this.state.validated}>
                                    <Row className=" mt-3 pt-4 ">
                                        <Col className="text-left">
                                            <Form.Group controlId="email"  >
                                                <Form.Label className="text-left">Email:</Form.Label>
                                                <Form.Control
                                                    required
                                                    name="email"
                                                    size="sm"
                                                    autoFocus
                                                    autoComplete="email"
                                                    type="email"
                                                    defaultValue={this.state.email}
                                                    onChange={this.handleChange}
                                                    pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$"
                                                />
                                                
                                                <Form.Control.Feedback type="invalid" >
                                                    Please enter a valid email address!
                                            </Form.Control.Feedback>

                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className="pt-3 ">
                                        <Col className="text-center">
                                            <Button className="lpbutton"
                                                type="submit" variant="zeitro-primary"
                                            > {this.state.loading ? <Spinner animation='border' size="sm" /> : "Click to request password reset" }</Button>
                                        </Col>


                                    </Row>
                                    <Row className="mt-3 ">
                                    </Row>
                                </Form>
                            </div>

                        </div>
                    </div>
                    </div>
                </div> 
            </div>
        );
    }
}

