import React, { Component } from "react";
import Toast from 'react-bootstrap/Toast'

import * as com from './Common';

const deepcopy = require('deepcopy');

export default class Notifications extends Component{

  constructor(props){
    super(props)
    this.inteval = undefined
    this.state = {
      notifications: []
    }
  }

  componentDidMount = () => {
    this.getNotifications()
    this.inteval = setInterval(this.getNotifications, 30 * 1000)
  }

  componentWillUnmount = () => {
    clearInterval(this.inteval)
  }

  getNotifications = () =>{
    let token = sessionStorage.getItem("ZeitroA")
    fetch(`/borrower/getnotifications`, {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      }
    }).then(response => {
      if (response.status !== 200) {
        console.log('Looks like there was a problem. Status Code: ' + response.status);
        return;
      }
      response.json().then(js => {
        if(js.Notifications != null && js.Notifications.length !== 0){
          this.setState({notifications: []}, () => this.setState({notifications: js.Notifications}))
        }
      })
    })
  }

  handleNotificationClose = (notification, i) => {
    let body = {
      NotificationId: notification.id,
      Closed: new Date()
    }
    let token = sessionStorage.getItem("ZeitroA")
    fetch('/borrower/archivenotification', {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
        }
    }).then(response => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          return;
        } else {
          let stateCopy = deepcopy(this.state)
          stateCopy.notifications.splice(i, 1)
          this.setState({notifications: stateCopy.notifications})
        }
      })
  }


  render() {
    let closeNotification = (notification, i) => {
      return e => this.handleNotificationClose(notification, i)
    }
    
    return(
      <div className='notification-pannel-specific'>
        {this.state.notifications.length !== 0 &&
          this.state.notifications.map((n, i) =>(
            <Toast
            onClose = {closeNotification(n, i)}
            className='pop-up-notification'
            >
                <Toast.Header>
                    <img className="logo-notifications" src={com.logo ? `data:image/png;base64, ${com.logo}` : "/logo.png"} alt="zeitro" />
                    <p className="notification-header-text">Read me</p>
                </Toast.Header>
                <Toast.Body>{n.text}</Toast.Body>
            </Toast>
          ))
        }
      </div>
  )
  }
}