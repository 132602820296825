import * as com from '../../Common'

// Step types
const APPLICATION_STEP_TYPES = {
    PERSONAL: 'Personal',
    SUBJECT_PROPERTY: 'Subject property',
    INCOME: 'Income',
    FINANCE: 'Finance',
    DECLARATIONS: 'Declarations',
}

// Menu items
const STEP_MENU_ITEMS = {
    YOUR_INFO: 'Your info',
    CURRENT_RESIDENCE: 'Current residence',
    EMPLOYMENT: 'Employment',
    OTHER_INCOME: 'Other income',
    ASSETS: 'Assets',
    CREDIT_CHECK: 'Real estate',
    LIABILITIES: 'Liabilities',
    PROPERTY_MONEY: 'Property & money',
    FINANCES: 'Finances',
}

// Applicant step IDs
export const APPLICANT_STEP_IDS = {
    PERSONAL: 1,
    YOUR_INFO: com.stageToNumberApplication.applicant,
    CURRENT_RESIDENCE: com.stageToNumberApplication.residence,
    SUBJECT_PROPERTY: com.stageToNumberApplication.property,
    INCOME: 4.5,
    EMPLOYMENT: com.stageToNumberApplication.income,
    OTHER_INCOME: com.stageToNumberApplication.income + 1,
    FINANCE: 6.5,
    ASSETS: com.stageToNumberApplication.assets,
    CREDIT_CHECK: com.stageToNumberApplication.creditcheck,
    LIABILITIES: com.stageToNumberApplication.liabilities,
    DECLARATIONS: 9.5,
    PROPERTY_MONEY: com.stageToNumberApplication.propertymoney,
    FINANCES: com.stageToNumberApplication.finances,
}

// Co-borrower step IDs
export const CO_BORROWER_STEP_IDS = {
    PERSONAL: 11.5,
    YOUR_INFO: com.stageToNumberApplication.coborrower,
    CURRENT_RESIDENCE: com.stageToNumberApplication.coborrower + 1,
    SUBJECT_PROPERTY: com.stageToNumberApplication.coborrower + 2,
    INCOME: 14.5,
    EMPLOYMENT: com.stageToNumberApplication.coborrower + 3,
    OTHER_INCOME: com.stageToNumberApplication.coborrower + 4,
    FINANCE: 16.5,
    ASSETS: com.stageToNumberApplication.coborrower + 5,
    CREDIT_CHECK: com.stageToNumberApplication.coborrower + 6,
    LIABILITIES: com.stageToNumberApplication.coborrower + 7,
    DECLARATIONS: 19.5,
    PROPERTY_MONEY: com.stageToNumberApplication.coborrower + 8,
    FINANCES: com.stageToNumberApplication.coborrower + 9,
}

// Base paths
const INTERVIEW_BASE_PATH = '/application/interview/main'

// Applicant flow steps
export const APPLICANT_FLOW_STEPS = [
    {
        type: APPLICATION_STEP_TYPES.PERSONAL,
        id: APPLICANT_STEP_IDS.PERSONAL,
        path: `${INTERVIEW_BASE_PATH}/applicant`,
        list: [
            {
                menu: STEP_MENU_ITEMS.YOUR_INFO,
                path: `${INTERVIEW_BASE_PATH}/applicant`,
                id: APPLICANT_STEP_IDS.YOUR_INFO,
            },
            {
                menu: STEP_MENU_ITEMS.CURRENT_RESIDENCE,
                path: `${INTERVIEW_BASE_PATH}/residence`,
                id: APPLICANT_STEP_IDS.CURRENT_RESIDENCE,
            },
        ],
    },
    {
        type: APPLICATION_STEP_TYPES.SUBJECT_PROPERTY,
        path: `${INTERVIEW_BASE_PATH}/property`,
        id: APPLICANT_STEP_IDS.SUBJECT_PROPERTY,
    },
    {
        type: APPLICATION_STEP_TYPES.INCOME,
        path: `${INTERVIEW_BASE_PATH}/income`,
        id: APPLICANT_STEP_IDS.INCOME,
        list: [
            {
                menu: STEP_MENU_ITEMS.EMPLOYMENT,
                path: `${INTERVIEW_BASE_PATH}/income`,
                id: APPLICANT_STEP_IDS.EMPLOYMENT,
            },
            {
                menu: STEP_MENU_ITEMS.OTHER_INCOME,
                path: `${INTERVIEW_BASE_PATH}/${com.stageToNumberApplication.income + 1}`,
                id: APPLICANT_STEP_IDS.OTHER_INCOME,
            },
        ],
    },
    {
        type: APPLICATION_STEP_TYPES.FINANCE,
        path: `${INTERVIEW_BASE_PATH}/assets`,
        id: APPLICANT_STEP_IDS.FINANCE,
        list: [
            {
                menu: STEP_MENU_ITEMS.ASSETS,
                path: `${INTERVIEW_BASE_PATH}/assets`,
                id: APPLICANT_STEP_IDS.ASSETS,
            },
            {
                menu: STEP_MENU_ITEMS.CREDIT_CHECK,
                path: `${INTERVIEW_BASE_PATH}/creditcheck`,
                id: APPLICANT_STEP_IDS.CREDIT_CHECK,
            },
            {
                menu: STEP_MENU_ITEMS.LIABILITIES,
                path: `${INTERVIEW_BASE_PATH}/liabilities`,
                id: APPLICANT_STEP_IDS.LIABILITIES,
            },
        ],
    },
    {
        type: APPLICATION_STEP_TYPES.DECLARATIONS,
        path: `${INTERVIEW_BASE_PATH}/propertymoney`,
        id: APPLICANT_STEP_IDS.DECLARATIONS,
        list: [
            {
                menu: STEP_MENU_ITEMS.PROPERTY_MONEY,
                path: `${INTERVIEW_BASE_PATH}/propertymoney`,
                id: APPLICANT_STEP_IDS.PROPERTY_MONEY,
            },
            {
                menu: STEP_MENU_ITEMS.FINANCES,
                path: `${INTERVIEW_BASE_PATH}/finances`,
                id: APPLICANT_STEP_IDS.FINANCES,
            },
        ],
    },
]

// Co-borrower flow steps
export const CO_BORROWER_FLOW_STEPS = [
    {
        type: APPLICATION_STEP_TYPES.PERSONAL,
        id: CO_BORROWER_STEP_IDS.PERSONAL,
        path: `${INTERVIEW_BASE_PATH}/coborrower`,
        list: [
            {
                menu: STEP_MENU_ITEMS.YOUR_INFO,
                path: `${INTERVIEW_BASE_PATH}/coborrower`,
                id: CO_BORROWER_STEP_IDS.YOUR_INFO,
            },
            {
                menu: STEP_MENU_ITEMS.CURRENT_RESIDENCE,
                path: `${INTERVIEW_BASE_PATH}/${
                    com.stageToNumberApplication.coborrower + 1
                }`,
                id: CO_BORROWER_STEP_IDS.CURRENT_RESIDENCE,
            },
        ],
    },
    {
        type: APPLICATION_STEP_TYPES.SUBJECT_PROPERTY,
        path: `${INTERVIEW_BASE_PATH}/${com.stageToNumberApplication.coborrower + 2}`,
        id: CO_BORROWER_STEP_IDS.SUBJECT_PROPERTY,
    },
    {
        type: APPLICATION_STEP_TYPES.INCOME,
        path: `${INTERVIEW_BASE_PATH}/${com.stageToNumberApplication.coborrower + 3}`,
        id: CO_BORROWER_STEP_IDS.INCOME,
        list: [
            {
                menu: STEP_MENU_ITEMS.EMPLOYMENT,
                path: `${INTERVIEW_BASE_PATH}/${com.stageToNumberApplication.coborrower + 3}`,
                id: CO_BORROWER_STEP_IDS.EMPLOYMENT,
            },
            {
                menu: STEP_MENU_ITEMS.OTHER_INCOME,
                path: `${INTERVIEW_BASE_PATH}/${com.stageToNumberApplication.coborrower + 4}`,
                id: CO_BORROWER_STEP_IDS.OTHER_INCOME,
            },
        ],
    },
    {
        type: APPLICATION_STEP_TYPES.FINANCE,
        path: `${INTERVIEW_BASE_PATH}/${
            com.stageToNumberApplication.coborrower + 5
        }`,
        id: CO_BORROWER_STEP_IDS.FINANCE,
        list: [
            {
                menu: STEP_MENU_ITEMS.ASSETS,
                path: `${INTERVIEW_BASE_PATH}/${com.stageToNumberApplication.coborrower + 5}`,
                id: CO_BORROWER_STEP_IDS.ASSETS,
            },
            {
                menu: STEP_MENU_ITEMS.CREDIT_CHECK,
                path: `${INTERVIEW_BASE_PATH}/${com.stageToNumberApplication.coborrower + 6}`,
                id: CO_BORROWER_STEP_IDS.CREDIT_CHECK,
            },
            {
                menu: STEP_MENU_ITEMS.LIABILITIES,
                path: `${INTERVIEW_BASE_PATH}/${com.stageToNumberApplication.coborrower + 7}`,
                id: CO_BORROWER_STEP_IDS.LIABILITIES,
            },
        ],
    },
    {
        type: APPLICATION_STEP_TYPES.DECLARATIONS,
        path: `${INTERVIEW_BASE_PATH}/${com.stageToNumberApplication.coborrower + 8}`,
        id: CO_BORROWER_STEP_IDS.DECLARATIONS,
        list: [
            {
                menu: STEP_MENU_ITEMS.PROPERTY_MONEY,
                path: `${INTERVIEW_BASE_PATH}/${com.stageToNumberApplication.coborrower + 8}`,
                id: CO_BORROWER_STEP_IDS.PROPERTY_MONEY,
            },
            {
                menu: STEP_MENU_ITEMS.FINANCES,
                path: `${INTERVIEW_BASE_PATH}/${com.stageToNumberApplication.coborrower + 9}`,
                id: CO_BORROWER_STEP_IDS.FINANCES,
            },
        ],
    },
]
