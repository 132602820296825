import React, { Component } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import { Skeleton, Typography } from "antd"

import * as com from "../Common.js"
import {connect} from "react-redux"
import * as act from "../Store/actions"
import {UpdateChangeProgressType} from "../Store/progress"
import * as prgr from "../Store/progress"

import PreQualLetterHistory from "./Prequal/PrequalLetterHistory"
import {PrequalLetterHeader} from "./Prequal/PrequalLetterHeader"
import {NoPrequalLetters} from "./Prequal/NoPrequalLetters"

import {
  getFees,
  getDTI,
  getCitizenship,
  getOccupancy,
  getPropertyType,
  productReload,
} from "./ProductPicker"

const {Paragraph} = Typography

const getType = (card) => {
  if (card.Arm === null || card.Arm === 0) return "Fixed rate"
  return "ARM " + card.Arm.fixedperiod + "/6"
}

const mapStateToProps = (state) => {
  return {
    hascoborrower: state.application.hascoborrower,
    cohabiting: state.application.cohabiting,
    application: state.application,
    property: state.application.property,
    selection: state.application.selection,
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateChangeApplication: (application) => {
    dispatch(act.updateApplication(application));
  },
  updateChangeProgressType: (step, stage) => {
    dispatch(UpdateChangeProgressType(step, stage));
  },
  updateChangeProgress: (tab, index) => {
    dispatch(prgr.UpdateChangeProgress(tab, index));
  },
  updateChangeProgressType: (tab) => {
    dispatch(prgr.UpdateChangeProgressType(tab));
  },
  updateChangeVisitedStage: (step, stage) => {
    dispatch(prgr.UpdateChangeVisitedStage(step, stage));
  },
  changeMainPropertyPurpose: (val) => {
    dispatch(act.ChangeMainPropertyPurpose(val));
  },
  changeMainPropertyAttribute: (t, verb) => {
    dispatch(act.ChangeMainPropertyAttribute(t, verb));
  },
  changeBorrowerInfo: (event, who, verb) => {
    dispatch(act.ChangeBorrowerInfo(event.target.value, who, verb));
  },
  changeBorrowerInfoCheck: (event, who, verb) => {
    dispatch(act.ChangeBorrowerInfo(event.target.checked, who, verb));
  },
  changePhoneInfo: (input, who, verb) => {
    dispatch(act.ChangeBorrowerInfo(input, who, verb));
  },

  updatePreviousAddress: (event, who, verb, n) => {
    dispatch(act.UpdatePreviousAddress(event.target.value, who, verb, n));
  },
  addPreviousAddress: (event, who) => {
    dispatch(act.AddPreviousAddress(event.target.value, who));
  },
  removePreviousAddress: (event, who, n) => {
    dispatch(act.RemovePreviousAddress(event.target.value, who, n));
  },
  clearPreviousAddress: (event, who) => {
    dispatch(act.ClearPreviousAddress(event.target.value, who));
  },
  changeMainProperty: (value, verb) => {
    dispatch(act.ChangeMainProperty(value, verb));
  },
  changeMainPropertyUnits: (event) => {
    dispatch(act.ChangeMainPropertyUnits(event.target.value));
  },
  changeMainPropertyYearBuilt: (event) => {
    dispatch(act.ChangeMainPropertyYearBuilt(event.target.value));
  },
  changeMainPropertyRefinanceYearAcquired: (event) => {
    dispatch(act.ChangeMainPropertyRefinanceYearAcquired(event));
  },
  changeMainPropertyRefinanceOriginalCost: (event) => {
    dispatch(act.ChangeMainPropertyRefinanceOriginalCost(event));
  },
  updateApplicationAttribute: (input, verb) => {
    dispatch(act.UpdateApplicationAttribute(input, verb));
  },
  updateSelectField: (input, verb) => {
    dispatch(act.UpdateSelectField(input, verb));
  },
})

class PrequalLetter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      loadingPdf: false,
      purpose: "Purchase", // propagated
      loansize: 0.8 * com.safeParseInt(this.props.property.salesprice),
      show: false,
      download: "",
      url: "",
      showContactModal: false,
      showCreateNewModal: false,
      loadingCreateNew: false,
      showPrequalLetterHistory: false,
      automatedPreQualLetter: false,
    }
    this.prequalLetterHistoryRef = React.createRef()

    this.Loan25 = null
    this.Loan20 = null
    this.Loan10 = null
    // this.fhaLoan = null
    this.count = 0
    this.customerid = com.getCustomerId()
  }
  componentDidMount() {
    const token = com.getUserToken()
    const paths = window.location.pathname.split("/")
    const loanID = paths[paths.length - 1]
    fetch("/borrower/get_all_prequal_letters_for_loan", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
      },
      body: JSON.stringify({ loanid: loanID }),
    }).then((response) => {
      response.json().then((data) => {
        if (data.length === 0) {
          fetch("/borrower/check-loan-officer-feature", {
            method: "GET",
            headers: {
              Authorization: "Bearer " + token,
              Cache: "no-cache",
            },
          }).then((response) => {
            response.json().then((data) => {
              if (data.AutomatedPreQualLetter) {
                this.setState({ automatedPreQualLetter: true })
                this.getFees()
              } else {
                // If there are no prequal letters and automated prequal letter is disabled, show the history (which will be empty)
                this.setState({ showPrequalLetterHistory: true })
              }
            })
          })
        } else {
          // If there are prequal letters, show the history, regardless of whether the automated prequal letter is enabled
          console.log(
            `Found ${data.length} prequal letters, displaying history.`
          )
          this.setState({ showPrequalLetterHistory: true })
        }
      });
    });
  }
  getFees = getFees.bind(this)
  getDTI = getDTI.bind(this)
  getCitizenship = getCitizenship.bind(this)
  getOccupancy = getOccupancy.bind(this)
  getPropertyType = getPropertyType.bind(this)
  productReload = productReload.bind(this)

  getLTV = () => {
    const { loansize } = this.state
    const { salesprice } = this.props.property
    const ltv = (com.safeParseInt(loansize) * 100) / com.safeParseInt(salesprice)
    const cltv = ((com.safeParseInt(loansize) + com.safeParseInt(this.cltv)) * 100) / com.safeParseInt(salesprice)
    const hcltv = ((com.safeParseInt(loansize) + com.safeParseInt(this.hcltv)) * 100) / com.safeParseInt(salesprice)
    return [ltv, cltv, hcltv]
  }

  askedForAssistance = () => false

  postCheckingAllProducts = () => {
    const hasLetters = this.Loan10 != null || this.Loan20 != null || this.Loan25 != null
    const numberOfLetters = (this.Loan10 != null ? 1 : 0) + (this.Loan20 != null ? 1 : 0) + (this.Loan25 != null ? 1 : 0)
    console.log("After checking all possible preset products.")
    if (hasLetters) {
      console.log(`${numberOfLetters} prequal letter(s) are generated, saving.`)
      this.saveAllPrequals()
    } else {
      console.log("No prequal letters is generated, showing history")
      this.setState({ showPrequalLetterHistory: true })
    }
  }

  /**
   * This function is called when the product picker fails to find a suitable product.
   * It will try to generate a new loan size and get fees again.
   *
   * When this.count reaches 2, it will check if any prequal letters are generated.
   * If not, it will show the history.
   */
  failedProduct = () => {
    const { salesprice } = this.props.property
    switch (this.count) {
      case 0: {
        this.count = 1
        const newloan = parseInt(0.9 * com.safeParseInt(salesprice))
        this.setState({ loading: true, loansize: newloan }, () => { this.getFees() })
        break
      }
      case 1: {
        this.count = 2
        const newloan = parseInt(0.75 * com.safeParseInt(salesprice))
        this.setState({ loading: true, loansize: newloan }, () => { this.getFees() })
        break
      }
      case 2: {
        this.setState({ loading: false })
        this.postCheckingAllProducts()
        break
      }
      default:
        break
    }
  }

  savePrequal = (pr, ltv) => {
    const { salesprice } = this.props.property
    const { email, firstname, lastname } = this.props.application.borrower
    const loansize = parseInt(com.safeParseInt(salesprice) * ltv)
    const downpayment = parseInt(com.safeParseInt(salesprice) - loansize)
    this.props.updateSelectField(downpayment, "downpayment")
    this.props.updateSelectField(loansize, "loansize")

    const data = {
      price: salesprice.toString(),
      loan: loansize.toString(),
      loanType: `${pr.Term} year ${getType(pr)}`,
      isFHA: pr.FHAMIP != null,
      email: email.toLowerCase(),
      name: `${firstname} ${lastname}`,
    }

    this.setState({ loadingPdf: true });

    const token = com.getUserToken()
    fetch("/borrower/save_prequal", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache",
        "X-Borrower": this.props.borrowerid,
      },
      body: JSON.stringify(data),
    }).then((response) => {
      if (response.status === 200) {
        this.setState({ showPrequalLetterHistory: true });
        this.prequalLetterHistoryRef.current.fetchData();
      }
    })
  }

  saveAllPrequals = () => {
    if (this.Loan25 != null) this.savePrequal(this.Loan25, 0.75)
    if (this.Loan20 != null) this.savePrequal(this.Loan20, 0.8)
    if (this.Loan10 != null) this.savePrequal(this.Loan10, 0.9)
  }

  cullRate = (products) => {
    if (products.length === 0) return products

    const newproducts = []
    newproducts.push(products[0])

    let base_rate = products[0].base_rate
    for (let i = 1; i < products.length; i++) {
      if (products[i].base_rate !== base_rate) {
        newproducts.push(products[i]);
        base_rate = products[i].base_rate;
      }
    }
    return newproducts
  }

  compareRate = (a, b) => {
    if (a.base_rate !== b.base_rate) return b.base_rate - a.base_rate
    return b.closing_cost - a.closing_cost // take into account remaining credits
  }

  /**
   * This function is called when the product picker finds a suitable product.
   * It will try to generate a new loan size and get fees again.
   *
   * When this.count reaches 2, it will check if any prequal letters are generated.
   * If not, it will show the history.
   */
  processProducts = (products) => {
    this.setState({ loading: true })
    let rearranged = []
    products.forEach((product) => {
      const { propertytype } = this.props.application.property
      const lender = product.Lender

      product.ProductRatesCosts.forEach((pr) => {
        const commonproduct = pr.product
        const ausengine = pr.AUSEngine
        if (propertytype !== "mobilehome") {
          if (commonproduct.Term !== 30) return
        }

        pr.rateCosts.forEach((rate) => {
          let typ = commonproduct.Arm
          if (typ === null || typeof typ === "undefined") typ = 0
          else typ = typ.fixedperiod
          // filter out non-30 day locks
          if (rate.lockDay !== 30) return

          const pdkt = {
            Lender: lender,
            ...commonproduct,
            Aus: ausengine,
            ...rate,
          }
          rearranged.push(pdkt)
        })
      })
    })

    rearranged.sort(this.compareRate)
    rearranged = this.cullRate(rearranged)

    let getBest = (rearranged) => {
      let minAPR = 99.9;
      let maxClosingCost = 10000
      let loan = null
      for (const ln of rearranged) {
        if (ln.closing_cost < 0) continue
        if (ln.closing_cost < maxClosingCost && ln.APR < minAPR) {
          minAPR = ln.APR
          loan = ln
        }
      }

      if (loan !== null) {
        return loan
      }

      for (let ln of rearranged) {
        if (ln.closing_cost > 0) {
          return ln
        }
      }

      return rearranged[0]
    }

    const { loansize } = this.state
    const { salesprice } = this.props.property
    switch (this.count) {
      case 0: {
        this.Loan20 = getBest(rearranged)
        const downpayment = Math.ceil(com.safeParseInt(salesprice) - loansize)
        this.props.updateSelectField(downpayment, "downpayment")
        this.props.updateSelectField(loansize, "loansize")
        this.count = 1
        const newloan = parseInt(0.9 * com.safeParseInt(salesprice))
        this.setState({ loading: true, loansize: newloan }, () => { this.getFees() })
        break
      }
      case 1: {
        this.Loan10 = getBest(rearranged);
        this.count = 2;
        const downpayment = Math.ceil(com.safeParseInt(salesprice) - loansize)
        this.props.updateSelectField(downpayment, "downpayment")
        this.props.updateSelectField(loansize, "loansize")
        const newloan = parseInt(0.75 * com.safeParseInt(salesprice))
        this.setState({ loading: true, loansize: newloan }, () => { this.getFees() })
        break
      }
      case 2: {
        this.Loan25 = getBest(rearranged);
        const downpayment = Math.ceil(com.safeParseInt(salesprice) - loansize)
        this.props.updateSelectField(downpayment, "downpayment")
        this.props.updateSelectField(loansize, "loansize")
        this.setState({ loading: false })
        this.postCheckingAllProducts()
        break
      }
      default:
        break
    }
  }

  render = () => {
    const { showPrequalLetterHistory, automatedPreQualLetter } = this.state

    return (
      <div className="mb-4  text-left text-wrap">
        {showPrequalLetterHistory ? (
          <PreQualLetterHistory ref={this.prequalLetterHistoryRef} />
        ) : automatedPreQualLetter ? (
          <div style={{ padding: "20px" }}>
            <PrequalLetterHeader />
            <Paragraph style={{ marginTop: "20px", marginBottom: "20px" }}>
              We are checking if you qualify for a prequalification letter.
            </Paragraph>
            <Skeleton active />
          </div>
        ) : (
          <div style={{ padding: "20px" }}>
            <PrequalLetterHeader />
            <NoPrequalLetters />
          </div>
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(PrequalLetter)
