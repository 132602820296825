import React, { useState } from "react";
import "./PurchaseCalc";
import { Col, Row, Button, Container } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import BootstrapTable from "react-bootstrap-table-next";

import * as com from "../Common.js";

const termOptions = [
  { label: "30 Year", value: "fixed30" },
  { label: "25 Year", value: "fixed25" },
  { label: "20 Year", value: "fixed20" },
  { label: "15 Year", value: "fixed15" },
  { label: "10 Year", value: "fixed10" },
  { label: "ARM 5/6", value: "arm56" },
  { label: "ARM 7/6", value: "arm76" },
  { label: "ARM 10/6", value: "arm106" },
];

export default function RateReport(props) {
  let headerStyle = {
    backgroundColor: "#F7F8FC",
    border:"#F7F8FC",
  };

  const columns = [
    {
      dataField: "type",
      text: "",
      headerStyle: headerStyle
    },
    {
      dataField: "rate",
      text: "Base Rate/APR",
      headerStyle: headerStyle,

    },
    {
      dataField: "monthlypay",
      text: "Monthly Payment",
      headerStyle: headerStyle,

    },
    {
      dataField: "closingcosts",
      text: "Est. Closing Costs",
      headerStyle: headerStyle,

    },
  ];
  let selected = [];
  let alternatives = [];

  const isSelected = (x) => {
    for (let s of props.data.selected) {
      if (s.value == x) {
        return true;
      }
    }
    return false;
  };
  const getType = (x) => {
    for (let s of termOptions) {
      if (s.value == x) {
        return s.label;
      }
    }
    return false;
  };

  let m = {
    fixed30: props.data.fixed30,
    fixed25: props.data.fixed25,
    fixed20: props.data.fixed20,
    fixed15: props.data.fixed15,
    fixed10: props.data.fixed10,
    arm56: props.data.arm56,
    arm76: props.data.arm76,
    arm106: props.data.arm106,
  };
  for (let k in m) {
    if (m[k] != undefined) {
      let d = {
        type: getType(k),
        rate: m[k].base_rate.toFixed(3) + "/" + m[k].APR,
        monthlypay: "$" + com.commaize(m[k].monthly_payment),
        // closingcosts: "$" + com.commaize(m[k].closing_cost),
        closingcosts: "$" + com.commaize(m[k].lender_credit),
      };
      if (isSelected(k)) {
        selected.push(d);
      } else {
        alternatives.push(d);
      }
    }
  }

  return (
    <Container id="ratesReport" className="ratesReportPreviewContainer">
      <div className="previewTip">You will receive emails with following content</div>
      <div className="previewInfo">
          <div className="previewInfoText1">Loan Purpose</div>
          <div className="previewInfoText2">{props.data.Purpose.toLowerCase()}</div>
          <div className="previewInfoText1">Loan amount</div>
          <div className="previewInfoText2">${com.commaize(props.data.LoanAmount)}</div>
      </div>
      <div className="previewInfo">
          <div className="previewInfoText1">FICO</div>
          <div className="previewInfoText2">{props.data.Fico}</div>
          <div className="previewInfoText1">Occupancy</div>
          <div className="previewInfoText2">{props.data.Occupancy.toLowerCase()}</div>
      </div>
      <div className="previewInfo">
          <div className="previewInfoText1">Property Type</div>
          <div className="previewInfoText2">{props.data.PropertyType.replaceAll("_", " ").toLowerCase()}{" "}</div>
        <div className="previewInfoText1"></div>
        <div className="previewInfoText2"></div>
      </div>
      {/* <hr/> */}
      <div className="previewTitle mt-5">Selected Products</div>
      <div className="previewTable">
        <BootstrapTable
          keyField="type"
          data={selected}
          columns={columns}
          bordered={false} 
          rowStyle ={{backgroundColor:"transparent", border:"none"}}
          classes="table-borderless"
        />
      </div>
      {alternatives.length !== 0 && (
        <div className="previewTitle mt-5">Alternative Products</div>
      )}
      {alternatives.length !== 0 && (
        <div className="previewTable">
          <BootstrapTable
            bootstrap4
            keyField="type"
            data={alternatives}
            columns={columns}
            bordered={false} 
            rowStyle ={{backgroundColor:"transparent", border:"none"}}
            classes="table-borderless"
          />
        </div>
      )}

      {/* <hr /> */}
      <div className="previewClaimer">
        Disclaimer: Mortgage interest rate and cost is changing all the time.
        Your actual rate, payment, and costs could be different. Get an
        official Loan Estimate before choosing a loan.
      </div>
    </Container>
  );
}
