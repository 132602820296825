import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Typography } from 'antd'
import { isEmpty, isNumber } from 'lodash'
import 'bootstrap/dist/css/bootstrap.min.css'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import * as st from '../State'
import '../BorrowerApplication.css'
import './Overview.css'
import * as com from '../Common.js'
import { getLoans } from '../newContent/api/api'
import { camelToTitleCase, isNumericOrNumericString } from '../Tools/String'
import { getLoanAddress, getLoanAmount } from '../Tools/Loan'

const { Text } = Typography

const mapStateToProps = (state) => {
    return {
        hascoborrower: state.application.hascoborrower,
        cohabiting: state.application.cohabiting,
        application: state.application,
        property: state.application.property,
        selection: state.application.selection,
        progress: state.progress,
    }
}

const mapDispatchToProps = (dispatch) => ({})

class Overview extends Component {
    constructor(props) {
        super(props)

        this.state = {
            address: localStorage.getItem('LOAN_ADDRESS') || '',
            loanOfficerName: localStorage.getItem('LOAN_OFFICER_NAME') || '',
            currentStage: localStorage.getItem('LOAN_CURRENT_STAGE') || '',
            currentStatus: localStorage.getItem('LOAN_CURRENT_STATUS') || '',
        }
    }

    componentDidMount() {
        getLoans().then((res) => {
            if (res.Status === 'OK') {
                const loan = res.loans.find((item) => item.loanid === res.loanid)
                if (loan) {
                    loan.state = JSON.parse(loan.state)
                    loan.lostate = JSON.parse(loan.lostate)
                    const address = getLoanAddress(loan)
                    const { currentStage, currentStatus } = loan.state.application
                    this.setState({
                        loanOfficerName: loan.loanofficername,
                        address,
                        currentStage,
                        currentStatus,
                    })
                    localStorage.setItem('LOAN_OFFICER_NAME', loan.loanofficername)
                    localStorage.setItem('LOAN_CURRENT_STAGE', currentStage)
                    localStorage.setItem('LOAN_CURRENT_STATUS', currentStatus)
                    localStorage.setItem('LOAN_ADDRESS', address)
                }
            }
        }).catch((err) => {
            console.error('Get loans error: ', err)
        })
    }

    componentWillUnmount() {}

    purposeImage = () => {
        const { purpose } = this.props.property
        return purpose !== 'purchase' ? (
            <img src="/money.svg" alt="money" />
        ) : (
            <img src="/house.svg" alt="house" />
        )
    }

    stepStyle = (status) => ({
        display: 'flex',
        alignItems: 'center',
        height: '32px',
        padding: '0 12px',
        borderRadius: '8px',
        fontSize: '12px',
        fontWeight: 600,
        whiteSpace: 'nowrap',
        border: status === 'completed' ? '1px solid #325CEB' : '1px solid #D9D9D9',
        background: status === 'completed' ? '#E0E7FF' : '#FFFFFF',
        color: status === 'completed' ? '#325CEB' : '#222222',
    })

    render() {
        const { purpose, salesprice, hascontract, appraisal } = this.props.application.property
        const { mortgageapplied } = this.props.application.product
        const { initialloanamount, refinanceloanamount } = this.props.application.hardmoneyloan
        const { downpayment, paydown, cashout, loansize } = this.props.application.selection
        const { base_rate, APR, monthly_payment, closing_cost, points } = this.props.application.selection.selectedloan

        const isHardMoneyLoan = mortgageapplied === st.TOM_Hardmoneyloan

        const priceValue = purpose === st.POL_Purchase && hascontract ? salesprice : appraisal
        const paymentValue = purpose === st.POL_Purchase ? downpayment : purpose === st.POL_Refinance ? paydown : cashout

        const purposeText = () => {
            if (!purpose) return 'Loan Application'
            if (purpose === 'cashoutrefinance') return 'Cashout'
            if (isHardMoneyLoan) return 'Hard Money Loan'
            return `Home ${purpose.charAt(0).toUpperCase()}${purpose.slice(1)}`
        }

        const formatRateAndAPR = () => {
            const isValidRate = isNumber(base_rate)
            const isValidAPR = isNumber(APR)

            if (!isValidRate && !isValidAPR) return '---'
            if (!isValidRate) return `---/${APR}%`
            if (!isValidAPR) return `${base_rate}%/---`
            return `${base_rate}%/${APR}%`
        }

        const showMainStages = () => {
            const stages = [
                { number: 1, title: 'Application', completed: false },
                { number: 2, title: 'Processing', completed: false },
                { number: 3, title: 'Closing', completed: false }
            ]

            const stageNum = com.CurrentStage[this.state.currentStage]
            if (stageNum === 3) return stages.map(stage => ({ ...stage, completed: true }))
            if (stageNum > 3) return stages
            return stages.map(stage => ({
                ...stage,
                completed: stage.number <= stageNum - 1
            }))
        }

        const lasttouch = new Date(this.props.progress.lasttouch)

        const SummaryItem = ({ label, value }) => (
            <div>
                <div style={{ color: '#6E6E70', fontSize: '14px', fontWeight: 500, marginBottom: '6px' }}>
                    {label}
                </div>
                <div style={{ color: '#333333', fontSize: '18px', fontWeight: 500 }}>
                    {value}
                </div>
            </div>
        )

        const priceLabel = () => {
            if (purpose === st.POL_Purchase && hascontract) {
                return 'Purchase price'
            }
            return 'Estimated home value'
        }

        const paymentLabel = () => {
            if (purpose === st.POL_Purchase) {
                return 'Down payment'
            }
            return purpose === st.POL_Refinance ? 'Principal paydown' : 'Cash out'
        }

        return (
            <div className="text-left text-wrap newInterviewPaneEmployment">
                <Row>
                    <Col>
                        <div className="page-header">
                            <span className="page-header-primary">
                                Overview
                            </span>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md="auto" xs={12} className="mt-4">
                        <div className="home-card">
                            <div style={{ width: '30px', height: '30px' }}>
                                {this.purposeImage()}
                            </div>
                            <div style={{ marginLeft: '12px' }}>
                                <Text style={{ fontSize: '16px', fontWeight: 600, color: '#081265', marginBottom: '10px' }}>
                                    {purposeText().toUpperCase()}
                                </Text>
                                <Text style={{
                                    display: '-webkit-box',
                                    WebkitLineClamp: 2,
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    fontSize: '18px',
                                    fontWeight: 400,
                                    color: '#333333',
                                    marginBottom: '10px',
                                    height: '55px',
                                }}>
                                    {this.state.address}
                                </Text>
                                <Text style={{ display: 'block', fontSize: '16px', fontWeight: 400, color: '#6E6E70' }}>
                                    Last updated on{' '}
                                    {lasttouch.toLocaleDateString()}
                                </Text>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md className="mt-4" style={{ paddingLeft: '65px' }}>
                        <div className="loan-tracker">
                            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                <Text style={{ fontSize: '18px', fontWeight: 500, color: '#000000', marginBottom: '17px' }}>
                                    Loan tracker
                                </Text>

                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
                                    <span
                                        style={{
                                            color: '#62707C',
                                            fontSize: '16px',
                                            fontWeight: 500,
                                            marginRight: '30px',
                                            width: '100px',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        Main stage
                                    </span>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {showMainStages().map((step, index) => (
                                            <React.Fragment key={step.number}>
                                                <div style={this.stepStyle(step.title === this.state.currentStage ? 'completed' : 'pending')}>
                                                    <span>
                                                        {step.completed ? (
                                                            <img
                                                                src="/images/sidebaricons/blue-check-mark.svg"
                                                                alt="completed"
                                                                style={{ verticalAlign: 'middle' }}
                                                            />
                                                        ) : (
                                                            `${step.number}. `
                                                        )}
                                                        {step.title}
                                                    </span>
                                                </div>
                                                {index < showMainStages().length - 1 && (
                                                    <div
                                                        style={{
                                                            width: '26px',
                                                            height: '1px',
                                                            background:
                                                                '#D9D9D9',
                                                            margin: '0',
                                                            position:
                                                                'relative',
                                                            top: '1px',
                                                        }}
                                                    ></div>
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span
                                        style={{
                                            color: '#62707C',
                                            fontSize: '16px',
                                            fontWeight: 500,
                                            marginRight: '30px',
                                            width: '100px',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        Current status
                                    </span>
                                    <div
                                        style={{
                                            height: '32px',
                                            padding: '0 12px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            border: '1px solid #1630AC',
                                            background: '#FFFFFF',
                                            color: '#1630AC',
                                            borderRadius: '8px',
                                            fontSize: '12px',
                                            fontWeight: 600,
                                        }}
                                    >
                                        {camelToTitleCase(this.state.currentStatus)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div style={{ marginBottom: '30px' }}>
                            <Text style={{ fontSize: '18px', fontWeight: 500, color: '#6E6E70', marginBottom: '10px', display: 'block' }}>
                                Loan summary
                            </Text>
                            <div className="loan-summary-table">
                                <Row gutter={[48, 20]}>
                                    <Col span={6}>
                                        <SummaryItem label={priceLabel()} value={isNumericOrNumericString(priceValue) ? `$ ${com.commaize(priceValue)}` : '$--'} />
                                    </Col>
                                    <Col span={6}>
                                        <SummaryItem
                                            label="Loan amount"
                                            value={
                                                getLoanAmount(
                                                    purpose,
                                                    mortgageapplied,
                                                    salesprice,
                                                    downpayment,
                                                    initialloanamount,
                                                    refinanceloanamount,
                                                    loansize,
                                                    hascontract,
                                                    appraisal
                                                )
                                            }
                                        />
                                    </Col>
                                    <Col span={6}>
                                        <SummaryItem label={paymentLabel()} value={isNumericOrNumericString(paymentValue) ? `$ ${com.commaize(paymentValue)}` : '$--'} />
                                    </Col>
                                    <Col span={6}>
                                        <SummaryItem label="Loan officer" value={isEmpty(this.state.loanOfficerName) ? 'N/A' : this.state.loanOfficerName} />
                                    </Col>
                                </Row>

                                <div style={{ borderBottom: '1px solid #e5e7eb', margin: '20px 0' }}></div>

                                <Row gutter={[48, 20]}>
                                    <Col span={6}>
                                        <SummaryItem
                                            label="Rate/APR"
                                            value={formatRateAndAPR()}
                                        />
                                    </Col>
                                    <Col span={6}>
                                        <SummaryItem label="Mo.payment" value={isNumericOrNumericString(monthly_payment) ? `$ ${com.commaize(monthly_payment)}` : '$--'} />
                                    </Col>
                                    <Col span={6}>
                                        <SummaryItem label="Closing cost" value={isNumericOrNumericString(closing_cost) ? `$ ${com.commaize(closing_cost)}` : '$--'} />
                                    </Col>
                                    <Col span={6}>
                                        <SummaryItem label="Point" value={isNumericOrNumericString(points) ? `${points}%` : '---'} />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>

                {/* <Row>
                    <Col>
                        <div>
                            <Text style={{ fontSize: '18px', fontWeight: 500, color: '#6E6E70', marginBottom: '10px', display: 'block' }}>
                                Next step
                            </Text>
                            <div className="next-step" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                <div>
                                    <Text style={{ color: '#222222', fontSize: '18px', fontWeight: 600, marginBottom: '10px', display: 'block' }}>
                                        Let us know the address of the property you are going to purchase
                                    </Text>
                                    <Text style={{ color: '#6E6E70', fontSize: '16px', fontWeight: 400, display: 'block' }}>
                                        Finalize your mortgage application by entering the address of the property you wish to purchase. This step is essential for calculating your rate and moving forward.
                                    </Text>
                                </div>
                                <Button
                                    type="primary"
                                    size="large"
                                    style={{
                                        background: '#325CEB',
                                        padding: '13px 11px',
                                        fontSize: '16px',
                                        fontWeight: 600,
                                        color: '#FFFFFF',
                                        borderRadius: '8px',
                                        border: '1px solid #325CEB',
                                    }}
                                >
                                    Add property details
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row> */}
            </div>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
        Overview
    )
)
