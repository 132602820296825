import React, { Component } from "react";
import Helmet from "react-helmet";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { withRouter } from "react-router-dom";

class Tools extends Component {
  constructor(props) {
    super(props);
    let tool = this.props.match.params["tool"];
    let selected = "calculator";
    if (["calculator", "historic"].includes(tool)) selected = tool;

    this.state = { selected, height: "1200px" };

    this.tabs = {
      historic: ["historic", "Historic Rates", "/resources/historicalchart"],
      calculator: [
        "calculator",
        "Mortgage Calculator",
        "/resources/calculator",
      ],
      affordability: [
        "affordability",
        "Affordability Check",
        "/resources/affordability",
      ],
    };
    window.addEventListener(
      "message",
      (event) => {
        if (event.data.source === "tools") {
          console.log(event.data.payload);
          this.setState({ height: event.data.payload[1] });
          event.stopPropagation();
        }
      },
      false
    );
  }
  onClick = (e) => {
    this.setState({ selected: e.target.id });
  };
  componentDidMount() {}
  resizeIframe = (o) => {
    let obj = o.currentTarget;

    let height = obj.contentWindow.document.documentElement.scrollHeight + "px";
    console.log("height: ", height);
    this.setState({ height });
  };

  render() {
    let displayTabs = () => {
      let tabs = [];
      Object.keys(this.tabs).forEach((x) => {
        /* eslint-disable-next-line no-unused-vars */
        let [token, name, component] = this.tabs[x];
        if (x === this.state.selected)
          tabs.push(
            <div
              key={token}
              id={token}
              onClick={this.onClick}
              className=" ml-2 my-1 p-2 vertabactive"
            >
              {" "}
              {name}
            </div>
          );
        else
          tabs.push(
            <div
              key={token}
              id={token}
              onClick={this.onClick}
              className=" ml-2 my-1 p-2 vertab"
            >
              {name}
            </div>
          );
      });
      return tabs;
    };
    return (
      <div>
        <div className="toolwrap">
          <div className="pr-0 ">{displayTabs()}</div>
          <div
            className="p-0 mb-5 border mr-1 w-100"
            style={{ minHeight: "1900 px" }}
          >
            <Helmet>
              <title>Zeitro {this.tabs[this.state.selected][1]} </title>
            </Helmet>
            <iframe title="tools"
              scrolling="no"
              style={{
                border: "none",
                width: "100%",
                height: this.state.height,
              }}
              onLoad={this.resizeIframe}
              className="toolsframe"
              src={this.tabs[this.state.selected][2]}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Tools);
