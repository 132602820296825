import React, { ref, useState, useEffect, useRef, useMemo } from "react";
import { Helmet } from "react-helmet"
import { CustomerHeader } from "../Landing/LandingMenu";
import Affordability from "./Affordability";

const AffordabilityPage = () => {
    let searchparams = new URLSearchParams(window.location.search);
    const customerid = searchparams.get("customerid")
    const loid = searchparams.get("loid")

    console.log(customerid, loid)
    return (
        <div>
            <CustomerHeader customerid={customerid} loid={loid} />
            <Affordability />
        </div>
    )
}

export default AffordabilityPage;