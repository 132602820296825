const theme = {
  chart: {
    colors: {
      'Fixed 15 Year': '#4666FF',
      'Fixed 30 Year': '#FF4136',
      'ARM 10 Year':'#01FF70',
      'ARM 7 Year': '#F012BE',
      'ARM 5 Year': '#39CCCC',
    },
    margin: {
      left: 50,
      top: 5,
      bottom: 50,
      right: 5,
    },
  },
}

export default theme
