import React, { useState } from 'react';
import './FloatingLabelInput.css';

export default function FloatingLabelInput({ type = 'text',value, label, onChange, name, className, handleKeyPress }) {
  
  const safeHandleKeyPress = (e) => {
    if (handleKeyPress) {
      handleKeyPress(e);
    }
  };
  
  return (
    <div className={"floating-label-input-container " + className} >
      <input type={type} value={value} onChange={onChange} name={name} onKeyPress={safeHandleKeyPress} />
      <label className={value && 'filled'} >
        {label}
      </label>
    </div>
  );
}
