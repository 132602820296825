import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

import 'awesome-steps/dist/style.css'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import { Link } from 'react-router-dom'
import './App.css';
import Steps from 'awesome-steps'
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux'
import * as com from './Common'
import YourLoans from './YourLoans'
import * as act from "./Store/actions"
import {UpdateChangeProgressType} from "./Store/progress"
const mapStateToProps = (state) => {
    return {
        progress: state.progress,
        application: state.application,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateApplicationAttribute: (t, verb) => {
        dispatch(act.UpdateApplicationAttribute(t, verb))
    },
    updateChangeProgressType: (step, stage) => {
        dispatch(UpdateChangeProgressType(step, stage))
    },      
});

class Status extends Component {

    constructor(props) {
        super(props);
        this.state = {
            toasts: [],
            show: false,
            showprogress: false,
            redirect: "",
        }
        
/*
        this.state.toasts.push(
            <Toast>
                <Toast.Header>

                    <strong className="mr-auto">Zeitro</strong>
                    <small>30 mins ago</small>
                </Toast.Header>
                <Toast.Body>You have new Action Requests!</Toast.Body>
            </Toast>
        )

        this.state.toasts.push(
            <Toast>
                <Toast.Header>

                    <strong className="mr-auto">Zeitro</strong>
                    <small>11 mins ago</small>
                </Toast.Header>
                <Toast.Body>Please sign the documents sent to you by the lender !</Toast.Body>
            </Toast>
        )
    */
    }

    componentDidMount() {
        this.props.updateChangeProgressType("status")
    }
    newApplication = () => {
        let token = sessionStorage.getItem("ZeitroA")        
        this.setState({showprogress: true})
        fetch('/data/newapplication', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
            body: ''
        }).then(
            response => {
                if (response.status !== 200) {
                    alert( 'Looks like there was a problem. Status Code: ' +
                            response.status)
                    window.document.dispatchEvent(new Event('reauthenticate'), "");
                    this.setState({showprogress: false})
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {
                    this.setState({ showprogress: false })
                    if(js.Status !== "OK") {
                        alert(js.Text)
                    } else {
                        sessionStorage.setItem("state", '{}')
                        sessionStorage.removeItem("originalstate")
                        window.location.href = "/app/interview/main/start"
                        //this.props.updateApplicationAttribute ("interviewstarted", "status") 
                   

                        //this.setState({redirect: <Redirect to='/app/interview/main/start' /> })
                    }
                    this.setState({showprogress: false})                    
                });
            }
        ).catch((err) => {
            alert('Fetch Error :', err);
            this.setState({showprogress: false})
        });        
    }
    withdraw = () => {
        let token = sessionStorage.getItem("ZeitroA")        
        this.setState({showprogress: true})
        fetch('/data/withdraw', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
            body: ''
        }).then(
            response => {
                if (response.status !== 200) {
                    alert( 'Looks like there was a problem. Status Code: ' +
                            response.status)
                    window.document.dispatchEvent(new Event('reauthenticate'), "");
                    this.setState({showprogress: false})
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {
                    this.setState({ showprogress: false })
                    if(js.Status !== "OK") {
                        alert(js.Text)
                    } else {
                        this.props.updateApplicationAttribute ("applicationwithdrawn", "status") 
                    }
                    this.setState({showprogress: false})                    
                });
            }
        ).catch((err) => {
            alert('Fetch Error :', err);
            this.setState({showprogress: false})
        });
    }
    render() {
        let current = 0


        current = com.getNumericalStatus(this.props.application.status)


        let handleClose = () => {
            this.setState({show: false})
        }
        let handleWithdrawal = () => {
            this.setState({show: false})
            this.withdraw()
        }
        let showWarning = () => {
            this.setState({show: true})
        }


        if( ['fundsreleased', 'applicationwithdrawn', 'applicationabandoned',
        'aplicationrejected', "borrowerwithdrew", "loancanceled", "loandenied"].includes(this.props.application.status )) {

            return (
                <div>
                    {this.state.redirect}
                    <div className="my-5">&nbsp;</div>                    
                    <h2 className="mt-5 ">Your loan status: {com.splitStatusByWords(this.props.application.status)}</h2>
                    <div className="my-2">&nbsp;</div>
                    { ['applicationabandoned', 'aplicationrejected', "borrowerwithdrew", "loancanceled", "loandenied"].includes(this.props.application.status ) ?
                   <h5> Please <Link to="/app/contact">contact your loan officer</Link> for details</h5> 
                : ""}
                    <h5 className="my-5">If you want to start new application from scratch, please <Button variant="primary" size="sm" className="lpbutton" onClick={this.newApplication}> click here</Button></h5>
                    <YourLoans header={"oldloans"}/>
                </div>
            )
        }
        return (
            <div className="text-left mx-3">
                {this.state.redirect}
                <Modal show={this.state.show} onHide={handleClose} >
                    <Modal.Header closeButton>
                        <Modal.Title>{'Withrdaw Loan Application'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to withdraw your loan application? This operation is irreversible, your loan officer will be notified.
                       { current >= 4 ? "If your application is already submitted to the lender, the lender will also be notified." : "" }
                    </Modal.Body>
                    <Modal.Footer>
                    <Row className="modalfooter">
                            <Col>
                                <Button variant="primary" size="sm" className="lpbutton" onClick={handleClose} >Cancel</Button>
                            </Col>
                            <Col>
                                <Button variant="danger" size="sm" className="lpbutton text-nowrap" style={{ fontSize: '1.0em' }} onClick={handleWithdrawal} >Withdraw Application</Button>
                            </Col>
                    </Row>
                    </Modal.Footer>
                </Modal>         


                <h2 className=" heading">
                <Spinner className="mb-1 mr-1 position-relative" size="sm" variant="primary" animation={ this.state.showprogress ? "border" : ""}/>  
                    Your Loan Application Status
                </h2>
                <Steps  id="explainsteps" direction="vertical" current={current + 1} className="mt-4 ml-3">
                    <Steps.Step title="Loan application started" description="You have started filling out the loan application" />
                    <Steps.Step title="Loan product preliminary identified" description="You have identified the kind of loan product that fits your needs." />
                    <Steps.Step title="Interview finished" description="You have entered all the information for the loan application." />
                    <Steps.Step title="Loan application submitted" description="You have submitted you loan application to your mortgage specialist at Zeitro" />
                    <Steps.Step title="Supplemental documentation requested" description="Zeitro sends you requests for supplemental documentation that is necessary for submitting your application to a lender" />
                    <Steps.Step title="Application submitted to a lender" description="Zeitro submitted your application to a lender" />
                    <Steps.Step title="Loan is conditionally approved" description="The lender has approved your application, but requested additional information" />
                    <Steps.Step title="Loan is final-approved and clear to close" description="The lender has approved your application" />
                    <Steps.Step title="Final documents drawn and submitted to Title/Escrow" description="Your documentation is at the Title or Escrow company." />
                    <Steps.Step title="Final documents signed, and back to the lender" description="The lender received all the final documents and signatures." />
                    <Steps.Step title="The funds have been released" description="The lender released the funds to escrow. Your loan is done! Coongratulations" />
                </Steps>
                <div
                    style={{
                        position: 'absolute',
                        top: "10px",
                        right: "10px",
                    }}
                >
                    {this.state.toasts}
                </div>
                <div className="mt-5 viewport mb-5">
                    Click on the button if you like to withdraw your loan  application

                    <div className="mt-2">
                    <Button size="sm"  onClick={showWarning} className="lpbutton"> Withdraw Loan Application </Button>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Status))
