import React, { Component } from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Spinner from 'react-bootstrap/Spinner'
import * as com from "./Common.js"
import { Redirect } from 'react-router-dom'
import { withRouter } from "react-router-dom";

class ActionRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect:  <Spinner className="text-center"
            animation="border" role="status" />,
            loading: true,
        }

        this.customerid = this.props.match.params["schema"]
        this.hex = this.props.match.params["hex"]

        // this.target='/app/tasks/'+this.hex
        // if(isMobileOnly) {
        //     // TODO - not sure this will work!
        //     let schema  = this.props.match.params["schema"]
        //     let mobile = com.hex2ascii(this.props.match.params["mobile"] )

        //     let newloc = mobile + "/d/"+this.hex + "/" + schema
        //     window.location = newloc

        //     return
        // }

        com.checkSession(this.onSessionExists, this.onNoSession)
    }
    onSessionExists = () => {
        // this.setState({redirect: <Redirect to={'/app/tasks/' + this.hex} />})
        this.setState({redirect: <Redirect to={'/app/tasks'} />})

    }
    onNoSession = () => {

        fetch('/data/companycustomization', {
            headers: {
                'X-CustomerId': this.customerid,
            }
        })
            .then(async response => {
                if (!response.ok) {
                    this.setState({loading: false})
                    this.setState({loading: false})
                    return;
                }
                response.json().then(json => {
                    this.setState({loading: false})
                    // let rehex = com.ascii2hex('/app/tasks/' + this.hex)
                    let rehex = com.ascii2hex('/app/tasks')
                    window.location.href = json.Paths["website"] + '/login?where=' + rehex
                })
            })
    }
    render() {
        return (
        <div className="mt-5 pt-5 mb-5 text-center w-100">
            {this.state.redirect}
        </div>
        )
    }
}

export default withRouter(ActionRequest)
