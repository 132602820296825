import React, { Component } from "react";
import "./Recaptcha.css"


export default class Recaptcha extends Component {
    componentDidMount() {
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;
        script.onload = () => this.scriptLoaded();
      
        document.body.appendChild(script);
      }
      scriptLoaded = () => {

      }
    callback = (a) => {
        //alert(a)
    }
    render() {

        return (
            <div id="g-recaptcha" className="g-recaptcha text-left" data-sitekey="6Lfss-UUAAAAAAZJ2MPSLAbpCoVoUuLWBCWtfLun"></div>
        )   
    }
}

export class RecaptchaV3 extends Component {
    componentDidMount() {
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js?render=6Lfn1C0kAAAAAG-A7Vzl6kxD75yhVsm2wpQnVc3s";
        script.async = true;
        script.onload = () => this.scriptLoaded();
      
        document.body.appendChild(script);
      }
      scriptLoaded = () => {
        // window.grecaptcha.ready(() => {
        //     window.grecaptcha.execute(RecaptchaV3SiteKey, { action: "submit" }).then((token) => {
        //       localStorage.setItem("recaptchaToken", token)
        //     })
        //   })
      }
    callback = (a) => {
        //alert(a)
    }
    render() {

        return (
            <div></div>
        )   
    }
}

export const RecaptchaV3SiteKey = "6Lfn1C0kAAAAAG-A7Vzl6kxD75yhVsm2wpQnVc3s"
export const RecaptchaV3SecretKey = "6Lfn1C0kAAAAAJAMzahqG7EelAgcyD-DgvOmTNah"
