import React, { Component } from "react";
import '@fortawesome/fontawesome-free/css/fontawesome.min.css'
import PropTypes from 'prop-types'


export default class HomeButton extends Component {

  static contextTypes = {
    router: PropTypes.object
  }
    constructor(props) {
        super(props);
        this.df_stl = "btn btn-info  btn-circle btn-xl"
        this.state = {
            stl: this.df_stl,
            redirect: ""
        };
    } 

    click = () => {
      // this does not reload the page
      window.location.hash=""
      window.scrollTo(0,0);
    }

    listenScrollEvent = e => {
        if (window.pageYOffset < 500) {
          this.setState({stl: this.df_stl + " d-none"})
        } else {
          this.setState({stl:  this.df_stl})
        }
      }
    
      componentDidMount() {
        window.addEventListener('scroll', this.listenScrollEvent)
        this.listenScrollEvent()
      }
      componentWillUnmount() {
        window.removeEventListener('scroll', this.listenScrollEvent)
      }

    render() {
        return (
          <div>
            <button id="homebutton" onClick={this.click} type="button" className={ this.state.stl} ><i 
            style={{position:'absolute', left:'10px', top:'7px'}} className="fa fa-home"></i>
            </button>
            {this.state.redirect}
            </div>
        )
    }
}