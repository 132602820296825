import styled from 'styled-components'

const Container = styled.span`
  display: flex;
  width: 100%;
  height: calc(0.625 * 100vw);
  align-items: center;
  justify-content: center;
`

const LoadingMessage = (props) => {
  return (
    <Container {...props} style={{backgroundColor:  '#f7f9fc'}}>
      <img className='loader' alt='spinner' src='/logo.png'></img>
    </Container>
  )
}

export default LoadingMessage
