import React, { Component } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'
import Spinner from "react-bootstrap/Spinner";
import * as com from './Common'
import Helmet from 'react-helmet'
import Modal from 'react-bootstrap/Modal'
import { Link } from 'react-router-dom'
import { State } from './State'
import Recaptcha from './Recaptcha'
import zxcvbn from "zxcvbn"
import { CustomerHeader } from "./Landing/LandingMenu";
import "./AgentBorrowerSignUpPage.css"
import { tooltip } from './Common/Tooltip'
import { withRouter } from "react-router-dom";


export const loadPasswordCheck = (callback) => {
    const existingScript = document.getElementById('zxcvbn');

    if (!existingScript) {
        const script = document.createElement('script');
        script.src = '/js/zxcvbn.js';
        script.id = 'zxcvbn';
        document.body.appendChild(script);

        script.onload = () => {
            if (callback) callback();
        };
    }

    if (existingScript && callback) callback();
};

export default class AgentBorrowerSignUpPage extends Component {
    constructor(props) {

        super(props);
        this.form = React.createRef();
        this.password = React.createRef();
        this.password1 = React.createRef();
        this.captcha = React.createRef();
        this.timer = null;
        this.buttonpressed = false;

        this.customerid = "individualcustomers"
        let searchparams = new URLSearchParams(window.location.search);
        this.loid = searchparams.get("loid")


        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            password1: "",
            signin: false,
            show: false,
            message: "",
            validated: false,
            captchasolved: true,
            passwordCheckReady: false,
            text: "",
            bgcolor: "rgb(232,235,239)",
            text2: "",
            bgcolor2: "rgb(232,235,239)",
            signingup: false,
            loid: props.agentID,
            LoanOfficerID: props.agentID,
            sendingEmail:false,
            emailSentFinished:false,
            signupsucceed: false,
        };

        this.local = window.location.hostname === "localhost"

        this.pattern = "^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$"
        if (this.state.signin)
            this.pattern = "^.+$"

            this.myObserver = new ResizeObserver(entries => {
                if (entries.length > 0) {
                    let bod = entries[0]
    
                    let _width = bod.contentRect.width
                    let _height = bod.contentRect.height
    
                    let height = Math.max(_height, document.body.clientHeight, 380)
                    if (this.state.signin) {
                        height = 500
                    }
                    let width = Math.max(_width, document.body.clientWidth)
    
                    window.parent.postMessage({ source: "tools", payload: [width, height] }, '*')
                }
            });

        this.ref = React.createRef()

        if (window.drift) {
            window.drift.hide()
            window.drift = null
        }
        
    }

    validateForm() {
        if (this.state.signin)
            return this.state.email.length > 0 && this.state.password.length > 8;
        else
            return this.state.firstName.length > 0 && this.state.lastName.length > 0 && this.state.email.length > 0 && this.state.password.length > 8
                && this.state.password === this.state.password1;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }
    validatePassword = () => {

    }
    onPassword = e => {
        this.setState({ password: e.target.value })
        if (this.state.signin)
            return

        if (this.state.passwordCheckReady) {

            let ret = zxcvbn(e.target.value)

            switch (ret.score) {
                case 0:
                    this.setState({ text: "Insecure", bgcolor: "rgb(255,64,64)" })
                    break;
                case 1:
                    this.setState({ text: "Too Weak", bgcolor: "rgb(255,128,64)" })
                    break;
                case 2:
                    this.setState({ text: "Breakable", bgcolor: "rgb(160,160,64)" })
                    break;
                case 3:
                    this.setState({ text: "Good enough", bgcolor: "rgb(128,180,64)" })
                    break;
                case 4:
                    this.setState({ text: "Strong!!!", bgcolor: "rgb(64,180,64)" })
                    break;
                default:
                    break;
            }
            if (this.state.password1 && this.state.password1 !== "") {
                if (e.target.value !== this.state.password1) {
                    this.password1.current.setCustomValidity("Passwords should match!")
                    this.setState({ text2: "Mismatch", bgcolor2: "rgb(255,64,64)" })
                } else {
                    this.setState({ text2: "Identical!", bgcolor2: "rgb(64,180,64)" })
                }
            }
            if (ret.score < 3) {
                this.password.current.setCustomValidity("Password is too weak. Try longer mix of letters, numbers and special characters")
            } else {
                this.password.current.setCustomValidity("")
            }
        }
    }
    onPassword1 = e => {
        this.setState({ password1: e.target.value })
        if (e.target.value !== this.state.password) {
            this.setState({ validated: true })
            this.password1.current.setCustomValidity("Passwords should match!")
            this.setState({ text2: "Mismatch", bgcolor2: "rgb(255,64,64)" })
        } else {
            this.password1.current.setCustomValidity("")
            this.setState({ text2: "Identical!", bgcolor2: "rgb(64,180,64)" })
        }
    }

    checkCaptcha = (event) => {
        if (!window.grecaptcha || !this.buttonpressed)
            return false;

        let captcha = this.local ? "XXX" : window.grecaptcha.getResponse()
        if (!captcha || captcha === "") {
            this.setState({ captchasolved: false })
            this["state"]["captchasolved"] = false
            this.setState({ validated: true })
            this.captcha.current.setCustomValidity("Please solve the Captcha")
            if (event) {
                event.preventDefault();
                event.stopPropagation();
            }
            this.captcha.current.required = true
            return false
        } else {

            this.setState({ captchasolved: true })
            this["state"].captchasolved = true
            this.captcha.current.setCustomValidity("")
            this.captcha.current.required = false
        }
        return true;
    }

    componentDidMount() {
        loadPasswordCheck(() => {
            // Work to do after the library loads.
            this.setState({ passwordCheckReady: true });
        });
        this.timer = window.setInterval(this.checkCaptcha, 1000)

        this.myObserver.observe(this.ref.current);
        if (window.drift) {
            window.drift.hide()
            window.drift = null;
        }
        
        
    }

    componentWillUnmount() {
        window.clearInterval(this.timer)
    }

    handleClose = () => { this.setState({ show: false }) }

    signup = (event, data) => {
        let captcha = (this.local) ? "XXXX" : window.grecaptcha.getResponse()
        if (!captcha || "" === captcha) {
            this.setState({ captchasolved: false })
            this["state"].captchasolved = false

            this.setState({ validated: true })
            this.captcha.current.setCustomValidity("Please solve the Captcha")
            return
        }
        let tosend = this.state.signin ?
            JSON.stringify({ email: data.get("email").toLowerCase(), password: data.get("password"), customerid: this.customerid })
            :
            JSON.stringify({
                email: data.get("email").toLowerCase(), 
                password: data.get("password"), 
                firstName: data.get("firstName"),
                lastName: data.get("lastName"), 
                leadSource: data.get("leadSource"), 
                customerid: this.customerid,
                captcha: captcha, 
                loid: this.loid,
            })
        this.setState({ signingup: true })
        fetch('/auth/signup?isagent=true', {
            method: 'POST',
            body: tosend,
            headers: { 'Content-Type': 'application/json' },
        }).then(
            response => {

                if (response.status !== 200) {
                    this.setState({
                        message: 'Looks like there was a problem. Status Code: ' +
                            response.status, show: true
                    })
                    if (!this.local)
                        window.grecaptcha.reset()
                    return;
                }
                // Examine the text in the response
                this.setState({ signingup: false })

                response.json().then(js => {

                    if (js.Status !== "OK") {
                        if (js.Text === "Account validated") {
                            this.setState({ message: "This email account has already signed up. Please log in to work on your loan application.", show: true })
                        } else if (js.Text === "Account exists but not validated") {
                            this.setState({ message: "This email account has already signed up but not validated. Please check your mailbox to validate the email.", show: true })
                        }  else {
                            this.setState({ message: "We could not sign up this account, please check later.", show: true })
                        }
                        sessionStorage.removeItem("ZeitroA")
                        sessionStorage.removeItem("state")
                        if (!this.local)
                            window.grecaptcha.reset()

                    } else {
                        this.setState({signupsucceed: true})

                        // if (this.props.view === "baresignup") {
                        //     let evt = { source: "plainredirect", payload: "/account-thanks" }
                        //     console.log(evt)
                        //     window.parent.postMessage(evt, '*')
                        // } else {
                        //     window.location.href = "/services/validate"
                        // }
                    }
                });
            }
        ).catch((err) => {
            this.setState({ signingup: false })
            console.log('Fetch Error :', err);
            this.setState({ message: "Our servers are experiencing problems. Please check later.", show: true })
            if (!this.local)
                window.grecaptcha.reset()

        });
    }
    sendRevalidationEmail = () => {

        this.setState({ emailSentFinished: false })
        this.setState({ sendingEmail: true })
        fetch('/agent/revalidateborrower', {
            method: 'POST',
            body: JSON.stringify({
                Email: this.state.email,
                LoID: this.props.agentID,
            }),
            headers: { 'Content-Type': 'application/json' },
        }).then(
            response => {
                this.setState({ sendingEmail: false })
                this.setState({ emailSentFinished: true })
            }
        ).catch((err) => {
            this.setState({ sendingEmail: false })
            console.log('Fetch Error in /agent/revalidateborrower:', err);
        });
    }

    handleSubmit = event => {
        this.buttonpressed = true;

        let captcha = (this.local) ? "XXXX" : window.grecaptcha.getResponse()

        if (!captcha || captcha === "") {
            this.setState({ captchasolved: false })
            this["state"].captchasolved = false

            this.setState({ validated: true })
            this.captcha.current.setCustomValidity("Please solve the Captcha")
            event.preventDefault();
            this.captcha.current.required = true
            return false
        } else {
            this.setState({ captchasolved: true })
            this["state"].captchasolved = true
            if (!this.local) {
                this.captcha.current.setCustomValidity("")
                this.captcha.current.required = false
            }
        }

        if (this.form.current.reportValidity() === false) {
            event.preventDefault();
            this.setState({ validated: true })


            return false
        }
        event.preventDefault();
        this.setState({ validated: false })
        event.stopPropagation();

        const data = new FormData(event.target);
        if (this.state.signin) {
            this.signin(event, data)
        } else {
            this.signup(event, data)

        }
    }
    forgot = () => {
        if (this.props.view === "bare")
            return ""
        return (
            <div>
                <Row className="text-center mt-3">
                    <Col className="text-center bluish" > <Link to="/services/forgot-password" >Forgot your password?</Link></Col>
                </Row>
                <Row className="text-center mt-1">
                    <Col className="text-center bluish" > <Link to="/services/signup" >Sign up for a different account</Link></Col>
                </Row>
            </div>
        )
    }
    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true;
    }
    header = () => {
        if (this.props.view === "bare")
            return ""
        if (this.state.signin) {
            return (
                <div className="pt-4">
                    <h5> Sign In </h5>
                    <div className="my-0 px-5 py-0 my-0 nav-link">Please log in to continue working on your loan application!</div>
                </div>
            )
        } else {
            if (this.props.view === "baresignup")
                return ""

            return (
                <div className="pt-4 text-center">
                    <h5> Sign Up </h5>
                </div>
            )
        }
    }
    onInvite = e => {

    }



    render() {

        let dummy = (e) => {

        }

        let renderSignupBox = () => {
            return (            
                <div id="loginbox" className="Login loginbox " ref={this.ref}>
                    <Helmet>
                        <title>Sign Up With Loan Officer</title>
                    </Helmet>
                    <Modal show={this.state.show} onHide={this.handleClose} onShow={this.onShow}>
                        <Modal.Header closeButton>
                            <Modal.Title>{this.state.signin ? 'Authentication Failed' : 'Sign Up Failed'}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="borrowerSignupMessage">{this.state.message}</div>
                            {this.state.message.includes("not validated") && <div className="resendEmailMessage">
                                {this.state.emailSentFinished ? "We've resent the validation email to " + this.state.email : <Button size="sm" variant="link" onClick={()=>this.sendRevalidationEmail()}>{this.state.sendingEmail?<Spinner animation="border" size="sm"/>:"Click here to resend the validation email"}</Button>}
                                </div>}
                            {this.state.message.includes("log in") && <div><Button variant="link" onClick={() =>(window.location.href = this.props.url + "/services/signin")}>Log in</Button></div>}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button id="Close1" variant="secondary" onClick={this.handleClose}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                    {this.header()}
    
                    {this.state.signupsucceed ? <div className="text-center p-4 pb-5">
                        <h5>Thanks for creating acount with us!</h5>
                        <div className=" bluish nav-link text-center mt-4  mb-5 pt-5 pb-5" style={{fontSize:"16px"}}>
                        In order to maintain your security we've sent a validation email to your account. 
                        <p/>
                        Please click on the link in this email, and it will take you to the login screen!
                    </div>
                    </div> : <div className="text-center px-5 pb-5">
                        { this.state.signingup ?
                            <div id="spinner" className="pt-5 mt-5">
    
                                <img alt="spinner" className="loader" src={com.logo ? `data:image/png;base64, ${com.logo}` : "/logo.png"}></img>
                            </div>
                            :
                            <Form style={{ fontSize: '0.8em' }} ref={this.form} onSubmit={this.handleSubmit} noValidate validated={this.state.validated}>
                                <Row className="mt-0 pt-4 ">
                                    <Col className="text-left">
                                        <Form.Group controlId="email"  >
                                            <Form.Label className="text-left" >Email:</Form.Label>
                                            <Form.Control
                                                required
                                                name="email"
                                                size="sm"
                                                autoFocus
                                                autoComplete="email"
                                                type="email"
                                                defaultValue={this.state.email}
                                                onChange={this.handleChange}
                                                pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$"
                                            />
                                            
                                            <Form.Control.Feedback type="invalid" >
                                                Please enter a valid email address!
                                            </Form.Control.Feedback>
    
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {this.props.view === "signup" || this.props.view === "baresignup" ?
                                    <div>
                                        <Row className="  text-right">
    
                                            <Col className="text-left">
                                                <Form.Group controlId="firstName"  >
                                                    <Form.Label className="text-left" >First Name:</Form.Label>
    
                                                    <Form.Control
                                                        name="firstName"
                                                        autoComplete="given-name"
                                                        required
                                                        size="sm"
                                                        pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                                        value={this.state.firstName}
                                                        onChange={this.handleChange}
                                                    />
                                                    
                                                    <Form.Control.Feedback type="invalid" >
                                                        Please enter your first name!
                                                    </Form.Control.Feedback>
    
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col className="text-left">
                                                <Form.Group controlId="lastName"  >
                                                    <Form.Label className="text-left" >Last Name:</Form.Label>
    
                                                    <Form.Control
                                                        size="sm"
                                                        name="lastName"
                                                        autoComplete="family-name"
                                                        pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                                        required
                                                        value={this.state.lastName}
                                                        onChange={this.handleChange}
                                                    />
                                                    
                                                    <Form.Control.Feedback type="invalid" >
                                                        Please enter your last name!
                                                    </Form.Control.Feedback>
    
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </div>
                                    : ""}
    
                                {this.props.view === "signin" || this.props.view === "bare" ?
                                    <div>
                                        <Row>
                                            <Col className=" text-left">
                                                <Form.Group controlId="password"  >
                                                    <Form.Label className="text-left" >Password:</Form.Label>
    
                                                    <Form.Control
                                                        size="sm"
                                                        name="password"
                                                        ref={this.password}
                                                        required
                                                        autoComplete="password"
                                                        value={this.state.password}
                                                        onChange={this.onPassword}
                                                        type="password"
                                                        pattern={this.pattern}
                                                    />
                                                    
                                                    <Form.Control.Feedback type="invalid" >
                                                        Password must be at least 8 charactes long and<br />contain large, small letters, and numbers!
                                                    </Form.Control.Feedback>
    
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </div> : " "}
                                {this.props.view === "signup" || this.props.view === "baresignup" ?
                                    <div>
                                        <Row>
                                            <Col className="  text-left">
                                                <Form.Group controlId="password"  >
                                                    <Form.Label className="text-left" >Password:</Form.Label>
    
                                                    <InputGroup size="sm" >
                                                        <Form.Control
                                                            size="sm"
                                                            name="password"
                                                            ref={this.password}
                                                            required
                                                            autoComplete="off"
                                                            value={this.state.password}
                                                            onChange={this.onPassword}
                                                            type="password"
                                                            pattern="^.+$"
                                                        //pattern={this.pattern}
                                                        />
    
                                                        <InputGroup.Append>
                                                            <InputGroup.Text className="pl-4" style={{ "whiteSpace": "nowrap", "textAlign": "right", "backgroundColor": this.state.bgcolor, "color": "white", "minWidth": "9em" }} size="sm">{this.state.text}</InputGroup.Text>
                                                        </InputGroup.Append>
    
                                                        
                                                        <Form.Control.Feedback type="invalid" >{tooltip("Password is too weak",
                                                            <div>
                                                                At Zeitro we want to make sure that your sensitive data are protected. <p />The choice of a password is very important!<p />
                                                                Password should be hard to guess, and be long enough.
                                                                If password strength is shown to be too weak, add more characters, and make sure it contains large and small letters, numbers and special symbols.
                                                            </div>
                                                        )}
                                                        </Form.Control.Feedback>
    
                                                    </InputGroup >
    
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="  ">
                                            <Col className="text-left" >
                                                <Form.Group controlId="password1" >
                                                    <Form.Label className="text-left" >Validate:</Form.Label>
    
                                                    <InputGroup size="sm" >
    
                                                        <Form.Control
                                                            ref={this.password1}
                                                            size="sm"
                                                            required
                                                            autoComplete="off"
                                                            value={this.state.password1}
                                                            onChange={this.onPassword1}
                                                            //pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$"
                                                            pattern="^.+$"
                                                            type="password"
                                                        />
                                                        <InputGroup.Append>
                                                            <InputGroup.Text className="pl-4" style={{ "whiteSpace": "nowrap", "textAlign": "right", "backgroundColor": this.state.bgcolor2, "color": "white", "minWidth": "9em" }} size="sm">{this.state.text2}</InputGroup.Text>
                                                        </InputGroup.Append>
                                                        
                                                        <Form.Control.Feedback type="invalid" >
                                                            Make sure validate and password fields are the same!
                                                        </Form.Control.Feedback>
                                                    </InputGroup >
    
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </div> : ""}
                                {(this.local) ? "" :
                                    <Row className="text-center mt-2  ">
                                        <Col className="text-left">
                                            <Recaptcha />
                                            <Form.Group controlId="hackid" >
                                                <Form.Control
                                                    size="sm"
                                                    ref={this.captcha}
                                                    required={!this.state.captchasolved}
                                                    value=""
                                                    name="hack"
                                                    hidden={true}
                                                    type="text"
                                                    onChange={dummy}
    
                                                />
                                                
                                                <Form.Control.Feedback type="invalid" >
                                                    Please solve the Captcha!
                                                </Form.Control.Feedback>
    
                                            </Form.Group>
    
                                        </Col>
                                    </Row>}
                                <Row className="pt-3 ">
                                    <Col >
                                        <Button id="loginorsignup"
                                            type="submit" className="lpbutton" variant="primary"
                                        >{this.state.signin ? 'Login' : 'Sign Up'} </Button>
                                    </Col>
                                </Row>
                                <Row className="mt-3 ">
                                </Row>
                                {this.state.signin ? this.forgot() : ""}
                                
    
                            </Form>}
                    </div>}
    
    
                    
                </div>
            );
        }

        if (this.props.inLinkPage) {
            return (
                <div>
                    <CustomerHeader/>
                        <div className="mt-5">
                            {renderSignupBox()}
                        </div>
                </div>
            )
        } else {
            return renderSignupBox()
        }
    }
}


AgentBorrowerSignUpPage = withRouter(AgentBorrowerSignUpPage)

