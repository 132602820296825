import { Row, Button, Typography } from "antd";
import "./NoPrequalLetters.css";
const { Paragraph } = Typography;

export const NoPrequalLetters = (props: { message?: string }) => {
  const message =
    props.message || "Your officer is working on your Pre-qual letter.";

  return (
    <div className="prequal-requested-container">
      <Row className="prequal-requested-header">
        <img
          src="/images/prequal-requested.svg"
          alt="Prequal Letter Requested"
        />
      </Row>
      <Row justify="center">
        <Paragraph className="prequal-requested-text">{message}</Paragraph>
      </Row>
      <Row justify="center">
        <Button disabled>Send reminder</Button>
      </Row>
    </div>
  );
};
