import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom'
import './App.css';
import { connect } from 'react-redux'
import { UpdateChangeProgress } from "./Store/progress"

/*
Basic Information needed to submit for Pre-Approval & Approval or Refi –
·      Collect fees to run credit Report – soft or hard – usually <30$
·      W-2 forms from the past two years
Pay stubs from the past 30 days – usually 2 paystubs
Federal tax returns from the past two years
Proof of other sources of income
Recent bank statements – 2 latest months 
Details on long-term debts such as car or student loans, 
ID and Social Security number
Mortgage payment stubs if they apply 
Home tax and insurance statements if applies  (2nd time home buyers and Refis) 
·      There may be other kinds of documentation required, depending on the type of mortgage getting.
If self-employed :
·      2 years Tax Returns for both Self including all schedules and the Business Tax Returns if more than 25% owner.
·      Articles of incorporation.
·      Business Registeration
*/


const mapStateToProps = (state) => {
    return {
        application: state.application,
        progress: state.progress,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateChangeProgress: (step, stage) => {
        dispatch(UpdateChangeProgress(step, stage))
    },
});
  
export default class Reset extends Component {
    constructor(props) {
        super(props)

        // DELETE ME
        this.props.updateChangeProgress(0, "max")        
    }

    render() {
        return (
            <div className="mx-5 mb-3 px-5 text-center">
                <h2 className=" heading">
                    The max interview page has been reset
                </h2>
                <div className=" bluish nav-link text-left mt-4 border-top border-bottom  border-dark mb-3 pt-5 pb-5 text-wrap" >
                    Our loan officer will contact you shortly. In the mean time,
                    we prepared a set of further tasks for you to find and 
                    upload the initial batch of 
                    supplemental documents.
                    <p/>
                    <Link to="/app/interview/main">Go back to interview.</Link>
                </div>
            </div>
        )
    }
}
Reset = connect(mapStateToProps, mapDispatchToProps, null)(Reset)
