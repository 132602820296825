import React, { Component } from 'react'
import '../App.css'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'



export default class LandingCopyright extends Component {

    constructor (props)  {
        super()
        this.state = {
            email: "",
            show: false,
            showFailure: false,
            message: 'Please enter your email address'
        }
    }
    onEmail = (e) => {
        this.setState({email: e.target.value})
    }
    join = (email) => {

        let tosend =
            JSON.stringify({
                email: email,

            })
    
        fetch('/serives/joinmailinglist', {
            method: 'POST',
            body: tosend,
            headers: { 'Content-Type': 'application/json' },
        }).then(
            response => {
                if (response.status !== 200) {
                    this.setState({
                        message: 'Looks like there was a problem. Status Code: ' +
                            response.status, showFailure: true
                    })

                    return;
                }
                // Examine the text in the response
                response.json().then(js => {

                   
                        this.setState({show: true, email: "", message: "Joined mailing list successfully"})
                    
                });
            }
        ).catch((err) => {
            console.log('Fetch Error :', err);
            this.setState({ message: "Our servers are experiencing problems. Please check later.", show: true })

        });
    }    
    sendEmail = (e) => {
        if(this.state.email !== "") {
            this.join(this.state.email)
        } else
            this.setState({showFailure: true, message: 'Please enter your email address'})
    }
    render = () => {
        return (
            <div className="footer" style={{ fontSize: '0.9em' }}>
        <div className="text-right ">
          
        {this.state.showFailure ? 
        <Alert className="text-center myalert" variant="danger"  onClose={() => this.setState({showFailure: false})} dismissible>
        <Alert.Heading>Zeitro</Alert.Heading>
        <p>
          {this.state.message}
        </p>
      </Alert> : 
      "" }

{this.state.show ? 
        <Alert className="text-center myalert" variant="success"  onClose={() => this.setState({show: false})} dismissible>
        <Alert.Heading>Zeitro</Alert.Heading>
        <p>
          You have successfully subscribed to the Zeitro newsletter
        </p>
      </Alert> : 
      "" }

</div>
                <Row style={{ minHeight: '150px', fontSize: '0.8em', backgroundColor: '#F7F9FC'}} 
                    className="p-0 m-0 text-left align-self-top">
                    <Col style={{ marginTop:'10px', marginLeft: '10px', maxWidth: '240px' }} >
                        <div className="d-flex">
                        <div className="social text-center p-0 m-0 align-self-center">
                                <a target="blank" href="https://twitter.com/zeitro"><img alt="twitter" className="socialicon" src="/twitter.png"></img></a>
                            </div>
                            <div className="social text-center p-0 m-0  ml-2 align-self-center">
                                <a target="blank" href="https://www.instagram.com/zeitro_/"><img alt="instagram" className="socialicon" src="/pinterest.png"></img></a>
                            </div>
                            <div  className="social text-center p-0 m-0 ml-2 align-self-center">
                                <a target="blank" href="https://www.linkedin.com/company/zeitro/?viewAsMember=true"><img alt="linkedin" className="socialicon" src="/linkedin.png"></img></a>
                            </div>
                            <div className="social text-center p-0 m-0 ml-2 align-self-center">
                                <a target="blank" href="https://www.facebook.com/Zeitro-103595054746282"><img alt="facebook" className="socialicon" src="/facebook.png"></img></a>
                            </div>
                        </div>
                       
                        <div className="whitecolor mt-1"></div>
                        <div className="whitecolor mt-2"><img alt="" src="/eho.png" style={{width: '100px'}}/></div>
                    </Col>
                    <Col style={{ color: '#060948' }} className="align-self-top">
                        <Row>
                            <Col style={{ maxWidth: '10em' }} className="ml-0 align-self-top pt-3 mr-5">
                                <h5>RESOURCES</h5>
                                <div className="mt-2"><a className="whitelink" href="/resources/faq">FAQs</a></div>
                                <div className="mt-2"><a className="whitelink" href="/resources/glossary">Loan Dictionary</a></div>
                                <div className="mt-2"><a className="whitelink" href="/resources/tools/calculator">Tools</a></div>
                            </Col>
                            <Col style={{ maxWidth: '10em' }} className="align-self-top pt-3 mr-3">
                                <h5>COMPANY</h5>
                                <div className="mt-2"><a className="whitelink" href="/resources/about">About Us</a></div>
                                <div className="mt-2"><a className="whitelink" href="/resources/privacy">Privacy Policy</a></div>
                                <div className="mt-2"><a className="whitelink" href="/resources/termsofuse">Terms of Use</a></div>
                            </Col>
                            <Col xs="auto" className="mr-3 align-self-top pt-3">
                                <h5>CONTACT US</h5>
                                <div className="mt-2"><a className="whitelink" href="mailto:mortgage@zeitro.com">mortgage@zeitro.com</a></div>
                                <div className="mt-2">340 E Middlefield Rd<br/>Mountain View, CA 94043</div>
                            </Col>
                            <Col xs="auto" className=" align-self-top pt-3">
                            <h5>SUBSCRIBE TO LATEST NEWS</h5>
                            <div className="d-flex">
                            <input onChange={this.onEmail} value={this.state.email} type="text" size="32" placeholder="Email"/><Button size="sm" onClick={this.sendEmail} variant="primary" >Submit</Button>   </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style={{ height: '60px' }}>
       
                    <Col style={{ color: '#999999', fontSize: '0.9em' }} className="mr-5 py-0 my-0 pr-5 text-center align-self-center">
                        © 2021 Zeitro. All rights reserved. Zeitro is a registered mortgage broker. <a  href="https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/1971695" target="about:blank">NMLS ID 1971695</a>
                    </Col>

                </Row>
            </div>
        )
    }
}