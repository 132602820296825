import React, { Component } from "react";
import Chart from "react-apexcharts";
import * as com from "../Common.js"
class BreakDownChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: props.series,
      options: {
        tooltip: {
          y: {
            formatter: function (value) {
              return "$" + com.commaizeFloat(value);
            }
          }
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: { show: true },
                total: {
                  show: true,
                  showAlways: true,
                  formatter: function (w) {
                    return "$" + com.commaizeFloat(Math.round(w.globals.seriesTotals.reduce((a, b) => {return a + b}, 0)))
                 },
                },
                value: {
                  formatter: function (w) {
                    return (
                      "$" + com.commaizeFloat(Math.round(w))
                    );
                  },
                },
              },
              size: "55%",
            },

            customScale: 0.9,
          },
        },
        labels: [
          "Principal and interest",
          "Property taxes",
          "Homeowners insurance",
          "Homeowners association (HOA) fees",
          "Private mortgage insurance (PMI)",
        ],
        chart: {
          size: 200,
          type: "donut",
        },
        colors: ['#93C3EE', '#91e3a7', '#e3d891', '#edc5ec', '#ebac98'],
        legend: {
          fontSize: "14px",
          show: true,
          position: "top",
          formatter: function(val, opts) {
            return val + ": $" + opts.w.globals.series[opts.seriesIndex].toFixed(1)
          },
          horizontalAlign: 'left', 
          itemMargin: {
            horizontal: 120,
          },
        },
        responsive: [
          {
            breakpoint: 700,
            options: {
              chart: {
                width: "135%",
                offsetX: -30,
              },
              legend: {
                position: "bottom",
                fontSize: "10px",
                horizontalAlign: 'left', 
                offsetX: 10,
                itemMargin: {
                  horizontal: 50,
                  vertical: 0
              },
              },
            },
          },
        ],
      },
    };
  }

  render() {
    return (
      <div id="chart" className="app">
        <Chart
          key={this.state.series}
          options={this.state.options}
          series={this.props.series}
          type="donut"
        />
      </div>
    );
  }
}

export default BreakDownChart;
