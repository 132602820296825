import "./HeaderFixed.css";
import { commaize } from "../../../Common";
import {
    Button,
    InputGroup,
} from "react-bootstrap";
import arrow from '../../icon/arrow.svg'
import HorizontalSlider, { AffordabilityHorizontalSlider } from "../../HorizontalSlider";

import React, { useState } from 'react';

export default function HeaderFixed(props) {
    const { getStartedUrl, loanAmount, mortgagePayment, dti, getShowValue, homePrice, counts, curPrice, fixHeight, changeIndexPayments } = props;

    const [showDtiSlider, setShowDtiSlider] = useState(false);
    const [showMoreInfo, setShowMoreInfo] = useState(false);

    const onDtiSlider = () => {
        if (showDtiSlider) {
            setShowDtiSlider(false);
            getShowValue(false)
        } else {
            setShowDtiSlider(true);
            getShowValue(true)
        }
    };

    const showMore = () => {
        setShowMoreInfo(!showMoreInfo)
        if (showMoreInfo) {
            getShowValue(false)
            setShowDtiSlider(false);
        }
    }

    return (
        <div className="sticky">
            <div className="fix-container">
                <div style={{display:"flex",flexDirection:"row"}}>
                    <div style={{marginRight:"15px"}}>
                        <div className="left-dec">You can afford a house up to:</div>
                        <div className="left-price"> ${commaize(homePrice)}</div>
                    </div>
                    <Button className="header-get-started" onClick={()=>window.open(getStartedUrl, "_blank")}>Get Started Now</Button>
                </div>
                <div>
                    <InputGroup>
                        {/* <Button variant="preapprvoal">preapprvoal</Button>{' '} */}
                    </InputGroup>
                </div>
            </div>
            <div className="fix-main" hidden={!showMoreInfo} style={{ borderBottom: showDtiSlider ? '' : '1px solid #D2D2D4' }}>
                <div className="main-left">
                    <div className="left-list">
                        <div>Monthly payment:</div>
                        <div className="list-price">${commaize(mortgagePayment)}</div>
                        <div className="right-icon" onClick={showMore}>
                            <img src={arrow} alt='' style={{ width: '0.75rem', height: '0.75rem', }}></img>
                        </div>
                    </div>
                    <div className="left-list">
                        <div>Loan amount:</div>
                        <div className="list-price">${commaize(loanAmount)}</div>
                    </div>
                    <div className="left-list">
                        <div>Debt-to-income ratio (DTI):</div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <b style={{ color: (dti <= 37) ? '#00BE63' : ((dti <= 44) && (dti > 37)) ? '#FFAA29' : '#FF2929', }}>{dti}% </b>{" "}
                            <img
                                onClick={() => onDtiSlider()}
                                className="affordabilityPlusSign"
                                src="/images/plusSign.png"
                                alt=''
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="min-main" hidden={showMoreInfo}>
                <span>Monthly payment: ${commaize(mortgagePayment)}</span>
                <span style={{ marginLeft: '0.5rem' }}>Loan amount: ${commaize(loanAmount)}</span>
                <span className="right-icon" onClick={showMore}>
                    <img src={arrow} alt='' style={{ width: '0.75rem', height: '0.75rem', }}></img>
                </span>
            </div>
            {showDtiSlider && (
                <div className="dtiSlider newSlider">
                    <div className="sliderBorder">
                        <div className="dtiSliderTip">
                            <div className="dtiSliderTipTitle">Why this ratio?</div>
                            <div className="dtiSliderTipText">
                                Typically your DTI should be 36% or lower to qualify for a
                                mortgage. You may enter the percentage of your income you
                                are comfortable spending towards your mortgage. This number
                                will also take monthly debts into account.
                            </div>
                            <div className="dtiSliderTipTitle" style={{ marginBottom: '2rem' }}>Debt-to-income ratio</div>
                        </div>
                        <AffordabilityHorizontalSlider
                            className="paymentSlider"
                            onUpdate={changeIndexPayments}
                            onChange={changeIndexPayments}
                            step={1}
                            domain={[counts[0], counts[counts.length - 1]]}
                            values={[curPrice.dti]}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}
