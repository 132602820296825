import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'

import { PieChart } from 'react-minimal-pie-chart'
import { every, omit } from 'lodash'
import * as com from '../Common.js'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import NumericalInput from '../NumericalInput'
import { connect } from 'react-redux'
import * as act from '../Store/actions'
import { getCurrentState } from '../Store'
import { Tips } from '../Common/Tooltip'
import CheckGroup from '../Common/components/CheckGroup'
import { isEmpty } from 'lodash'
import { Input, Select, Checkbox } from 'antd'
import { DollarInput } from '../Common/components/DollarInput'

export function getassets() {
    let total = 0

    const { accounts, brokerages, lifeinsurance, gifts, otherassets } = this.props.application.assetsandliabilities

    accounts && accounts.forEach((x) => {
        if (x.balance !== '') total += parseFloat(x.balance)
    })
    brokerages && brokerages.forEach((x) => {
        if (x.balance !== '') total += parseFloat(x.balance)
    })
    lifeinsurance && lifeinsurance.forEach((x) => {
        if (x.netvalue !== '') total += parseFloat(x.netvalue)
    })
    gifts && gifts.forEach((x) => {
        if (x.value !== '') total += parseFloat(x.value)
    })
    otherassets && otherassets.forEach((x) => {
        if (x.value !== '') total += parseFloat(x.value)
    })

    return com.commaizeFloat(total)
}

const mapStateToPropsA = (state) => {
    return {
        application: state.application,
    }
}

const mapDispatchToPropsA = (dispatch) => ({})

class Allocation extends Component {
    constructor(props) {
        super(props)

        const [stocks, bonds, cash] = props.allocation
        this.props = props
        this.state = {
            show: false,
            stocks,
            bonds,
            cash,
            input: stocks === 0 && bonds === 0 && cash === 0 ? '' : 'set',
        }
    }

    componentDidMount = () => {
        const e = document.getElementById('allocation' + this.props.index)
        e.checkValidity = function () {
            alert()
        }
        e.reportValidity = function () {
            alert()
        }
    }

    apply = (e) => {
        const val = [
            parseFloat(this.state.stocks),
            parseFloat(this.state.bonds),
            parseFloat(this.state.cash),
        ]
        this.props.onChange(val)
        this.setState({ show: false })
    }

    render = () => {
        const onStocks = (e) => {
            let val = e.target.value
            if (val.length > 1 && val[0] === '0') {
                val = val.substr(1, val.length)
            }

            let v = parseFloat(val)
            if (isNaN(v)) {
                v = 0
                val = 0
            }

            v = Math.max(0, Math.min(100, v))
            if (val[val.length - 1] === '.') val = v.toString() + '.'
            else val = v.toString()

            this.setState({ stocks: val })
            if (v + parseFloat(this.state.bonds) > 100) {
                this.setState({
                    bonds: Math.max(100 - v),
                    cash: 0,
                    input: 'set',
                })
            } else {
                this.setState({
                    cash: parseFloat(
                        100 - v - parseFloat(this.state.bonds).toFixed(2)
                    ),
                    input: 'set',
                })
            }
        }

        const onBonds = (e) => {
            let val = e.target.value
            if (val.length > 1 && val[0] === '0') {
                val = val.substr(1, val.length)
            }

            let v = parseFloat(val)
            if (isNaN(v)) {
                v = 0
                val = 0
            }

            v = Math.max(0, Math.min(100, v))
            if (val[val.length - 1] === '.') val = v.toString() + '.'
            else val = v.toString()

            this.setState({ bonds: val })
            if (v + parseFloat(this.state.stocks) > 100) {
                this.setState({
                    stocks: Math.max(0, 100 - v),
                    cash: 0,
                    input: 'set',
                })
            } else {
                this.setState({
                    cash: parseFloat(
                        100 - v - parseFloat(this.state.stocks).toFixed(2)
                    ),
                    input: 'set',
                })
            }
        }

        const isAllZero = this.state.stocks === 0 && this.state.bonds === 0 && this.state.cash === 0
        const notSetData = [
            {
                title: 'Not set',
                value: 100,
                color: '#AAAAAA',
            },
        ]

        const stocks = parseFloat(this.state.stocks)
        const bonds = parseFloat(this.state.bonds)
        const cash = 100 - stocks - bonds
        const allocationData = [
            {
                title: `Stocks:${stocks.toFixed(1)}%`,
                value: stocks,
                color: '#EC6B56',
            },
            {
                title: `Bonds:${bonds.toFixed(1)}%`,
                value: bonds,
                color: '#FFC154',
            },
            {
                title: `Cash:${cash.toFixed(1)}%`,
                value: cash,
                color: '#47B39C',
            },
        ]


        return (
            <div className="p-0 m-0">
                <Modal
                    dialogClassName=" align-center"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.show}
                    onHide={() => this.setState({ show: false })}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Please specify asset allocation in your portfolio:
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body as="div" className="">
                        <Row>
                            <Col>
                                <Form.Group controlId="Broker" className="text-left" size="sm">
                                    <Form.Label className="text-left">
                                        <i style={{ color: '#EC6B56' }} className="fas fa-square" ></i>
                                        Stocks:
                                    </Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            isValid={false}
                                            size="sm"
                                            required
                                            name={'stocks'}
                                            autoFocus
                                            type="text"
                                            pattern="^[0-9.]+$"
                                            onChange={onStocks}
                                            value={this.state.stocks}
                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text
                                                className="pl-3"
                                                style={{
                                                    fontSize: '0.9em',
                                                    lineHeight: '1.3',
                                                }}
                                                size="sm"
                                            >
                                                %
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide the name of the bank/credit union.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="Broker" className="text-left" size="sm">
                                    <Form.Label className="text-left">
                                        <i style={{ color: '#FFC154' }} classNme="fas fa-square" ></i>
                                        Bonds:
                                    </Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            isValid={false}
                                            size="sm"
                                            required
                                            name={'bonds'}
                                            pattern="^[0-9.]+$"
                                            type="text"
                                            onChange={onBonds}
                                            value={this.state.bonds}
                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text
                                                className="pl-3"
                                                style={{ fontSize: '0.9em', lineHeight: '1.3' }}
                                                size="sm"
                                            >
                                                %
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide the name of the bank/credit union.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="Broker" className="text-left" size="sm">
                                    <Form.Label className="text-left">
                                        <i style={{ color: '#47B39C' }} classNme="fas fa-square" ></i>
                                        Cash:
                                    </Form.Label>
                                    <br />
                                    <Form.Label className="text-left">
                                        {' '}
                                        {this.state.cash.toFixed(2)}%
                                    </Form.Label>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                <PieChart
                                    className="mr-3"
                                    style={{ height: '256px', width: '256px' }}
                                    data={[
                                        {
                                            title: 'Stocks',
                                            value: parseFloat(this.state.stocks),
                                            color: '#EC6B56',
                                        },
                                        {
                                            title: 'Bonds',
                                            value: parseFloat(this.state.bonds),
                                            color: '#FFC154',
                                        },
                                        {
                                            title: 'Cash',
                                            value: 100 - parseFloat(this.state.stocks) - parseFloat(this.state.bonds),
                                            color: '#47B39C',
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.apply}>
                            Apply
                        </Button>
                    </Modal.Footer>
                </Modal>

                <input
                    hidden
                    value={this.state.input}
                    required
                    type="text"
                    id={'allocation' + this.props.index}
                    className="form-control "
                />
                <Button
                    style={{ marginTop: '9px' }}
                    size="sm"
                    onClick={(e) => this.setState({ show: true })}
                    required
                    className="zeitro-radio obutton text-left pr-3"
                    variant="outline-primary"
                >
                    <PieChart
                        className="mr-3"
                        style={{ height: '32px', width: '32px' }}
                        data={isAllZero ? notSetData : allocationData}
                    />{' '}
                    Allocation
                </Button>
            </div>
        )
    }
}

connect(mapStateToPropsA, mapDispatchToPropsA, null, {
    forwardRef: true,
})(Allocation)

const mapStateToProps = (state) => {
    return {
        finances: state.application.borrower.finances,
        application: state.application,
        borrower: state.application.borrower.declarations,
        coborrower: state.application.coborrower.declarations,
        hascoborrower: state.application.hascoborrower,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateJointly: (event) => {
        dispatch(act.UpdateJointly(event.target.checked))
    },
    updateFinances: (event, who, verb) => {
        dispatch(act.UpdateFinances(event.target.value, who, verb))
    },
    clearBorrowerBankAccounts: (index, who) => {
        dispatch(act.ClearBorrowerBankAccounts(index, who))
    },
    addBorrowerBankAccount: (i, who) => {
        dispatch(act.AddBorrowerBankAccount(i, who))
    },
    clearBorrowerBrokerAccounts: (index, who) => {
        dispatch(act.ClearBorrowerBrokerAccounts(index, who))
    },
    addBorrowerBrokerAccount: (i, who) => {
        dispatch(act.AddBorrowerBrokerAccount(i, who))
    },
    removeBorrowerBrokerAccount: (index, who) => {
        dispatch(act.RemoveBorrowerBrokerAccount(index, who))
    },
    changeBorrowerBrokerName: (event, index, who) => {
        dispatch(act.ChangeBorrowerBrokerName(event.target.value, index, who))
    },
    changeBorrowerBrokerAccount: (event, index, who) => {
        dispatch(
            act.ChangeBorrowerBrokerAccount(event.target.value, index, who)
        )
    },
    changeBorrowerBrokerBalance: (event, index, who) => {
        dispatch(
            act.ChangeBorrowerBrokerBalance(event.target.value, index, who)
        )
    },
    changeBorrowerBrokerAttribution: (event, index, who) => {
        dispatch(
            act.ChangeBorrowerBrokerAttribution(event.target.value, index, who)
        )
    },
    changeBorrowerBrokerTaxStatus: (event, index, who) => {
        dispatch(
            act.ChangeBorrowerBrokerTaxStatus(event.target.value, index, who)
        )
    },
    changeBorrowerBrokerAllocation: (value, index, who) => {
        dispatch(act.ChangeBorrowerBrokerAllocation(value, index, who))
    },
    changeDeclaration: (event, who, verb) => {
        dispatch(act.ChangeDeclaration(event, who, verb))
    },
    changeBorrowerInfo: (event, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(event, who, verb))
    },
    updateEqualOpportunity: (value, who, verb) => {
        dispatch(act.UpdateEqualOpportunity(value, who, verb))
    },
    updateEqualOpportunitySub: (value, who, section, verb) => {
        dispatch(act.UpdateEqualOpportunitySub(value, who, section, verb))
    },
})

class Finances extends Component {
    constructor(props) {
        super(props)

        this.formRef = React.createRef()

        this.state = {
            validated: false,
            totalAssets: 0,
        }

        this.isValidated = this.isValidated.bind(this)

        const { who } = this.props
        const { brokerages, accounts } = this.props.application.assetsandliabilities
        if (typeof brokerages === undefined)
            this.props.application.assetsandliabilities.brokerages = []

        if (accounts === null) {
            this.props.clearBorrowerBankAccounts(0, who)
        }

        if (brokerages === null) {
            this.props.clearBorrowerBrokerAccounts(0, who)
        }

        const { currentStatus, hascoborrower } = this.props.application
        // Change button text "Next" button to "Submit"
        if (currentStatus === 'ApplicationStarted') {
            const { who } = this.props
            const { completedsteps } = this.props.application[who]
            // Omit finances from completed steps because it is not set yet
            const shouldSubmit = every(omit(completedsteps, ['finances']), (step) => step === true)
            if (shouldSubmit) {
                if (who === com.borrower) {
                    if (hascoborrower !== 'withcoborrower') {
                        this.props.changeContinueButtonText('Submit')
                    } else {
                        const coborrowerCompleted = every(this.props.application[com.coborrower].completedsteps, (step) => step === true)
                        if (coborrowerCompleted) {
                            this.props.changeContinueButtonText('Submit')
                        }
                    }
                }
                if (who === com.coborrower) {
                    const borrowerCompleted = every(this.props.application[com.borrower].completedsteps, (step) => step === true)
                    if (borrowerCompleted) {
                        this.props.changeContinueButtonText('Submit')
                    }
                }
            }
        }
    }

    recalcAssets = () => {
        //setTimeout( () => this.setState({totalAssets: this.calculateAssets()}), 100)
        this.calculateAssets()
    }

    componentDidMount() {
        this.recalcAssets()
        setTimeout(() => {
            this.completion()
        }, 100)
    }

    componentDidUpdate(prevProps) {
        this.completion()
    }

    componentWillUnmount() { }

    onFinish = () => {
        const { who } = this.props
        const { completedsteps } = this.props.application[who] || {}

        if (!completedsteps?.finances) {
            this.props.changeBorrowerInfo(
                { ...completedsteps, finances: true },
                who,
                'completedsteps'
            )
        }
    }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        }

        return true
    }

    completion = () => {
        const form = this.props.self.current
        const formElements = form?.elements
        if (formElements && formElements.length !== 0) {
            let totalCount = 2
            let fillNum = 2
            for (let i = 0; i < formElements.length; i++) {
                const element = formElements[i]
                if (
                    element.type !== 'button' &&
                    element.type !== 'fieldset' &&
                    element.type !== 'submit'
                ) {
                    totalCount++
                    if (!isEmpty(element.value)) {
                        fillNum++
                    }
                }
            }

            // Define declaration fields to check
            const declarationFields = [
                'comakeronnote',
                'judgements',
                'delinquentondebt',
                'lawsuit',
                'conveyedtitle',
                'loanforeclosure',
                'propertyforeclosure',
                'loansinforebearance',
                'bancrupt'
            ]

            // Create borrower declarations object using field list
            const borrowerDeclarations = declarationFields.reduce((acc, field) => {
                acc[field] = this.props.borrower[field]
                return acc
            }, {})

            // Create co-borrower declarations if needed
            const coborrowerDeclarations = this.props.hascoborrower !== 'alone'
                ? declarationFields.reduce((acc, field) => {
                    acc[`co${field.charAt(0).toUpperCase()}${field.slice(1)}`] = this.props.coborrower[field]
                    return acc
                }, {})
                : {}

            // Merge declarations
            const mergedObj = { ...borrowerDeclarations, ...coborrowerDeclarations }

            const emptyNum = Object.keys(mergedObj).filter((key) => {
                const value = mergedObj[key]
                return value === '' || value === null || value === undefined
            }).length
            totalCount = totalCount + Object.keys(mergedObj).length
            fillNum = fillNum + Object.keys(mergedObj).length - emptyNum
            const progressValue = Math.round((fillNum / totalCount) * 100)
            sessionStorage.setItem('progressValue', progressValue)
        }
    }

    processBrokerages = (val, index) => {
        if (null === val) return ''

        const { bankname, account, key, balance, attribution, isretirement } = val
        return this.generateBrokerAccount(
            index,
            bankname,
            account,
            balance,
            attribution,
            isretirement,
            key
        )
    }

    generateBrokerAccount = (index, brokername, account, balance, attribution, isretirement, key) => {
        index = parseInt(index)

        const { who } = this.props

        const removeBorrowerBrokerAccount = (event) => {
            com.touch()
            this.props.removeBorrowerBrokerAccount(index, who)
        }
        const updateBroker = (event) => {
            com.touch()
            this.props.changeBorrowerBrokerName(event, index, who)
        }
        const updateAccount = (event) => {
            com.touch()
            this.props.changeBorrowerBrokerAccount(event, index, who)
        }
        const changeBorrowerBrokerBalance = (event) => {
            com.touch()
            this.props.changeBorrowerBrokerBalance(event, index, who)
        }
        const addBorrowerBrokerAccount = (event) => {
            com.touch()
            return this.props.addBorrowerBrokerAccount(event, who)
        }

        const changeAttribution = (event) => {
            com.touch()
            this.props.changeBorrowerBrokerAttribution(
                event,
                index,
                who
            )
        }
        const changeTaxStatus = (event) => {
            com.touch()
            const val = event.target.checked
            this.props.changeBorrowerBrokerTaxStatus(
                { target: { value: val } },
                index,
                who
            )
        }

        const amountName = () => {
            return isretirement ? (
                <Form.Label>
                    Vested amount
                    <Tips
                        content={
                            <div>
                                Vested amount refers to the part of the retirement account that can be potentially withdrawn. Employer matching contribution typically has a vesting schedule, and only belongs to you after it vests.
                            </div>
                        }
                        placement="top"
                    />
                </Form.Label>
            ) : (
                <Form.Label>Balance</Form.Label>
            )
        }

        const { hascoborrower, married, borrower, coborrower, assetsandliabilities } = this.props.application
        const { brokerages } = assetsandliabilities

        const borrowerName = `${borrower.firstname} ${borrower.lastname}`
        const coborrowerName = `${coborrower.firstname} ${coborrower.lastname}`

        return (
            <div key={key} className="incard m-0 mb-3 px-1 mt-4">
                <Row>
                    <Col className="p-0 pl-2">
                        <Form.Row>
                            <Col>
                                <Form.Group controlId="Broker" className="text-left" size="sm">
                                    <Form.Label className="text-left">
                                        Institution
                                    </Form.Label>
                                    <Form.Control
                                        isValid={false}
                                        size="sm"
                                        required
                                        name={'brokername' + index}
                                        autoFocus
                                        type="text"
                                        defaultValue={brokername}
                                        onChange={updateBroker}
                                        pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide the name of the bank/credit union.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="BrokerAccount" className="text-left" size="sm">
                                    <Form.Label className="text-left">
                                        Account
                                    </Form.Label>
                                    <Form.Control
                                        isValid={false}
                                        size="sm"
                                        required
                                        name={'brookeraccount' + index}
                                        type="text"
                                        defaultValue={account}
                                        onChange={updateAccount}
                                        placeholder="XXXX (Last Four Digits)"
                                        pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide account number.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="MarketValue" className="text-left" size="sm">
                                    {amountName()}
                                    <div>
                                        <Input
                                            prefix="$"
                                            value={balance}
                                            type="number"
                                            onBlur={this.recalcAssets()}
                                            onChange={(e) =>
                                                changeBorrowerBrokerBalance(
                                                    e.target.value < 1 ? { target: { value: 1 } } : e
                                                )
                                            }
                                        />
                                    </div>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide account balance.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col xs="auto" className="pl-2 d-flex align-items-center">
                                <div style={{ marginTop: '10px' }}>
                                    <Checkbox id={'401k' + index} value={isretirement} onChange={changeTaxStatus}>
                                        <div id={'401label' + index} className="divlink ml-2" htmlFor={'401k' + index}>
                                            Check if this is a retirement account{' '}
                                            <Tips
                                                content={
                                                    <div>
                                                        If this is a retirement account (401K, IRA, SEP, Keogh, Annuities) choose Retirement, and specify only the vested account. Otherwise, choose Regular.
                                                    </div>
                                                }
                                                placement="bottom"
                                                title="Retirement"
                                            />
                                        </div>
                                    </Checkbox>
                                </div>
                            </Col>
                            {hascoborrower === 'withcoborrower' && married && (
                                <Col xs="auto" className="pl-2">
                                    <div>Ownership</div>
                                    <Form.Group controlId="employment">
                                        <Form.Control
                                            name="employment"
                                            as="select"
                                            size="sm"
                                            defaultValue={attribution}
                                            onChange={changeAttribution}
                                            required
                                        >
                                            <option value="">...</option>
                                            <option value="1">{borrowerName}</option>
                                            <option value="2">{coborrowerName}</option>
                                            <option value="3">Joint</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            )}
                            <Col></Col>
                        </Form.Row>
                    </Col>
                    <Col xs="auto" as="div" className="text-right aligh-top px-0">
                        <i
                            hidden={index !== brokerages.length - 1}
                            className="far fahover fa-plus-square text-primary aligh-top fa-1x mr-1 divlink"
                            onClick={addBorrowerBrokerAccount}
                        />
                        <i
                            hidden={index === brokerages.length - 1}
                            className="far fahover fa-plus-square aligh-top fa-1x mr-1 transparent"
                        >
                            {' '}
                        </i>
                        <i
                            className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink"
                            onClick={removeBorrowerBrokerAccount}
                        />
                    </Col>
                </Row>
            </div>
        )
    }

    addOtherSources = (e) => {
        com.touch()
        const val = e.target.value === 'true'
        if (!val) {
            this.props.clearBorrowerBankAccounts(0, this.props.who)
        } else {
            this.props.addBorrowerBankAccount(0, this.props.who)
        }
    }

    updateJointly = (e) => {
        com.touch()
        const f = e.target.value === 'true'
        this.props.updateJointly({ target: { checked: f } })
    }

    addOtherBrokerages = (e) => {
        com.touch()
        const val = e.target.value === 'true'
        if (!val) {
            this.props.clearBorrowerBrokerAccounts(0, this.props.who)
        } else {
            this.props.addBorrowerBrokerAccount(0, this.props.who)
        }
    }

    addOtherProperties = (e) => {
        com.touch()
        const val = e.target.value === 'true'
        if (!val) {
            this.props.clearBorrowerAdditionalProperty(0, this.props.who)
        } else {
            this.props.addBorrowerAdditionalProperty(0, this.props.who)
        }
    }

    calculateAssets = getassets.bind(this)

    updateFinances = (verb) => {
        return (e) =>
            this.props.updateFinances(e.target.value, this.props.who, verb)
    }

    showInvestment = () => {
        return null !== this.props.application.assetsandliabilities.accounts
    }

    render = () => {
        const changeDeclaration = (verb, who) => {
            return (event) => {
                com.touch()
                this.props.changeDeclaration(event, who, verb)
            }
        }

        const yesnooptions = [
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' },
        ]

        const declaration = (title, val) => {
            return (
                <>
                    <Row>
                        <Col xs={12} className="mt-4 pl-0">
                            <Form.Label>{title}</Form.Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="pl-0" md={6} xs={12}>
                            <Form.Group className="inputSection">
                                <CheckGroup
                                    data={yesnooptions}
                                    onChangeSelect={changeDeclaration(val, this.props.who)}
                                    value={this.props[this.props.who][val]}
                                    type="radio"
                                    direction="horizontal"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </>
            )
        }

        const onEthnicity = (event) => {
            com.touch()
            this.props.updateEqualOpportunity(event.target.value, who, 'ethnicity')
        }

        const onOtherHispanic = (event) => {
            com.touch()
            this.props.updateEqualOpportunitySub(event.target.value, who, 'hispanic', 'otherdesctiption')
        }

        const onPacific = (event) => {
            com.touch()
            this.props.updateEqualOpportunitySub(event.target.value, who, 'race|pacificdetails', 'otherdetails')
        }

        const onTribe = (event) => {
            com.touch()
            this.props.updateEqualOpportunitySub(event.target.value, who, 'race', 'indiantribe')
        }

        const onOtherAsian = (event) => {
            com.touch()
            this.props.updateEqualOpportunitySub(event.target.value, who, 'race|asiandetails', 'otherdesctiption')
        }

        const onSex = (event) => {
            com.touch()
            this.props.updateEqualOpportunity(event.target.value, who, 'sex')
        }


        const updateEqualOpportunityCheck = (who, section, verb) => {
            return event => {
                com.touch()
                this.props.updateEqualOpportunitySub(event.target.checked, who, section, verb)
            }
        }

        const { who, borrower, coborrower } = this.props
        const { servearmedforces, militaryservice } = this.props.application[who].declarations
        const { ethnicity, hispanic, race, sex } = this.props.application[who].equalopportunity

        const bancrupt = who === com.borrower ? borrower.bancrupt : coborrower.bancrupt

        const isHispanicRequired = () => {
            if (ethnicity !== 'hispanic') return false
            return !hispanic.mexican && !hispanic.puertorican && !hispanic.cuban && !hispanic.other
        }

        const isRaceRequired = () => {
            const { notfurnished, asian, pacific, black, americanindian, white } = race
            if (notfurnished) return false
            return !asian && !pacific && !black && !americanindian && !white
        }

        const isPacificRequired = () => {
            const { pacific, pacificdetails } = race
            if (!pacific) return false
            return !pacificdetails.hawaian && !pacificdetails.samoan && !pacificdetails.guamanian && !pacificdetails.other
        }

        const isAsianRequired = () => {
            const { asian, asiandetails } = race
            if (!asian) return false
            return (
                !asiandetails.otherasian &&
                !asiandetails.asianindian &&
                !asiandetails.chinese &&
                !asiandetails.korean &&
                !asiandetails.vietnamese &&
                !asiandetails.filipino &&
                !asiandetails.japanese
            )
        }

        const handleMilitaryService = (e) => {
            com.touch()
            this.props.changeDeclaration(e, who, 'servearmedforces')
        }

        const changeMilitary = (verb, isdate) => {
            const m = {...militaryservice}
            return (e) => {
                com.touch()
                let value = isdate ? e.target.value : ""
                if (isdate){
                    if (/^[\d/]*$/.test(value)){
                        let formattedValue = value.replace(/\D/g,'')
                        if (formattedValue.length > 2){
                            formattedValue = formattedValue.slice(0, 2) + "/" + formattedValue.slice(2, 4) + "/" + formattedValue.slice(4, 8)
                        } else if (formattedValue.length > 2){
                            formattedValue = formattedValue.slice(0, 2) + "/" + formattedValue.slice(2, 4)
                        }
                        value = formattedValue
                    }
                }
                m[verb] = isdate ? value : e.target.checked
                this.props.changeDeclaration(m, who, 'militaryservice')
            }
        }

        const { chapter7, chapter11, chapter12, chapter13 } = this.props[who]

        return (
            <div className="text-left newInterviewPaneProperty mb-3">
                <div className="page-header">
                    <span className="page-header-primary">Declarations</span>
                    <span className="page-header-separator">/</span>
                    <span className="page-header-secondary">Finances</span>
                </div>

                {declaration(
                    'Are you a co-signer or guarantor on any debt or loan that is not disclosed on this application?',
                    'comakeronnote'
                )}
                {declaration(
                    'Are there any outstanding judgments against you?',
                    'judgements'
                )}
                {declaration(
                    'Are you currently delinquent or in default on a Federal debt?',
                    'delinquentondebt'
                )}
                {declaration(
                    'Are you a party to a lawsuit in which you potentially have any personal financial liability?',
                    'lawsuit'
                )}
                {declaration(
                    'Have you conveyed title to any property in lieu of foreclosure in the past 7 years?',
                    'conveyedtitle'
                )}
                {declaration(
                    'Within the past 7 years, have you completed a pre-foreclosure sale or short sale? whereby the property was sold to a third party and the Lender agreed to accept less than the outstanding mortgage balance due?',
                    'loanforeclosure'
                )}
                {declaration(
                    'Have you had property foreclosed upon in the last 7 years?',
                    'propertyforeclosure'
                )}
                {declaration(
                    'Do you currently have any loans in forebearance?',
                    'loansinforebearance'
                )}
                {declaration(
                    'Have you been declared bankrupt within the past 7 years?',
                    'bancrupt'
                )}

                {bancrupt === 'yes' && (
                    <>
                        <div style={{ marginTop: '10px', color: '#222222', fontSize: 16 }}>
                            please Identify the type(s) of bankruptcy:
                        </div>
                        <div className="d-flex" style={{ marginTop: 10, color: '#222222', fontSize: 16 }}>
                            <div>
                                <div>
                                    <Checkbox
                                        checked={chapter7}
                                        onChange={(e) => {
                                            this.props.changeDeclaration(e.target.checked, who, 'chapter7')
                                        }}
                                    >
                                        chapter7
                                    </Checkbox>
                                    <Checkbox
                                        checked={chapter11}
                                        onChange={(e) => {
                                            this.props.changeDeclaration(e.target.checked, who, 'chapter11')
                                        }}
                                    >
                                        chapter11
                                    </Checkbox>
                                    <Checkbox
                                        checked={chapter12}
                                        onChange={(e) => {
                                            this.props.changeDeclaration(e.target.checked, who, 'chapter12')
                                        }}
                                    >
                                        chapter12
                                    </Checkbox>
                                    <Checkbox
                                        checked={chapter13}
                                        onChange={(e) => {
                                            this.props.changeDeclaration(e.target.checked, who, 'chapter13')
                                        }}
                                    >
                                        chapter13
                                    </Checkbox>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                <Row>
                    <Col className="mt-4 px-0" md={12} xs={12}>
                        <Form.Group className="inputSection">
                            <Form.Label>
                                Did you (or your deceased spouse) ever serve, or are you currently serving, in the United States Armed Forces?
                            </Form.Label>
                            <Row>
                                <Col className="pl-0" md={6} xs={12}>
                                    <Form.Group className="inputSection">
                                        <CheckGroup
                                            data={[
                                                { value: "yes", label: "Yes" },
                                                { value: "no", label: "No" },
                                            ]}
                                            onChangeSelect={handleMilitaryService}
                                            value={servearmedforces === 'yes' ? 'yes' : 'no'}
                                            type="radio"
                                            direction="horizontal"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>
                {servearmedforces === 'yes' && (
                    <>
                        <Form.Row className="mt-1">
                            <Col md="auto" style={{ display: 'flex', alignItems: 'center' }} >
                                <Checkbox
                                    checked={militaryservice.currentlyserving === true}
                                    onChange={changeMilitary('currentlyserving')}
                                >
                                    Currently serving on active duty with projected expiration date of service/tour
                                </Checkbox>
                            </Col>
                        </Form.Row>
                        {militaryservice.currentlyserving && (
                            <>
                                <div style={{ marginTop: '10px', color: '#222222', fontSize: 14 }}>Expiration date</div>
                                <Form.Row className="mt-1">
                                    <Col md={3} xs={12} className="pr-3 mb-2">
                                        <Input
                                            placeholder="MM/DD/YYYY"
                                            type="text"
                                            value={militaryservice.projectedexpirationdate}
                                            onChange={changeMilitary('projectedexpirationdate', true)}
                                        />
                                    </Col>
                                </Form.Row>
                            </>
                        )}
                        <Form.Row className="mt-1">
                            <Col md="auto" style={{ display: 'flex', alignItems: 'center' }} >
                                <Checkbox
                                    checked={militaryservice.currentlyretired === true}
                                    onChange={changeMilitary('currentlyretired')}
                                >
                                    Currently retired, discharged, or separated from service
                                </Checkbox>
                            </Col>
                        </Form.Row>
                        <Form.Row className="mt-1">
                            <Col md="auto" style={{ display: 'flex', alignItems: 'center' }} >
                                <Checkbox
                                    checked={militaryservice.nonactive === true}
                                    onChange={changeMilitary('nonactive')}
                                >
                                    Only period of service was as a non-activated member of the Reserve or National Guard
                                </Checkbox>
                            </Col>
                        </Form.Row>
                        <Form.Row className="mt-1">
                            <Col md="auto" style={{ display: 'flex', alignItems: 'center' }} >
                                <Checkbox
                                    checked={militaryservice.survivingspouse === true}
                                    onChange={changeMilitary('survivingspouse')}
                                >
                                    Surviving spouse
                                </Checkbox>
                            </Col>
                        </Form.Row>
                    </>
                )}

                <Form.Row>
                    <Col className="mt-4 sectionquestion">
                        <Form.Label>Ethnicity</Form.Label>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col md={2} xs={12}>
                        <input
                            required
                            type="radio"
                            checked={ethnicity === 'hispanic'}
                            onChange={onEthnicity}
                            value="hispanic"
                            name={'ethnicity' + who}
                            id={'hispanic' + who}
                        />
                        <label id={'hispaniclabel' + who} className="zeitro-radio" htmlFor={'hispanic' + who}>Hispanic or Latino</label>
                    </Col>
                    <Col md={2} xs={12}>
                        <input
                            required
                            type="radio"
                            checked={ethnicity === 'nonhispanic'}
                            onChange={onEthnicity}
                            value="nonhispanic"
                            name={'ethnicity' + who}
                            id={'nothispanic' + who}
                        />
                        <label id={'nothispaniclabel' + who} className="zeitro-radio" htmlFor={'nothispanic' + who}>Not Hispanic or Latino</label>
                    </Col>
                    <Col md={3} xs={12} className="ml-4">
                        <input
                            required
                            type="radio"
                            checked={ethnicity === 'donttell'}
                            onChange={onEthnicity}
                            value="donttell"
                            id={'ethnicitydonttell' + who}
                            name={'ethnicity' + who}
                        />
                        <label
                            id={'ethnicitydonttelllabel' + who}
                            className="zeitro-radio text-wrap"
                            htmlFor={'ethnicitydonttell' + who}
                            style={{ fontWeight: 'bold' }}
                        >
                            I do not wish to provide this information
                        </label>
                    </Col>
                </Form.Row>

                {ethnicity === 'hispanic' && (
                    <>
                        <div style={{ marginTop: '10px', color: '#222222', fontSize: 14 }}>Check one or more</div>
                        <Form.Row className="mt-1">
                            <Col md="auto" style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    required={isHispanicRequired()}
                                    type="checkbox"
                                    id="mexican"
                                    checked={hispanic.mexican}
                                    className="mr-1 "
                                    onChange={updateEqualOpportunityCheck(who, 'hispanic', 'mexican')}
                                />
                                <label className=" zeitro-checkbox" htmlFor="mexican">Mexican</label>
                            </Col>
                            <Col md="auto" style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    required={isHispanicRequired()}
                                    type="checkbox"
                                    id="puertorican"
                                    checked={hispanic.puertorican}
                                    className="mr-1"
                                    onChange={updateEqualOpportunityCheck(who, 'hispanic', 'puertorican')}
                                />
                                <label className="zeitro-checkbox" htmlFor="puertorican">Puerto Rican</label>
                            </Col>
                            <Col md="auto" style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    required={isHispanicRequired()}
                                    type="checkbox"
                                    id="cuban"
                                    checked={hispanic.cuban}
                                    className="mr-1 "
                                    onChange={updateEqualOpportunityCheck(who, 'hispanic', 'cuban')}
                                />
                                <label className=" zeitro-checkbox" htmlFor="cuban">Cuban</label>
                            </Col>
                        </Form.Row>
                        <Form.Row style={{ marginTop: '10px' }}>
                            <Col md="auto" style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    required={isHispanicRequired()}
                                    type="checkbox"
                                    id="other"
                                    className="mr-1"
                                    checked={hispanic.other}
                                    onChange={updateEqualOpportunityCheck(who, 'hispanic', 'other')}
                                />
                                <label className=" zeitro-checkbox" htmlFor="other">Other Hispanic or Latino </label>
                            </Col>
                            {hispanic.other && (
                                <Col md="auto">
                                    <input
                                        required
                                        type="text"
                                        id="othervalue"
                                        style={{ width: '200px' }}
                                        className="gubmint"
                                        defaultValue={hispanic.otherdesctiption}
                                        onChange={onOtherHispanic}
                                        placeholder="Chilean, Perivuan, and so on."
                                    />
                                </Col>
                            )}
                        </Form.Row>
                    </>
                )}

                <Form.Row>
                    <Col className="sectionquestion mt-4">
                        <Form.Label>Sex</Form.Label>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col md={2} xs={12}>
                        <input
                            required
                            type="radio"
                            checked={sex === "male"}
                            onChange={onSex}
                            value="male"
                            name={'sex' + who}
                            id={'male' + who}
                        />
                        <label id="malelabel" className="zeitro-radio" htmlFor={'male' + who}>Male</label>
                    </Col>
                    <Col md={2} xs={12}>
                        <input
                            required
                            type="radio"
                            checked={sex === 'female'}
                            onChange={onSex}
                            value="female"
                            name={'sex' + who}
                            id={'female' + who}
                        />
                        <label id="femalelabel" className="zeitro-radio" htmlFor={'female' + who}>Female</label>
                    </Col>
                    <Col md={3} xs={12} className="ml-4">
                        <input
                            required
                            type="radio"
                            checked={sex === 'donttell'}
                            onChange={onSex}
                            value="donttell"
                            name={'sex' + who}
                            id={'sexdonttell' + who}
                        />
                        <label
                            id="sexdonttelllabel"
                            className="zeitro-radio text-wrap"
                            htmlFor={'sexdonttell' + who}
                            style={{ fontWeight: 'bold' }}
                        >
                            I do not wish to provide this information
                        </label>
                    </Col>
                </Form.Row>

                <Form.Row className="mt-4">
                    <Col className="sectionquestion">
                        <Form.Label>Race</Form.Label>
                    </Col>
                </Form.Row>
                <Form.Row className="mt-1">
                    <Col md="auto" style={{ display: 'flex', alignItems: 'center', }}>
                        <input
                            type="checkbox"
                            id={'dontwantto' + who}
                            required={isRaceRequired()}
                            checked={race.notfurnished}
                            onChange={updateEqualOpportunityCheck(who, 'race', 'notfurnished')}
                        />
                        <label
                            className="ml-1 zeitro-checkbox text-wrap"
                            htmlFor={'dontwantto' + who}
                            style={{ fontWeight: 'bold', marginTop: 3 }}
                        >
                            I do not wish to provide this information
                        </label>
                    </Col>
                </Form.Row>

                {race.notfurnished ? <></> :
                    <>
                        <Form.Row className="mt-1">
                            <Col md="auto" style={{ display: 'flex', alignItems: 'center' }} >
                                <input
                                    type="checkbox"
                                    defaultChecked={race.black}
                                    onChange={updateEqualOpportunityCheck(who, 'race', 'black')}
                                    required={isRaceRequired()}
                                    id={'black' + who}
                                />
                                <label className="ml-1 zeitro-checkbox" htmlFor={'black' + who}>Black or African American</label>
                            </Col>
                            <Col md="auto" style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="checkbox"
                                    required={isRaceRequired()}
                                    defaultChecked={race.white}
                                    onChange={updateEqualOpportunityCheck(who, 'race', 'white')}
                                    id={'white' + who}
                                />
                                <label className="ml-1 zeitro-checkbox" htmlFor={'white' + who}>White</label>
                            </Col>
                        </Form.Row>
                        <Form.Row className="mt-0" >
                            <Col md="auto" className="mt-1" style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="checkbox"
                                    defaultChecked={race.pacific}
                                    onChange={updateEqualOpportunityCheck(who, 'race', 'pacific')}
                                    required={isRaceRequired()}
                                    id={'pacific' + who}
                                />
                                <label className="ml-1 zeitro-checkbox text-wrap" htmlFor={'pacific' + who}>
                                    Native Hawaian or Other Pacific Islander
                                </label>
                            </Col>
                        </Form.Row>
                        {race.pacific && (
                            <>
                                <div className="ml-4" style={{ marginTop: '10px', color: '#222222', fontSize: 14 }}>Check one or more</div>
                                <Form.Row className="mt-1 ml-4">
                                    <Col md="auto" style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            required={isPacificRequired()}
                                            defaultChecked={race.pacificdetails.hawaian}
                                            onChange={updateEqualOpportunityCheck(who, 'race|pacificdetails', 'hawaian')}
                                            type="checkbox"
                                            id={'hawaian' + who}
                                        />
                                        <label className="ml-1 zeitro-checkbox" htmlFor={'hawaian' + who}>Native Hawaian</label>
                                    </Col>
                                    <Col md="auto" style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            required={isPacificRequired()}
                                            defaultChecked={race.pacificdetails.guamanian}
                                            onChange={updateEqualOpportunityCheck(who, 'race|pacificdetails', 'guamanian')}
                                            type="checkbox"
                                            id={'guamanian' + who}
                                        />
                                        <label className="ml-1 zeitro-checkbox" htmlFor={'guamanian' + who}>Guamanian or Chamorro</label>
                                    </Col>
                                    <Col md="auto" style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            required={isPacificRequired()}
                                            defaultChecked={race.pacificdetails.samoan}
                                            onChange={updateEqualOpportunityCheck(who, 'race|pacificdetails', 'samoan')}
                                            type="checkbox"
                                            id={'samoan' + who}
                                        />
                                        <label className="ml-1 zeitro-checkbox" htmlFor={'samoan' + who}>Samoan</label>
                                    </Col>
                                </Form.Row>
                                <Form.Row className="ml-4">
                                    <Col md="auto" style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            required={isPacificRequired()}
                                            defaultChecked={race.pacificdetails.other}
                                            onChange={updateEqualOpportunityCheck(who, 'race|pacificdetails', 'other')}
                                            type="checkbox"
                                            id={'other' + who}
                                        />
                                        <label className="ml-1 zeitro-checkbox" htmlFor={'other' + who}>Other Pacific Islander</label>
                                    </Col>
                                    {race.pacificdetails.other && (
                                        <Col md="auto" style={{ display: 'flex', alignItems: 'center' }}>
                                            <input
                                                type="text"
                                                id="othervalue"
                                                style={{ width: '200px' }}
                                                required
                                                className="gubmint"
                                                placeholder="Fijian, Tongan, and so on."
                                                onChange={onPacific}
                                                defaultValue={race.pacificdetails.otherdetails}
                                            />
                                        </Col>
                                    )}
                                </Form.Row>
                            </>
                        )}
                        <Form.Row className="mt-1" >
                            <Col md="auto" style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    required={isRaceRequired()}
                                    defaultChecked={race.americanindian}
                                    onChange={updateEqualOpportunityCheck(who, 'race', 'americanindian')}
                                    type="checkbox"
                                    value={true}
                                    id={'americanindian' + who}
                                />
                                <label className="ml-1 zeitro-checkbox text-wrap" htmlFor={'americanindian' + who}>
                                    American Indian or Alaska Native
                                </label>
                            </Col>
                            <Col></Col>
                        </Form.Row>
                        {race.americanindian && (
                            <Form.Row className="my-0 py-0">
                                <Col md="auto" className="ml-4 pt-1">Print name of enrolled or principal tribe:</Col>
                                <Col md="auto">
                                    <input
                                        type="text"
                                        required
                                        onChange={onTribe}
                                        defaultValue={race.indiantribe}
                                        className="gubmint ml-2"
                                    />
                                </Col>
                            </Form.Row>
                        )}
                        <Form.Row className="mt-1" >
                            <Col md="auto" style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="checkbox"
                                    id={'asian' + who}
                                    required={isRaceRequired()}
                                    defaultChecked={race.asian}
                                    onChange={updateEqualOpportunityCheck(who, 'race', 'asian')}
                                />
                                <label className="ml-1 zeitro-checkbox" htmlFor={'asian' + who}>Asian</label>
                            </Col>
                        </Form.Row>
                        {race.asian && (
                            <>
                                <div className="ml-4" style={{ marginTop: '10px', color: '#222222', fontSize: 14 }}>Check one or more</div>
                                <Form.Row className="mt-1 ml-4">
                                    <Col md="auto" style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            type="checkbox"
                                            id={'asianindian' + who}
                                            required={isAsianRequired()}
                                            defaultChecked={race.asiandetails.asianindian}
                                            onChange={updateEqualOpportunityCheck(who, 'race|asiandetails', 'asianindian')}
                                        />
                                        <label className="ml-1 zeitro-checkbox" htmlFor={'asianindian' + who}>Asian Indian</label>
                                    </Col>
                                    <Col md="auto" style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            type="checkbox"
                                            id={"chinese" + who}
                                            required={isAsianRequired()}
                                            defaultChecked={race.asiandetails.chinese}
                                            onChange={updateEqualOpportunityCheck(who, 'race|asiandetails', 'chinese')}
                                        />
                                        <label className="ml-1 zeitro-checkbox" htmlFor={'chinese' + who}>Chinese</label>
                                    </Col>
                                    <Col md="auto" style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            type="checkbox"
                                            id={'filipino' + who}
                                            required={isAsianRequired()}
                                            defaultChecked={race.asiandetails.filipino}
                                            onChange={updateEqualOpportunityCheck(who, 'race|asiandetails', 'filipino')}
                                        />
                                        <label className="ml-1 zeitro-checkbox" htmlFor={'filipino' + who}>Filipino</label>
                                    </Col>
                                </Form.Row>
                                <Form.Row className="mt-1 ml-4">
                                    <Col md="auto" style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            type="checkbox"
                                            id={"japanese" + who}
                                            required={isAsianRequired()}
                                            defaultChecked={race.asiandetails.japanese}
                                            onChange={updateEqualOpportunityCheck(who, 'race|asiandetails', 'japanese')}
                                        />
                                        <label className="ml-1 zeitro-checkbox" htmlFor={'japanese' + who}>Japanese</label>
                                    </Col>
                                    <Col md="auto" style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            type="checkbox"
                                            id={'korean' + who}
                                            required={isAsianRequired()}
                                            defaultChecked={race.asiandetails.korean}
                                            onChange={updateEqualOpportunityCheck(who, 'race|asiandetails', 'korean')}
                                        />
                                        <label className="ml-1 zeitro-checkbox" htmlFor={'korean' + who}>Korean</label>
                                    </Col>
                                    <Col md="auto" style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            type="checkbox"
                                            id={"vietnamese" + who}
                                            required={isAsianRequired()}
                                            defaultChecked={race.asiandetails.vietnamese}
                                            onChange={updateEqualOpportunityCheck(who, 'race|asiandetails', 'vietnamese')}
                                        />
                                        <label className="ml-1 zeitro-checkbox" htmlFor={'vietnamese' + who}>Vietnamese</label>
                                    </Col>
                                </Form.Row>
                                <Form.Row className="mt-1 ml-4">
                                    <Col md="auto" style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            type="checkbox"
                                            id={"otherasian" + who}
                                            required={isAsianRequired()}
                                            defaultChecked={race.asiandetails.otherasian}
                                            onChange={updateEqualOpportunityCheck(who, 'race|asiandetails', 'otherasian')}
                                        />
                                        <label className="ml-1 zeitro-checkbox" htmlFor={'otherasian' + who}>Other Asian</label>
                                    </Col>
                                    {race.asiandetails.otherasian && (
                                        <Col md="3 ">
                                            <input
                                                type="text"
                                                required
                                                id={'othervalue' + who}
                                                style={{ width: '200px' }}
                                                onChange={onOtherAsian}
                                                className="gubmint"
                                                defaultValue={race.asiandetails.otherdesctiption}
                                                placeholder="Hmong, Laotian, and so on."
                                            />
                                        </Col>
                                    )}
                                </Form.Row>
                            </>
                        )}
                    </>
                }
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(Finances)

const mapStateToPropsStocks = (state) => {
    return {
        finances: state.application.borrower.finances,
        application: state.application,
        borrower: state.application.borrower.declarations,
        coborrower: state.application.coborrower.declarations,
        hascoborrower: state.application.hascoborrower,
    }
}

const mapDispatchToPropsStocks = (dispatch) => ({
    updateFinances: (event, who, verb) => {
        dispatch(act.UpdateFinances(event.target.value, who, verb))
    },
    addBorrowerOtherAsset: (event, who) => {
        dispatch(act.AddBorrowerOtherAsset(event, who))
    },
    removeBorrowerOtherAsset: (event, index, who) => {
        dispatch(act.RemoveBorrowerOtherAsset(index, who))
    },
    clearBorrowerOtherAssets: (event, who) => {
        dispatch(act.ClearBorrowerOtherAssets(event, who))
    },
    changeBorrowerOtherAssetName: (event, index, who) => {
        dispatch(
            act.ChangeBorrowerOtherAssetName(event.target.value, index, who)
        )
    },
    changeBorrowerOtherAssetValue: (event, index, who) => {
        dispatch(act.ChangeBorrowerOtherAssetValue(event, index, who))
    },
    changeBorrowerOtherAssetAttribution: (event, index) => {
        dispatch(
            act.ChangeBorrowerOtherAssetAttribution(event.target.value, index)
        )
    },
    changeBorrowerOtherAsset: (value, verb, index) => {
        dispatch(act.ChangeBorrowerOtherAsset(value, verb, index))
    },
    changeBorrowerInsuranceValue: (event, index, verb) => {
        dispatch(
            act.ChangeBorrowerInsuranceValue(event.target.value, index, verb)
        )
    },
    clearBorrowerInsurance: () => {
        dispatch(act.ClearBorrowerInsurance())
    },
    removeBorrowerInsurance: (index) => {
        dispatch(act.RemoveBorrowerInsurance(index))
    },
    addBorrowerInsurance: () => {
        dispatch(act.AddBorrowerInsurance())
    },
    addBorrowerGift: () => {
        dispatch(act.AddBorrowerGift())
    },
    removeBorrowerGift: (index) => {
        dispatch(act.RemoveBorrowerGift(index))
    },
    clearBorrowerGift: () => {
        dispatch(act.ClearBorrowerGift())
    },
    updateBorrowerGift: (value, verb, index) => {
        dispatch(act.UpdateBorrowerGift(value, verb, index))
    },
    changeBorrowerInfo: (event, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(event, who, verb))
    },
    removeBorrowerBankAccount: (index, who) => {
        dispatch(act.RemoveBorrowerBankAccount(index, who))
    },
    changeBorrowerBankName: (event, index, who) => {
        dispatch(act.ChangeBorrowerBankName(event.target.value, index, who))
    },
    changeBorrowerBankAccount: (event, index, who) => {
        dispatch(act.ChangeBorrowerBankAccount(event.target.value, index, who))
    },
    changeBorrowerBankBalance: (event, index, who) => {
        dispatch(act.ChangeBorrowerBankBalance(event, index, who))
    },
    addBorrowerBankAccount: (i, who) => {
        dispatch(act.AddBorrowerBankAccount(i, who))
    },
    changeBorrowerBankAttribution: (event, index, who) => {
        dispatch(
            act.ChangeBorrowerBankAttribution(event.target.value, index, who)
        )
    },
    changeBorrowerBankAccountType: (event, index, who) => {
        dispatch(
            act.ChangeBorrowerBankAccountType(event.target.value, index, who)
        )
    },
    clearBorrowerBankAccounts: (index, who) => {
        dispatch(act.ClearBorrowerBankAccounts(index, who))
    },
})

export class MoreFinances extends Component {
    constructor(props) {
        super(props)

        this.state = {
            validated: false,
            totalAssets: 0,
        }

        this.isValidated = this.isValidated.bind(this)
        this.form = React.createRef()
        this.formRef = React.createRef()
    }

    recalcAssets = () => {
        setTimeout(
            () => this.setState({ totalAssets: this.calculateAssets() }),
            100
        )
    }

    componentDidMount() {
        this.recalcAssets()

        const { gifts, otherassets } = this.props.application.assetsandliabilities
        if (gifts === null) {
            this.props.clearBorrowerGift()
        }
        if (null === otherassets) {
            this.props.clearBorrowerOtherAssets(0, this.props.who)
        }
        setTimeout(() => {
            this.completion()
        }, 100)
    }

    componentDidUpdate(prevProps) {
        this.completion()
    }

    componentWillUnmount() {}

    completion = () => {
        const form = this.props.self.current
        const formElements = form?.elements
        if (formElements && formElements.length !== 0) {
            let totalCount = 2
            let fillNum = 2
            for (let i = 0; i < formElements.length; i++) {
                const element = formElements[i]
                if (!['button', 'fieldset', 'submit'].includes(element.type)) {
                    totalCount++
                    if (!isEmpty(element.value)) {
                        fillNum++
                    }
                }
            }
            const progressValue = Math.round((fillNum / totalCount) * 100)
            sessionStorage.setItem('progressValue', progressValue)
        }
    }

    onFinish = () => {
        const { who } = this.props
        const { completedsteps } = this.props.application[who] || {}

        if (!completedsteps?.assets) {
            this.props.changeBorrowerInfo(
                { ...completedsteps, assets: true },
                who,
                'completedsteps'
            )
        }
    }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        }

        return true
    }

    updateInsurance = (e) => {
        com.touch()

        const val = e.target.value === 'true'
        this.props.updateFinances(
            { target: { value: val } },
            this.props.who,
            'havelifeinsurance'
        )
    }

    calculateAssets = getassets.bind(this)

    processInsurance = (val, index) => {
        if (null === val) return ''

        const { issuer, netvalue, facevalue, key, attribution } = val

        return this.renderInsurance(
            index,
            issuer,
            netvalue,
            facevalue,
            attribution,
            key
        )
    }

    renderInsurance(index, issuer, netvalue, facevalue, attribution, key) {
        index = parseInt(index)

        const changeBorrowerInsuranceValue = (verb) => {
            return (event) => {
                com.touch()
                this.props.changeBorrowerInsuranceValue(event, index, verb)
            }
        }

        const removeBorrowerInsurance = (event) => {
            com.touch()
            this.props.removeBorrowerInsurance(index)
        }
        const addBorrowerInsurance = (event) => {
            com.touch()
            this.props.addBorrowerInsurance()
        }

        const { lifeinsurance } = this.props.application.assetsandliabilities
        const { hascoborrower, married } = this.props.application

        const borrowerName = `${this.props.application.borrower.firstname} ${this.props.application.borrower.lastname}`
        const coborrowerName = `${this.props.application.coborrower.firstname} ${this.props.application.coborrower.lastname}`

        return (
            <div className="incard m-0 mb-3 px-1" key={key}>
                <Row>
                    <Col className="pl-2">
                        <Form.Row className="">
                            <Col xs="auto" className="mr-2 p-0">
                                <Form.Group
                                    controlId={'CompanyName' + index}
                                    className="text-left  m-0 p-0"
                                >
                                    <Form.Label className="text-left">
                                        Issuer:
                                    </Form.Label>
                                    <Form.Control
                                        isValid={false}
                                        size="sm"
                                        required
                                        name={'issuer' + index}
                                        type="text"
                                        defaultValue={
                                            lifeinsurance[index].issuer
                                        }
                                        onChange={changeBorrowerInsuranceValue(
                                            'issuer'
                                        )}
                                        pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide issuer.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs="auto" className="text-left  mr-2  p-0">
                                <Form.Group
                                    controlId={'face' + index}
                                    className="text-left  m-0 p-0"
                                >
                                    <Form.Label className="text-left">
                                        Net Value:
                                    </Form.Label>
                                    <NumericalInput
                                        size="sm"
                                        required
                                        onBlur={this.recalcAssets}
                                        name={'amount' + index}
                                        defaultValue={
                                            lifeinsurance[index].netvalue
                                        }
                                        onChange={changeBorrowerInsuranceValue(
                                            'netvalue'
                                        )}
                                        min={1}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide your annual income.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs="auto" className="text-left  p-0">
                                <Form.Group
                                    controlId={'face' + index}
                                    className="text-left  m-0 p-0"
                                >
                                    <Form.Label className="text-left">
                                        Face Value:
                                    </Form.Label>
                                    <NumericalInput
                                        size="sm"
                                        required
                                        name={'amount' + index}
                                        defaultValue={
                                            lifeinsurance[index].facevalue
                                        }
                                        onChange={changeBorrowerInsuranceValue(
                                            'facevalue'
                                        )}
                                        min={1}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide your annual income.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            {hascoborrower === 'withcoborrower' && married && (
                                <Col xs="auto">
                                    <Form.Label className="text-left">
                                        Ownership
                                    </Form.Label>
                                    <Form.Group controlId="employment">
                                        <Form.Control
                                            name="employment"
                                            as="select"
                                            size="sm"
                                            defaultValue={attribution}
                                            required
                                            onChange={changeBorrowerInsuranceValue(
                                                'attribution'
                                            )}
                                        >
                                            <option value="">...</option>
                                            <option value="1">
                                                {borrowerName}
                                            </option>
                                            <option value="2">
                                                {coborrowerName}
                                            </option>
                                            <option value="3">Joint</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            )}
                        </Form.Row>
                    </Col>
                    <Col
                        xs="auto"
                        as="div"
                        className="text-right aligh-top  m-0 p-0"
                    >
                        <i
                            hidden={index !== lifeinsurance.length - 1}
                            className="far fahover fa-plus-square text-primary aligh-top fa-1x mr-1 divlink"
                            onClick={addBorrowerInsurance}
                        ></i>
                        <i
                            hidden={index === lifeinsurance.length - 1}
                            className="far fahover fa-plus-square aligh-top fa-1x mr-1 transparent"
                        >
                            {' '}
                        </i>
                        <i
                            className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink"
                            onClick={removeBorrowerInsurance}
                        ></i>
                    </Col>
                </Row>
            </div>
        )
    }

    processGifts = (val, index) => {
        const { assettype, deposited, source, attribution, value, key } = val
        return this.renderGifts(index, assettype, deposited, source, attribution, value, key)
    }

    renderGifts = (index, assettype, deposited, source, attribution, value, key) => {
        const removeGift = (e) => {
            this.props.removeBorrowerGift(index)
        }
        const addGift = (e) => {
            this.props.addBorrowerGift()
        }
        const achangeGiftValue = (verb) => {
            return (e) => {
                this.props.updateBorrowerGift(e, verb, index)
            }
        }
        const changeGiftAmount = (e) => {
            this.props.updateBorrowerGift(e < 1 ? 1 : e, 'value', index)
        }

        const getSourceDescription = () => {
            const { gifts } = this.props.application.assetsandliabilities
            const t = gifts[index].source
            if (t === 'Other') return 'Source'

            let help = ''
            com.fundssourcetype.forEach((st) => {
                if (t === st[0]) {
                    help = st[2]
                }
            })

            if (help === '') return 'Source'

            return (
                <>
                    Source
                    <Tips content={help} placement="bottom" title="Source" />
                </>
            )
        }

        const changeAttribution = (e) => {
            this.props.updateBorrowerGift(e.target.value, 'attribution', index)
        }
        const borrowerName = `${this.props.application.borrower.firstname} ${this.props.application.borrower.lastname}`
        const coborrowerName = `${this.props.application.coborrower.firstname} ${this.props.application.coborrower.lastname}`


        const showAttribution = () => {
            if (attribution === 1) return borrowerName
            if (attribution === 2) return coborrowerName
            if (attribution === 3) return 'Joint'
            return ''
        }

        const changeGiftSource = (src) => {
            this.props.updateBorrowerGift(src, 'source', index)
            let desc = 0
            com.fundssourcetype.forEach((st) => {
                if (st[0] === src) {
                    desc = st[1]
                    if (desc === '') desc = com.unCamelCase(st[0])
                }
            })
            if (src === 'Other') desc = 0

            this.props.updateBorrowerGift(desc, 'sourcedescription', index)
        }

        const getDeposited = () => {
            const { gifts } = this.props.application.assetsandliabilities
            if (gifts[index].deposited === true) return 'deposited'
            if (gifts[index].deposited === false) return 'notdeposited'
            return ''
        }

        const changeDeposited = (event) => {
            if (event === 'deposited') {
                this.props.updateBorrowerGift(true, 'deposited', index)
            } else if (event === 'notdeposited') {
                this.props.updateBorrowerGift(false, 'deposited', index)
            } else this.props.updateBorrowerGift(null, 'deposited', index)
        }

        const { gifts } = this.props.application.assetsandliabilities
        const { hascoborrower, married } = this.props.application


        return (
            <div key={'gift' + key} className="finances-type">
                <div className="incard mt-4 pt-0" style={{ margin: '1rem 15px', padding: '1rem' }}>
                    <Row>
                        <Col>
                            <Form.Row className="finances-type-mobile">
                                <Col className="mt-4">
                                    <Form.Label className="text-left">
                                        Asset type
                                    </Form.Label>
                                    <Form.Group controlId={'assettype' + key}>
                                        <div>
                                            <Select
                                                style={{ width: '100%', height: 44 }}
                                                value={['GiftOfCash', 'GiftOfPropertyEquity', 'Grant'].includes(assettype) ? assettype : ''}
                                                onChange={achangeGiftValue('assettype')}
                                            >
                                                <Select.Option value="GiftOfCash">Cash Gift</Select.Option>
                                                <Select.Option value="GiftOfPropertyEquity">Gift of Equity</Select.Option>
                                                <Select.Option value="Grant">Grant</Select.Option>
                                            </Select>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col className="mt-4">
                                    <Form.Label className="text-left">
                                        Is deposited?
                                        <Tips
                                            placement="bottom"
                                            title="Is it already deposited in your bank account"
                                        />
                                    </Form.Label>
                                    <Form.Group controlId={'deposited' + key}>
                                        <div>
                                            <Select
                                                style={{ width: '100%', height: 44 }}
                                                value={getDeposited()}
                                                onChange={(e) => changeDeposited(e)}
                                            >
                                                <Select.Option value="deposited">Deposited</Select.Option>
                                                <Select.Option value="notdeposited">Not Deposited</Select.Option>
                                            </Select>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col className="mt-4">
                                    <Form.Group controlId={'source' + key}>
                                        <Form.Label className="text-left">
                                            {getSourceDescription()}
                                        </Form.Label>
                                        <div>
                                            <Select
                                                onChange={(e) => { changeGiftSource(e) }}
                                                style={{ width: '100%', height: 44 }}
                                                value={gifts[index].sourcedescription || gifts[index].source}
                                            >
                                                {com.fundssourcetype.map(
                                                    (st, index) => {
                                                        let desc = st[1]
                                                        if ('' === desc) {
                                                            desc = com.unCamelCase(st[0])
                                                        }
                                                        return (
                                                            <Select.Option key={index} value={st[0]} >{desc}</Select.Option>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col className="mt-4">
                                    <Form.Group controlId={'GiftAmount' + key} className="text-left  m-0 p-0">
                                        <Form.Label className="text-left">
                                            Market value
                                        </Form.Label>
                                        <div style={{}}>
                                            <DollarInput
                                                onChange={(e) => changeGiftAmount(e)}
                                                value={gifts[index].value}
                                            />
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide gift value.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                {hascoborrower === 'withcoborrower' && married && (
                                <Col className="mt-4">
                                    <Form.Label className="text-left">
                                        Ownership
                                    </Form.Label>
                                    <div>
                                        <Select
                                            style={{ width: '100%', height: 44 }}
                                            value={showAttribution()}
                                            onChange={(e) => changeAttribution({ target: { value: e } })}
                                        >
                                            <Select.Option value="1">{borrowerName}</Select.Option>
                                            <Select.Option value="2">{coborrowerName}</Select.Option>
                                            <Select.Option value="3">Joint</Select.Option>
                                        </Select>
                                    </div>
                                </Col>
                            )}
                            </Form.Row>
                        </Col>

                        <Col xs="auto" as="div" className="text-right aligh-top  m-0 p-0">
                            <i
                                hidden={index !== gifts.length - 1}
                                className="far fahover fa-plus-square text-primary aligh-top fa-1x mr-1 divlink"
                                onClick={addGift}
                            />
                            <i
                                hidden={index === gifts.length - 1}
                                className="far fahover fa-plus-square aligh-top fa-1x mr-1 transparent"
                            >
                                {' '}
                            </i>
                            <i
                                className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink"
                                onClick={removeGift}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }

    processAssets = (val, index) => {
        const { description, value, attribution, key } = val
        return this.listOtherAssets(index, description, value, attribution, key)
    }

    addOtherAssets = (e) => {
        com.touch()
        const val = e.target.value === 'true'
        if (!val) {
            this.props.clearBorrowerOtherAssets(0, this.props.who)
        } else {
            this.props.addBorrowerOtherAsset(0, this.props.who)
        }
    }

    listOtherAssets = (index, description, value, attribution, key) => {
        index = parseInt(index)
        const addBorrowerOtherAsset = (event) => {
            com.touch()
            this.props.addBorrowerOtherAsset(event, this.props.who)
        }
        const removeBorrowerOtherAsset = (event) => {
            com.touch()
            this.props.removeBorrowerOtherAsset(event, index, this.props.who)
        }

        const changeBorrowerOtherAssetValue = (event) => {
            com.touch()
            this.props.changeBorrowerOtherAssetValue(
                event,
                index,
                this.props.who
            )
        }

        const changeBorrowerOtherAssetAttribution = (event) => {
            com.touch()
            this.props.changeBorrowerOtherAssetAttribution(event, index)
        }

        const otherAssetSelect = (event) => {
            com.touch()
            this.props.changeBorrowerOtherAsset(event, 'assettype', index)

            let description = ''
            com.otherassetsandcredits.forEach((st) => {
                if (event === st[0]) {
                    description = st[1]
                }
            })

            if (event === 'Other') {
                this.props.changeBorrowerOtherAsset('', 'description', index)
            } else {
                this.props.changeBorrowerOtherAsset(
                    description,
                    'description',
                    index
                )
            }

            getCurrentState()
        }

        const getDescription = () => {
            const { otherassets } = this.props.application.assetsandliabilities
            const t = otherassets[index].assettype
            if (t === 'Other') return 'Asset or Credit Type'

            let help = ''
            com.otherassetsandcredits.forEach((st) => {
                if (t === st[0]) {
                    help = st[2]
                }
            })

            if (help === '') return 'Asset or Credit Type'

            return <Tips content={help} placement="bottom" title="Asset or Credit Type" />
        }

        const { otherassets } = this.props.application.assetsandliabilities
        const { hascoborrower, married } = this.props.application

        return (
            <div key={'other' + key} className="finances-type">
                <div className="incard mt-4 pt-0" style={{ margin: '1rem 15px', padding: '1rem' }}>
                    <Row>
                        <Col>
                            <Form.Row className="finances-type-mobile">
                                <Col className="mt-4">
                                    <Form.Group controlId="unmarriedtype">
                                        <Form.Label className="text-left">
                                            {getDescription()}
                                        </Form.Label>
                                        <div>
                                            <Select
                                                style={{ height: 44, width: '100%' }}
                                                value={otherassets[index].description || otherassets[index].assettype}
                                                onChange={(e) => otherAssetSelect(e)}
                                            >
                                                {com.otherassetsandcredits.map(
                                                    (st, index) => {
                                                        return (
                                                            <Select.Option key={index} onSelect={otherAssetSelect} value={st[0]}>
                                                                {st[1]}
                                                            </Select.Option>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col className="mt-4">
                                    <Form.Group controlId="Account" className="text-left">
                                        <Form.Label className="text-left">
                                            Value
                                        </Form.Label>
                                        <div style={{}}>
                                            <DollarInput
                                                value={otherassets[index].value}
                                                onChange={(e) =>
                                                    changeBorrowerOtherAssetValue(e < 1 ? 1 : e)
                                                }
                                                onBlur={this.recalcAssets}
                                            />
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide your middle name.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                {hascoborrower === 'withcoborrower' &&
                                    married && (
                                        <Col xs="2 ml-4">
                                            <Form.Label className="text-left">
                                                Ownership
                                            </Form.Label>
                                            <Form.Group controlId="employment">
                                                <Form.Control
                                                    name="employment"
                                                    as="select"
                                                    size="sm"
                                                    defaultValue={attribution}
                                                    onChange={
                                                        changeBorrowerOtherAssetAttribution
                                                    }
                                                    required
                                                >
                                                    <option value="">
                                                        ...
                                                    </option>
                                                    <option value="1">{`${this.props.application.borrower.firstname} ${this.props.application.borrower.lastname}`}</option>
                                                    <option value="2">{`${this.props.application.coborrower.firstname} ${this.props.application.coborrower.lastname} `}</option>
                                                    <option value="3">
                                                        Joint
                                                    </option>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    )}
                            </Form.Row>
                        </Col>
                        <Col xs="auto" as="div" className="text-right aligh-top m-0 p-0">
                            <i
                                hidden={index !== otherassets.length - 1}
                                className="far fahover fa-plus-square text-primary aligh-top fa-1x mr-1 divlink"
                                onClick={addBorrowerOtherAsset}
                            />
                            <i
                                hidden={index === otherassets.length - 1}
                                className="far fahover fa-plus-square aligh-top fa-1x mr-1 transparent"
                            >
                                {' '}
                            </i>
                            <i
                                className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink"
                                onClick={removeBorrowerOtherAsset}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }

    generateAccount = (index, bankname, account, balance, attribution, accounttype, key) => {
        index = parseInt(index)

        const removeBorrowerBankAccount = (event) => {
            com.touch()
            this.props.removeBorrowerBankAccount(index, this.props.who)
        }
        const updateBank = (event) => {
            com.touch()
            this.props.changeBorrowerBankName(event, index, this.props.who)
        }
        const updateAccount = (event) => {
            com.touch()
            this.props.changeBorrowerBankAccount(event, index, this.props.who)
        }
        const changeBorrowerBankBalance = (event) => {
            com.touch()
            this.props.changeBorrowerBankBalance(event, index, this.props.who)
        }

        const addBorrowerBankAccount = (event) => {
            com.touch()
            return this.props.addBorrowerBankAccount(event, this.props.who)
        }

        const changeAttribution = (event) => {
            com.touch()
            this.props.changeBorrowerBankAttribution(
                event,
                index,
                this.props.who
            )
        }

        const onSelect = (e) => {
            com.touch()
            this.props.changeBorrowerBankAccountType(
                { target: { value: e } },
                index,
                this.props.who
            )
        }

        let at = undefined
        com.bankaccounttypes.forEach((x) => {
            if (x[0] === accounttype) at = x[1]
        })

        if (undefined !== at) accounttype = at

        const { hascoborrower, married } = this.props.application
        const { accounts } = this.props.application.assetsandliabilities

        return (
            <div key={key} className="finances-type">
                <div className="incard mt-4" style={{ margin: '1rem 15px', paddingTop: 0 }}>
                    <Row>
                        <Col style={{ paddingTop: '30px' }}>
                            <Form.Row className="finances-type-mobile">
                                <Col>
                                    <Form.Group controlId="Bank" className="text-left" size="sm">
                                        <Form.Label className="text-left text-nowrap">
                                            Institution
                                        </Form.Label>
                                        <Form.Control
                                            isValid={false}
                                            size="sm"
                                            required
                                            name={'bankname' + index}
                                            autoFocus
                                            type="text"
                                            defaultValue={bankname}
                                            onChange={updateBank}
                                            pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide the name of the bank/credit union.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId={'type' + index} className="text-left">
                                        <Form.Label className="text-left text-nowrap">
                                            Account type
                                        </Form.Label>
                                        <div>
                                            <Select
                                                onChange={(e) => onSelect(e)}
                                                style={{ height: 44, width: '100%' }}
                                                value={accounts[index].accounttype}
                                            >
                                                {com.bankaccounttypes.map(
                                                    (st, index) => {
                                                        return (
                                                            <Select.Option key={index} value={st[0]}>
                                                                {st[1]}
                                                            </Select.Option>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="Account" className="text-left" size="sm">
                                        <Form.Label className="text-left text-nowrap">
                                            Account number
                                        </Form.Label>
                                        <Form.Control
                                            isValid={false}
                                            size="sm"
                                            style={{}}
                                            required
                                            name={'account' + index}
                                            type="text"
                                            defaultValue={account}
                                            onChange={updateAccount}
                                            placeholder="XXXX (last four digits)"
                                            pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide account number.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="Balance" className="text-left" size="sm">
                                        <Form.Label className="text-left">
                                            Balance
                                        </Form.Label>
                                        <div>
                                            <DollarInput
                                                value={balance}
                                                defaultValue={balance}
                                                onBlur={this.recalcAssets()}
                                                onChange={changeBorrowerBankBalance}
                                            />
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide account balance.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                {hascoborrower === 'withcoborrower' && married && (
                                    <Col>
                                        <Form.Label className="text-left">
                                            Ownership
                                        </Form.Label>
                                        <Form.Group controlId="employment">
                                            <Form.Control
                                                name="employment"
                                                as="select"
                                                size="sm"
                                                defaultValue={attribution}
                                                onChange={changeAttribution}
                                                required
                                            >
                                                <option value="">...</option>
                                                <option value="1">{`${this.props.application.borrower.firstname} ${this.props.application.borrower.lastname}`}</option>
                                                <option value="2">{`${this.props.application.coborrower.firstname} ${this.props.application.coborrower.lastname} `}</option>
                                                <option value="3">Joint</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                )}
                            </Form.Row>
                        </Col>
                        <Col xs="auto" as="div" className="text-right aligh-top m-0 p-0">
                            <i
                                hidden={index !== accounts.length - 1}
                                className="far fahover fa-plus-square text-primary aligh-top fa-1x mr-1 divlink"
                                onClick={addBorrowerBankAccount}
                            />
                            <i
                                hidden={index === accounts.length - 1}
                                className="far fahover fa-plus-square aligh-top fa-1x mr-1 transparent"
                            >
                                {' '}
                            </i>
                            <i
                                className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink"
                                onClick={removeBorrowerBankAccount}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }

    process = (val, index) => {
        const { bankname, account, key, balance, attribution, accounttype } = val
        return this.generateAccount(index, bankname, account, balance, attribution, accounttype, key)
    }

    render() {
        const yesnooptions = [
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' },
        ]

        const { who } = this.props
        const { accounts, gifts, otherassets } = this.props.application.assetsandliabilities

        return (
            <div className="text-left newInterviewPaneProperty">
                <Row>
                    <Col>
                        <div className="page-header">
                            <span className="page-header-primary">Finance</span>
                            <span className="page-header-separator">/</span>
                            <span className="page-header-secondary">Assets</span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="mt-4" md={6} xs={12}>
                        <Form.Group className="inputSection">
                            <Form.Label>
                                <span style={{ whiteSpace: 'nowrap' }}>
                                    Would you like to add any financial accounts to your application?
                                    <Tips
                                        content={
                                            <div>
                                                This is only necessary for{' '}<b>asset depletion</b> mortgages, and to ensure you have enough reserves for certain loans.
                                            </div>
                                        }
                                        placement="bottom"
                                        title="Asset depletion only"
                                        />
                                </span>
                            </Form.Label>
                            <CheckGroup
                                data={yesnooptions}
                                onChangeSelect={(e) => {
                                    com.touch()
                                    const val = e === 'yes'
                                    if (!val) {
                                        this.props.clearBorrowerBankAccounts(0, who)
                                    } else {
                                        this.props.addBorrowerBankAccount(0, who)
                                    }
                                }}
                                value={!isEmpty(accounts) ? 'yes' : 'no'}
                                type="radio"
                                direction="horizontal"
                            />
                        </Form.Group>
                    </Col>
                </Row>
                {!isEmpty(accounts) && accounts.map(this.process)}
                <Row>
                    <Col className="mt-4" xs={12}>
                        <Form.Label className="w-100">
                            Have you received any gifts in the last 2 months, or do you expect to receive any gifts to help with obtaining this loan?
                        </Form.Label>
                    </Col>
                    <Col md={6} xs={12}>
                        <Form.Group className="inputSection">
                            <CheckGroup
                                data={yesnooptions}
                                onChangeSelect={(e) => {
                                    com.touch()
                                    const val = e === 'yes'
                                    if (val) {
                                        this.props.addBorrowerGift()
                                    } else {
                                        this.props.clearBorrowerGift()
                                    }
                                }}
                                value={!isEmpty(gifts) ? 'yes' : 'no'}
                                type="radio"
                                direction="horizontal"
                            />
                        </Form.Group>
                    </Col>
                </Row>
                {!isEmpty(gifts) && gifts.map(this.processGifts)}
                <Row>
                    <Col className="mt-4" xs={12}>
                        <Form.Label className="w-100">
                            Do you have any other assets or credits you would like to use to qualify for this loan?
                        </Form.Label>
                    </Col>
                    <Col md={6} xs={12}>
                        <Form.Group className="inputSection">
                            <CheckGroup
                                data={yesnooptions}
                                onChangeSelect={(e) => {
                                    com.touch()
                                    const val = e === 'yes'
                                    if (!val) {
                                        this.props.clearBorrowerOtherAssets(0, who)
                                    } else {
                                        this.props.addBorrowerOtherAsset(0, who)
                                    }
                                }}
                                value={!isEmpty(otherassets) ? 'yes' : 'no'}
                                type="radio"
                                direction="horizontal"
                            />
                        </Form.Group>
                    </Col>
                </Row>
                {!isEmpty(otherassets) && otherassets.map(this.processAssets)}
            </div>
        )
    }
}

MoreFinances = connect(mapStateToPropsStocks, mapDispatchToPropsStocks, null, {
    forwardRef: true,
})(MoreFinances)
