export const IncomeLimits = {
  Alameda: [120880, 282000, 1089300],
  Alpine: [75600, 188000, 726200],
  Amador: [69280, 171000, 726200],
  Butte: [68000, 168000, 726200],
  Calaveras: [72000, 178000, 726200],
  Colusa: [64240, 159000, 726200],
  "Contra Costa": [120880, 282000, 1089300],
  "Del Norte": [64240, 159000, 726200],
  "El Dorado": [81920, 202000, 763600],
  Fresno: [58320, 159000, 726200],
  Glenn: [64240, 159000, 726200],
  Humboldt: [64240, 159000, 726200],
  Imperial: [51120, 159000, 726200],
  Inyo: [66160, 163000, 726200],
  Kern: [54320, 159000, 726200],
  Kings: [54400, 159000, 726200],
  Lake: [64240, 159000, 726200],
  Lassen: [64240, 159000, 726200],
  "Los Angeles": [78320, 180000, 1089300],
  Madera: [60400, 159000, 726200],
  Marin: [120880, 300000, 1089300],
  Mariposa: [64240, 159000, 726200],
  Mendocino: [64240, 159000, 726200],
  Merced: [58480, 159000, 726200],
  Modoc: [64240, 159000, 726200],
  Mono: [64240, 161000, 726200],
  Monterey: [72080, 178000, 915400],
  Napa: [95520, 236000, 1017750],
  Nevada: [78720, 194000, 726200],
  Orange: [78320, 235000, 1089300],
  Placer: [81920, 202000, 763600],
  Plumas: [65920, 163000, 726200],
  Riverside: [69920, 173000, 726200],
  Sacramento: [81920, 202000, 763600],
  "San Benito": [133280, 208000, 1089300],
  "San Bernardino:": [69920, 173000, 726200],
  "San Diego": [85520, 211000, 977500],
  "San Francisco": [120880, 300000, 1089300],
  "San Joaquin": [68000, 168000, 726200],
  "San Luis Obispo": [87360, 216000, 911950],
  "San Mateo": [120880, 300000, 1089300],
  "Santa Barbara": [80080, 198000, 805000],
  "Santa Clara": [133280, 300000, 1089300],
  "Santa Cruz": [95440, 236000, 1089300],
  Shasta: [71840, 177000, 726200],
  Sierra: [72000, 178000, 726200],
  Siskiyou: [64240, 159000, 726200],
  Solano: [86960, 215000, 726200],
  Sonoma: [90240, 223000, 861350],
  Stanislaus: [63440, 159000, 726200],
  Sutter: [60720, 159000, 726200],
  Tehama: [64240, 159000, 726200],
  Trinity: [64240, 159000, 726200],
  Tulare: [53520, 159000, 726200],
  Tuolumne: [67440, 166000, 726200],
  Ventura: [92320, 228000, 948750],
  Yolo: [81920, 211000, 763600],
  Yuba: [60720, 159000, 726200],
};

export const newCountyIncomeLimit ={
  Alameda: 234000,
  Alpine: 181000,
  Amador: 160000,
  Butte: 145000,
  Calaveras: 152000,
  Colusa: 132000,
  "Contra Costa": 234000,
  "Del Norte": 132000,
  "El Dorado": 180000,
  Fresno: 132000,
  Glenn: 132000,
  Humboldt: 132000,
  Imperial: 132000,
  Inyo: 135000,
  Kern: 132000,
  Kings: 132000,
  Lake: 132000,
  Lassen: 132000,
  "Los Angeles": 155000,
  Madera: 132000,
  Marin: 277000,
  Mariposa: 132000,
  Mendocino: 142000,
  Merced: 132000,
  Modoc: 132000,
  Mono: 151000,
  Monterey: 159000,
  Napa: 205000,
  Nevada: 178000,
  Orange: 202000,
  Placer: 180000,
  Plumas: 132000,
  Riverside: 149000,
  Sacramento: 180000,
  "San Benito": 222000,
  "San Bernardino:": 149000,
  "San Diego": 185000,
  "San Francisco": 277000,
  "San Joaquin": 158000,
  "San Luis Obispo": 179000,
  "San Mateo": 277000,
  "Santa Barbara": 169000,
  "Santa Clara": 287000,
  "Santa Cruz": 210000,
  Shasta: 142000,
  Sierra: 142000,
  Siskiyou: 132000,
  Solano: 178000,
  Sonoma: 202000,
  Stanislaus: 146000,
  Sutter: 132000,
  Tehama: 132000,
  Trinity: 132000,
  Tulare: 132000,
  Tuolumne: 154000,
  Ventura: 195000,
  Yolo: 180000,
  Yuba: 132000,
}