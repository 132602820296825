import { Button, Form, Input, Popconfirm, Table, Select } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import { commaize } from "../../Common";



const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  type,
  options,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };
  let childNode = children;
  if (type === "operation") {
    return <td {...restProps}>{childNode}</td>
  }
  try {
    if (editing) {
      let ele = <Input ref={inputRef} onPressEnter={save} onBlur={save} />;
      if (type === "inputdollar") {
        ele = (
          <Input ref={inputRef} onPressEnter={save} onBlur={save} prefix="$" />
        );
      } else if (type === "inputdollarmonth") {
        ele = (
          <Input
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            suffix="mo"
            prefix="$"
          />
        );
      } else if (type === "select") {
        ele = (
          <Select
            ref={inputRef}
            onBlur={save}
            onChange={save}
            options={options}
          />
        );
      }
      childNode = (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          {ele}
        </Form.Item>
      );
    } else {
      let text = record[dataIndex].toString().split(/(?=[A-Z][a-z])/).join(' ')
      if (type === "inputdollarmonth" || type === "inputdollar") {
        text = "$ " + commaize(text) 
      }
      childNode = (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {text}
        </div>
      );
    }
    return <td {...restProps}>{childNode}</td>;
  } catch (error) {
    return <td {...restProps}>No data</td>;
  }

};

const EditableTable = (props) => {
  const [dataSource, setDataSource] = useState(props.dataSource.map(obj => ({
    ...obj, 
    key: Math.random()
})));
  useEffect(()=>{
    console.log(dataSource)
  },[dataSource])
  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
    sendData(newData)
  };
  const defaultColumns = [
    ...props.columns,
    {
      title: "Delete",
      dataIndex: "operation",
      fixed: 'right',
      width: 80,
      type: "operation",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <MinusCircleOutlined  style={{fontSize:16, color:"#325CEB", cursor:"pointer"}}/>
          </Popconfirm>
        ) : null,
    },
  ];
  const handleAdd = () => {
    const newData = {
      ...props.newDataTemplate,
      key: Math.random().toString(),
    };
    setDataSource([...dataSource, newData]);
  };
  const sendData = (newData) => {
    props.saveFunc(newData);
  }

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
    sendData(newData.map(({ key, ...rest }) => rest))
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        record,
        type: col.type,
        options: col.options,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  return (
    <div className="incomeSourcesTable">
      <Table
        scroll={{
          x: true,
        }}
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
      <Button onClick={handleAdd} type="form1003AddRow" className="mt-3">
        <img
          src="/images/bluePlusInCircleMark.png"
          style={{ width: 15, height: 15 }}
          className="mr-2"
        />
        {props.title}
      </Button>
    </div>
  );
};
export default EditableTable;