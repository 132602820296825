import React, { Component } from "react";
import { Button, Spinner } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { CustomerHeader } from "./Landing/LandingMenu";

  
export default class Validation extends Component {
    constructor(props) {
        super(props);
        let searchparams = new URLSearchParams(window.location.search);
        this.customerid = searchparams.get("customerid")
        this.email = searchparams.get("email")
        this.state = {
            loading: false,
        };
    }
    revalidate = ()=> {
        this.setState({loading: true})
        let tosend =
        JSON.stringify({ email: this.email, customerid: this.customerid })
        fetch('/services/revalidate', {
            method: 'POST',
            body: tosend,
            headers: { 'Content-Type': 'application/json' },
        }).then(
            response => {
                if (response.status !== 200) {
                    this.setState({loading: false})
                    // window.document.dispatchEvent(new Event('reauthenticate'), "");
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {
                    this.setState({loading: false})
                    if (js.Status === "Error") {
                        alert(js.Text)
                    }
                    console.log(js)
                });
            }
        ).catch((err) => {
            this.setState({loading: false})
            console.log('Fetch Error :', err);
        });
    }
    render() {
        return (
            <div>
            <CustomerHeader />
            <div className="mt-5 mb-3 px-5 text-center">
                <h2>
                    Thank you for creating an account with us! 
                </h2>
                <div className="text-center mt-4  mb-2 pt-3" >
                    In order to maintain your privacy and security we've generated and sent validation email to your account. 
                    <p/>
                    Please click on the link in this email, and it will take you to the login screen!
                </div>
                <div className="mt-2">
                    <Button variant="link" onClick={()=>this.revalidate()}>
                        {this.state.loading ? <Spinner size="sm" animation="border"/> : "Resend account validation email"}
                    </Button>
                </div>
            </div>
            </div>
        )
    }
}
