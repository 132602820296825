import { createStore } from "redux"
import throttle from 'lodash.throttle'
import rootReducer from "../Reducers/index"
import * as com from "../Common.js"
import { State } from '../State.js'
import { updateApplication } from './actions'
var stringify = require('fast-json-stable-stringify')
var md5 = require('md5')
const merge = require('deepmerge')
const cloneDeep = require('lodash/cloneDeep')
//var jsonDiff = require('json-diff')

let inCurrentState = false
let stampCurrentState = 0
export const initialState = JSON.parse((() => { let a = new State(); return a.toJson() })())

export const loadState = () => {
    try {
        const serializedState = sessionStorage.getItem('state')

        if (serializedState === null) {
            return undefined
        }
        return JSON.parse(serializedState)
    } catch (err) {
        console.log("loadState: parse failed!!")
        return new State()
    }
}

export const saveState = (state) => {
    try {
        if (!state)
            state = store.getState()
        const serializedState = JSON.stringify(state)
        sessionStorage.setItem('state', serializedState)
    } catch {
        // ignore write errors
    }
}

const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray

let persistedState = loadState()

if (typeof persistedState !== 'undefined') {
    persistedState = merge(initialState, persistedState, { arrayMerge: overwriteMerge })
} else {
    persistedState = initialState
}
const store = createStore(rootReducer, persistedState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true }))

let lastSentHash = ""

store.subscribe(throttle(() => {
    if (sessionStorage.getItem("edit") === "false") return
    let current = store.getState()
    saveState(current)
}, 1000))

store.subscribe(throttle(() => {
    if (sessionStorage.getItem("edit") === "false") return
    let current = store.getState()
    let currentHash = md5(stringify(current))
    if (currentHash !== lastSentHash) {
        let token = window.sessionStorage.getItem("ZeitroA")
        if (null != token) {
            if (!inCurrentState) {
                getCurrentState()
                lastSentHash = currentHash
            } else {
                //console.log("skip getCurrentState")
            }
        }
    }
}, 5000))

export function mergeState(origin, masterState = cloneDeep(store.getState()), ps = sessionStorage.getItem("originalstate")) {
    let originState
    let broken = false

    try {
        originState = JSON.parse(origin)
    } catch (e) {
        console.log("Error: Invalid json from the server in merge")
        broken = true
    }

    let previousState
    if (broken) {
        console.log("state broken")
        originState = new State()
        previousState = new State()
    } else {
        try {
            previousState = (ps === "{}" || ps === null) ? cloneDeep(store.getState()) : JSON.parse(ps)
        } catch (x) {
            originState = new State()
            previousState = new State()
        }
    }
    let appAtOrigin = originState.application
    let appCurrent = masterState.application
    let appPrevious = previousState.application
    let inp = stringify(appCurrent)
    let pr = stringify(appPrevious)
    let or = stringify(appAtOrigin)
    /* eslint-disable-next-line */
    if (pr != inp) {
        console.log("new local changes detected")
        //console.log(jsonDiff.diffString(appPrevious, appCurrent))
    } else {
        /* eslint-disable-next-line */
        if (inp != or)
            console.log("new remote changes detected")
    }
    let gettype = o => {
        let whattype = typeof o
        if ("object" === whattype) {
            if (Array.isArray(o)) {
                whattype = "array"
            }
        }
        return whattype
    }
    let dumpArray = (obj, prev, origin, level) => {
        for (let i = 0; i < obj.length; i++) {
            let whattype = gettype(obj[i])
            if (whattype === "object") {
                dumpObject(obj[i], prev[i], origin[i], level + 1)
            } else if (whattype === "array") {
                dumpArray(obj[i], prev[i], origin[i], level + 1)
            } else { // number, boolean, string
                if (obj[i] === prev[i] && obj[i] !== origin[i]) {
                    obj[i] = origin[i]
                    continue
                }
            }
        }
    }
    let dumpObject = (obj, prev, origin, level) => {
        for (var propertyName in obj) {
            let whattype = gettype(obj[propertyName])
            if (obj == null || origin == null) {
                // console.log(obj, origin, propertyName)
                continue
            }
            if (obj[propertyName] == null && origin[propertyName] != null)
                whattype = gettype(origin[propertyName])

            if (whattype === "object") {
                if (prev == null) // new wins
                    continue
                if (origin == null) // local wins
                    continue
                dumpObject(obj[propertyName], prev[propertyName], origin[propertyName], level + 1)
            } else if (whattype === "array") {
                if (prev == null || prev[propertyName] == null) // local change wins
                    continue

                if (origin == null || origin[propertyName] == null) // nothing to merge with
                    continue

                if (obj[propertyName].length === prev[propertyName].length && prev[propertyName].length === origin[propertyName].length) {
                    // just iterate
                    dumpArray(obj[propertyName], prev[propertyName], origin[propertyName], level + 1)
                } else {
                    if (obj[propertyName].length === prev[propertyName].length) {
                        // use reliable serializer
                        let pa = stringify(prev[propertyName])
                        let oa = stringify(obj[propertyName])
                        if (pa === oa) {
                            // take remote, SUBPAR, todo
                            obj[propertyName] = origin[propertyName]
                            prev[propertyName] = origin[propertyName]
                        }
                    }
                    // keep local
                }
            } else { // number, bool, string
                if (prev == null || origin == null)
                    continue
                if (undefined === typeof origin[propertyName])
                    continue

                if (obj[propertyName] === prev[propertyName] && obj[propertyName] !== origin[propertyName]) {

                    console.log("set ", propertyName, " to remote value: ", origin[propertyName],
                        " local value: ", obj[propertyName], ", previous: ", prev[propertyName])
                    obj[propertyName] = origin[propertyName]
                    continue
                }
            }
        }
    }

    try {
        dumpObject(appCurrent, appPrevious, appAtOrigin, 0)
    } catch (x) {
        console.log("exception in dumpObject", x)
    }
    let out = stringify(appCurrent)

    masterState.application = appCurrent
    /* eslint-disable-next-line */
    if (or != out) {
        // application part mediated

        sessionStorage.setItem("originalstate", JSON.stringify(masterState))

        const unsubscribe = store.subscribe(handleChange)
        function handleChange() {
            unsubscribe()
            if (inp !== or) {
                console.log("Update the remote state")
                updateState(masterState)
            } else {
                console.log("don't update the remote state, it is the same, updated local")
            }
        }
        store.dispatch(updateApplication(appCurrent))
    } else {
        inCurrentState = false
        sessionStorage.setItem("originalstate", JSON.stringify(masterState))
        //console.log("no change from origin, no local changes")
    }
    return masterState
}

export function getCurrentState(masterState = cloneDeep(store.getState()), previousState = sessionStorage.getItem("originalstate")) {
    if (inCurrentState) {
        const elapsed = Date.now() - stampCurrentState
        if (elapsed < 10000) {
            return // should be more sophisticated?
        }
        console.log("Error: 10 sec state management timeout! Elapsed ", elapsed)
    }

    stampCurrentState = Date.now()
    inCurrentState = true

    saveState(masterState)

    const token = window.sessionStorage.getItem("ZeitroA")
    fetch(window.location.origin + "/borrower/currentstate", {
        cache: 'no-cache',
        method: 'GET',
        headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
        },
    }).then(response => {
        if (!response.ok) {
            console.log("getCurrentState: Auth fetch error")
            window.document.dispatchEvent(new Event('reauthenticate'), "")
        } else {
            response.json().then(js => {
                if (js.Status !== "OK") {
                    console.log("State Update Error: " + js.Status)
                    window.document.dispatchEvent(new Event('reauthenticate'), "")
                } else {
                    if (js.Text !== '{}') {
                        masterState = store.getState()
                        previousState = sessionStorage.getItem("originalstate")
                        if (typeof masterState === 'undefined' && typeof previousState === 'undefined') {
                            mergeState(js.Text)
                        } else {
                            mergeState(js.Text, masterState, previousState)
                        }
                    } else {
                        saveState(masterState)
                        sessionStorage.setItem("originalstate", JSON.stringify(store.getState()))
                        updateState(masterState)
                    }
                }
            })
        }
    }).catch(error => {
        inCurrentState = false
        console.log("getCurrentState: error")
    })
}

export function updateState(s) {
    const sstate = JSON.stringify(s)
    sessionStorage.setItem("originalstate", sstate)
    const tosend = com.ascii2hex(sstate)

    const token = window.sessionStorage.getItem("ZeitroA")
    if (token === null) {
        console.log("updateState: not authenticated")
        window.location.href = "/#home"
        return
    }
    if (sessionStorage.getItem("edit") === "false") {
        console.log("Editing is not allowed.")
        return
    };

    inCurrentState = true

    fetch(window.location.origin + "/auth/updatestate", {
        cache: 'no-cache',
        method: 'POST',
        body: tosend,
        headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
        },
    }).then(response => {
        if (!response.ok) {
            window.document.dispatchEvent(new Event('reauthenticate'), "")
            this.setState({ show: true })
        } else {
            response.json().then(js => {
                if (js.Status !== "OK") {
                    console.log("State Update Error: " + js.Status)
                    if (js.Status.indexOf("token is expired")) {
                        sessionStorage.removeItem("ZeitroA")
                    }
                    window.document.dispatchEvent(new Event('reauthenticate'), "")
                }
                inCurrentState = false
            })
        }
    }).catch(error => {
        window.document.dispatchEvent(new Event('reauthenticate'), "")
        console.log("updateState: error")
        inCurrentState = false
    })
}

window.setInterval(() => {
    if (sessionStorage.getItem("edit") === "false") return
    let token = window.sessionStorage.getItem("ZeitroA")
    if (null != token)
        getCurrentState()
}, 5000)

export default store