
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { connect } from 'react-redux'
import { Document, Page, pdfjs } from 'react-pdf';
import { PDFDocument, rgb } from 'pdf-lib';
import { CloseOutlined } from "@ant-design/icons";
import { Button, Form, Checkbox, Input, Modal, Spin } from 'antd';
import moment from 'moment';
import { useHistory } from "react-router-dom"
import { saveAs } from 'file-saver';
import './HardMoneyLoan/PDFPreview.css';
import './HardMoneyLoan/TextLayer.css';
// import "react-pdf/dist/esm/Page/TextLayer.css";

export function blobToURL(blob) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            const base64data = reader.result;
            resolve(base64data);
        };
    });
}

const mapStateToProps = (state) => {
    return {
        hasCoBorrower: state.application.hascoborrower === "withcoborrower",
    }
}

// Directly copied from form_marks.go
const signCoordinates = {
    "page2": {
        "borrower.signature": { Left: 149.938012, Top: 699.655642, Right: 448.457415, Bottom: 724.989162 },
        "borrower.signature.date": { Left: 492.877884, Top: 700.794918, Right: 576.247894, Bottom: 724.431574 },
        "coborrower.signature": { Left: 149.194504, Top: 740.631587, Right: 447.174893, Bottom: 764.115037 },
        "coborrower.signature.date": { Left: 494.044096, Top: 739.106156, Right: 575.960673, Bottom: 764.784832 },
    },
    "page6": {
        "borrower.signature": { Left: 154.992455, Top: 692.501348, Right: 412.125321, Bottom: 712.885257 },
        "borrower.signature.date": { Left: 455.208178, Top: 690.486024, Right: 592.914131, Bottom: 712.987128 },
        "coborrower.signature": { Left: 169.909857, Top: 737.242870, Right: 421.305854, Bottom: 758.380672 },
        "coborrower.signature.date": { Left: 465.925736, Top: 734.865661, Right: 585.810826, Bottom: 758.224112 },
    },
    "page7": {
        "borrower.signature": { Left: 150.868699, Top: 690.059578, Right: 414.187342, Bottom: 713.687426 },
        "borrower.signature.date": { Left: 456.687334, Top: 685.539165, Right: 547.043647, Bottom: 710.956520 },
        "coborrower.signature.date": { Left: 465.536239, Top: 726.710112, Right: 553.345275, Bottom: 756.527421 },
        "coborrower.signature": { Left: 169.022238, Top: 732.849779, Right: 423.397619, Bottom: 759.467932 },
    },
    "page8": {
        "borrower.signature": { Left: 156.361317, Top: 679.203119, Right: 409.458129, Bottom: 702.315068 },
        "borrower.signature.date": { Left: 454.912180, Top: 678.029100, Right: 587.751260, Bottom: 700.909247 },
        "coborrower.signature": { Left: 175.000186, Top: 723.278574, Right: 424.748534, Bottom: 748.862620 },
        "coborrower.signature.date": { Left: 468.006864, Top: 719.840562, Right: 590.132241, Bottom: 746.434448 },
    },
    "page9": {
        "borrower.signature": { Left: 152.011897, Top: 463.909080, Right: 407.620574, Bottom: 487.300107 },
        "borrower.signature.date": { Left: 452.712552, Top: 463.862488, Right: 588.684658, Bottom: 485.761183 },
        "coborrower.signature": { Left: 168.497061, Top: 512.377038, Right: 421.376242, Bottom: 534.365661 },
        "coborrower.signature.date": { Left: 463.001602, Top: 508.093347, Right: 601.608988, Bottom: 532.818064 },
    },
}
const PT_PX_RATIO = 1.46

let cosignMarks = [
    {
        page: 1,
        signid: "page2cosignature",
        pageName: "page2",
        type: 'signature',
        key: "coborrower.signature",
        style: {
        }
    },
    {
        page: 1,
        signid: "page2cosignature",
        pageName: "page2",
        type: 'date',
        key: "coborrower.signature.date",
        style: {
        }
    },
    {
        page: 5,
        signid: "page6cosignature",
        pageName: "page6",
        type: 'signature',
        key: "coborrower.signature",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 5,
        signid: "page6cosignature",
        pageName: "page6",
        type: 'date',
        key: "coborrower.signature.date",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 6,
        signid: "page7cosignature",
        pageName: "page7",
        type: 'signature',
        key: "coborrower.signature",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 6,
        signid: "page7cosignature",
        pageName: "page7",
        type: 'date',
        key: "coborrower.signature.date",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 7,
        signid: "page8cosignature",
        pageName: "page8",
        type: 'signature',
        key: "coborrower.signature",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 7,
        signid: "page8cosignature",
        pageName: "page8",
        type: 'date',
        key: "coborrower.signature.date",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 8,
        signid: "page9cosignature",
        pageName: "page9",
        type: 'signature',
        key: "coborrower.signature",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 8,
        signid: "page9cosignature",
        pageName: "page9",
        type: 'date',
        key: "coborrower.signature.date",
        style: {
            top: 0,
            left: 0,
        }
    }
]



function PDFPreview(props) {
    pdfjs.GlobalWorkerOptions.workerSrc =
        `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const history = useHistory()
    const pageRefs = useRef({});
    // const [pdf, setPdf] = useState('');
    const [numPages, setNumPages] = useState(null);
    const [showSignPad, setShowSignPad] = useState(false)
    const [currentSignature, setCurrentSignature] = useState('')
    const [currentSignId, setCurrentSignId] = useState('')
    const [loading, setLoading] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [signItems, setSignItems] = useState({})
    const { pdf, fileName, signMarks, visible, onClose } = props
    const documentRef = useRef(null);
    const [submitLoading, setSubmitLoading] = useState(false)
    const containerRef = useRef(null)
    const WIDTH = 900
    const [form] = Form.useForm()

    const onPageLoadSuccess = (page) => {
    };

    /*To Prevent right click on screen*/
    document.addEventListener("contextmenu", (event) => {
        event.preventDefault();
    });

    function insertSignature() {
        const foundList = signMarks.filter(x => x.signid === currentSignId)
        let newSignItems = JSON.parse(JSON.stringify(signItems))
        for (const { pageName, key, type } of foundList) {
            if (type === 'signature') {
                newSignItems[pageName][key] = form.getFieldValue("signature")
            } else if (type === 'date') {
                newSignItems[pageName][key] = moment().format("MM/DD/YYYY")
            }
        }
        console.log(newSignItems)
        setSignItems(newSignItems)
        setCurrentSignId("")
        setShowSignPad(false)
    }

    /*When document gets loaded successfully*/
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    function verifySignItems(signItems) {
        for (const pageName in signItems) {
            for (const key in signItems[pageName]) {
                if (!signItems[pageName][key]) return [pageName, key]
            }
        }
        return true
    }

    function hasNextSign() {
        const verifyResult = verifySignItems(signItems)
        return Array.isArray(verifyResult)
    }

    function scrollNextSign() {
        const verifyResult = verifySignItems(signItems)
        if (Array.isArray(verifyResult)) {
            const [pageName, key] = verifyResult
            let found = signMarks.find(x => x.pageName === pageName && key === key)
            if (found && containerRef.current && pageRefs.current) {
                let pageOffset = pageRefs.current[found.page]?.offsetTop || 0
                containerRef.current.scrollTop = found.style.top + pageOffset - 50
            }
            return
        }
    }
    const onSubmit = async () => {
        setSubmitLoading(true)
        const pdfDoc = await PDFDocument.load(pdf);
        // const fontBytes = await fetch("../../fonts/Smooch-Regular.ttf").then((res) => res.arrayBuffer())
        // pdfDoc.registerFontkit(fontkit)

        const font = await pdfDoc.embedFont('Helvetica')
        signMarks.forEach(signMark => {
            const page = pdfDoc.getPage(signMark.page - 1)
            const scale = page.getWidth() / 900
            const textSize = signMark.type === 'signature' ? 26 : 14
            const { left, top, width, height } = signMark.style
            const textHeight = font.heightAtSize(textSize)
            const text = signItems[signMark.pageName][signMark.key]
            const textY = page.getHeight() - (top * scale + (height * scale + textHeight) / 2)
            // page.drawRectangle({   
            //     x: left * scale, 
            //     y: page.getHeight() - (top * scale) - (height * scale), 
            //     width: width * scale, 
            //     height: height * scale, 
            //     borderColor: rgb(1, 1, 1), 
            //     fill: false, 
            // })
            page.drawText(text, {
                x: left * scale,
                y: textY,
                font,
                size: textSize,
                color: rgb(0, 0, 139 / 255),
            })
        })

        const modifiedPdfBytes = await pdfDoc.save()
        const modifiedPdfBlob = new Blob([modifiedPdfBytes], { type: 'application/pdf' })
        const downloadLink = document.createElement('a')
        downloadLink.href = URL.createObjectURL(modifiedPdfBlob)
        downloadLink.download = fileName
        downloadLink.click()
        setSubmitLoading(false)
        props.onSubmit(modifiedPdfBlob)
    }

    function openSignPad(signid) {
        setCurrentSignId(signid)
        setShowSignPad(true)
    }

    useEffect(() => {
        if (visible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [visible])


    useEffect(async () => {
        setLoading(true)
        // if (props.hasCoBorrower) {
        //     signMarks = [
        //         ...signMarks,
        //         ...cosignMarks,
        //     ]
        // }

        let initSignItems = {}
        for (const { pageName, key, type } of signMarks) {
            if (!(pageName in initSignItems)) {
                initSignItems[pageName] = {}
            }
            if (!(key in initSignItems[pageName])) {
                initSignItems[pageName][key] = ''
            }
        }
        console.log(signMarks, initSignItems)
        setSignItems(initSignItems)
        // let token = sessionStorage.getItem("ZeitroA")

        // const existingPdfBytes = await fetch('/borrower/hardmoneyloan/previewyeeleeform', {
        //     method: 'GET',
        //     headers: {
        //         Authorization: "Bearer " + token,
        //         Cache: "no-cache"
        //     },
        // }).then(res => res.arrayBuffer()).catch(function (err) {
        //     console.log('Fetch Error :-S', err);
        // });
        // const blob = new Blob([new Uint8Array(existingPdfBytes)]);

        // const URL = await blobToURL(blob);
        // setPdf(URL);
        setLoading(false)
    }, []);
    const PDFComponent = () => {
        return (
            <Document
            className="pdf-content"
            file={pdf}
            ref={documentRef}
            onLoadSuccess={onDocumentLoadSuccess}
        >
            {Array.from(
                new Array(numPages),
                (el, index) => (
                    <Page
                        inputRef={ref => {
                            pageRefs.current[index] = ref;
                        }}
                        width={WIDTH}
                        key={`page_${index + 1}`}
                        pageNumber={index}
                        renderTextLayer={false}
                        onLoadSuccess={onPageLoadSuccess}
                        onRenderSuccess={(props) => {

                        }}
                    >
                        <div
                            className="page-sign-layer"
                            style={{
                                width: WIDTH,
                                marginLeft: -WIDTH / 2,
                                left: '50%',
                            }}
                        >
                            {signMarks.map((signMark, signIdx) => {
                                if (signMark.page === index) {
                                    let signContent = signItems[signMark.pageName][signMark.key]
                                    return <>
                                            <div
                                                key={signMark.key}
                                                className={signMark.type === 'signature' ? "sign-mark" : "sign-mark-date"}
                                                onClick={() => openSignPad(signMark.signid)}
                                                style={{
                                                    position: 'absolute',
                                                    ...signMark.style
                                                }}
                                            >
                                                {signContent}
                                            </div>
                                            {!signContent ? <div
                                                className='sign-thumb-wrapper'
                                                onClick={() => openSignPad(signMark.signid)}
                                            >
                                                <div
                                                    className='sign-thumb'
                                                    style={{
                                                        position: 'absolute',
                                                        top: signMark.style.top,
                                                        left: -140,
                                                    }}
                                                >
                                                    Sign Here
                                                </div>
                                                <div
                                                    className="sign-thumb-triangle"
                                                    style={{
                                                        position: 'absolute',
                                                        top: signMark.style.top,
                                                        left: -40,
                                                    }}
                                                ></div>
                                            </div> : null}
                                        </>
                                }
                                return null;
                            })}
                        </div>
                    </Page>
                )
            )}
            </Document>
        )
    }
    const renderSignPad = () =>
        <Modal
            open={showSignPad}
            closable={false}
            className="sign-pad"
            title="Please type your name"
            footer={null}
        >
            <Form
                form={form}
                name='sign-pad'
                onFinish={insertSignature}
            >
                <Form.Item
                    name="signature"
                    rules={[{ required: true, message: 'Please sign your name' }]}
                >
                    <Input
                        className="signature"
                        style={{
                            height: 42,
                            lineHeight: '26px',
                            fontSize: 26,
                            padding: 8,
                            borderRadius: 0,
                            border: '1px solid #325CEB',
                        }}
                        // onChange={onCurrentSignature}
                    />
                </Form.Item>
                <Form.Item
                    name="consent"
                    valuePropName="checked"
                    style={{ textAlign: 'left' }}
                    rules={[{ required: true, message: 'Please select the checkbox' }]}
                >
                    <Checkbox>I agree to electronically sign this document, understand this is a legal
                        representation of my signature, and agree to the <a className='signature-terms-link' onClick={() => setIsModalOpen(true)}>electronic signature terms</a>.</Checkbox>
                </Form.Item>

                <div className="action-button-group">
                    <Form.Item>
                        <Button
                            className="cancel-button"
                            onClick={() => {
                                setShowSignPad(false)
                                setCurrentSignId("")
                            }}
                        >Cancel</Button>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType='submit' className="insert-button">Insert</Button>
                    </Form.Item>
                </div>
            </Form>
            <Modal
                title="Electronic Signature Terms"
                className='signature-terms-modal'
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                footer={[<Button type="primary" onClick={() => setIsModalOpen(false)}>Got it</Button>]}
            >
                <p>By using the electronic signature feature on this website, you agree to the legal validity and binding nature of your electronic signature on the loan application forms. The company reserves the right to implement authentication measures to verify the identity of the applicant when using electronic signatures.
You can withdraw consent by notifying the company in writing, but this may affect your loan application.We will retain electronic signature records as required by law.
You can request a copy of your signed documents.The company may update these terms. Continued use of electronic signatures implies acceptance of any changes.By using our electronic signature feature, you confirm your understanding and acceptance of these terms.</p>
            </Modal>
        </Modal>

    return (
        <>
            <div className="pdf-preview-main" style={{ display: visible ? 'block' : 'none' }}>
                <div className="top-nav">
                    <div>Please follow the instruction to provide your signatures and then click Submit.</div>
                    <CloseOutlined
                        onClick={onClose}
                        className="close-icon"
                    />
                </div>
                <center className="pdf-container" ref={containerRef}>
                {!loading ? PDFComponent(): <div className="spin-wrapper"> <Spin /></div>}
                </center>

                {renderSignPad()}
                <div className="bottom-nav">
                    <Button
                        type="primary"
                        disabled={loading}
                        loading={submitLoading}
                        className='submit-btn'
                        onClick={hasNextSign() ? scrollNextSign : onSubmit}
                    >
                        {hasNextSign() ? 'Next' : 'Submit'}
                    </Button>
                </div>
            </div>
        </>
    );
}

export default PDFPreview = connect(mapStateToProps)(PDFPreview);
