import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Button from 'react-bootstrap/Button'
import Helmet from 'react-helmet'
import Modal from 'react-bootstrap/Modal'
import HubspotForm from 'react-hubspot-form'
import { Redirect } from 'react-router-dom'
import LandingMenu from './Landing/LandingMenu'
import LandingCopyright from './Landing/LandingCopyright'

export default class WaitlistPage extends Component {
    constructor(props) {
        super(props)
        window.scrollTo(0, 0);
        this.state = {
            show: false,
            message: "",
            validated: false,
            loantype: "Purchase",
            redirect: false,
            redirectto: ""
        }
        this.form = React.createRef()
    }
    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }
    handleSize = event => {
        this.setState({
            loansize: event.target.value
        });
    }
    handleClose = () => {
        this.setState({ show: false })
        if (this.state.redirect) {
            this.setState({ redirectto: <Redirect to="/" /> })
        }
    }


    render = () => {
        return (
            <div>
                <LandingMenu />
                {this.state.redirectto}
                <div id="landing1" className="text-center my-4 " >
                    <div className="text-center pt-2 pb-5">
                        <div className="hubspotbox mt-3   text-center"  >
                            <div className="Login" >
                                <Helmet>
                                    <title> Beta Program </title>
                                </Helmet>
                                <Modal show={this.state.show} onHide={this.handleClose} >
                                    <Modal.Header closeButton>
                                        <Modal.Title>Beta Program</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>{this.state.message}</Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={this.handleClose}>Close</Button>
                                    </Modal.Footer>
                                </Modal>
                                <h5 className="pt-3"> Beta Program </h5>

                                <div id="waitingid" className="text-center px-5 pb-5">
                                    <div className="nav-link text-left mb-3">
                                    Here’s your chance to shape tomorrow’s mortgage shopping experience. Become a “Friend of Zeitro” and Beta User. We want your help in rolling out our website and in exchange, we will provide you undivided attention and a lowest rate guarantee.
                                        </div>
                                    <HubspotForm
                                        portalId='8947143'
                                        // css=''
                                        // cssRequired=''
                                        // cssClass='ppc'
                                        formId='83d0eaaf-fbba-4537-9ba7-6b562ae441b5'
                                        onSubmit={() => console.log('Submit!')}
                                        onReady={(form) => console.log('Form ready!')}
                                        loading={<div>Loading...</div>}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <LandingCopyright />
            </div>
        )
    }
}



