import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import * as com from "./Common.js"

const sessionTimeoutMin = 20

export default class Auth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            lastActivity: 0,
            active: false,
        }
        this.state.lastActivity = Date.now()/1000
        this.lastSessionUpdate = 0
        this.showtimer = null
        window.addEventListener("mousemove", this.onActivity.bind(this), {passive: true} )
        window.addEventListener("keydown", this.onActivity.bind(this), {passive: true} )
        window.addEventListener("wheel", this.onActivity.bind(this), {passive: true} )
        window.addEventListener("DOMMouseScroll", this.onActivity.bind(this), {passive: true} )
        window.addEventListener("mouseWheel", this.onActivity.bind(this), {passive: true} )
        window.addEventListener("mousedown", this.onActivity.bind(this), {passive: true} )
        window.addEventListener("touchstart", this.onActivity.bind(this), {passive: true} )
        window.addEventListener("touchmove", this.onActivity.bind(this), {passive: true} )
        window.addEventListener("MSPointerDown", this.onActivity.bind(this), {passive: true} )
        window.addEventListener("MSPointerMove", this.onActivity.bind(this), {passive: true} )
        window.addEventListener("visibilitychange", this.onActivity.bind(this), {passive: true} )

        window.document.addEventListener('reauthenticate', this.onReauthenticate);

    }
    onReauthenticate = (e) =>{
        console.log("Reauthentication requested on failed fetch!")
        this.revalidate()
    }
    onTimeout = () => {
        let d = new Date()
        console.log("in onTimeout, revalidate possible expiration, %s", d.toLocaleString() )
        console.log("Elapsed: " + (Date.now()/1000 - this.lastSessionUpdate))
        this.revalidate();
    }
    onActivity = (e) => {
        let now = Date.now()/1000 // in seconds
        if (now - this.lastSessionUpdate >= 30) {
            //console.log("last session update was " + this.lastSessionUpdate + ", elapsed " + 
            //(now - this.lastSessionUpdate))
     
            this.lastSessionUpdate =now            
            //console.log("in onActivity, session active, revalidate at" + d.toLocaleString())
            //console.log("last session update set to " + d.toLocaleString())
            // update every minute now, later change to sessionTimeoutMin
            // less a minute
            // eslint-disable-next-line react/no-direct-mutation-state

            this.revalidate();
        }
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.lastActivity = now
        clearTimeout(this.timeoutId)
        // trigger failed revalidation in sessionTimeToLive + 5 sec
        // eslint-disable-next-line react/no-direct-mutation-state
        this.timeoutId = setTimeout(this.onTimeout, 1000*(sessionTimeoutMin*60 + 5))

    }
    revalidate () {
        if(!this.active)
            return
        let token = window.sessionStorage.getItem("ZeitroA");
        this.lastSessionUpdate = Date.now()/1000                       
        if (token === null) {
            console.log("not authenticated");
            window.location.href = com.redirectPublicWebsite();
            return;
        }
        fetch(window.location.origin + "/auth/refresh", {
            cache: 'no-cache',
            method: 'POST',
            body: "",            
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"                      
            },
        }).then(response => {
            let d = new Date()
            //console.log(JSON.stringify(response))
            if (!response.ok) {
                console.log("Auth fetch error, at: " + d.toLocaleString())
                sessionStorage.removeItem("ZeitroA")
                this.setState({ show: true })
            } else {
                response.json().then(js => {
                    if (js.Status !== "OK") {
                        console.log("Status not OK,  " + JSON.stringify(js) + ", at: " + d.toLocaleString())
                        sessionStorage.removeItem("ZeitroA")
                        console.log("Pop the message")
                        this.showtimer = setTimeout(t => { 
                            console.log("Don't wait until popup shows, close the view")
                            window.location.href = com.redirectPublicWebsite()
                        }, 30000)
                        if(window.location.pathname !== '/')
                            this.setState({ show: true })
                    } else {
                        //console.log("Revalidated ok, at:" + d.toLocaleString())
                        sessionStorage.setItem("ZeitroA", js.Token)
                    }
                })
            }
        }).catch(error => {
            let d = new Date()
            console.log("Revalidate catch error " + error + ", at: " + d.toLocaleString())
        })
    }

    componentDidMount() {
        if(this.timeoutId)
            clearTimeout(this.timeoutId)
        this.timeoutId = setTimeout(this.onTimeout, 1000*(sessionTimeoutMin*60 + 5))

        this.active=true
        this.revalidate();
        com.getReturnURLs()
        this.handleShow()
    }
    componentWillUnmount() {
        if(this.timeoutId)
            clearTimeout(this.timeoutId)
        this.timeoutId = null            
        this.active=false

    }
    onShow = () => {
        if(this.showtimer)
            clearTimeout(this.showtimer)
        this.showtimer = null
        setTimeout(t => { 
            console.log("kill the popup from popup")
            window.location.href = com.redirectPublicWebsite()
        }, 5000)
    }
    handleClose = () => { this.setState({ show: true }); window.location.href = com.redirectPublicWebsite(); }
    handleShow = () => this.setState({ show: false });
    render() {
        return (
            <div className="mx-0 px-0">
                <Modal show={this.state.show} onHide={this.handleClose} onShow={this.onShow}>
                    <Modal.Header closeButton>
                        <Modal.Title>Session Expired</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>This session timed out because of inactivity</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

