import React, {useEffect, useState} from 'react';
import "./NonQMBorrowerQualifier.css";
import { Button, Modal } from 'antd';
import * as com from "../../Common.js"
import { PhoneOutlined, MailOutlined } from '@ant-design/icons';
import {useSelector, useDispatch} from "react-redux";
import { getCurrentState, loadState, updateState, mergeState } from '../../Store'
import { State } from '../../State'
import { updateApplication } from '../../Store/actions';
import { UpdateChangeProgress, UpdateChangeVisitedStage } from '../../Store/progress';
import CheckGroup from "../../Common/components/CheckGroup"

const NonQMResult = ({ qualifiedProducts, loName,loPhone,loEmail, alreadySignedUp, pastLoans, onFinish }) => {

  const [showPastLoans, setShowPastLoans] = useState(false);
  const [selectedLoanId, setSelectedLoanId] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const borrowerEmail = useSelector(state => state.application.borrower.email.toLowerCase());
  const borrowerFirstName = useSelector(state => state.application.borrower.firstname);
  const borrowerLastName = useSelector(state => state.application.borrower.lastname);

  const createLoan = () => {
    if (!alreadySignedUp) {
        createNewBorrower()
    } else {
        setShowPastLoans(true)
    }
  }

  const createNewBorrower = () => {
    
    fetch("/auth/signupfrompreapp", {
        cache: 'no-cache',
        method: 'POST',
        body: JSON.stringify({
            email: borrowerEmail,
            firstName: borrowerFirstName,
            lastName: borrowerLastName,
            customerID: sessionStorage.getItem("cid"),
            loID: sessionStorage.getItem("loid") === null ? "" : sessionStorage.getItem("loid"),
            State: JSON.stringify(loadState()),
            IsNonQM: true,
        }),
        headers: {
            Cache: "no-cache"
        },
    }).then(response => {
        if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' + response.status)
            return
        }
        response.json().then(js => {

            if (js.Status !== "OK") {
                console.log(js)

            } else {
                if (onFinish) {
                    onFinish()
                }

                sessionStorage.setItem("ZeitroA", js.Token)
                let state = State.fromJson(JSON.parse(js.State))
                let statejs = state.toJson()
                sessionStorage.removeItem("state")
                sessionStorage.setItem("state", statejs)
                sessionStorage.setItem("originalstate", statejs)
                sessionStorage.setItem("newcustomer", "false")
                let path = "/application/interview/main/applicant"
                window.location.href = path
            }
        })

    }).catch(error => {
        console.log(error)
        this.setState({ code: "" })
    })
}   

const showPastLoansModal = () => {
    if (!showPastLoans) {
        return ""
    }
    let propertyType = {
        'singlefamilydetached': 'Single Family',
        'singlefamilyattached': 'Townhouse',
        'twotofourfamily': 'Multi-family',
        'condominium': 'Condo'
    }
    let filterValue = (val) => {
        return val && val !== '' ? val : '--'
    }
    const data = []
    for (let loan of pastLoans) {
        let lasttouch = new Date(loan.state.progress.lasttouch)
        let isPrequal = loan.state.application.property.purpose === "purchase" && !loan.state.application.property.hascontract
        if (loan.state.application.property.purpose === null) {
            data.push({
                value: loan.loanid,
                label: <div>
                    <div>Loan purpose unselected</div>
                    <div style={{ color: "#6E6E70", fontSize: '14px', marginTop: '6px' }}>Last edit at {lasttouch.toLocaleDateString()}</div>
                </div>
            })
        } else {
            data.push({
                value: loan.loanid,
                label: (
                    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div>
                            <div><b>{com.capitalize(loan.state.application.property.purpose)}</b> {!isPrequal ? " | Application" : " | Pre-qualification"}</div>
                            <div className="mt-2" style={{ fontSize: '14px' }}>{filterValue(propertyType[loan.state.application.property.propertytype]) + ", " + filterValue(loan.state.application.property.county) + ", " + filterValue(loan.state.application.property.state)}</div>
                        </div>
                        <div style={{ color: "#6E6E70", fontSize: '14px', marginTop: '6px' }}>Last edit at {lasttouch.toLocaleDateString()}</div>
                    </div>
                )
            })
        }
    }


    return (
        <Modal
            wrapClassName='full-height-modal'
            className="pastLoansModal"
            open={showPastLoansModal}
            title="Use a previous application"
            onOk={() => setShowPastLoans(false)}
            onCancel={() => setShowPastLoans(false)}
            width="768px"
            maskClosable={false}
            closable={false}
            footer={[
                <Button style={{ marginRight: 18 }} type='primary'
                    disabled={selectedLoanId === "" ? true : false}
                    onClick={() => createNewLoan(true)}>
                    Auto fill my information
                </Button>,
                <Button style={{ color: '#325CEB', fontWeight: 700, border: 'none', boxShadow: 'none' }} variant="link" onClick={() => createNewLoan(false)}>
                    Skip
                </Button>,
            ]}
        >
            <div className="pastLoansModal-title">We noticed that you have {pastLoans.length} existing applications. Would you like to use your previous information for this application?</div>
            <div className="subheading">You can edit your information any time during the application process.</div>
            <div style={{ margin: '26px 0', maxHeight: '400px', overflow: 'auto' }}>
                <CheckGroup
                    data={data}
                    onChangeSelect={e => {
                        setSelectedLoanId(e)
                    }}
                    value={selectedLoanId}
                    mode="flex"
                    type='radio'
                    direction="horizontal"
                    fixedWidth="280px" />
            </div>
        </Modal>
    )
}
const createNewLoan = (withRecord) => {
    setLoading(true)
    let token = sessionStorage.getItem("ZeitroA")
    let js = {
        Email: borrowerEmail,
        CustomerID: sessionStorage.getItem("cid"),
        LoanID: selectedLoanId !== "" ? selectedLoanId : "",
        NewState: withRecord ? "" : com.ascii2hex(sessionStorage.getItem("state")),
        LoID: sessionStorage.getItem("loid") === null ? "" : sessionStorage.getItem("loid"),
    }
    fetch('/auth/createnewapplication', {
        method: 'POST',
        headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
        },
        body: JSON.stringify(js)
    }).then(
        response => {
            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' +
                    response.status)
                window.document.dispatchEvent(new Event('reauthenticate'), "")
                setLoading(false)
                return
            }
            response.json().then(js => {
                if (js.Status !== "OK") {
                    console.log(js.Text)
                    alert("Looks like there was a problem, please try later.")
                } else {
                    if (onFinish) {
                        onFinish()
                    }
                    sessionStorage.setItem("ZeitroA", js.Token)
                    if (withRecord) {
                        let st = JSON.parse(js.State)
                        st.progress.stage = "interview"
                        st.progress.interviewfinished = false
                        st.progress.interviewstarted = false
                        st.progress.productselectionstarted = false
                        st.progress.interview.step = 0
                        st.progress.interview.max = 0
                        st.application.currentStatus = 'ApplicationStarted'
                        st.application.currentStage = 'Application'
                        st.application.selection.selectedloan = null
                        st.application.property.purpose = this.props.application.property.purpose
                        st.application.property.hascontract = false
                        st.application.borrower.firstname = this.props.application.borrower.firstname
                        st.application.borrower.lastname = this.props.application.borrower.lastname
                        st.application.product.mortgageapplied = "nonqm"
                        dispatch(updateApplication(st.application));
                        dispatch(UpdateChangeVisitedStage("interviewstarted"));
                        dispatch(UpdateChangeProgress(0, "max"));
                        dispatch(UpdateChangeProgress(0, "interview"));
                        getCurrentState()
                        com.touch()
                    } else {

                    }

                    setLoading(false)
                    let path = "/application/interview/main/applicant"
                    window.location.href = path
                }

            })
        }
    ).catch((err) => {
        this.setState({ loading: false })
        console.log('Fetch Error :', err)
    })
}
  const getDescriptions = (incomeDocTypes) => {
    let displayedName = ""
    let imgUrl = ""
    let description = ""

    if (incomeDocTypes.some(type => type.includes("VOE"))) {
        displayedName = "VOE"
        imgUrl = "/images/nonqm/voe.svg"
        description = "The loan accepts 1 year or 2 year of verification of employment as income documentation."
    } else if (incomeDocTypes.some(type => type.includes("PL"))) {
        displayedName = "Profit and Loss"
        imgUrl = "/images/nonqm/pl.svg"
        description = "The loan accepts 1 year or 2 year of profit and loss statement as income documentation."
    } else if (incomeDocTypes.some(type => type.includes("BankStatement"))) {
        displayedName = "Bank Statement"
        imgUrl = "/images/nonqm/bankstatement.svg"
        description = "The loan accepts 12 months of bank statement as income documentation for self-employed borrowers."
    } else if (incomeDocTypes.some(type => type.includes("Form1099"))) {
        displayedName = "Form 1099"
        imgUrl = "/images/nonqm/form1099.svg"
        description = "The loan accepts 12 months of 1099 statement as income documentation for self-employed contractors."
    } else if (incomeDocTypes.some(type => type.includes("DebtServiceCoverageRatio"))) {
        displayedName = "DSCR"
        imgUrl = "/images/nonqm/dscr.svg"
        description = "DSCR loans are perfect for real estate investors who can secure a real estate loan based on their rental property's cash flow."
    } else if (incomeDocTypes.some(type => type.includes("Asset"))) {
        displayedName = "Asset Utilization"
        imgUrl = "/images/nonqm/asset.svg"
        description = "Asset utilization loans allow borrowers to use their substantial assets to qualify for a mortgage loan instead of employment income."
    }
    return [displayedName, imgUrl, description]
  }


  return (
    <div className="nonqm-result">
    <div className="header-1 text-center mb-4">{qualifiedProducts.length ===0 ?"Almost there! Explore your options by applying": "Programs you might be qualified for"}</div>
      <div className='text-left' style={{color:"#586570"}}> Thank you for your interest in securing financing! Based on the information provided, here are some programs you may qualify for. To proceed to an application, please click "Proceed to application" for next steps.</div>
      
      <div className="program-grid">
        {qualifiedProducts.map((product, index) => {
          const [displayedName, imgUrl, description] = getDescriptions(product.incomeDocTypes);

          return (
            <div key={index} className="program-card">
              <div><img src={imgUrl}  style={{width:38}}/></div>
              <div style={{fontWeight: 500, fontSize: 18}}>{displayedName}</div>
              <div className='d-flex'>
                <div style={{color:"#5E6E70", marginRight:5}}>Estimated rate: </div>
                <div style={{fontWeight: 500, color:"#325CEB"}}>{product.rate}</div>
              </div>
              <div>
                <div style={{fontSize:14, lineHeight:1, color:"#6E7E70", fontWeight:300}}>{description}</div>
              </div>
            </div>
          );
        })}
        <div className="program-card" style={{ backgroundColor: '#EAEFFD', border: '1px solid #C3CDEA', lineHeight:1.4 }}>
            <div className='fw-500' style={{fontSize:18, color:"#474849", marginTop: 10}}>Need more information?</div>
            <div className='d-flex align-items-baseline mt-2'><p style={{color:"#325CEB", fontWeight: 500, fontSize:18, marginRight:5}}>{loName}</p> <span style={{color:"#081265", fontSize: 10}}>LOAN OFFICER</span></div>
            {loPhone!=="" && <div><PhoneOutlined className='mr-3'/>{loPhone}</div>}
            {loEmail && <div><MailOutlined className='mr-3'/>{loEmail}</div>}
        </div>
      </div>
      

    <div className='text-left'>
      <Button type='primary' size='large'  style={{minWidth:150}} onClick={()=>createLoan()}>Proceed to application</Button>
    </div>
    {showPastLoansModal()}
    </div>
  );
};

export default NonQMResult;