import React from 'react';
import Card from 'react-credit-cards';
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
    formatFormData,
} from './ccutils.js';


import 'react-credit-cards/es/styles-compiled.css';

export default class CreditCard extends React.Component {
    state = {
        number: '',
        name: '',
        expiry: '',
        cvc: '',
        issuer: '',
        focused: '',
        formData: null,
    };

    handleCallback = ({ issuer }, isValid) => {
        if (isValid) {
            this.setState({ issuer });
        }
    };

    handleInputFocus = ({ target }) => {
        this.setState({
            focused: target.name,
        });
    };

    handleInputChange = ({ target }) => {

        if (target.name === 'number') {
            target.value = formatCreditCardNumber(target.value);
        } else if (target.name === 'expiry') {
            target.value = formatExpirationDate(target.value);
        } else if (target.name === 'cvc') {
            target.value = formatCVC(target.value);
        }

        this.setState({ [target.name]: target.value });
    };

    handleSubmit = e => {
        e.preventDefault();

        const formData = [...e.target.elements]
            .filter(d => d.name)
            .reduce((acc, d) => {
                acc[d.name] = d.value;
                return acc;
            }, {});

        this.setState({ formData });
        this.form.reset();
    };

    render() {
        const { name, number, expiry, cvc, focused, issuer, formData } = this.state;

        return (
            <div className="text-center w-100 mr-5 pr-5 ml-1 pl-1 d-inline">
                <h2 className="mb-5 heading">Please provide your credit card</h2>

                <Form  ref={c => (this.form = c)} onSubmit={this.handleSubmit}>
                    <div className=" text-center">
                        <Form.Row>
                            <Col></Col>
                            <Col className="text-right">
                                <Card
                                    number={number}
                                    name={name}
                                    expiry={expiry}
                                    cvc={cvc}
                                    focused={focused}
                                    callback={this.handleCallback}
                                />
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <input
                                        type="tel"
                                        name="number"
                                        className="form-control"
                                        placeholder="Card Number"
                                        pattern="[\d| ]{16,22}"
                                        required
                                        onChange={this.handleInputChange}
                                        onFocus={this.handleInputFocus}
                                    />
                                    <small>E.g.: 49..., 51..., 36..., 37...</small>
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        placeholder="Name"
                                        required
                                        onChange={this.handleInputChange}
                                        onFocus={this.handleInputFocus}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-7">
                                        <input
                                            type="tel"
                                            name="expiry"
                                            className="form-control"
                                            placeholder="Valid Thru"
                                            pattern="\d\d/\d\d"
                                            required
                                            onChange={this.handleInputChange}
                                            onFocus={this.handleInputFocus}
                                        />
                                    </div>
                                    <div className="col-5">
                                        <input
                                            type="tel"
                                            name="cvc"
                                            className="form-control"
                                            placeholder="CVC"
                                            pattern="\d{3,4}"
                                            required
                                            onChange={this.handleInputChange}
                                            onFocus={this.handleInputFocus}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col></Col>

                        </Form.Row>
                            <Col></Col>
                        <Form.Row className="mt-4">
                            <Col></Col>
                            <Col>
                                <Button variant="primary">&nbsp;Submit&nbsp;</Button>
                            </Col>
                            <Col></Col>
                        </Form.Row>
                        <input type="hidden" name="issuer" value={issuer} />

{formData && (
    <div className="App-highlight">
        {formatFormData(formData).map((d, i) => <div key={i}>{d}</div>)}
    </div>
)}
</div>
                </Form>

            </div>
        );
    }
}


