import { compact, join, startCase, toLower, upperFirst, isNumber, isString, isNaN, toNumber } from 'lodash'

/**
 * Assemble full name from first name, middle name, and last name
 * @param {string} firstName - The first name
 * @param {string} middleName - The middle name
 * @param {string} lastName - The last name
 * @returns {string} The assembled full name
 */
export const assembleFullName = (firstName, middleName, lastName) => {
    return join(compact([firstName, middleName, lastName]), ' ')
}

/**
 * Convert camelCase string to Title Case with spaces
 * Example:
 * - "firstName" -> "First name"
 * - "lastNameUSA" -> "Last name usa"
 * - "SSNNumber" -> "Ssn number"
 *
 * @param {string} str - The camelCase string to convert
 * @return {string} Converted string with first word capitalized and others lowercase
 */
export const camelToTitleCase = (str) => {
    if (!str) return ''
    return upperFirst(toLower(startCase(str)))
}

/**
 * Checks if a variable is a number or a string that can be converted to a number.
 * @param {*} value - The variable to check.
 * @return {boolean} Returns true if the variable is a number or a string that can be converted to a number, otherwise false.
 */
export const isNumericOrNumericString = (value) => {
    // Check if it is a number
    if (isNumber(value)) {
        return true
    }

    // If it's a string, check if it can be parsed as a valid number and is not an empty string
    if (isString(value) && value.trim() !== '' && !isNaN(toNumber(value))) {
        return true
    }

    // It is neither a number nor a numeric string
    return false
}
