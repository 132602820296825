import 'bootstrap/dist/css/bootstrap.min.css'
import React, { Component } from 'react'
import smartlookClient from 'smartlook-client'

import { message, Modal, Spin, FloatButton, Card, Space, Typography } from 'antd'
import { CustomerServiceOutlined, CloseOutlined, PhoneOutlined, MailOutlined } from '@ant-design/icons'
import { Spinner } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { updateTaskStatus } from '../AppEvents'
import * as com from '../Common.js'
import Docs from '../Documents/Documents'
import NewSidebarApplication from '../newContent/page/newSideBarApplication'
import StepApplication from '../newContent/page/stepApplication'
import { getCurrentState } from '../Store'
import * as act from '../Store/actions'
import { UpdateChangeLastTouch, UpdateChangeProgress, UpdateChangeVisitedStage } from '../Store/progress'
import Tasks from '../Tasks'
import Applicant, { Residence } from './Applicant'
import Declarations from './Declarations'
import Email from './Email'
import EmailVerification from './EmailVerification'
// import Expenses from './Expenses'
import Finances, { MoreFinances } from './Finances'
import Income, { OtherIncome } from './Income'
import Liabilities from './Liabilities'
import MyRates from './MyRates/MyRates'
import PrequalLetter from './PrequalLetter'
import Property from './Property'
import Overview from './Overview'
import SSN from './SSN'
// import CreditReport from './CreditReport'
// import Escrow from './Escrow'
import Closing from './Closing'
import { assembleFullName } from '../Tools/String'
import { calculateBalancesAndPayments } from '../Tools/Loan'

const { Text, Link } = Typography

const LoanOfficerPanel = ({ showLoanOfficerPanel, officer, toggleLoanOfficerPanel, step, hascoborrower }) => {
    const { FirstName, MiddleName, LastName, Phone, Email, CompanyName } = officer || {}
    const fullName = assembleFullName(FirstName, MiddleName, LastName)
    const isCoBorrowerStep = step === com.stageToNumberApplication.coborrower && hascoborrower !== 'withcoborrower'

    return (
        <>
            {!showLoanOfficerPanel && (
                <FloatButton
                    icon={<CustomerServiceOutlined />}
                    type="primary"
                    onClick={() => toggleLoanOfficerPanel(true)}
                    className="loan-officer-button"
                    style={{ bottom: isCoBorrowerStep ? '82px' : '20px' }}
                />
            )}

            <Card
                className="loan-officer-card"
                style={{
                    transform: showLoanOfficerPanel ? 'translateY(0)' : 'translateY(150%)',
                    opacity: showLoanOfficerPanel ? 1 : 0,
                    bottom: isCoBorrowerStep ? '82px' : '20px',
                }}
                styles={{ body: { padding: '20px 16px' } }}
                bordered={false}
            >
                <CloseOutlined className="card-close-icon" onClick={() => toggleLoanOfficerPanel(false)} />

                <Space direction="vertical" size="small" style={{ width: '100%', textAlign: 'left' }}>
                    <Text style={{ fontWeight: '600', fontSize: '14px', color: '#081265' }}>
                        MY LOAN OFFICER
                    </Text>

                    <Space direction="vertical" size={0} style={{ marginTop: '4px', marginBottom: '10px' }}>
                        <Text style={{ fontSize: '16px', fontWeight: '600', display: 'block', color: '#222222' }}>
                            {fullName}
                        </Text>
                        <Text style={{ fontSize: '14px', fontWeight: '400', color: '#6E6E70' }}>{CompanyName}</Text>
                    </Space>

                    <Space direction="vertical" size="small" style={{ width: '100%' }}>
                        <Link
                            href={`tel:${Phone}`}
                            style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                        >
                            <PhoneOutlined rotate={90} style={{ fontSize: '18px', color: '#545F71' }} />
                            <Text style={{ fontSize: '16px', fontWeight: '400', color: '#222222' }}>{Phone}</Text>
                        </Link>

                        <Link
                                href={`mailto:${Email}`}
                            style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                        >
                            <MailOutlined style={{ fontSize: '18px', color: '#545F71' }} />
                            <Text style={{ fontSize: '16px', fontWeight: '400', color: '#325CEB' }}>{Email}</Text>
                        </Link>
                    </Space>
                </Space>
            </Card>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        application: state.application,
        progress: state.progress,
        hascoborrower: state.application.hascoborrower,
        borrower: state.borrower,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateChangeProgress: (step, stage) => {
        dispatch(UpdateChangeProgress(step, stage))
    },
    updateApplicationAttribute: (t, verb) => {
        dispatch(act.UpdateApplicationAttribute(t, verb))
    },
    updateChangeLastTouch: (t) => {
        dispatch(UpdateChangeLastTouch(t))
    },
    updateChangeVisitedStage: (step, stage) => {
        dispatch(UpdateChangeVisitedStage(step, stage))
    },
})

class SubInterview extends Component {
    returnTrue = () => true
    returnFalse = () => false

    personalCoBorrower = () => {
        const { hascoborrower, married } = this.props.application
        return (
            hascoborrower === 'withcoborrower' ||
            (hascoborrower === 'multiple' && married)
        )
    }

    returnTruePurchase = () => {
        return true
        // return this.props.application.property.purpose === 'purchase'
    }

    coBorrowerPurchase = () => {
        return this.personalCoBorrower() && this.returnTruePurchase()
    }

    newCustomer = () => {
        return sessionStorage.getItem('newcustomer') === 'true'
    }

    creditNotPulled = () => {
        if (this.props.application.scre === 0) {
            return true
        }

        const assetsAndLiabilities = this.props.application.assetsandliabilities

        // if using estimated FICO, return true
        if (
            assetsAndLiabilities !== null &&
            assetsAndLiabilities.loans.length === 1 &&
            assetsAndLiabilities.loans[0].name === 'Estimated'
        ) {
            return true
        }

        const { purpose } = this.props.application.property
        const { loans } = this.props.application.assetsandliabilities
        const { otherliens } = this.props.application
        const [totalMortgage] = calculateBalancesAndPayments(purpose, loans, otherliens)
        if (
            purpose === 'refinance' &&
            totalMortgage === 0 &&
            assetsAndLiabilities.propertyownedfreeandclear === true
        ) {
            // can't refinance the property that you own free and clear
            return true
        }

        return false
    }

    notRemote = () => {
        const { hascoborrower } = this.props.application
        return hascoborrower !== 'remote'
    }

    coBorrowerNotRemote = () => {
        return this.notRemote() && this.personalCoBorrower()
    }

    coBorrowerNotCohabiting = () => {
        const { hascoborrower, cohabiting } = this.props.application
        return hascoborrower === 'withcoborrower' && !cohabiting
    }

    coBorrowerNotJoint = () => {
        const { hascoborrower, married } = this.props.application
        return hascoborrower === 'withcoborrower' && !married
    }

    coBorrowerNotJointPurchase = () => {
        const { purpose } = this.props.application.property
        if (purpose !== 'purchase') return false
        const { hascoborrower, married } = this.props.application
        return hascoborrower === 'withcoborrower' && !married
    }

    checkExpenses = () => {
        const { purpose } = this.props.application.property
        const { presentaddressownership } = this.props.application.borrower
        return !(purpose === 'purchase' && presentaddressownership === 'rentfree')
    }

    checkPreChoice = () => {
        const { hascoborrower } = this.props.application
        return hascoborrower !== 'remote'
    }

    checkCoExpenses = () => {
        const { hascoborrower, married } = this.props.application
        if (['alone', 'remote'].includes(hascoborrower)) return false
        if (['multiple', 'withcoborrower'].includes(hascoborrower) && married)
            return false

        const { purpose } = this.props.application.property
        const { presentaddressownership } = this.props.application.coborrower
        return !(purpose === 'purchase' && presentaddressownership === 'rentfree')
    }

    checkOverview = () => {
        const isManualClick = this.props.location?.state?.fromManualClick
        console.log('Navigation source:', isManualClick ? 'Manual click' : 'Wizard navigation')

        if (isManualClick) {
            // Clear the manual click state
            this.props.history.replace({
                pathname: this.props.location.pathname,
                state: {},
            })
            return true
        }

        return false
    }

    remoteCoBorrowers = () => {
        const { hascoborrower } = this.props
        return hascoborrower === 'multiple'
    }

    returnProperty = () => {
        const { hascoborrower } = this.props.application
        return hascoborrower !== 'remote'
    }

    coBorrowerProperty = () => {
        return this.personalCoBorrower() && this.returnProperty()
    }

    returnProductPicker = () => {
        const { hascoborrower } = this.props
        return hascoborrower !== 'remote'
    }

    returnMoreProperty = () => {
        const { hascoborrower } = this.props
        return hascoborrower !== 'remote'
    }

    coBorrowerMoreProperty = () => {
        return this.personalCoBorrower() && this.returnMoreProperty()
    }

    getURLIndex = (section) => {
        let newIndex = 0
        if (typeof com.stageToNumberApplication[section] !== 'undefined') {
            newIndex = com.stageToNumberApplication[section]
        } else {
            try {
                newIndex = parseInt(section)
            } catch (error) {
                console.error('Error getting URL index for section:', section, error)
                newIndex = 0
            }
        }

        return Math.min(this.wizard.length - 1, newIndex)
    }

    checkAuthorizationValidity = (v) => {
        const hasCoBorrower = this.props.application.hascoborrower
        const bSocialSecurity = this.props.application.borrower.socialsecurity
        const cbSocialSecurity = this.props.application.coborrower.socialsecurity
        try {
            v = v.replace('null', '{}') // temp hack

            const json = JSON.parse(v)
            const parsedBorrowerSocialSecurity = json.application.borrower.socialsecurity
            const parsedCoBorrowerSocialSecurity = json.application.coborrower.socialsecurity

            if (hasCoBorrower === 'alone' || hasCoBorrower === 'remote') {
                return bSocialSecurity === parsedBorrowerSocialSecurity
            }
            if (hasCoBorrower === 'withcoborrower') {
                return (
                    bSocialSecurity === parsedBorrowerSocialSecurity &&
                    cbSocialSecurity === parsedCoBorrowerSocialSecurity
                )
            }

            if (hasCoBorrower === 'multiple') {
                if (this.props.application.married) {
                    return (
                        bSocialSecurity === parsedBorrowerSocialSecurity &&
                        cbSocialSecurity === parsedCoBorrowerSocialSecurity
                    )
                } else {
                    return bSocialSecurity === parsedBorrowerSocialSecurity
                }
            }

            return false
        } catch (x) {
            return false
        }
    }

    constructor(props) {
        super(props)

        this.form = React.createRef()
        this.wizard = []
        this.assembleWizard()

        let index = 0
        if (this.props.progress.stage === 'interview') {
            index = Math.min(
                this.wizard.length - 1,
                parseInt(this.props.progress.interview.step)
            )
        }

        const section = this.props.match.params['section']
        this.state = {
            section: section,
            handingoff: false,
            showLoanOfficerPanel: false,
            officer: null,
        }

        if (section) {
            index = this.getURLIndex(section)
        }

        if (section !== 'overview') {
            while (true) {
                if (!this.wizard[index] || !this.wizard[index][1]) break
                const check = this.wizard[index][1]
                if (check()) break
                index += 1
                if (index === this.wizard.length - 1) break
            }
        }

        this.state = {
            currentIndex: index,
            currentPane: this.wizard[index][0],
            hiddenNext: index === this.wizard.length - 1,
            validated: false,
            continueButtonText: 'Next',
            showSubmitModal: false,
            notifyingLO: false,
            whoValue: com.borrower,
            loading: false,
        }

        this.timer = 0
        this.props.updateChangeProgress(index, 'interview')
        if (
            index > this.props.progress.interview.max &&
            index <= this.wizard.length - 1 &&
            index <= this.wizard.length - 1
        ) {
            this.props.updateChangeProgress(index, 'max')
        }
    }

    componentDidMount = () => {
        window.document.addEventListener('checkwalkback', this.onTouch)
        if (!window.location.hostname.includes('localhost')) {
            const token = sessionStorage.getItem('ZeitroA')
            fetch('/auth/getprofile', {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + token,
                    Cache: 'no-cache',
                    'X-Borrower': this.props.borrowerid,
                },
            })
                .then((response) => {
                    if (response.status !== 200) {
                        console.warn( 'Looks like there was a problem. Status Code: ', response.status)
                    }
                    response.json().then((js) => {
                        if (js.Status !== 'OK') {
                            console.warn('Error: ', js.Text)
                        } else {
                            smartlookClient.init(
                                'd837cbb6bdd09c2c425261ab6ab338c05aac8ef0'
                            )
                            smartlookClient.record({
                                emails: true,
                                forms: true,
                                numbers: true,
                                ips: true,
                            })
                            smartlookClient.identify(this.props.borrowerid, {
                                name: js.FirstName + ' ' + js.LastName,
                                email: js.Email,
                            })
                        }
                    })
                })
                .catch((err) => {
                    console.error('Fetch Error :', err)
                })
        }

        this.getLoanOfficerInfo()
    }

    componentWillUnmount = () => {
        window.document.removeEventListener('checkwalkback', this.onTouch)
    }

    getLoanOfficerInfo = async () => {
        try {
            const token = sessionStorage.getItem('ZeitroA')
            const response = await fetch('/borrower/getloanofficerinfo', {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            })

            if (response.status !== 200) {
                console.warn('Get loan officer info failed: ', response.status)
                return
            }

            const data = await response.json()
            this.setState({ officer: data.Profile })
        } catch (error) {
            console.error('Error fetching loan officer info:', error)
        }
    }

    /**
     * Assembles the wizard steps
     */
    assembleWizard = () => {
        const { borrowerid } = this.props

        const createWizardStep = (Component, condition, props = {}) => {
            if (Component === MyRates) {
                this.wizard.push([
                    <Component self={this.form} {...props} />,
                    condition,
                ])
            } else {
                const r = React.createRef()
                this.wizard.push([
                    <Component ref={r} self={this.form} {...props} />,
                    condition,
                    r,
                ])
            }
        }

        // Start
        createWizardStep(Email, this.newCustomer)
        createWizardStep(EmailVerification, this.newCustomer)

        /* -------------------------------------------------------------------------- */
        /*                                   Borrower                                 */
        /* -------------------------------------------------------------------------- */
        createWizardStep(Applicant, this.notRemote, { who: com.borrower }) // 2 - applicant
        createWizardStep(Residence, this.notRemote, { who: com.borrower }) // 3 - residence
        createWizardStep(Property, this.returnProperty, { borrowerid, who: com.borrower }) // 4 - property
        createWizardStep(Income, this.returnTrue, { // 5 - income
            borrowerid,
            who: com.borrower,
            toggleLoanOfficerPanel: this.toggleLoanOfficerPanel
        })
        createWizardStep(OtherIncome, this.returnTrue, { borrowerid, who: com.borrower })
        createWizardStep(MoreFinances, this.returnTruePurchase, { who: com.borrower }) // 7 - assets
        createWizardStep(SSN, this.returnTrue, { // 8 - credit check
            who: com.borrower,
            changeContinueButtonText: this.changeNextButtonText,
            changeLoading: this.changeLoading,
        })
        createWizardStep(Liabilities, this.returnTrue, { // 9 - liabilities
            clearValidate: this.clearValidate,
            checkValidate: this.checkValidate,
            who: com.borrower,
            borrowerid,
        })
        createWizardStep(Declarations, this.returnTrue, { who: com.borrower }) // 10 - property & money
        createWizardStep(Finances, this.returnTrue, { // 11 - finances
            who: com.borrower,
            changeContinueButtonText: this.changeNextButtonText,
        })

        /* -------------------------------------------------------------------------- */
        /*                                   Co-Borrower                              */
        /* -------------------------------------------------------------------------- */
        createWizardStep(Applicant, this.notRemote, { who: com.coborrower }) // 12 - co-borrower
        createWizardStep(Residence, this.coBorrowerNotRemote, { who: com.coborrower })
        createWizardStep(Property, this.coBorrowerProperty, { borrowerid, who: com.coborrower})
        createWizardStep(Income, this.personalCoBorrower, {
            borrowerid,
            who: com.coborrower,
            toggleLoanOfficerPanel: this.toggleLoanOfficerPanel
        })
        createWizardStep(OtherIncome, this.personalCoBorrower, { borrowerid, who: com.coborrower })
        createWizardStep(MoreFinances, this.coBorrowerPurchase, { who: com.coborrower})
        createWizardStep(SSN, this.personalCoBorrower, {
            who: com.coborrower,
            changeContinueButtonText: this.changeNextButtonText,
            changeLoading: this.changeLoading,
        })
        createWizardStep(Liabilities, this.personalCoBorrower, {
            clearValidate: this.clearValidate,
            checkValidate: this.checkValidate,
            who: com.coborrower,
            borrowerid,
        })
        createWizardStep(Declarations, this.personalCoBorrower, { who: com.coborrower })
        createWizardStep(Finances, this.personalCoBorrower, {
            who: com.coborrower,
            changeContinueButtonText: this.changeNextButtonText,
        })

        /* -------------------------------------------------------------------------- */
        /*                                   APPLICATION                              */
        /* -------------------------------------------------------------------------- */
        createWizardStep(Overview, this.checkOverview, { // 22 - overview
            who: com.borrower,
            changeContinueButtonText: this.changeNextButtonText,
        })
        createWizardStep(MyRates, this.returnProductPicker, { // 23 - rates
            borrowerid,
            changeContinueButtonText: this.changeNextButtonText,
        })
        createWizardStep(PrequalLetter, this.returnProductPicker, { // 24 - prequal letter
            borrowerid,
            changeContinueButtonText: this.changeNextButtonText,
        })

        /* -------------------------------------------------------------------------- */
        /*                                   PROCESSING                               */
        /* -------------------------------------------------------------------------- */
        createWizardStep(Docs, this.returnMoreProperty) // 25 - docs
        createWizardStep(Tasks, this.returnMoreProperty) // 26 - tasks

        /* -------------------------------------------------------------------------- */
        /*                                   CLOSING                                  */
        /* -------------------------------------------------------------------------- */
        createWizardStep(Closing, this.returnTrue) // 30 - closing
    }

    changeContinueButtonText = () => {
        this.setState({ continueButtonText: 'Submit' })
    }
    changeNextButtonText = (text) => {
        this.setState({ continueButtonText: text })
    }
    restoreContinueButtonText = () => {
        this.setState({ continueButtonText: 'Next' })
    }

    changeLoading = (state) => {
        this.setState({ loading: state })
    }

    onTouch = () => {
        this.props.updateChangeLastTouch(new Date().toUTCString())

        const { max } = this.props.progress.interview
        const { currentIndex } = this.state

        if (currentIndex < max && currentIndex <= this.wizard.length - 1) {
            this.props.updateChangeProgress(currentIndex, 'max')
        }
    }

    oninit = (callback) => {
        this.submit = callback
    }

    update = (newindex) => {
        const { max } = this.props.progress.interview

        while (true) {
            if (!this.wizard[newindex] || !this.wizard[newindex][1]) break
            const check = this.wizard[newindex][1]
            if (check()) break
            newindex += 1
        }

        this.setState({ currentIndex: newindex })
        this.setState({ validated: false })
        this.setState({
            currentPane: this.wizard[newindex][0],
            hiddenNext: newindex === this.wizard.length - 1,
        })

        this.props.updateChangeProgress(newindex, 'interview')

        if (typeof max === 'undefined' || max < newindex)
            this.props.updateChangeProgress(newindex, 'max')
    }

    previous = () => {
        const { currentIndex } = this.state
        const { borrowerid } = this.props

        const child = this.wizard[currentIndex][2].current
        if (child && child.handlePrevious) {
            if (!child.handlePrevious()) {
                return false
            }
        }

        let newindex = Math.max(currentIndex - 1, 0)
        while (true) {
            if (!this.wizard[newindex] || !this.wizard[newindex][1]) break
            const check = this.wizard[newindex][1]
            if (check()) break
            newindex -= 1
            if (newindex === 0) break
        }

        if (!this.wizard[newindex][1]()) newindex = currentIndex

        this.update(newindex)
        window.scrollTo(0, 0)

        if (typeof borrowerid === 'undefined')
            this.props.history.push('/application/interview/main/' + newindex)
    }

    next = () => {
        const { currentIndex } = this.state
        const currentComponent = this.wizard[currentIndex]
        // Call the onFinish method if it exists
        if (currentComponent[2]?.current?.onFinish) {
            currentComponent[2]?.current?.onFinish()
        }

        const wizardLength = this.wizard.length
        const { max, interviewstarted } = this.props.progress.interview
        const { borrowerid } = this.props

        let newindex = Math.min(currentIndex + 1, wizardLength - 1)
        if (currentIndex === com.stageToNumberApplication.finances ||
            currentIndex === com.stageToNumberApplication.coborrower + 9) {
            newindex = com.stageToNumberApplication.overview
        } else {
            while (true) {
                if (!this.wizard[newindex] || !this.wizard[newindex][1]) break
                const check = this.wizard[newindex][1]
                if (check()) break
                newindex += 1
                if (newindex === wizardLength - 1) break
            }
        }

        this.setState({
            currentIndex: newindex,
            currentPane: this.wizard[newindex][0],
            hiddenNext: newindex === wizardLength - 1,
        })

        this.props.updateChangeProgress(newindex, 'interview')

        if (typeof max === 'undefined' || max < newindex)
            this.props.updateChangeProgress(newindex, 'max')

        if (typeof borrowerid === 'undefined')
            this.props.history.push('/application/interview/main/' + newindex)

        switch (newindex) {
            case com.stageToNumberApplication.documents: {
                this.props.history.push('/application/interview/main/documents')
                break
            }
            case com.stageToNumberApplication.start + 2: {
                if (!interviewstarted) {
                    this.props.updateChangeVisitedStage('interviewstarted')
                    this.notifyLO('interviewstarted')
                }
                break
            }
            case com.stageToNumberApplication.overview: {
                this.props.history.push('/application/interview/main/overview')
                break
            }
            default:
                break
        }

        window.scrollTo(0, 0)
        message.success('Your changes have been saved successfully.', 1)
    }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        }
        return true
    }

    clearValidate = () => {
        this.setState({ validated: false })
    }

    checkValidate = (event) => {
        if (this.form.current.reportValidity() === false) {
            event.preventDefault()
            event.stopPropagation()

            let els = document.getElementsByClassName('zeitro-radio')
            for (let i = 0; i < els.length; i++) {
                let inp = els[i].previousSibling

                if (!inp.validity.valid) els[i].classList.add('in-valid')
                else els[i].classList.replace('in-valid', 'totally-valid')
            }

            els = document.getElementsByClassName('zeitro-checkbox')
            for (let i = 0; i < els.length; i++) {
                let inp = els[i].previousSibling

                if (!inp.validity.valid) els[i].classList.add('in-valid')
                else els[i].classList.replace('in-valid', 'totally-valid')
            }
            this.setState({ validated: true })
            return false
        }
        return true
    }

    handleSubmit = (event) => {
        if (!this.checkValidate(event)) {
            event.preventDefault()
            event.stopPropagation()
            return false
        }

        if (this.state.continueButtonText === 'Submit') {
            event.preventDefault()
            event.stopPropagation()
            this.setState({ showSubmitModal: true })
            return
        }

        if (this.state.currentIndex !== 2) {
            const child = this.wizard[this.state.currentIndex][2].current
            if (child && child.handleSubmit) {
                if (!child.handleSubmit(event)) {
                    event.preventDefault()
                    event.stopPropagation()
                    this.setState({ validated: true })
                    return false
                }
            }
        }

        getCurrentState(
            {
                application: this.props.application,
                borrower: this.props.borrower,
            },
            sessionStorage.getItem('originalstate')
        )

        event.preventDefault()
        this.setState({ validated: false })
        event.stopPropagation()
        this.next()
        return true
    }

    rollback = () => {
        let index = 0
        while (true) {
            if (!this.wizard[index] || !this.wizard[index][1]) break
            const check = this.wizard[index][1]
            if (check()) break
            index += 1
        }

        this.setState({ currentIndex: index })
        this.setState({
            currentPane: this.wizard[index][0],
            validated: false,
            hiddenNext: false,
        })
        this.props.updateChangeProgress(0, 'interview')
        window.scrollTo(0, 0)

        if ('undefined' === typeof this.props.borrowerid)
            this.props.history.push('/application/interview/main/' + 0)
    }

    isRollbackHidden = () => {
        let index = 0
        while (true) {
            if (!this.wizard[index] || !this.wizard[index][1]) break
            const check = this.wizard[index][1]
            if (check()) break
            index += 1
        }

        return this.state.currentIndex === index
    }

    isPreviousHidden = () => {
        let index = 0
        while (true) {
            if (!this.wizard[index] || !this.wizard[index][1]) break
            const check = this.wizard[index][1]
            if (check()) break
            index += 1
        }

        return this.state.currentIndex === index
    }

    handoff = async (e) => {
        try {
            const token = sessionStorage.getItem('ZeitroA')
            const id = this.props.borrowerid
            this.setState({ handingoff: true })

            const response = await fetch('/borrower/handoff', {
                method: 'POST',
                body: JSON.stringify({ token: id }),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                    Cache: 'no-cache',
                },
            })

            if (response.status !== 200) {
                alert('Looks like there was a problem. Status Code: ' + response.status)
                this.setState({ handingoff: false })
                return
            }

            const js = await response.json()
            this.setState({ handingoff: false })
            alert(js.Text)
        } catch (err) {
            console.error('Fetch Error:', err)
            this.setState({ handingoff: false })
        }
    }

    autoCreateTasksAndNotifyLO = async () => {
        try {
            const token = sessionStorage.getItem('ZeitroA')
            this.setState({ notifyingLO: true })

            const response = await fetch('/borrower/autocreatetasksandnotifylo', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                    Cache: 'no-cache',
                },
            })

            if (response.status !== 200) {
                console.warn('Looks like there was a problem. Status Code: ', response.status)
                return
            }

            this.setState({ notifyingLO: false, showSubmitModal: false })
            this.next()
            updateTaskStatus()
        } catch (err) {
            this.setState({ notifyingLO: false, showSubmitModal: false })
            console.error('Fetch Error:', err)
        }
    }

    showSubmitModal = () => {
        const handleOk = () => {
            this.restoreContinueButtonText()
            this.props.updateApplicationAttribute('interviewfinished', 'status')
            const currentStatus = this.props.application.currentStatus;
            if (
                com.CurrentStatus.hasOwnProperty(currentStatus) &&
                com.CurrentStatus[currentStatus] < com.CurrentStatus["ApplicationFinished"]
            ) {
                this.props.updateApplicationAttribute('ApplicationFinished', 'currentStatus');
            }
            this.autoCreateTasksAndNotifyLO()
        }

        return (
            <Modal
                title="Congratulations on getting ready to submit your loan application!"
                open={this.state.showSubmitModal}
                onOk={handleOk}
                onCancel={() => this.setState({ showSubmitModal: false })}
                okText={
                    this.state.notifyingLO ? (
                        <Spinner size="sm" animation="border" />
                    ) : (
                        'Submit'
                    )
                }
                centered
            >
                <p>What's the next step?</p>
                <p>
                    Your loan officer will review your application and keep you updated on any progress or any further tasks. Once your submission is complete, feel free to:{' '}
                </p>
                <ul>
                    <li>
                        Check out your personalized rates by selecting "Continue"
                    </li>
                    <li>Fill out declarations</li>
                    <li>Move ahead with uploading the required documents </li>
                </ul>
                <p>
                    Don't hesitate to reach out if you have any questions or concerns!
                </p>
            </Modal>
        )
    }

    /*
     * Determine if we should show bottom navigation bar
     */
    shouldShowBottomNavigation = () => {
        const { step } = this.props.progress.interview
        return step >= com.stageToNumberApplication.applicant && step < com.stageToNumberApplication.overview
    }

    /*
     * Determine if we should show step application sidebar
     */
    shouldShowStepApplication = () => {
        const { step } = this.props.progress.interview
        const { hascoborrower } = this.props.application
        if (step === com.stageToNumberApplication.coborrower && hascoborrower !== 'withcoborrower') {
            return false
        }
        return step >= com.stageToNumberApplication.applicant && step < com.stageToNumberApplication.overview
    }

    toggleLoanOfficerPanel = (visible) => {
        this.setState({
            showLoanOfficerPanel: typeof visible === 'boolean' ? visible : !this.state.showLoanOfficerPanel
        })
    }


    render = () => {
        const { fromiframe, smallScreen, location } = this.props
        const { loading, showSubmitModal, validated, currentPane, showLoanOfficerPanel, officer } = this.state
        const { status, hascoborrower } = this.props.application
        const { interview, lasttouch } = this.props.progress

        const isChecked = sessionStorage.getItem('edit') === 'true'

        const onEdit = (e) => {
            sessionStorage.setItem('edit', e.target.checked)
            this.forceUpdate()
        }

        return (
            <Spin
                wrapperClassName={fromiframe === 'true' ? 'antSpinWrapper' : null}
                className="application-loding"
                spinning={loading}
            >
                <div
                    className="d-flex"
                    style={
                        fromiframe === 'true'
                            ? { border: '2px solid #ECECEC', height: '100%' }
                            : { height: '100vh' }
                    }
                >
                    {showSubmitModal && this.showSubmitModal()}
                    {!smallScreen && <NewSidebarApplication />}
                    <div
                        className="prequalInterviewPane newInterviewPane"
                        style={{
                            margin: 0,
                            borderRadius: 0,
                            border: 'none',
                            position: 'relative',
                        }}
                    >
                        <div
                            key={this.props.match.params['section']}
                            id="envelope"
                            className={
                                fromiframe === 'true'
                                    ? 'text-center mt-0 text-nowrap iframeHeightAdjuster'
                                    : 'text-center mt-0 text-nowrap full-height'
                            }
                            style={{ padding: 0 }}
                        >
                            <Form
                                style={{ padding: 0 }}
                                className={fromiframe === 'true' ? 'iframeHeightAdjuster' : 'full-height'}
                                key={location}
                                autoComplete="off"
                                ref={this.form}
                                id="personalform"
                                noValidate
                                validated={validated}
                                onSubmit={this.handleSubmit}
                            >
                                <fieldset
                                    style={{ overflow: 'auto' }}
                                    className={fromiframe === 'true' ? 'iframeFieldset' : 'full-height'}
                                    id="scrollBox"
                                    disabled={false}
                                >
                                    <Row className="ml-0 padding">
                                        <Col
                                            className={
                                                fromiframe === 'true'
                                                    ? 'text-center m-0 px-0 interviewpanes'
                                                    : 'text-center m-0 px-0'
                                            }
                                        >
                                            {currentPane}
                                        </Col>
                                    </Row>
                                </fieldset>

                                {this.shouldShowBottomNavigation() && (
                                    <Row className="text-left application-bottom-bar">
                                        <div className="d-flex align-items-center">
                                            <div
                                                hidden={!this.state.hiddenNext}
                                                style={{ color: '#62707C', maxWidth: 480, marginRight: 10, textWrap: 'wrap' }}
                                            >
                                                This concludes the application process. Your loan officer has been alerted and will be in touch shortly to guide you through the next steps.
                                            </div>
                                            <Button
                                                variant="zeitro-primary"
                                                id="previous"
                                                hidden={this.isPreviousHidden()}
                                                className="operate-btn back"
                                                onClick={this.previous}
                                            >
                                                Back
                                            </Button>
                                            <Button
                                                variant="zeitro-primary"
                                                id="next"
                                                type="submit"
                                                hidden={this.state.hiddenNext}
                                                className="text-center operate-btn btn ml-3"
                                            >
                                                {this.state.continueButtonText}
                                            </Button>
                                        </div>
                                    </Row>
                                )}

                                {typeof this.props.borrowerid !== 'undefined' && (
                                    <div className="viewport  mt-5">
                                        <Row className="text-left" style={{ position: 'relative' }}>
                                            <Col xs="auto" style={{ marginTop: '11px' }}>
                                                Last change:{' '}
                                                {lasttouch != null ? lasttouch : 'unknown'}
                                            </Col>
                                            <Col style={{ marginTop: '11px' }}>
                                                <Form.Group controlId="enableedit" className="text-left d-block text-nowrap">
                                                    <Form.Check
                                                        type="checkbox"
                                                        className="mx-1 px-0"
                                                        defaultChecked={isChecked}
                                                        onChange={onEdit}
                                                        label="Enable editing"
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col hidden={status !== 'loinitialized'} style={{ position: 'relative' }} className="text-right">
                                                <Button onClick={this.handoff} style={{ right: '0px', marginTop: '5px' }}>
                                                    {this.state.handingoff ? (
                                                        <img
                                                            alt="wait"
                                                            className="loader"
                                                            style={{ width: '24px', opacity: '1.0', marginRight: '12px' }}
                                                            src={
                                                                com.logo
                                                                    ? `data:image/png;base64, ${com.logo}`
                                                                    : '/logo.png'
                                                            }
                                                        />
                                                    ) : (
                                                        <span>&nbsp;</span>
                                                    )}{' '}
                                                    Hand off to borrower
                                                </Button>
                                            </Col>
                                        </Row>{' '}
                                    </div>
                                )}
                            </Form>
                        </div>
                    </div>
                    {window.innerWidth >= 768 && (
                        <div>
                            {this.shouldShowStepApplication() && (
                                <StepApplication />
                            )}
                        </div>
                    )}

                    {/* contact loan officer button */}
                    <LoanOfficerPanel
                        showLoanOfficerPanel={showLoanOfficerPanel}
                        officer={officer}
                        toggleLoanOfficerPanel={this.toggleLoanOfficerPanel}
                        step={interview.step}
                        hascoborrower={hascoborrower}
                    />
                </div>
            </Spin>
        )
    }

    notifyLO = async (what) => {
        try {
            const token = sessionStorage.getItem('ZeitroA')
            const tosend = JSON.stringify({ notification: what })
            this.setState({ notifyingLO: true })

            const response = await fetch('/borrower/notifylo', {
                method: 'POST',
                body: tosend,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                    Cache: 'no-cache',
                },
            })

            if (response.status !== 200) {
                console.warn('Looks like there was a problem. Status Code: ', response.status)
                return
            }

            this.setState({ notifyingLO: false, showSubmitModal: false })
        } catch (err) {
            this.setState({ notifyingLO: false, showSubmitModal: false })
            console.error('Fetch Error:', err)
        }
    }
}

SubInterview = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SubInterview)
)

class ApplicationInterview extends Component {
    shouldComponentUpdate = () => {
        return true
    }
    render = () => (
        <SubInterview
            {...this.props}
            key={this.props.match.params['section']}
        />
    )
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ApplicationInterview)
)
