import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux'
import { Alert, Skeleton, Switch, Table, Tag, Spin, Button as AntButton, Modal as AntModal, Upload as AntUpload  } from 'antd';
import { CaretDownOutlined, CaretUpOutlined, DeleteOutlined, UploadOutlined, InboxOutlined } from "@ant-design/icons";
import { cloneDeep } from 'lodash'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css';
import { Redirect } from 'react-router-dom'
import { UpdateChangeProgressLastVisited, UpdateChangeProgressType } from "../../Store/progress"
import { saveState } from '../../Store/index'
import { humanReadableFileSize } from './utils'
import * as com from '../../Common'
import "./MyTasks.css"
const { Dragger } = AntUpload;
const mapStateToProps = (state) => {
    return {
        progress: state.progress,
        application: state.application,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateChangeProgressLastVisited: (step) => {
        dispatch(UpdateChangeProgressLastVisited(step))
    },
    updateChangeProgressType: (step, stage) => {
        dispatch(UpdateChangeProgressType(step, stage))
    },
});
class Tasks extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fileList: [],
            uploading: false,
            tasks: [],
            maxCountModalOpen: false,
            loadedDocuments: {},
            activeUploadTask: {},
            uploadModalOpen: false,
            url: "",
            download: "",
            redirect: "",
            show: false,
            modalTitle: "",
            index: 0,
            id: 0,
            taskid: "",
            forwhom: "",
            showAI: false,
            loading: false,
            showclosed: false,
            rowLoading: -1,
        }
        if (window.location.href.includes("app/tasks") && this.props.application.property.purpose === "purchase" && !this.props.application.property.hascontract) {
            window.location = "/prequal/tasks"
        }
        this.columns = [
            {
                title: "NAME",
                dataIndex: 'name',
                key: 'name',
                sorter: (a, b) => a.name.localeCompare(b.name),
                render: (text, row, index) => {
                    let prefix = ""
                    if (row.tasktype === "upload")
                        prefix = "Document upload |"
                    if (row.tasktype === "sign")
                        prefix = "Sign |"


                    return <div className="text-nowrap d-flex" style={{ fontWeight: 500 }}>
                        <div style={{ color: "grey", marginRight: 5 }}>
                            {prefix}
                        </div>{row.name}
                    </div>
                },
            },
            {
                title: 'DUE DATE',
                dataIndex: 'duedate',
                key: 'duedate',
                sorter: (a, b) => a.duedate - b.duedate,
            },
            {
                title: 'STATUS',
                dataIndex: 'status',
                key: 'status',
                sorter: (a, b) => {
                    if (a.status === b.status) return 0
                    if (a.status === "open" && b.status !== "open") return -1
                    if (b.status === "open" && a.status !== "open") return 1
                    if (a.status === "pending" && b.status === "closed") return -1
                    if (b.status === "pending" && a.status === "closed") return 1
                    return -1
                },

                render: (_, { status }) => {
                    if (status === "open") return <span style={{ color: '#325CEB', fontWeight: 500 }}>Action Required</span>
                    else if (status === "closed") return <Tag color="#E9F4EB" className="status-tag" style={{ color: "#2E6333" }}>Closed</Tag>
                    else if (status === "pending") return <Tag color="#FFF2C5" className="status-tag" style={{ color: "#8D5013" }}>Pending</Tag>
                    return null
                },
            },
            Table.EXPAND_COLUMN,
        ]
        this.aref = React.createRef();
    }
    handleUpload = (taskid) => {

        let token = sessionStorage.getItem("ZeitroA")
        this.setState({ uploading: true });

        for (let i = 0; i < this.state.fileList.length; i++) {
            const formData = new FormData();
            formData.append("upload", this.state.fileList[i])

            let taskid = this.state.activeUploadTask?.id
            if (!taskid) continue

            fetch("/borrower/hardmoneyloan/docs/upload", {
                cache: 'no-cache',
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache",
                    "X-FileName": this.state.fileList[i].name,
                    "X-Comment": "",
                    "X-ForWhom": 'borrower',
                    "X-Taskid": 'other',
                    "X-UserTaskid": taskid,
                    "X-Id": 0,
                },
            }).then(response => {
                if (response.status !== 200) {
                    console.warn('Looks like there was a problem. Status Code: ', response.status);
                    return
                }
                // Examine the text in the response
                response.json().then(js => {
                    this.setState({ uploadModalOpen: false, fileList: [] }, () => this.fetchUploadedFiles(taskid))
                    return js
                })
            }).catch(error => {
                // Not Production ready! Do some error handling here instead...
                this.setState({ uploading: false });
                alert("Upload error! Please try again")
            })
        }
    }

    componentDidMount() {
        this.props.updateChangeProgressType("tasks")
        let now = new Date()
        let snow = now.toUTCString()
        this.props.updateChangeProgressLastVisited(snow)
        saveState()
        this.reload()
    }
    reload = () => {
        this.setState({ loading: true })
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/borrower/hardmoneyloan/getLoanTasks', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    this.setState({ loading: false })
                    return;
                }
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                    } else {

                        let tasks = js.Tasks.map(s => {
                            let dd = com.timeGoToJS(s.Created)
                            let dt = new Date(dd)
                            dd = com.timeGoToJS(s.Deadline)
                            let deadline = new Date(dd)
                            return {
                                rand: Math.random(), id: s.ID, name: s.TaskName, created: dt.toLocaleString("en-US"), status: s.Status,
                                forwhom: s.ForWhom, tasktype: s.TaskType, taskid: s.TaskID, deadline: deadline,
                                size: s.FileSize, extraInfo: s.ExtraInfo, submittedDate: s.Submitted
                            }

                        })
                        this.rawdocs = tasks;
                        this.loadtasks(tasks, this.state.showclosed)
                    }
                    this.setState({ loading: false })
                });
            }
        ).catch((err) => {
            this.setState({ loading: false })
            console.log('Fetch Error :', err);
        });
    }
    loadtasks = (tasks, showclosed) => {
        if (!showclosed) {

            let newtasks = []
            tasks.forEach(doc => {
                newtasks.push(doc)
            })
            tasks = newtasks
        }
        this.setState({ tasks })
        let where = this.props.match.params["where"]
        if ("undefined" === typeof where)
            return
        where = com.hex2ascii(where)

        let split = where.split('|')
        let id = parseInt(split[0])
        let index = 0
        for (let i = 0; i < tasks.length; i++) {
            if (tasks[i].id === id) {
                index = i
                break
            }
        }

        switch (this.state.tasks[index].tasktype) {
            case 'upload':
                return this.showTask(index)
            case 'sign':
                return this.showSign(index)
            default:
                return this.showAI(index)
        }

    }
    markClosed = () => {
        let token = sessionStorage.getItem("ZeitroA")
        let id = this.state.tasks[this.state.index].id
        this.setState({ loading: true })

        fetch('/brwr/closetask/' + id, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    alert('Looks like there was a problem. Status Code: ' +
                        response.status);
                    this.setState({ loading: false })
                    window.document.dispatchEvent(new Event('reauthenticate'), "");
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {
                    if (js.Status !== "OK") {
                        alert("Error: " + js.Text)
                        window.document.dispatchEvent(new Event('reauthenticate'), "");

                    } else {
                    }
                    this.setState({ loading: false })
                    this.reload()
                });
            }
        ).catch((err) => {
            this.setState({ loading: false })
            console.log('Fetch Error :', err);
        });
    }
    forWhomDecoded = (_forwhom) => {
        let forwhom = ""

        if (_forwhom === 'joint' && this.props.application.coborrower.firstname === '' &&
            this.props.application.coborrower.lastname === '') {
            _forwhom = 'borrower'
        }
        try {
            switch (_forwhom) {
                case 'borrower':
                    forwhom = this.props.application.borrower.firstname + " " +
                        this.props.application.borrower.lastname
                    break
                case 'coborrower':
                    forwhom = this.props.application.coborrower.firstname + " " +
                        this.props.application.coborrower.lastname
                    break;
                case 'joint':
                    forwhom = this.props.application.borrower.firstname + " " +
                        this.props.application.borrower.lastname + " and " + this.props.application.coborrower.firstname + " " +
                        this.props.application.coborrower.lastname
                    break;
                default:
                    forwhom = _forwhom
                    break;
            }
        } catch (x) {
            forwhom = _forwhom
        }
        return forwhom
    }
    showTask = (index) => {
        let forwhom = this.forWhomDecoded(this.state.tasks[index].forwhom)
        this.setState({
            show: true, modalTitle: this.state.tasks[index].name,
            taskid: this.state.tasks[index].taskid,
            forwhom,
            _forwhom: this.state.tasks[index].forwhom,
            index: index,
            id: this.state.tasks[index].id,
        })
    }
    showSign = (index) => {
        this.setState({ redirect: <Redirect to={'/app/sign/' + this.state.tasks[index].taskid + "/" + this.state.tasks[index].id} /> })
    }
    showAI = (index) => {
        let forwhom = this.forWhomDecoded(this.state.tasks[index].forwhom)

        this.setState({
            showAI: true, modalTitle: this.state.tasks[index].name,
            taskid: "other",
            forwhom,
            _forwhom: this.state.tasks[index].forwhom,
            index: index,
            id: 0,
        })
    }
    handleClose = () => {
        this.setState({ show: false })

    }
    handleCloseAI = () => {
        this.setState({ showAI: false })
    }
    handleCloseClose = () => {
        this.setState({ show: false })
        this.markClosed()
    }
    handleCloseAIClose = () => {
        this.setState({ showAI: false })
        this.markClosed()
    }
    documentsJSON = (document, index) => {
        return {
            name: document.name,
            duedate: document.deadline.toLocaleDateString(),
            deadline: document.deadline,
            status: document.status,
            id: document.id,
            index: index,
            tasktype: document.tasktype,
            submittedDate: document.submittedDate,
        }
    }
    onshowclosed = (checked) => {
        this.setState({ showclosed: !checked })
        this.loadtasks(this.rawdocs, !checked)
    }
    onUploaded = () => {
        this.handleCloseClose()
        this.reload()
    }
    openUploadModal = (task) => {
        this.setState({
            uploadModalOpen: true,
            activeUploadTask: task
        })
    }
    onSubmitTask = (task) => {
        const { id } = task

        if (task.status === "open") {
            let token = sessionStorage.getItem("ZeitroA")
            let body = {
                TaskID: id,
                NewValue: "pending"
            }

            fetch('/borrower/hardmoneyloan/changetaskstatus', {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache"
                }
            }).then(
                response => {
                    if (response.status !== 200) {
                        alert('Looks like there was a problem. Status Code: ' +
                            response.status);
                        return;
                    }
                    // Examine the text in the response
                    response.json().then(js => {

                        if (js.Status !== "OK") {
                            alert("Error: " + js.Text)
                        } else {
                            let tmp = this.state.tasks.map(d => {
                                if (d.id === id) {
                                    d.status = "pending"
                                    d.submitted = js.Text
                                }
                                return d
                            })
                            this.setState({ tasks: cloneDeep(tmp) })
                        }
                    });
                }
            )
                .catch(function (err) {
                    console.log('Fetch Error :-S', err);
                });
        }
    }
    renderExpandRow = (task, row) => {
        const isTaskOpen = task.status === "open"
        const formattedSubmitTime = task.submittedDate ? 'Submitted on ' + new Date(task.submittedDate).toLocaleString() : '';
        const hasItem = Boolean(this.state.loadedDocuments[task.id]) && this.state.loadedDocuments[task.id]?.length > 0

        return <div className="expand-row-wrapper">
            {task.id === this.state.rowLoading ? <Spin /> : <>
                <div className="header">
                    <div className="header-title">Uploaded Files</div>
                    <div className="header-right">
                        {isTaskOpen ? <>
                            <AntButton className="upload-btn" type="text" size="large" icon={<UploadOutlined />} onClick={(e) => {
                                this.openUploadModal(task)
                            }}>
                                Upload More
                            </AntButton>
                            <AntButton className="submit-btn" disabled={!hasItem} type="primary" size="large" onClick={(e) => {
                                this.onSubmitTask(task)
                            }}>
                                Submit
                            </AntButton>
                        </> : <span className="submit-time">{formattedSubmitTime}</span>
                        }
                    </div>
                </div>
                <div className="body">
                    {hasItem ?
                        this.state.loadedDocuments[task.id].map(file => this.renderFileSlab(file, this.state.tasks[row].id, isTaskOpen)) : null
                    }
                </div>
            </>}

        </div>
    }
    deleteDoc = (id, taskid) => {

        let token = sessionStorage.getItem("ZeitroA")

        fetch('/docs/deletedoc/' + id, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    window.document.dispatchEvent(new Event('reauthenticate'), "");
                    this.setState({ show: false })
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        window.document.dispatchEvent(new Event('reauthenticate'), "");
                        console.log("Error: " + js.Text)
                    } else {
                        this.fetchUploadedFiles(taskid)
                    }
                });
            }
        )
            .catch(function (err) {
                this.setState({ show: false })
                console.log('Fetch Error :', err);
            });
    }

    renderFileSlab = (file, taskid, isTaskOpen) => {
        return <div className="file-slab">
            <img className="pdfIcon" src="/images/pdf-icon.svg" />
            <div className="info">
                <div className="name">{file.FileName}</div>
                <div className="size">{humanReadableFileSize(file.FileSize)}</div>
            </div>
            {isTaskOpen ? <AntButton className="deleteBtn" onClick={() => this.deleteDoc(file.ID, taskid)} type="text" shape="circle" icon={<DeleteOutlined />} /> : null}
        </div>
    }

    fetchUploadedFiles = (TaskID) => {
        if (!TaskID) return
        let token = sessionStorage.getItem("ZeitroA")

        this.setState({ rowLoading: TaskID })
        fetch('/borrower/hardmoneyloan/getTaskFiles?' + new URLSearchParams({
            TaskID
        }), {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
                TaskID
            },
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    window.document.dispatchEvent(new Event('reauthenticate'), "");
                    this.setState({ rowLoading: -1 })
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                        window.document.dispatchEvent(new Event('reauthenticate'), "");
                    } else {
                        this.setState({
                            loadedDocuments: {
                                ...this.state.loadedDocuments,
                                [TaskID]: js.Docs.map(x => ({
                                    ...x,
                                    extraInfo: x.ExtraInfo ? JSON.parse(x.ExtraInfo) : {},
                                }))
                            }
                        })
                    }
                    this.setState({ rowLoading: -1 })
                    return js
                });
            }
        ).catch((err) => {
            this.setState({ rowLoading: -1 })
            console.log('Fetch Error :', err);
        });
    }


    handleSubmit = () => {
        this.handleUpload()
    }

    renderUploadModal = () => {
        const uploadDraggerProps = {
            listType: "picture",
            accept: "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*",
            onRemove: (file) => {
                const index = this.state.fileList.indexOf(file);
                const newFileList = this.state.fileList.slice();
                newFileList.splice(index, 1);
                this.setState({ fileList: newFileList });
            },
            beforeUpload: (file) => {
                if (this.state.fileList.length >= 100) {

                }
                this.setState({ fileList: [...this.state.fileList, file] });
                return false;
            },
            fileList: this.state.fileList,
        }
        const { name } = this.state.activeUploadTask
        return <AntModal
            className="upload-modal"
            title={"Upload Files | " + name}
            onCancel={() => this.setState({ uploadModalOpen: false, fileList: [] })}
            open={this.state.uploadModalOpen}
            footer={[
                <AntButton type="default" onClick={() => this.setState({ uploadModalOpen: false, fileList: [] })}>Cancel</AntButton>,
                <AntButton loading={this.state.loading} disabled={this.state.fileList.length === 0} type="primary" onClick={this.handleSubmit}>Add Files</AntButton>
            ]}
        >
            <Dragger {...uploadDraggerProps}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Drag & Drop or Choose File from your device to upload</p>
            </Dragger>
        </AntModal>
    }
    render() {

        const showTaskTable = () => {
            return <div className="currentTasksWrap">
                <Table
                    scroll={{ x: true }}
                    columns={this.columns}
                    dataSource={this.state.tasks.map(this.documentsJSON)}
                    pagination={false}
                    rowKey="id"
                    expandable={{
                        expandedRowRender: this.renderExpandRow,
                        rowExpandable: (record) => true,
                        onExpand: (expanded, record) => {
                            this.fetchUploadedFiles(record.id)
                        },
                        expandIcon: ({ expanded, onExpand, record }) => expanded ?
                            <CaretUpOutlined onClick={e => onExpand(record, e)} /> :
                            <CaretDownOutlined onClick={e => onExpand(record, e)} />
                    }}
                />
            </div>
        }

        return (
            <div className="taskPageWrap text-left mx-3">
                {this.state.redirect}
                {this.renderUploadModal()}

                <a hidden ref={this.aref} href={this.state.url} download={this.state.download}>&nbsp;</a>
                <div className="title-2">My Tasks</div>
                {this.state.loading ? <Skeleton className="mt-3" active /> : <div>

                    {this.state.tasks.length === 0 ?
                        <div className="infoTip mt-3">There are currently no outstanding tasks</div> :
                        <div>
                            <div className="taskTips">
                                <Alert
                                    message={<div><img src="/images/checkEmail.png" style={{ height: 18, width: 21, marginRight: 10 }} />You got {this.state.tasks.length} new tasks from your loan officer! Check it now.</div>}
                                    className="mt-2"
                                    type="info"
                                    closable
                                />
                            </div>
                            <div className="d-flex justify-content-end my-3">
                                <div className="mr-2" style={{ fontWeight: 500 }}>View new task only</div>
                                <Switch defaultChecked={!this.state.showclosed} onChange={this.onshowclosed} />
                            </div>
                            {showTaskTable()}
                        </div>
                    }
                </div>
                }



            </div>
        )
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tasks))
