import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

export default function FaqPage() {

    return (
        <div className=" py-0 my-0">
            <h2>F.A.Q.</h2>


            <div id="landing1" className="container my-5 text-left" >

                <div className="my-3">
                    <b>What is the difference between a fixed-rate loan and an adjustable-rate loan?</b> <br />
                    With a fixed-rate mortgage, the interest rate stays the same during the life of the loan. With an adjustable-rate mortgage (ARM), the interest changes periodically, typically in relation to an index. While the monthly payments that you make with a fixed-rate mortgage are relatively stable, payments on an ARM loan will likely change. There are advantages and disadvantages to each type of mortgage, and the best way to select a loan product is by talking to us.
                </div>
                <div className="my-3"></div>
                <b>What documents will be required to close a loan?</b><br />

                Documents required vary from loan to loan, but generally the following are required, often for up to two years back:
                <ul>
                    <li>statements of income such as W-2’s, pay stubs, financial statements</li>
                    <li>bank statements</li>
                    <li>a list of any assets that you own</li>
                    <li>rental or mortgage history</li>
                    <li>employment history and current information</li>
                    <li>personal identification, including Green Card if applicable</li>
                    <li>purchase contract</li>
                    <li>Other pertinent items such as: Bankruptcy Discharge Notice or Divorce Decree
                loan application</li>
                </ul>
                <div className="my-3 text-left">
                <b>How do I know how much house I can afford?</b><br />
                Generally speaking, you can purchase a home with a value of two or three times your annual household income. However, the amount that you can borrow will also depend upon your employment history, credit history, current savings and debts, and the amount of down payment you are willing to make. You may also be able to take advantage of special loan programs for first time buyers to purchase a home with a higher value. Give us a call, and we can help you determine exactly how much you can afford.
                </div>
                <div className="my-3 text-left">
                <b>What is a rate lock?</b><br/>
                A rate lock is a contractual agreement between the lender and buyer. There are four components to a rate lock: loan program, interest rate, points, and the length of the lock.
                </div>

            </div>

        </div>


    );
}
