import React, { Component } from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import Form1003 from '../Interview/Form1003'
import { Redirect } from 'react-router-dom'
import { withRouter } from "react-router-dom";

class Sign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: "",
            loading: false,
        }
        this.taskid = this.props.match.params["taskid"]
        this.id = this.props.match.params["id"]
        this.rf = React.createRef()
        this.form = React.createRef()
    }

    componentDidMount() {

    }
    checkAuthorizationValidity = (e) => {
    
    }

    markClosed = () => {
        let token = sessionStorage.getItem("ZeitroA")
        let id = this.id
        this.setState({loading: true})

        fetch('/brwr/closetask/' + id, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    alert('Looks like there was a problem. Status Code: ' +
                        response.status);
                        this.setState({loading: false})
                        window.document.dispatchEvent(new Event('reauthenticate'), "");
                    return;
                    
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        alert("Error: " + js.Text)
                        window.document.dispatchEvent(new Event('reauthenticate'), "");

                    } else {
                        this.setState({redirect: <Redirect to="/app/tasks"/>})
                    }
                    this.setState({loading: false})
            
            });
            }
        ).catch( (err) => {
            this.setState({loading: false})
            console.log('Fetch Error :', err);
        });
    }

    onSigned = () => {
        this.markClosed()
    }
    render() {
        return (
        <div>
            {this.state.redirect}
           <Form1003 ref={this.rf} title="Please sign"
           onSigned={this.onSigned}
           signedtitle="Please sign the document"
           borrowerid={this.props.borrowerid} 
           checkValidity={this.checkAuthorizationValidity} 
           filename = {this.taskid + ".pdf"}
           input={"/borrower/form/"+this.taskid}
           poke={"/borrower/checksignedform/"+this.taskid}
           download={"/borrower/formpdf/" + this.taskid} 
           output={"/borrower/signform/" + this.taskid} self={this.form} />
        </div>
        )
    }
}

export default withRouter(Sign)
