import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as com from "./Common.js"
import './App.css';
import { getCurrentState } from './Store'
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux'


const mapStateToProps = (state) => {
    return {
        application: state.application,

    }
}

const mapDispatchToProps = (dispatch) => ({

});


class LogoutPage extends Component  {

    componentDidMount = () => {
        let logoutpath = com.redirectPublicWebsite()
        if (com.getCustomerId() === "individualcustomers") {
            logoutpath = sessionStorage.getItem("logoutpath")
        } 
        if (window.location.href.includes("localhost")) {
            logoutpath="http://localhost:3000/services/signin?customerid=zeitro"
        }
        // getCurrentState()
        window.setTimeout( () => {
        window.sessionStorage.removeItem("ZeitroA")
        window.sessionStorage.removeItem("state")    
        window.sessionStorage.removeItem("originalstate")    
        window.sessionStorage.removeItem("f1003")       
        window.sessionStorage.removeItem("newcustomer")       

        window.location.href = window.location.href =  logoutpath
        }, 500 ) 
    }
    render = () => {

        return (

            <div id="spinner" className="pt-5 mt-5 w-100 text-center">
                    <Spinner animation='border' />
            </div>

        )
    }
}




export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(LogoutPage)