import styled from 'styled-components'

export const ButtonGroup = styled.div`
  display: inline-flex;
  border-radius: 4px;

  > * {
    min-width: 40px;

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: -1px;
    }

    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right-color: transparent;
    }
  }
  margin: 5px;
`

export default ButtonGroup
