import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Checkbox, DatePicker, Input, InputNumber, Select, Switch } from 'antd'
import 'bootstrap/dist/css/bootstrap.min.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import './ApplicationInterview.css'
import '../BorrowerApplication.css'
import DateOfBirth from '../DateOfBirth'
import * as act from '../Store/actions'
import * as st from '../State'
import * as com from '../Common'
import { UpdateChangeProgress, UpdateChangeLastTouch } from '../Store/progress'
import { updateLeadSource } from '../Store/accountinfo'
import { Tips } from '../Common/Tooltip'
import InputGroupComponent from '../Common/InputGroup'
import AddressAutocomplete from '../Common/AddressAutocomplete'
import CheckGroup from '../Common/components/CheckGroup'
import eventBus from '../Bus'
import { getStates, getCounties } from '../Tools/Address'

const PHONE_NUMBER_PLACEHOLDER = '(000)-000-0000'

const visas = [
    st.VT_A_1,
    st.VT_E_1,
    st.VT_E_2,
    st.VT_E_3,
    st.VT_F_1,
    st.VT_G_1,
    st.VT_G_2,
    st.VT_G_3,
    st.VT_G_4,
    st.VT_H_1B,
    st.VT_H_4,
    st.VT_L_1,
    st.VT_L_2,
    st.VT_O_1,
    st.VT_O_2,
    st.VT_O_3,
    st.VT_P_1,
    st.VT_P_2,
    st.VT_P_3,
    st.VT_P_4,
    st.VT_TN,
]

class PhoneInput extends Component {
    constructor(props) {
        super(props)
        this.state = {
            displayValue: props.value === PHONE_NUMBER_PLACEHOLDER ? '' : props.value
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                displayValue: this.props.value === PHONE_NUMBER_PLACEHOLDER ? '' : this.props.value
            })
        }
    }

    handleChange = (e) => {
        const { onChange } = this.props
        // 限制输入长度为14个字符 (格式如: (123)-456-7890)
        const newValue = e.target.value.slice(0, 14)

        // 更新内部显示值
        this.setState({ displayValue: newValue })

        // 触发外部onChange
        onChange({
            target: {
                value: newValue || PHONE_NUMBER_PLACEHOLDER
            }
        })
    }

    render() {
        const { type, phoneRef, onDelete, onTypeChange, showDelete = true } = this.props
        const { displayValue } = this.state
        const isEmpty = !displayValue

        return (
            <div style={{ flex: '1 1 0'}}>
                <div md={4} xs={12}>
                    <div className="mb-3">
                        {showDelete && (
                            <div className="d-flex justify-content-end mb-1">
                                <span
                                    style={{ cursor: 'pointer', color: '#C21818', fontWeight: 500, fontSize: '16px' }}
                                    onClick={() => onDelete(type)}
                                >
                                    Delete
                                </span>
                            </div>
                        )}
                        <div className="phone-input-container">
                            <Form.Control
                                style={{ flex: '2', border: 'none', borderRadius: '8px 0 0 8px', boxShadow: 'none' }}
                                isValid={false}
                                ref={phoneRef}
                                placeholder={PHONE_NUMBER_PLACEHOLDER}
                                value={displayValue}
                                onChange={this.handleChange}
                                required
                                type="tel"
                                pattern="\([0-9]{3}\)-[0-9]{3}-[0-9]{4}"
                                inputMode="numeric"
                                onKeyPress={(e) => {
                                    if (!/[0-9-()\s]/.test(e.key)) {
                                        e.preventDefault()
                                    }
                                }}
                            />
                            <div style={{ width: '1px', background: '#d9d9d9' }} />
                            <Select
                                style={{ flex: '1', height: '44px', background: '#FCFCFC' }}
                                className="contact-select"
                                placeholder="Type"
                                defaultValue={isEmpty ? undefined : type}
                                value={isEmpty ? undefined : type}
                                onChange={onTypeChange}
                            >
                                <Select.Option value="home">Home</Select.Option>
                                <Select.Option value="mobile">Mobile</Select.Option>
                                <Select.Option value="work">Work</Select.Option>
                            </Select>
                        </div>
                        <Form.Control.Feedback type="invalid">
                            Please enter a valid phone number.
                        </Form.Control.Feedback>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    // Helper function to determine occupation status based on employment and self-employment flags
    const getOccupation = (employed, selfemployed) => {
        if (employed !== 'employed') return 'unemployed'
        return selfemployed ? 'selfemployed' : 'employed'
    }

    return {
        application: state.application,
        accountInfo: state.accountInfo,
        hascoborrower: state.application.hascoborrower,
        property: state.application.property,
        cohabiting: state.application.cohabiting,
        financingsameproperty: state.application.financingsameproperty,
        borroweroccupation: getOccupation(
            state.application.borrower.occupation.hasoccupation,
            state.application.borrower.occupation.selfemployed
        ),
        coborroweroccupation:
            'co' +
            getOccupation(
                state.application.coborrower.occupation.hasoccupation,
                state.application.coborrower.occupation.selfemployed
            ),
        progress: state.progress,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateLeadSource: (t) => {
        dispatch(updateLeadSource(t))
    },
    changeBorrowerInfoByValue: (val, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(val, who, verb))
    },
    changePhoneInfo: (input, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(input, who, verb))
    },
    updateSameProperty: (event) => {
        dispatch(act.UpdateSameProperty(event.target.checked))
    },
    updateCurrentResidence: (event) => {
        dispatch(act.UpdateCurrentResidence(event.target.value))
    },
    updateCoborrower: (event) => {
        dispatch(act.UpdateHasCoborrower(event.target.value))
    },
    updateCohabiting: (event) => {
        dispatch(act.UpdateCohabiting(event.target.checked))
    },
    changeMainProperty: (value, verb) => {
        dispatch(act.ChangeMainProperty(value, verb))
    },
    changeMainPropertyPurpose: (event) => {
        dispatch(act.ChangeMainPropertyPurpose(event.target.value))
    },
    changeMainPropertyAppraisal: (event) => {
        dispatch(act.ChangeMainPropertyAppraisal(event.target.value))
    },
    changeMainPropertyAttribute: (t, verb) => {
        dispatch(act.ChangeMainPropertyAttribute(t, verb))
    },
    updateBorrowerAttribute: (who, value, verb) => {
        dispatch(act.UpdateBorrowerAttribute(who, value, verb))
    },
    changeMainPropertySalesPrice: (event) => {
        dispatch(act.ChangeMainPropertySalesPrice(event.target.value))
    },
    addPreviousAddress: (event, who) => {
        dispatch(act.AddPreviousAddress(event.target.value, who))
    },
    updatePreviousAddress: (event, who, verb, n) => {
        dispatch(act.UpdatePreviousAddress(event.target.value, who, verb, n))
    },
    removePreviousAddress: (event, who, n) => {
        dispatch(act.RemovePreviousAddress(event.target.value, who, n))
    },
    clearPreviousAddress: (event, who) => {
        dispatch(act.ClearPreviousAddress(event.target.value, who))
    },
    changeOccupationInfo: (event, who, verb) => {
        dispatch(act.ChangeOccupationInfo(event.target.value, who, verb))
    },
    changeOccupationCheck: (check, who, verb) => {
        dispatch(act.ChangeOccupationInfo(check, who, verb))
    },
    changeBorrowerInfo: (event, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(event.target.value, who, verb))
    },
    updateApplicationAttribute: (input, verb) => {
        dispatch(act.UpdateApplicationAttribute(input, verb))
    },
    updateApplicationCheck: (event, verb) => {
        dispatch(act.UpdateApplicationAttribute(event.target.checked, verb))
    },
    changeMainPropertyPropertyType: (event) => {
        dispatch(act.ChangeMainPropertyPropertyType(event.target.value))
    },
    updateChangeProgress: (step, stage) => {
        dispatch(UpdateChangeProgress(step, stage))
    },
    addRemoteCoborrower: (i) => {
        dispatch(act.AddRemoteCoborrower(i))
    },
    changeMainPropertyRefinanceAttribute: (value, verb) => {
        dispatch(act.ChangeMainPropertyRefinanceAttribute(value, verb))
    },
    updateSelectField: (input, verb) => {
        dispatch(act.UpdateSelectField(input, verb))
    },
    changeReviewedLoans: (value) => {
        dispatch(act.ChangeReviewedLoans(value))
    },
    changeMainPropertyOccupancy: (event) => {
        dispatch(act.ChangeMainPropertyOccupancy(event.target.value))
    },
    clearBorrowerGift: () => {
        dispatch(act.ClearBorrowerGift())
    },
    clearBorrowerInsurance: () => {
        dispatch(act.ClearBorrowerInsurance())
    },
    addBorrowerOwnedProperty: (t) => {
        dispatch(act.AddBorrowerOwnedProperty(t))
    },
    prependOwnedProperty: (t) => {
        dispatch(act.PrependOwnedProperty(t))
    },
    prependBorrowerOwnedProperty: () => {
        dispatch(act.PrependBorrowerOwnedProperty())
    },
    prependCoborrowerOwnedProperty: () => {
        dispatch(act.PrependCoborrowerOwnedProperty())
    },
    changeOwnedProperty: (t, i, v) => {
        dispatch(act.ChangeOwnedProperty(t, i, v))
    },
    changeBorrowerOwnedProperty: (t, i, v) => {
        dispatch(act.ChangeBorrowerOwnedProperty(t, i, v))
    },
    changeCoborrowerOwnedProperty: (t, i, v) => {
        dispatch(act.ChangeCoborrowerOwnedProperty(t, i, v))
    },
    removeOwnedProperty: (i) => {
        dispatch(act.RemoveOwnedProperty(i))
    },
    removeBorrowerOwnedProperty: (i) => {
        dispatch(act.RemoveBorrowerOwnedProperty(i))
    },
    removeCoborrowerOwnedProperty: (i) => {
        dispatch(act.RemoveCoborrowerOwnedProperty(i))
    },
    clearBorrowerOwnedProperty: (t) => {
        dispatch(act.ClearBorrowerOwnedProperty(t))
    },
    updateChangeLastTouch: (t) => {
        dispatch(UpdateChangeLastTouch(t))
    },
    updateExpenses: (event, who, verb) => {
        dispatch(act.UpdateExpenses(event, who, verb))
    },
    changeBorrowerCheck: (event, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(event.target.checked, who, verb))
    },
})

class Applicant extends Component {
    constructor(props) {
        super(props)

        this.form = React.createRef()
        this.cform = React.createRef()

        this.state = {
            selectedalone: props.hascoborrower ? '' : 'selected',
            withcoborrower: props.hascoborrower ? 'selected' : '',
            show: false,
            email: '',
            isValidEmail: true,
            showSelf: false,
            showCashout: false,
            qualify: '',
            additional: '',
            residence: '',
            validated: false,
            loanLimits: {},
            sameAddress: false,
        }

        this.radiocoborrower = React.createRef()
        this.homePhoneRef = React.createRef()
        this.mobilePhoneRef = React.createRef()
        this.workPhoneRef = React.createRef()
        this.dob = React.createRef()

        if (sessionStorage.getItem('ZeitroA') !== null) {
            const { firstname, lastname } = this.props.application.borrower
            if (firstname === '' && lastname === '') {
                this.getName()
            }
        }

        if (this.props.application.firsttimebuyer === null) {
            this.props.updateApplicationAttribute(false, 'firsttimebuyer')
        }
    }

    onRef = (ref) => {
        this.dob = ref
    }

    getName = async () => {
        try {
            const token = sessionStorage.getItem('ZeitroA')
            const response = await fetch('/auth/getprofile', {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + token,
                    Cache: 'no-cache',
                    'X-Borrower': this.props.borrowerid,
                },
            })

            if (response.status !== 200) {
                console.warn('Looks like there was a problem. Status Code: ' + response.status)
                return
            }

            const js = await response.json()

            if (js.Status !== 'OK') {
                console.error('Error: ' + js.Text)
                return
            }

            // Update borrower info
            this.props.changeBorrowerInfo(
                { target: { value: js.FirstName } },
                'borrower',
                'firstname'
            )
            this.props.changeBorrowerInfo(
                { target: { value: js.LastName } },
                'borrower',
                'lastname'
            )
            this.props.changeBorrowerInfo(
                { target: { value: js.Email } },
                'borrower',
                'email'
            )

        } catch (err) {
            console.error('Fetch Error:', err)
        }
    }

    updateBorrower = async () => {
        try {
            const token = sessionStorage.getItem('ZeitroA')
            const bo = this.props.application.borrower
            const cb = this.props.application.coborrower

            const response = await fetch('/auth/updateborrower', {
                body: JSON.stringify({
                    Email: bo.email,
                    FirstName: bo.firstname,
                    MiddleName: bo.middlename,
                    LastName: bo.lastname,
                    CoborrowerFirstName: cb.firstname,
                    CoborrowerMiddleName: cb.middlename,
                    CoborrowerLastName: cb.lastname,
                    CoborrowerEmail: cb.email,
                    CoborrowerPhone: cb.homephone,
                }),
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + token,
                    Cache: 'no-cache',
                    'X-Borrower': this.props.borrowerid,
                },
            })

            if (response.status !== 200) {
                console.warn('Looks like there was a problem. Status Code: ' + response.status)
                return
            }
        } catch (err) {
            console.error('Fetch Error:', err)
        }
    }

    updateYears = (value) => {
        com.touch()

        const { who } = this.props
        const { previousaddresses } = this.props.application[who]

        if (value !== '' && parseInt(value) < 2 && previousaddresses.length === 0) {
            this.props.addPreviousAddress(
                { target: { value: 0 } },
                who
            )
            this.props.changeBorrowerInfo(
                { target: { value: '0' } },
                who,
                'presentaddressmonths'
            )
        }

        if (value !== '' && parseInt(value) >= 2 && previousaddresses.length !== 0) {
            this.props.clearPreviousAddress(
                { target: { value: 0 } },
                who
            )
        }

        this.props.changeBorrowerInfo(
            { target: { value } },
            who,
            'presentaddressyears'
        )
    }

    updateMonths = (value) => {
        com.touch()

        this.props.changeBorrowerInfo(
            { target: { value } },
            this.props.who,
            'presentaddressmonths'
        )
    }

    componentDidMount() {
        if (this.props.application.hascoborrower === null) {
            this.updateCoborrower({ target: { value: st.HC_Alone } })
        }

        this.completion()
        this.setState({ sameAddress: this.hasSameAddress() })

        const inputs = document.querySelectorAll('input')
        // Iterate over each input
        inputs.forEach(function (input) {
            // Set autocomplete attribute to off or new-password
            input.setAttribute('autocomplete', 'off')
            if (input.type === 'password') {
                input.setAttribute('autocomplete', 'new-password')
            }
        })
    }

    componentDidUpdate() {
        this.completion()
    }

    completion = () => {
        const formElements = this.form.current.elements
        if (formElements.length === 0) {
            sessionStorage.setItem('progressValue', 100)
            return
        }

        let totalCount = 0
        let fillNum = 0
        for (let i = 0; i < formElements.length; i++) {
            const element = formElements[i]
            if (element.type !== 'button') {
                totalCount++
                if (!isEmpty(element.value)) {
                    fillNum++
                }
            }
        }

        const progressValue = Math.round((fillNum / totalCount) * 100)
        sessionStorage.setItem('progressValue', progressValue)
    }

    hasSameAddress = () => {
        const application = this.props.application
        const borrower = application.borrower
        const coBorrower = application.coborrower

        return (
            borrower.presentaddressstreet === coBorrower.presentaddressstreet &&
            borrower.presentaddressstate === coBorrower.presentaddressstate &&
            borrower.presentaddresscounty === coBorrower.presentaddresscounty &&
            borrower.presentaddresscity === coBorrower.presentaddresscity &&
            borrower.presentaddresszip === coBorrower.presentaddresszip
        )
    }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        }

        return true
    }

    onFinish = () => {
        const { who } = this.props
        const { hascoborrower } = this.props.application
        const { completedsteps } = this.props.application[who] || {}

        if (who === com.coborrower && hascoborrower !== 'withcoborrower') {
            return
        }

        if (!completedsteps?.yourinfo) {
            // Mark the step as completed
            this.props.changeBorrowerInfo(
                {
                    target: {
                        value: { ...completedsteps, yourinfo: true },
                    },
                },
                who,
                'completedsteps'
            )
        }
    }

    handleSubmit = (e) => {
        const { who } = this.props
        const { homephone, mobilephone, workphone } = this.props.application[who]
        const phones = [
            { value: homephone, ref: this.homePhoneRef },
            { value: mobilephone, ref: this.mobilePhoneRef },
            { value: workphone, ref: this.workPhoneRef }
        ]

        // Check all existing phone numbers
        let isValid = true
        phones.forEach(({ value, ref }) => {
            if (ref.current) {
                if (value && value.length !== 14) {
                    ref.current.setCustomValidity('Incorrect phone number format.')
                    isValid = false
                } else if (value) {
                    ref.current.setCustomValidity('')
                }
            }
        })

        if (!isValid) {
            this.setState({ validated: true })
            return false
        }

        if (this.form.current.reportValidity() === false) {
            e.preventDefault()
            this.setState({ validated: true })
            return false
        }

        const { hascoborrower } = this.props.application
        if (hascoborrower === 'withcoborrower') {
            if (this.cform.current.reportValidity() === false) {
                e.preventDefault()
                e.stopPropagation()
                this.setState({ validated: true })
                return false
            }
        }

        const { purpose } = this.props.property
        if (purpose !== st.POL_Purchase && this.props.financingsameproperty) {
            this.props.changeBorrowerInfo(
                { target: { value: st.O_Own } },
                com.borrower,
                'presentaddressownership'
            )
        }

        this.updateBorrower()

        return true
    }

    chooseBorrower = (event) => {
        com.touch()
        this.radioself.current.checked = true
        this.updateCoborrower({ target: { value: st.HC_Alone } }) // this is a hack
    }

    chooseCoborrower = (event) => {
        com.touch()
        this.radiocoborrower.current.checked = true
        this.updateCoborrower({ target: { value: st.HC_Withcoborrower } }) // this is a hack
    }

    updateCoborrower = (event) => {
        com.touch()

        const eventTargetId = event.target.id
        if (eventTargetId === 'multiple') {
            if (this.props.application.remotecoborrowers.length === 0)
                this.props.addRemoteCoborrower()
        }
        if (eventTargetId === 'withcoborrower') {
            this.updateMarried({ target: { value: 'true' } })
        }
        if (eventTargetId === 'alone') {
            this.updateMarried({ target: { value: 'false' } })
        }

        this.props.updateCoborrower(event)
    }

    updateCohabiting = (e) => {
        com.touch()
        this.props.updateCohabiting({
            target: { checked: e.target.value === 'true' },
        })
    }

    updateMarried = (e) => {
        com.touch()
        this.props.updateApplicationAttribute(
            e.target.value === 'true',
            st.MS_Married
        )
    }

    changeMainPropertyAppraisal = (e) => {
        com.touch()

        const { purpose } = this.props.property
        if (purpose !== st.POL_Purchase && this.props.financingsameproperty) {
            this.props.changeBorrowerInfo(e, com.borrower, 'marketvalue')
        }

        this.props.changeMainPropertyAppraisal(e)
    }

    onMarried = (e) => {
        com.touch()

        const isCurrentlyCohabiting = this.props.application.cohabiting
        this.props.updateCohabiting({ target: { checked: !isCurrentlyCohabiting } })
        this.props.updateApplicationAttribute(!isCurrentlyCohabiting, st.MS_Married)
    }

    updateCoborrowerAssetsUsed = (e) => {
        com.touch()

        if (e.target.checked) {
            this.radiocoborrower.current.checked = true
            this.updateCoborrower({ target: { value: 'withcoborrower' } }) // this is a hack
        }

        this.props.updateApplicationCheck(e, 'assetsofotherpersonused')
    }

    updateCoborrowerLiabilitiesUsed = (e) => {
        com.touch()

        if (e.target.checked) {
            this.radiocoborrower.current.checked = true
            this.updateCoborrower({ target: { value: st.HC_Withcoborrower } }) // this is a hack
        }

        this.props.updateApplicationCheck(e, 'liabilitiesofotherpersonrequired')
    }

    figureOut = () => {
        this.setState({ show: true })
    }
    handleClose = () => {
        this.setState({ show: false })
    }
    handleSelfClose = () => {
        this.setState({ showSelf: false })
    }
    selfEmployedAsessment = () => {
        this.setState({ showSelf: true })
    }
    qualifyForCashout = () => {
        this.setState({ showCashout: true })
    }
    handleCloseCashout = () => {
        this.setState({ showCashout: false })
    }

    changeMainPropertyPurpose = (e) => {
        com.touch()

        this.props.updateSelectField(new st.ChosenLoan(), 'selectedloan')
        this.props.changeReviewedLoans(0)

        if (e.target.value === 'cashoutrefinance') {
            this.setState({ showCashout: true })
        }

        if (this.props.property.purpose !== 'purchase') {
            // if change from purpose, clear gifts/insurance
            this.props.clearBorrowerGift()
            this.props.clearBorrowerInsurance()
        }

        this.props.changeMainPropertyPurpose(e)
    }

    render() {
        const schangeBorrowerInfo = (who, verb) => {
            return event => {
                com.touch()
                this.props.changeBorrowerInfo(
                    {
                        target: {
                            value: event == null ? '' : event,
                        },
                    },
                    who,
                    verb
                )
            }
        }

        const changeBorrowerInfo = (who, verb) => {
            return event => {
                com.touch()
                this.props.changeBorrowerInfo(event, who, verb)
            }
        }

        const changeCoBorrower = (who, verb) => {
            return event => {
                com.touch()
                this.props.changeBorrowerInfo(event, who, verb)
                this.updateCoborrower({
                    target: { value: st.HC_Withcoborrower }
                })
                const { hascoborrower, cohabiting } = this.props.application
                if (hascoborrower === st.HC_Withcoborrower && cohabiting === null) {
                    this.props.updateCohabiting({
                        target: { checked: true },
                    })
                }
            }
        }

        const handleChangePhoneNumber = (who, verb) => {
            return event => {
                com.touch()
                if (this.homePhoneRef.current) {
                    this.homePhoneRef.current.setCustomValidity('')
                }
                if (this.mobilePhoneRef.current) {
                    this.mobilePhoneRef.current.setCustomValidity('')
                }
                if (this.workPhoneRef.current) {
                    this.workPhoneRef.current.setCustomValidity('')
                }
                const phone = com.fixPhoneInput(event.target.value)
                return this.props.changePhoneInfo(phone, who, verb)
            }
        }

        const onQualify = (e) => {
            this.setState({ qualify: e.target.value })
        }

        const onAdditional = (e) => {
            this.setState({ additional: e.target.value })
        }

        const defDate = new Date()
        defDate.setFullYear(defDate.getFullYear() + 10)

        const changeExpiration = (event, who) => {
            com.touch()
            this.expiration.current.setCustomValidity('')
            const date = com.fixDateInput(event.target.value)
            return this.props.changePhoneInfo(date, who, 'visaexpiration')
        }

        const Citizenship = (citizenship, visa, visaexpiration) => {
            const isAlien = citizenship === 'alien'

            return (
                <>
                    <Col xs={12} md={isAlien ? 3 : 6} className="mt-4">
                        <Form.Group controlId="citizenship">
                            <Form.Label className="text-left">*What's your citizenship?</Form.Label>
                            <div>
                                <Select
                                    defaultValue={citizenship}
                                    value={citizenship}
                                    onChange={schangeBorrowerInfo(who, 'citizenship')}
                                    style={{ width: '100%', height: '44px', background: '#FCFCFC' }}
                                >
                                    <Select.Option value="citizen">US Citizen</Select.Option>
                                    <Select.Option value="permanent">Permanent Resident Alien</Select.Option>
                                    <Select.Option value="alien">Non-Permanent Resident Alien</Select.Option>
                                </Select>
                            </div>
                            <Form.Control.Feedback type="invalid">
                                Please provide your citizenship status.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={isAlien ? 3 : 6} className="mt-4">
                        {isAlien && (
                            <Form.Group controlId="visa">
                                <Form.Label className="text-left">Visa:</Form.Label>
                                <div>
                                    <Select
                                        defaultValue={visa}
                                        value={visa}
                                        onChange={schangeBorrowerInfo(who, 'visa')}
                                        style={{ width: '100%', height: '44px', background: '#FCFCFC' }}
                                    >
                                        {massage(visas, who).map(
                                            (value, index) => <Select.Option key={'visa' + index} value={value}>{value}</Select.Option>
                                        )}
                                    </Select>
                                </div>
                            </Form.Group>
                        )}
                    </Col>
                    <Col xs={12} md={isAlien ? 3 : 6} className="mt-4">
                        {isAlien && (
                            <Form.Group controlId="expiration" className="text-left">
                                <Form.Label className="text-left">Expires:</Form.Label>
                                <div>
                                    <DatePicker
                                        disabledDate={(current) => {
                                            return (
                                                current &&
                                                current.valueOf() < dayjs().startOf('day').valueOf()
                                            )
                                        }}
                                        name="dateofbirth"
                                        style={{ width: '100%' }}
                                        onRef={(ref) => { this.expiration = ref }}
                                        required
                                        size="sm"
                                        placeholder="MM/DD/YYYY"
                                        format="MM/DD/YYYY"
                                        value={visaexpiration ? dayjs(visaexpiration, 'MM/DD/YYYY') : ''}
                                        onChange={(e) => {
                                            changeExpiration(
                                                {
                                                    target: {
                                                        value: dayjs(e).format('MM/DD/YYYY'),
                                                    },
                                                },
                                                who
                                            )
                                        }}
                                    />
                                </div>
                                <div style={{ opacity: 0 }}>
                                    <DateOfBirth
                                        size="md"
                                        isValid={false}
                                        maxdate={defDate}
                                        onRef={(ref) => { this.expiration = ref }}
                                        required={citizenship === 'alien' ? true : false}
                                        placeholder="MM/DD/YYYY"
                                        name="expiration"
                                        type="text"
                                        value={visaexpiration}
                                        onChange={(e) => {changeExpiration(e, who)}}
                                        negativefeedback="Visa should be valid for at least 90 days."
                                        pattern="^(01|02|03|04|05|06|07|08|09|10|11|12)\/[0-9]{2,2}\/[1-9][0-9]{3,3}$"
                                    />
                                </div>
                            </Form.Group>
                        )}
                    </Col>
                </>
            )
        }

        const massage = (visas, who) => {
            if (who === 'coborrower') return visas

            return visas.filter((x) => {
                if ([st.VT_H_4, st.VT_L_2, st.VT_O_3, st.VT_P_4].includes(x))
                    return false

                return true
            })
        }

        const onChangeSwitch = (checked) => {
            const value = checked ? 'withcoborrower' : 'alone'
            this.updateCoborrower({ target: { value } })

            if (!checked) {
                const who = 'coborrower'
                this.props.updateCohabiting({ target: { checked: false } })
                this.props.updateApplicationAttribute(false, st.MS_Married)

                this.props.changeBorrowerInfoByValue('', who, 'firstname')
                this.props.changeBorrowerInfoByValue('', who, 'lastname')
                this.props.changeBorrowerInfoByValue('', who, 'email')
                this.props.changeBorrowerInfoByValue('', who, 'homephone')
                this.props.changeBorrowerInfoByValue('', who, 'citizenship')
            }
        }

        const { who } = this.props
        const { qualify, additional, showSelf, validated, isValidEmail } = this.state

        const { assetsofotherpersonused, liabilitiesofotherpersonrequired, hascoborrower, cohabiting, } = this.props.application
        const {
            firstname,
            middlename,
            lastname,
            suffix,
            dateofbirth,
            homephone,
            mobilephone,
            workphone,
            email,
            maritalstatus,
            citizenship,
            visa,
            visaexpiration,
        } = this.props.application[who]

        const isAlien = citizenship === 'alien'
        const visiblePhoneCount = [homephone, mobilephone, workphone].filter(phone => !isEmpty(phone)).length

        const { leadSource } = this.props.accountInfo

        const handleDeletePhone = (phoneType) => {
            schangeBorrowerInfo(who, `${phoneType}phone`)('')
        }

        const handleChangePhoneType = (oldType, newType, currentValue) => {
            if (oldType === newType) return

            // Set the new type's phone number
            schangeBorrowerInfo(who, `${newType}phone`)(currentValue)
            // Clear the old type's phone number
            schangeBorrowerInfo(who, `${oldType}phone`)('')
        }

        const handleAddPhone = () => {
            const phoneTypes = ['home', 'mobile', 'work']
            for (const type of phoneTypes) {
                const phoneValue = this.props.application[who][`${type}phone`]
                if (isEmpty(phoneValue)) {
                    schangeBorrowerInfo(who, `${type}phone`)(PHONE_NUMBER_PLACEHOLDER)
                    return
                }
            }
        }

        if (who === com.borrower) {
            return (
                <div className=" text-left mb-3">
                    <Modal
                        dialogClassName="modal-75w align-center"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={this.state.showCashout}
                        onHide={this.handleCloseCashout}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Do you qualify for cash out refinance?
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body as="div" className="">
                            <div className="my-4 ">
                                The borrower must be on the property title for at least 6 months in order to qualify for cash out refinance.
                            </div>
                            <div>
                                Have you been on the title for more than 6 months?
                            </div>
                            <div className="mt-3 mb-3">
                                <input
                                    type="radio"
                                    checked={qualify === 'yes6month'}
                                    onChange={onQualify}
                                    value="yes6month"
                                    name="forcashout"
                                    id="yes6month"
                                />
                                <label id="yes6monthlabel" className="divlink" htmlFor="yes6month">
                                    Yes, I have
                                </label>
                                <input
                                    className="ml-3"
                                    type="radio"
                                    checked={qualify === 'no6month'}
                                    onChange={onQualify}
                                    value="no6month"
                                    name="forcashout"
                                    id="no6month"
                                />
                                <label id="no6monthlabel" className="divlink" htmlFor="no6month">
                                    No, I have not
                                </label>
                            </div>
                            {qualify === 'yes6month' && (
                                <div style={{ fontSize: '1.2em', color: '#007700', fontWeight: 'bold' }}>
                                    Yes! Please continue filling out the application
                                </div>
                            )}
                            {qualify === 'no6month' && (
                                <div>
                                    Does the following apply to you?
                                    <ul>
                                        <li>You acquired the property through inheritance, divorce, separation or dissolution of domestic partnership.</li>
                                        <li>Purchased the property using all cash.</li>
                                        <li>Property was under an LLC or under a Trust for a minimum of six months.</li>
                                    </ul>
                                    <div className="mt-3 mb-3">
                                        <input
                                            type="radio"
                                            checked={additional === 'yesapplies'}
                                            onChange={onAdditional}
                                            value="yesapplies"
                                            name="yesapplies"
                                            id="yesapplies"
                                        />
                                        <label className="divlink" id="yesapplieslabel" htmlFor="yesapplies">
                                            Yes, it does
                                        </label>
                                        <input
                                            className="ml-3"
                                            checked={additional === 'doesnotapply'}
                                            type="radio"
                                            onChange={onAdditional}
                                            value="doesnotapply"
                                            name="yesapplies"
                                            id="doesnotapply"
                                        />
                                        <label id="noapplieslabel" className="divlink" htmlFor="doesnotapply">
                                            No, it doesn't
                                        </label>
                                    </div>
                                    {additional === 'yesapplies' && (
                                        <div style={{ fontSize: '1.2em', color: '#007700', fontWeight: 'bold' }}>
                                            Yes! Please continue filling out the application
                                        </div>
                                    )}
                                    {additional === 'doesnotapply' && (
                                        <div style={{ fontSize: '1.2em', color: '#770000', fontWeight: 'bold' }}>
                                            Sorry, you can't apply for a cash out loan at this point in time.
                                        </div>
                                    )}
                                </div>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleCloseCashout}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        dialogClassName="modal-75w align-center"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={this.state.show}
                        onHide={this.handleClose}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                When you need to apply with a coborrower:
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body as="div" className="">
                            <Form id="modal" noValidate validated={false} onSubmit={this.handleSubmitModal}>
                                <div className="my-4 ">
                                    Applicants should complete this form as "Borrower" or "Additional Borrower," as applicable.
                                </div>
                                <div className="mb-4">
                                    Additional Borrower information must also be provided (and the appropriate box checked) when
                                </div>
                                <Row>
                                    <Col>
                                        <input
                                            type="checkbox"
                                            id="assetsofotherpersonused"
                                            name="assetsofotherpersonused"
                                            defaultChecked={assetsofotherpersonused}
                                            onChange={
                                                this.updateCoborrowerAssetsUsed
                                            }
                                        />
                                        <label
                                            id="assetsofotherpersonusedlabel"
                                            style={{ textDecoration: 'underline' }}
                                            className="divlink"
                                            htmlFor="assetsofotherpersonused"
                                        >
                                            The INCOME or ASSETS of a person other than the Borrower (including the Borrower's spouse) will be used as a basis for loan qualification
                                        </label>
                                    </Col>
                                </Row>
                                or
                                <Row>
                                    <Col>
                                        <Form.Group
                                            controlId="liabilitiesofotherpersonrequired"
                                            className="text-left form-inline "
                                        >
                                            <Form.Check
                                                className="divlink"
                                                type="checkbox"
                                                id="liabilitiesofotherpersonrequired"
                                                name="liabilitiesofotherpersonrequired"
                                                defaultChecked={liabilitiesofotherpersonrequired}
                                                onChange={this.updateCoborrowerLiabilitiesUsed}
                                                label="The income or assets of the Borrower's spouse or other person who has community property rights pursuant to state law will NOT be used as a basis for loan qualification, but his or her LIABILITIES must be considered because the spouse or other person has community property rights pursuant to applicable law and Borrower resides in a community property state, the security property is located in a community property state, or the Borrower is relying on other property located in a community property state as a basis for repayment of the loan."
                                            />
                                        </Form.Group>
                                    </Col>{' '}
                                </Row>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        dialogClassName="modal-75w align-center employed"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={showSelf}
                        onHide={this.handleSelfClose}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Are you self employed?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body as="div" className="">
                            <div className="mb-4">You only qualify for self employed status if you own 25% of your company or more.</div>
                            <div>{' '}Otherwise you are a salaried employee for the purpose of obtaining a loan.</div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleSelfClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Form ref={this.form} id="applicantform" noValidate validated={validated} autoComplete="false">
                        <Row>
                            <Col>
                                <div className="page-header">
                                    <span className="page-header-primary">Personal</span>
                                    <span className="page-header-separator">/</span>
                                    <span className="page-header-secondary">Your info</span>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={4} xs={12} className="mt-4">
                                <Form.Group>
                                    <Form.Label>*First name</Form.Label>
                                    <Form.Control
                                        name="firstname"
                                        placeholder="Enter your first name"
                                        value={firstname}
                                        onChange={changeBorrowerInfo('borrower', 'firstname')}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12} className="mt-4">
                                <Form.Group>
                                    <Form.Label>Middle name</Form.Label>
                                    <Form.Control
                                        name="middlename"
                                        placeholder="Enter your middle name"
                                        value={middlename}
                                        onChange={changeBorrowerInfo('borrower', 'middlename')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12} className="mt-4">
                                <Form.Group>
                                    <Form.Label>*Last name</Form.Label>
                                    <Form.Control
                                        name="lastname"
                                        placeholder="Enter your last name"
                                        value={lastname}
                                        onChange={changeBorrowerInfo('borrower', 'lastname')}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} xs={12} className="mt-3">
                                <Form.Group controlId="MaritalStatus" className="text-left">
                                    <Form.Label className="text-left">Suffix</Form.Label>
                                    <div>
                                        <Select
                                            defaultValue={suffix}
                                            value={suffix}
                                            onChange={schangeBorrowerInfo(com.borrower, 'suffix')}
                                            style={{ width: '100%', height: '44px', background: '#FCFCFC' }}
                                        >
                                            <Select.Option
                                                value=""
                                                style={{
                                                    color: '#666',
                                                    fontStyle: 'italic',
                                                    borderBottom: '1px solid #eee',
                                                    marginBottom: '4px',
                                                    paddingBottom: '8px'
                                                }}
                                            >
                                                -- No Suffix --
                                            </Select.Option>
                                            <Select.Option value="Jr.">Jr.</Select.Option>
                                            <Select.Option value="Sr.">Sr.</Select.Option>
                                            <Select.Option value="I">I</Select.Option>
                                            <Select.Option value="II">II</Select.Option>
                                            <Select.Option value="III">III</Select.Option>
                                            <Select.Option value="IV">IV</Select.Option>
                                            <Select.Option value="Ph.D.">Ph.D.</Select.Option>
                                            <Select.Option value="M.D.">M.D.</Select.Option>
                                            <Select.Option value="Esq.">Esq.</Select.Option>
                                            <Select.Option value="J.D.">J.D.</Select.Option>
                                            <Select.Option value="CPA">CPA</Select.Option>
                                            <Select.Option value="DDS">DDS</Select.Option>
                                            <Select.Option value="KBE">KBE</Select.Option>
                                            <Select.Option value="OBE">OBE</Select.Option>
                                            <Select.Option value="MBE">MBE</Select.Option>
                                        </Select>
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={isAlien ? 3 : 6} xs={12} className="mt-4">
                                <Form.Group controlId="dob" className="text-left d-flex flex-column">
                                    <Form.Label className="text-left  text-nowrap">
                                        *Date of birth
                                    </Form.Label>
                                    <DatePicker
                                        name="dateofbirth"
                                        style={{ width: '100%' }}
                                        required
                                        size="sm"
                                        placeholder="MM/DD/YYYY"
                                        format="MM/DD/YYYY"
                                        value={dateofbirth ? dayjs(dateofbirth, 'MM/DD/YYYY') : ''}
                                        onChange={(e) => {
                                            this.props.changeBorrowerInfoByValue(
                                                e ? dayjs(new Date(e)).format('MM/DD/YYYY') : '',
                                                com.borrower,
                                                'dateofbirth'
                                            )
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            {Citizenship(citizenship, visa, visaexpiration)}
                        </Row>
                        <Row style={{ marginTop: isAlien ? '-20px' : '0' }}>
                            <Col md={12} xs={12}>
                                <Form.Group controlId="MaritalStatus" className="text-left">
                                    <Form.Label className="text-left">
                                        *Marital status
                                        <Tips
                                            content={
                                                <div>
                                                    If you are in a domestic partnership, single or divorced, select Unmarried
                                                </div>
                                            }
                                        />
                                    </Form.Label>
                                    <div>
                                        <CheckGroup
                                            data={[
                                                { value: 'married', label: 'Married' },
                                                { value: 'separated', label: 'Separated' },
                                                { value: 'unmarried', label: 'Unmarried' }
                                            ]}
                                            value={maritalstatus}
                                            onChangeSelect={schangeBorrowerInfo(com.borrower, 'maritalstatus')}
                                            type="radio"
                                            direction="horizontal"
                                            style={{ display: 'flex', width: '100%' }}
                                        />
                                    </div>
                                    <div className="invalid-feedback">
                                        Please provide your marital status
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={visiblePhoneCount <= 1 ? 4 : visiblePhoneCount === 2 ? 8 : 12} xs={12} className="mt-4">
                                <Form.Group>
                                    <Form.Label className="text-secondary fs-5">*Contact information</Form.Label>

                                    <div
                                        className="d-flex"
                                        style={{
                                            gap: '20px',
                                            marginTop: visiblePhoneCount > 1 ? '-28px' : '0',
                                            paddingBottom: 0
                                        }}
                                    >
                                        {visiblePhoneCount === 0 && (
                                            <PhoneInput
                                                type="home"
                                                value={PHONE_NUMBER_PLACEHOLDER}
                                                phoneRef={this.homePhoneRef}
                                                onChange={handleChangePhoneNumber(com.borrower, 'homephone')}
                                                onTypeChange={(newType) => handleChangePhoneType('home', newType, homephone)}
                                                showDelete={false}
                                            />
                                        )}

                                        {/* Home Phone */}
                                        {!isEmpty(homephone) && (
                                            <PhoneInput
                                                type="home"
                                                value={homephone}
                                                phoneRef={this.homePhoneRef}
                                                onDelete={handleDeletePhone}
                                                onChange={handleChangePhoneNumber(com.borrower, 'homephone')}
                                                onTypeChange={(newType) => handleChangePhoneType('home', newType, homephone)}
                                                showDelete={visiblePhoneCount > 1}
                                            />
                                        )}

                                        {/* Mobile Phone */}
                                        {!isEmpty(mobilephone) && (
                                            <PhoneInput
                                                type="mobile"
                                                value={mobilephone}
                                                phoneRef={this.mobilePhoneRef}
                                                onDelete={handleDeletePhone}
                                                onChange={handleChangePhoneNumber(com.borrower, 'mobilephone')}
                                                onTypeChange={(newType) => handleChangePhoneType('mobile', newType, mobilephone)}
                                                showDelete={visiblePhoneCount > 1}
                                            />
                                        )}

                                        {/* Work Phone */}
                                        {!isEmpty(workphone) && (
                                            <PhoneInput
                                                type="work"
                                                value={workphone}
                                                phoneRef={this.workPhoneRef}
                                                onDelete={handleDeletePhone}
                                                onChange={handleChangePhoneNumber(com.borrower, 'workphone')}
                                                onTypeChange={(newType) => handleChangePhoneType('work', newType, workphone)}
                                                showDelete={visiblePhoneCount > 1}
                                            />
                                        )}
                                    </div>

                                    <Button
                                        disabled={!isEmpty(homephone) && !isEmpty(mobilephone) && !isEmpty(workphone)}
                                        variant="link"
                                        className="text-primary p-0"
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: 500,
                                            color: '#325CEB',
                                            textDecoration: 'none',
                                            border: 'none',
                                            background: 'none',
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            handleAddPhone()
                                        }}
                                    >
                                        Add contact information
                                    </Button>

                                    <div className="text-muted" style={{ fontSize: '12px' }}>
                                        We hate spam just as much as you do. You won't receive any unwanted calls or texts from us.
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>

                        {com.getCustomerId() === 'ratedna' && (
                            <Row className="mt-4">
                                <Col md={6} xs={12}>
                                    <Form.Group>
                                        <Form.Label>
                                            Where did you hear about us? (optional)
                                        </Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={leadSource ? leadSource : ''}
                                            onChange={(e) => {this.props.updateLeadSource(e.target.value)}}
                                        >
                                            <option value="">...</option>
                                            <option value="sunny">From Sunny</option>
                                            <option value="socialmedia">Social Media</option>
                                            <option value="friend">Friend Referral</option>
                                            <option value="website">Website</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                        )}
                    </Form>
                </div>
            )
        }

        if (who === com.coborrower) {
            return (
                <div className=" text-left mb-3">
                    <Modal
                        dialogClassName="modal-75w align-center"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={this.state.showCashout}
                        onHide={this.handleCloseCashout}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Do you qualify for cash out refinance?
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body as="div" className="">
                            <div className="my-4 ">
                                The borrower must be on the property title for at least 6 months in order to qualify for cash out refinance.
                            </div>
                            <div>
                                Have you been on the title for more than 6 months?
                            </div>
                            <div className="mt-3 mb-3">
                                <input
                                    type="radio"
                                    checked={qualify === 'yes6month'}
                                    onChange={onQualify}
                                    value="yes6month"
                                    name="forcashout"
                                    id="yes6month"
                                />
                                <label id="yes6monthlabel" className="divlink" htmlFor="yes6month">
                                    Yes, I have
                                </label>
                                <input
                                    className="ml-3"
                                    type="radio"
                                    checked={qualify === 'no6month'}
                                    onChange={onQualify}
                                    value="no6month"
                                    name="forcashout"
                                    id="no6month"
                                />
                                <label id="no6monthlabel" className="divlink" htmlFor="no6month">
                                    No, I have not
                                </label>
                            </div>
                            {qualify === 'yes6month' && (
                                <div style={{ fontSize: '1.2em', color: '#007700', fontWeight: 'bold' }}>
                                    Yes! Please continue filling out the application
                                </div>
                            )}
                            {qualify === 'no6month' && (
                                <div>
                                    Does the following apply to you?
                                    <ul>
                                        <li>You acquired the property through inheritance, divorce, separation or dissolution of domestic partnership.</li>
                                        <li>Purchased the property using all cash.</li>
                                        <li>Property was under an LLC or under a Trust for a minimum of six months.</li>
                                    </ul>
                                    <div className="mt-3 mb-3">
                                        <input
                                            type="radio"
                                            checked={additional === 'yesapplies'}
                                            onChange={onAdditional}
                                            value="yesapplies"
                                            name="yesapplies"
                                            id="yesapplies"
                                        />
                                        <label className="divlink" id="yesapplieslabel" htmlFor="yesapplies">
                                            Yes, it does
                                        </label>
                                        <input
                                            className="ml-3"
                                            checked={additional === 'doesnotapply'}
                                            type="radio"
                                            onChange={onAdditional}
                                            value="doesnotapply"
                                            name="yesapplies"
                                            id="doesnotapply"
                                        />
                                        <label id="noapplieslabel" className="divlink" htmlFor="doesnotapply">
                                            No, it doesn't
                                        </label>
                                    </div>
                                    {additional === 'yesapplies' && (
                                        <div style={{ fontSize: '1.2em', color: '#007700', fontWeight: 'bold' }}>
                                            Yes! Please continue filling out the application{' '}
                                        </div>
                                    )}
                                    {additional === 'doesnotapply' && (
                                        <div style={{ fontSize: '1.2em', color: '#770000', fontWeight: 'bold' }}>
                                            Sorry, you can't apply for a cash out loan at this point in time.
                                        </div>
                                    )}
                                </div>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleCloseCashout}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        dialogClassName="modal-75w align-center"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={this.state.show}
                        onHide={this.handleClose}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                When you need to apply with a coborrower:
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body as="div" className="">
                            <Form
                                id="modal"
                                noValidate
                                validated={false}
                                onSubmit={this.handleSubmitModal}
                            >
                                <div className="my-4 ">
                                    Applicants should complete this form as "Borrower" or "Additional Borrower," as applicable.
                                </div>
                                <div className="mb-4">
                                    Additional Borrower information must also be provided (and the appropriate box checked) when
                                </div>
                                <Row>
                                    <Col>
                                        <input
                                            type="checkbox"
                                            id="assetsofotherpersonused"
                                            name="assetsofotherpersonused"
                                            defaultChecked={assetsofotherpersonused}
                                            onChange={this.updateCoborrowerAssetsUsed}
                                        />
                                        <label
                                            id="assetsofotherpersonusedlabel"
                                            style={{ textDecoration: 'underline' }}
                                            className="divlink"
                                            htmlFor="assetsofotherpersonused"
                                        >
                                            The INCOME or ASSETS of a person other than the Borrower (including the Borrower's spouse) will be used as a basis for loan qualification
                                        </label>
                                    </Col>
                                </Row>
                                or
                                <Row>
                                    <Col>
                                        <Form.Group
                                            controlId="liabilitiesofotherpersonrequired"
                                            className="text-left form-inline "
                                        >
                                            <Form.Check
                                                className="divlink"
                                                type="checkbox"
                                                id="liabilitiesofotherpersonrequired"
                                                name="liabilitiesofotherpersonrequired"
                                                defaultChecked={liabilitiesofotherpersonrequired}
                                                onChange={this.updateCoborrowerLiabilitiesUsed}
                                                label="The income or assets of the Borrower's spouse or other person who has community property rights pursuant to state law will NOT be used as a basis for loan qualification, but his or her LIABILITIES must be considered because the spouse or other person has community property rights pursuant to applicable law and Borrower resides in a community property state, the security property is located in a community property state, or the Borrower is relying on other property located in a community property state as a basis for repayment of the loan."
                                            />
                                        </Form.Group>
                                    </Col>{' '}
                                </Row>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        dialogClassName="modal-75w align-center employed"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={showSelf}
                        onHide={this.handleSelfClose}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Are you self employed?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body as="div" className="">
                            <div className="mb-4">You only qualify for self employed status if you own 25% of your company or more.</div>
                            <div>{' '}Otherwise you are a salaried employee for the purpose of obtaining a loan.</div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleSelfClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Form ref={this.form} id="applicantform" noValidate validated={validated} autoComplete="false">
                        <Row>
                            <Col>
                                <div className="page-header">
                                    <span className="page-header-primary">Personal</span>
                                    <span className="page-header-separator">/</span>
                                    <span className="page-header-secondary">Your info</span>
                                </div>
                            </Col>
                        </Row>

                        <Row className="mt-4">
                            <Col style={{ display: 'flex' }}>
                                <Switch
                                    defaultChecked={hascoborrower === 'withcoborrower'}
                                    onChange={onChangeSwitch}
                                />
                                <span style={{ marginLeft: 18, fontSize: 14, fontWeight: 500, color: '#222' }}>
                                    I have a co-borrower
                                </span>
                            </Col>
                        </Row>

                        {hascoborrower === 'withcoborrower' && (
                            <>
                                <Form ref={this.cform}>
                                    <Row className="mt-3">
                                        <Col md={4} xs={12} className="mt-1">
                                            <Form.Group>
                                                <Form.Label>*First name</Form.Label>
                                                <Form.Control
                                                    name="coborrowerfirstname"
                                                    placeholder="Co-borrower first name"
                                                    value={firstname}
                                                    onChange={changeCoBorrower('coborrower', 'firstname')}
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4} xs={12} className="mt-1">
                                            <Form.Group>
                                                <Form.Label>Middle name</Form.Label>
                                                <Form.Control
                                                    name="coborrowermiddlename"
                                                    placeholder="Co-borrower middle name"
                                                    value={middlename}
                                                    onChange={changeCoBorrower('coborrower', 'middlename')}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4} xs={12} className="mt-1">
                                            <Form.Group>
                                                <Form.Label>*Last name</Form.Label>
                                                <Form.Control
                                                    name="coborrowerlastname"
                                                    placeholder="Co-borrower last name"
                                                    value={lastname}
                                                    onChange={changeCoBorrower('coborrower', 'lastname')}
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4} xs={12} className="mt-2 mb-3">
                                            <Form.Group>
                                                <Form.Label>Suffix</Form.Label>
                                                <div>
                                                    <Select
                                                        defaultValue={suffix}
                                                        value={suffix}
                                                        onChange={schangeBorrowerInfo(com.coborrower, 'suffix')}
                                                        style={{ width: '100%', height: '44px', background: '#FCFCFC' }}
                                                    >
                                                        <Select.Option
                                                            value=""
                                                            style={{
                                                                color: '#666',
                                                                fontStyle: 'italic',
                                                                borderBottom: '1px solid #eee',
                                                                marginBottom: '4px',
                                                                paddingBottom: '8px'
                                                            }}
                                                        >
                                                            -- No Suffix --
                                                        </Select.Option>
                                                        <Select.Option value="Jr.">Jr.</Select.Option>
                                                        <Select.Option value="Sr.">Sr.</Select.Option>
                                                        <Select.Option value="I">I</Select.Option>
                                                        <Select.Option value="II">II</Select.Option>
                                                        <Select.Option value="III">III</Select.Option>
                                                        <Select.Option value="IV">IV</Select.Option>
                                                        <Select.Option value="Ph.D.">Ph.D.</Select.Option>
                                                        <Select.Option value="M.D.">M.D.</Select.Option>
                                                        <Select.Option value="Esq.">Esq.</Select.Option>
                                                        <Select.Option value="J.D.">J.D.</Select.Option>
                                                        <Select.Option value="CPA">CPA</Select.Option>
                                                        <Select.Option value="DDS">DDS</Select.Option>
                                                        <Select.Option value="KBE">KBE</Select.Option>
                                                        <Select.Option value="OBE">OBE</Select.Option>
                                                        <Select.Option value="MBE">MBE</Select.Option>
                                                    </Select>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>

                                <Row>
                                    <Col md={6} xs={12} className="mt-1">
                                        <Form.Group>
                                            <Form.Label>Email</Form.Label>
                                            <InputGroupComponent
                                                onBlur={(e) => {
                                                    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                                                    if (emailRegex.test(e.target.value)) {
                                                        this.setState({ isValidEmail: true })
                                                    } else {
                                                        this.setState({ isValidEmail: false })
                                                        this.props.changeBorrowerInfo(
                                                            { target: { value: '' } },
                                                            'coborrower',
                                                            'email'
                                                        )
                                                    }
                                                }}
                                                placeholder="Enter your co-applicant's email address"
                                                value={email}
                                                onChange={changeCoBorrower('coborrower', 'email')}
                                            />
                                            {!isValidEmail && (
                                                <div style={{ color: 'red' }}>
                                                    please enter your co-applicant's vaild email address
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row style={{ marginTop: -15 }}>
                                    <Col md={isAlien ? 3 : 6} xs={12} className="mt-4">
                                        <Form.Group controlId="dob" className="text-left d-flex flex-column">
                                            <Form.Label className="text-left  text-nowrap">
                                                *Date of birth
                                            </Form.Label>

                                            <DatePicker
                                                name="dateofbirth"
                                                style={{ width: '100%' }}
                                                required
                                                size="sm"
                                                placeholder="MM/DD/YYYY"
                                                format="MM/DD/YYYY"
                                                value={dateofbirth ? dayjs(dateofbirth, 'MM/DD/YYYY') : ''}
                                                onChange={(e) => {
                                                    this.props.changeBorrowerInfoByValue(
                                                        e ? dayjs(new Date(e)).format('MM/DD/YYYY') : '',
                                                        'coborrower',
                                                        'dateofbirth'
                                                    )
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    {Citizenship(citizenship, visa, visaexpiration)}
                                </Row>
                                <Row style={{ marginTop: isAlien ? '-20px' : '0' }}>
                                    <Col md={12} xs={12}>
                                        <Form.Group controlId="MaritalStatus" className="text-left">
                                            <Form.Label className="text-left">
                                                *Marital status
                                                <Tips
                                                    content={
                                                        <div>
                                                            If you are in a domestic partnership, single or divorced, select Unmarried
                                                        </div>
                                                    }
                                                />
                                            </Form.Label>
                                            <div>
                                                <CheckGroup
                                                    data={[
                                                        { value: 'married', label: 'Married' },
                                                        { value: 'separated', label: 'Separated' },
                                                        { value: 'unmarried', label: 'Unmarried' }
                                                    ]}
                                                    value={maritalstatus}
                                                    onChangeSelect={schangeBorrowerInfo(com.coborrower, 'maritalstatus')}
                                                    type="radio"
                                                    direction="horizontal"
                                                    style={{ display: 'flex', width: '100%' }}
                                                />
                                            </div>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide your marital status
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {maritalstatus === 'married' && (
                                    <Row className="mt-3">
                                        <Col className="d-flex">
                                            <Checkbox onChange={this.onMarried} checked={cohabiting}>
                                                <span style={{ fontWeight: 600 }}>
                                                    {' '}I am married to the primary applicant.
                                                </span>
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                )}

                                <Row>
                                    <Col md={visiblePhoneCount <= 1 ? 4 : visiblePhoneCount === 2 ? 8 : 12} xs={12} className="mt-4">
                                        <Form.Group>
                                            <Form.Label className="text-secondary fs-5">*Contact information</Form.Label>

                                            <div
                                                className="d-flex"
                                                style={{
                                                    gap: '20px',
                                                    marginTop: visiblePhoneCount > 1 ? '-28px' : '0',
                                                    paddingBottom: 0
                                                }}
                                            >
                                                {visiblePhoneCount === 0 && (
                                                    <PhoneInput
                                                        type="home"
                                                        value={PHONE_NUMBER_PLACEHOLDER}
                                                        phoneRef={this.homePhoneRef}
                                                        onChange={handleChangePhoneNumber(com.coborrower, 'homephone')}
                                                        onTypeChange={(newType) => handleChangePhoneType('home', newType, homephone)}
                                                        showDelete={false}
                                                    />
                                                )}

                                                {/* Home Phone */}
                                                {!isEmpty(homephone) && (
                                                    <PhoneInput
                                                        type="home"
                                                        value={homephone}
                                                        phoneRef={this.homePhoneRef}
                                                        onDelete={handleDeletePhone}
                                                        onChange={handleChangePhoneNumber(com.coborrower, 'homephone')}
                                                        onTypeChange={(newType) => handleChangePhoneType('home', newType, homephone)}
                                                        showDelete={visiblePhoneCount > 1}
                                                    />
                                                )}

                                                {/* Mobile Phone */}
                                                {!isEmpty(mobilephone) && (
                                                    <PhoneInput
                                                        type="mobile"
                                                        value={mobilephone}
                                                        phoneRef={this.mobilePhoneRef}
                                                        onDelete={handleDeletePhone}
                                                        onChange={handleChangePhoneNumber(com.coborrower, 'mobilephone')}
                                                        onTypeChange={(newType) => handleChangePhoneType('mobile', newType, mobilephone)}
                                                        showDelete={visiblePhoneCount > 1}
                                                    />
                                                )}

                                                {/* Work Phone */}
                                                {!isEmpty(workphone) && (
                                                    <PhoneInput
                                                        type="work"
                                                        value={workphone}
                                                        phoneRef={this.workPhoneRef}
                                                        onDelete={handleDeletePhone}
                                                        onChange={handleChangePhoneNumber(com.coborrower, 'workphone')}
                                                        onTypeChange={(newType) => handleChangePhoneType('work', newType, workphone)}
                                                        showDelete={visiblePhoneCount > 1}
                                                    />
                                                )}
                                            </div>

                                            <Button
                                                disabled={!isEmpty(homephone) && !isEmpty(mobilephone) && !isEmpty(workphone)}
                                                variant="link"
                                                className="text-primary p-0"
                                                style={{
                                                    fontSize: '16px',
                                                    fontWeight: 500,
                                                    color: '#325CEB',
                                                    textDecoration: 'none',
                                                    border: 'none',
                                                    background: 'none',
                                                }}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    handleAddPhone()
                                                }}
                                            >
                                                Add contact information
                                            </Button>

                                            <div className="text-muted" style={{ fontSize: '12px' }}>
                                                We hate spam just as much as you do. You won't receive any unwanted calls or texts from us.
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Form>
                </div>
            )
        }

        return null
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(Applicant)

export class Residence extends Component {
    constructor(props) {
        super(props)

        this.formRef = React.createRef()

        const borrowerStreet = this.props.application.borrower.presentaddressstreet
        const coBorrowerStreet = this.props.application.coborrower.presentaddressstreet
        this.state = {
            validated: false,
            loanLimits: {},
            tabAvtive: 0,
            sameAddress: borrowerStreet === coBorrowerStreet,
            errMsg: false,
        }

        this.fetchLoanLimits()
    }

    componentDidMount() {
        this.completion('application')
        this.setState({ sameAddress: this.hasSameAddress() })
        const { who } = this.props
        const { presentaddressmonths } = this.props.application[who]
        if (presentaddressmonths === '') {
            this.props.changeBorrowerInfoByValue(
                '0',
                who,
                'presentaddressmonths'
            )
        }
    }

    componentDidUpdate() {
        this.completion('application')
    }

    completion = (stateKey) => {
        const { who } = this.props
        const keysToFilter = [
            `${who}.presentaddressstreet`,
            `${who}.presentaddressstate`,
            `${who}.presentaddresscounty`,
            `${who}.presentaddresscity`,
            `${who}.presentaddresszip`,
            `${who}.presentaddressownership`,
            `${who}.presentaddressyears`,
        ]

        const { presentaddressyears } = this.props[stateKey][who]
        if (presentaddressyears !== '' && parseInt(presentaddressyears) < 2) {
            keysToFilter.push(`${who}.presentaddressmonths`)
        }

        const filteredObj = keysToFilter.reduce((obj, key) => {
            if (key.includes('.')) {
                const nestedKeys = key.split('.')

                let value = this.props[stateKey]
                let nestedKeyString = ''
                for (let i = 0; i < nestedKeys.length - 1; i++) {
                    const nestedKey = nestedKeys[i]

                    if (value.hasOwnProperty(nestedKey)) {
                        value = value[nestedKey]
                        nestedKeyString += nestedKey + '.'
                    } else {
                        value = undefined
                        break
                    }
                }

                const lastNestedKey = nestedKeys[nestedKeys.length - 1]
                if (value !== undefined && value.hasOwnProperty(lastNestedKey)) {
                    obj[nestedKeyString + lastNestedKey] = value[lastNestedKey]
                }
            } else if (this.props[stateKey].hasOwnProperty(key)) {
                obj[key] = this.props[stateKey][key]
            }

            return obj
        }, {})

        const emptyNum = Object.keys(filteredObj).filter((key) => {
            const value = filteredObj[key]
            return value === '' || value === null || value === undefined
        }).length

        const progressValue = Math.round(
            ((keysToFilter.length - emptyNum) / keysToFilter.length) * 100
        )

        sessionStorage.setItem('progressValue', progressValue)
    }

    hasSameAddress = () => {
        const { borrower, coborrower } = this.props.application
        return (
            borrower.presentaddressstreet === coborrower.presentaddressstreet &&
            borrower.presentaddressstate === coborrower.presentaddressstate &&
            borrower.presentaddresscounty === coborrower.presentaddresscounty &&
            borrower.presentaddresscity === coborrower.presentaddresscity &&
            borrower.presentaddresszip === coborrower.presentaddresszip
        )
    }

    isValidated() {
        if (!this.formRef.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        }

        return true
    }

    fetchLoanLimits = async () => {
        try {
            const response = await fetch('/data/loanlimits')
            if (!response.ok) {
                throw Error(response.statusText)
            }
            const data = await response.json()
            this.setState({ loanLimits: data })
        } catch (err) {
            console.error(err)
        }
    }

    onStreetAddressCompletion = (address, who) => {
        const num = com.getSubaddress(address, 'street_number', 'long_name')
        const city = com.getSubaddress(address, 'locality', 'long_name')
        const zip = com.getSubaddress(address, 'postal_code', 'long_name')
        const county = com.getSubaddress(address, 'administrative_area_level_2', 'long_name')
        const longstate = com.getSubaddress(address, 'administrative_area_level_1', 'long_name')

        this.props.changePhoneInfo(city, who, 'presentaddresscity')
        this.props.changePhoneInfo(longstate, who, 'presentaddressstate')
        this.props.changePhoneInfo(zip, who, 'presentaddresszip')
        this.props.changePhoneInfo(county, who, 'presentaddresscounty')

        const short = com.getSubaddress(address, 'route', 'short_name')
        const stradd = num + ' ' + short
        this.props.changeBorrowerInfo(
            { target: { value: stradd } },
            who,
            'presentaddressstreet'
        )

        const { presentaddressownership, ownedproperties } = this.props.application[who]
        if (presentaddressownership === st.O_Own && ownedproperties.length >= 1) {
            const changeOwnedProperty = who === com.borrower ?  this.props.changeBorrowerOwnedProperty : this.props.changeCoborrowerOwnedProperty
            changeOwnedProperty(stradd, 0, 'address')
            changeOwnedProperty(longstate, 0, 'state')
            changeOwnedProperty(county, 0, 'county')
            changeOwnedProperty(city, 0, 'city')
            changeOwnedProperty(zip, 0, 'zipcode')
        }

        const { presentaddressstreet, presentaddressstate, presentaddresszip } = this.props.application.coborrower
        if (who === com.borrower && presentaddressstreet === '' && presentaddressstate === '' && presentaddresszip === '') {
            this.props.changePhoneInfo(city, com.coborrower, 'presentaddresscity')
            this.props.changePhoneInfo(longstate, com.coborrower, 'presentaddressstate')
            this.props.changePhoneInfo(zip, com.coborrower, 'presentaddresszip')
            this.props.changePhoneInfo(county, com.coborrower, 'presentaddresscounty')
            this.props.changePhoneInfo(stradd, com.coborrower, 'presentaddressstreet')
        }
    }

    updateMonths = (value) => {
        com.touch()

        this.props.changeBorrowerInfo(
            { target: { value } },
            this.props.who,
            'presentaddressmonths'
        )
    }

    updateYears = (value) => {
        com.touch()

        const { who } = this.props
        const { previousaddresses } = this.props.application[who]
        if (value !== '' && parseInt(value) < 2 && previousaddresses.length === 0) {
            this.props.addPreviousAddress(
                { target: { value: 0 } },
                who
            )
            this.props.changeBorrowerInfo(
                { target: { value: '0' } },
                who,
                'presentaddressmonths'
            )
        }

        if (value !== '' && parseInt(value) >= 2 && previousaddresses.length !== 0) {
            this.props.clearPreviousAddress(
                { target: { value: 0 } },
                who
            )
        }

        this.props.changeBorrowerInfo(
            { target: { value } },
            who,
            'presentaddressyears'
        )
    }

    onFinish = () => {
        const { who } = this.props
        const { completedsteps } = this.props.application[who] || {}

        if (!completedsteps?.residence) {
            // Mark the step as completed
            this.props.changeBorrowerInfo(
                {
                    target: {
                        value: { ...completedsteps, residence: true },
                    },
                },
                who,
                'completedsteps'
            )
        }
    }

    handleSubmit = (e) => {
        const { who } = this.props
        const { presentaddressstreet } = this.props.application[who]

        // There is no need to validate the address for the co-borrower
        if (who === 'borrower' && presentaddressstreet === '') {
            this.setState({ errMsg: true }, () => {
                // Scroll to the address section
                const addressSection = document.querySelector('#Address')
                if (addressSection) {
                    addressSection.scrollIntoView({ behavior: 'smooth' })
                }
            })
            return false
        }

        return true
    }

    handlePrevious = () => {
        return true
    }

    changeTab = (tab) => {
        this.setState(
            { tabAvtive: tab, who: tab === 0 ? com.borrower : com.coborrower },
            () => {
                this.completion('application')
                eventBus.emit('changeProgressValue')
            }
        )
    }

    displayPreviousAddress = (address, index) => {
        const { who } = this.props

        const achangeAddressInfo = (who, verb) => {
            return (event) =>
                this.props.updatePreviousAddress(
                    { target: { value: event } },
                    who,
                    verb,
                    index
                )
        }

        const changeAddressInfo = (who, verb) => {
            return (event) => this.props.updatePreviousAddress(event, who, verb, index)
        }

        const onPreviousStreetAddressCompletion = (address) => {
            const city = com.getSubaddress(address, 'locality', 'long_name')
            const state = com.getSubaddress(address, 'administrative_area_level_1', 'long_name')
            const county = com.getSubaddress(address, 'administrative_area_level_2', 'long_name')

            let zip = com.getSubaddress(address, 'postal_code', 'long_name')
            const zips = com.getSubaddress(address, 'postal_code_suffix', 'short_name')
            if (zips !== '') zip = zip + '-' + zips

            const num = com.getSubaddress(address, 'street_number', 'long_name')
            const short = com.getSubaddress(address, 'route', 'short_name')
            const stradd = num + ' ' + short

            this.props.updatePreviousAddress(
                { target: { value: stradd } },
                who,
                'street',
                index
            )
            this.props.updatePreviousAddress(
                { target: { value: city } },
                who,
                'city',
                index
            )
            this.props.updatePreviousAddress(
                { target: { value: county } },
                who,
                'county',
                index
            )
            this.props.updatePreviousAddress(
                { target: { value: state } },
                who,
                'state',
                index
            )
            this.props.updatePreviousAddress(
                { target: { value: zip } },
                who,
                'zip',
                index
            )
        }

        return (
            <div className="addresspadidng">
                <div className="addAddress mt-4 ml-3 mr-3" key={'prev_' + index}>
                    <Row>
                        <Col>
                            <Form.Row>
                                <Col xl={6} className="mt-4">
                                    <AddressAutocomplete
                                        label="Previous street address"
                                        required={true}
                                        name={'oldadressline' + index}
                                        id={'prevaddr' + index}
                                        defaultValue={address.street}
                                        onChange={changeAddressInfo(who, 'street')}
                                        pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                        placeholder="No PO Box addresses are accepted"
                                        goodfeedback=""
                                        badfeedback="Please provide previous street address."
                                        onCompletion={
                                            onPreviousStreetAddressCompletion
                                        }
                                    />
                                </Col>
                                <Col xl={2} className="mt-4">
                                    <Form.Group controlId="PrStte" className="text-left">
                                        <Form.Label className="text-left">
                                            State
                                        </Form.Label>
                                        <div>
                                            <Select
                                                style={{ width: '100%', height: 44 }}
                                                placeholder="Select state"
                                                value={address.state}
                                                onChange={changeAddressInfo(who, 'state')}
                                            >
                                                {getStates(this.state.loanLimits).map((x, index) => (
                                                    <Select.Option value={x.value} key={index}>
                                                        {x.label}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col xl={2} className="mt-4">
                                    <Form.Group controlId="PrCty" className="text-left">
                                        <Form.Label className="text-left">
                                            City
                                        </Form.Label>
                                        <Form.Control
                                            isValid={false}
                                            size="sm"
                                            required
                                            name="previouscty"
                                            value={address.city}
                                            onChange={changeAddressInfo(who, 'city')}
                                            autoComplete="off"
                                            pattern="^[\p{L}\p{N}\s,-@.\/#+]+$"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide the city.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col xl={2} className="mt-4">
                                    <Form.Group controlId="PrZp" className="text-left">
                                        <Form.Label className="text-left">
                                            ZIP code
                                        </Form.Label>
                                        <Form.Control
                                            isValid={false}
                                            size="sm"
                                            required
                                            name="previouszip"
                                            type="text"
                                            placeholder="#####"
                                            value={address.zip}
                                            onChange={changeAddressInfo(who, 'zip')}
                                            pattern="^([0-9]{5}(?:-[0-9]{4})?|([a-zA-Z][0-9]){3})$"
                                            autoComplete="off"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide the ZIP code.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col xl={2} className="mt-4">
                                    <Form.Group controlId={'PreviousYears' + index} className="text-left">
                                        <Form.Label className="text-left">
                                            Years&nbsp;
                                        </Form.Label>
                                        <div>
                                            <InputNumber
                                                value={address.years}
                                                min={0}
                                                size="large"
                                                className="w-100"
                                                onChange={achangeAddressInfo(who, 'years')}
                                            />
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col xl={2} className="mt-4">
                                    <Form.Group controlId={'PreviousMonths' + index} className="text-left">
                                        <Form.Label className="text-left">
                                            Months&nbsp;
                                        </Form.Label>
                                        <Form.Control
                                            isValid={false}
                                            size="sm"
                                            required={parseInt(address.years) < 2}
                                            name="oldmonths"
                                            type="number"
                                            min={0}
                                            max={11}
                                            value={address.months}
                                            onChange={changeAddressInfo(who, 'months')}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide months.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col xl={3} className="mt-4">
                                    <Form.Group controlId="previouspropertytype" className="text-left">
                                        <Form.Label className="text-left">
                                            Ownership&nbsp;
                                        </Form.Label>
                                        <div>
                                            <Select
                                                value={address.ownership}
                                                className="w-100"
                                                style={{ height: 44 }}
                                                onChange={achangeAddressInfo(who, 'ownership')}
                                            >
                                                <option value="own">Owned</option>
                                                <option value="rent">Rented</option>
                                                <option value="rentfree">Lived Rent Free</option>
                                            </Select>
                                        </div>
                                    </Form.Group>
                                </Col>
                                {address.ownership === st.O_Rent && (
                                    <Col xl={3} className="mt-4">
                                        <div className="formLabel">
                                            Monthly rent
                                        </div>
                                        <InputNumber
                                            prefix="$"
                                            className="w-100"
                                            defaultValue={address.rent}
                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                            onChange={achangeAddressInfo(who, 'rent')}
                                            size="large"
                                        />
                                    </Col>
                                )}
                            </Form.Row>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }

    displayPreviousAddresses = () => {
        const { who, application } = this.props
        const { presentaddressyears, previousaddresses } = application[who]

        if (!presentaddressyears) return <></>

        if (who === com.borrower && parseInt(presentaddressyears) < 2)
            return previousaddresses.map(this.displayPreviousAddress)

        if (who === com.coborrower) {
            const { cohabiting } = application
            const borrowerYears = parseInt(application.borrower.presentaddressyears)
            const coborrowerYears = parseInt(presentaddressyears)
            if (cohabiting) {
                if (borrowerYears < 2)
                    return previousaddresses.map(this.displayPreviousAddress)
            } else {
                if (coborrowerYears < 2)
                    return previousaddresses.map(this.displayPreviousAddress)
            }
        }

        return <></>
    }

    render() {
        const onAddressChange = (e) => {
            com.touch()
            return this.props.changeBorrowerInfo(e, who, 'presentaddressstreet')
        }
        const onUnitChange = (e) => {
            com.touch()
            return this.props.changeBorrowerInfo(e, who, 'presentaddressunit')
        }

        const setLoanState = (v) => {
            this.props.changePhoneInfo(v, who, 'presentaddressstate')
        }

        const setLoanCounty = (v) => {
            this.props.changePhoneInfo(v, who, 'presentaddresscounty')
        }

        const setLoanCity = (v) => {
            this.props.changePhoneInfo(v, who, 'presentaddresscity')
        }

        const setZip = (v) => {
            v = v.replace(/\D/g, '')
            this.props.changePhoneInfo(v, who, 'presentaddresszip')
        }

        const onChangeCheckbox = (e) => {
            this.setState({ sameAddress: e.target.checked })
            if (e.target.checked) {
                const {
                    presentaddressstreet,
                    presentaddressstate,
                    presentaddresscounty,
                    presentaddresscity,
                    presentaddresszip,
                } = this.props.application.borrower

                this.props.changeBorrowerInfo(
                    {
                        target: {
                            value: presentaddressstreet,
                        },
                    },
                    com.coborrower,
                    'presentaddressstreet'
                )
                setLoanState(presentaddressstate, com.coborrower)
                setLoanCounty(presentaddresscounty, com.coborrower)
                setLoanCity(presentaddresscity, com.coborrower)
                setZip(presentaddresszip, com.coborrower)
            } else {
                this.props.removeCoborrowerOwnedProperty(0)
                const { presentaddressownership } = this.props.application.coborrower
                if (presentaddressownership === st.O_Own) {
                    this.props.prependCoborrowerOwnedProperty()
                }
            }
        }

        const { who } = this.props
        const { sameAddress, validated } = this.state

        const {
            presentaddressstreet,
            presentaddressunit,
            presentaddressstate,
            presentaddresscounty,
            presentaddresscity,
            presentaddresszip,
            presentaddressmonths,
            presentaddressyears,
            samemailingaddress,
            mailingaddressstreet,
            mailingaddressunit,
            mailingaddresscounty,
            mailingaddresscity,
            mailingaddressstate,
            mailingaddresszip,
        } = this.props.application[who]

        const handleSameMailingAddress = (checked) => {
            com.touch()
            this.props.changeBorrowerCheck(
                { target: { checked } },
                who,
                'samemailingaddress'
            )
        }

        const handleMailingAddressChange = (e) => {
            com.touch()
            return this.props.changeBorrowerInfo(e, who, 'mailingaddressstreet')
        }
        const handleMailingUnitChange = (e) => {
            com.touch()
            return this.props.changeBorrowerInfo(e, who, 'mailingaddressunit')
        }
        const handleMailingStateChange = (v) => {
            this.props.changePhoneInfo(v, who, 'mailingaddressstate')
        }

        const handleMailingCountyChange = (v) => {
            this.props.changePhoneInfo(v, who, 'mailingaddresscounty')
        }

        const handleMailingCityChange = (v) => {
            this.props.changePhoneInfo(v, who, 'mailingaddresscity')
        }

        const handleMailingZipChange = (v) => {
            v = v.replace(/\D/g, '')
            this.props.changePhoneInfo(v, who, 'mailingaddresszip')
        }

        const onMailingAddressCompletion = (address) => {
            const { who } = this.props
            const num = com.getSubaddress(address, 'street_number', 'long_name')
            const city = com.getSubaddress(address, 'locality', 'long_name')
            const zip = com.getSubaddress(address, 'postal_code', 'long_name')
            const county = com.getSubaddress(address, 'administrative_area_level_2', 'long_name')
            const longstate = com.getSubaddress(address, 'administrative_area_level_1', 'long_name')

            this.props.changePhoneInfo(city, who, 'mailingaddresscity')
            this.props.changePhoneInfo(longstate, who, 'mailingaddressstate')
            this.props.changePhoneInfo(zip, who, 'mailingaddresszip')
            this.props.changePhoneInfo(county, who, 'mailingaddresscounty')

            const short = com.getSubaddress(address, 'route', 'short_name')
            const stradd = num + ' ' + short

            this.props.changeBorrowerInfo(
                { target: { value: stradd } },
                who,
                'mailingaddressstreet'
            )
        }

        return (
            <div className="text-left mb-3">
                <Row>
                    <Col>
                        <div className="page-header">
                            <span className="page-header-primary">Personal</span>
                            <span className="page-header-separator">/</span>
                            <span className="page-header-secondary">Current Residence</span>
                        </div>
                        {who === com.coborrower && (
                            <div className="mt-4">
                                <Checkbox checked={this.state.sameAddress} onChange={onChangeCheckbox}>
                                    <span style={{ fontSize: 14, fontWeight: 500, color: '#222', whiteSpace: 'normal' }}>
                                        Co-borrower has the same address as the primary borrower.
                                    </span>
                                </Checkbox>
                            </div>
                        )}
                        {!(sameAddress && who === com.coborrower) && (
                            <div className="mt-4">
                                <Form ref={this.formRef} id="applicantform" noValidate validated={validated} autoComplete="false">
                                    <AddressAutocomplete
                                        id="Address"
                                        label="Current residential address"
                                        required={true}
                                        name="addessline"
                                        defaultValue={presentaddressstreet}
                                        onChange={onAddressChange}
                                        pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                        placeholder="No PO Box addresses are accepted"
                                        goodfeedback=""
                                        badfeedback="Please provide your street address."
                                        onCompletion={(e) => this.onStreetAddressCompletion(e, who)}
                                        size="md"
                                    />
                                </Form>
                                <Form.Group className="mt-2">
                                    <Form.Control
                                        size="sm"
                                        onChange={onUnitChange}
                                        value={presentaddressunit}
                                        placeholder="Unit"
                                    />
                                </Form.Group>
                            </div>
                        )}
                    </Col>
                </Row>
                {!(sameAddress && who === com.coborrower) && (
                    <div>
                        <Row>
                            <Col md={3} xs={12} className="mt-2">
                                <Form.Group>
                                    <Select
                                        style={{ width: '100%', height: 44 }}
                                        placeholder="Select state"
                                        value={presentaddressstate}
                                        onChange={(e) => setLoanState(e)}
                                    >
                                        {getStates(this.state.loanLimits).map((x, index) => (
                                            <Select.Option value={x.value} key={index}>
                                                {x.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Group>
                            </Col>
                            <Col md={3} xs={12} className="mt-2">
                                <Form.Group>
                                    <Select
                                        style={{ width: '100%', height: 44 }}
                                        onChange={(e) => setLoanCounty(e)}
                                        value={presentaddresscounty}
                                    >
                                        {getCounties(this.state.loanLimits, presentaddressstate).map((x, index) => (
                                            <Select.Option value={x.value} key={index}>
                                                {x.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Group>
                            </Col>
                            <Col md={3} xs={12} className="mt-2">
                                <Form.Group>
                                    <Form.Control
                                        required
                                        size="sm"
                                        onChange={(e) => setLoanCity(e.target.value)}
                                        value={presentaddresscity}
                                        placeholder="City"
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3} xs={12} className="mt-2">
                                <Form.Group>
                                    <Form.Control
                                        required
                                        size="sm"
                                        onChange={(e) => setZip(e.target.value)}
                                        value={presentaddresszip}
                                        placeholder="Zip Code"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                )}

                <Row className="mt-4">
                    <Col md={6} xs={12} className="pr-2">
                        <div>
                            <Form.Label>*How long at current address?</Form.Label>
                            {presentaddressyears !== '' && parseInt(presentaddressyears) < 2 ? (
                                <Row>
                                    <Col md={6} xs={12} className="px-0">
                                        <Input
                                            required
                                            suffix="Years"
                                            size="large"
                                            type='number'
                                            value={presentaddressyears}
                                            onChange={(e) => {this.updateYears(e.target.value)}}
                                        />
                                        <Input
                                            suffix="Months"
                                            size="large"
                                            type='number'
                                            style={{ marginLeft: 10 }}
                                            value={presentaddressmonths}
                                            onChange={(e) => {this.updateMonths(e.target.value > 11 ? 11 : e.target.value)}}
                                        />
                                    </Col>
                                </Row>
                            ) : (
                                <Row>
                                    <Col md={6} xs={12} className="px-0">
                                        <Input
                                            required
                                            suffix="Years"
                                            size="large"
                                            type='number'
                                            value={presentaddressyears}
                                            onChange={(e) => {this.updateYears(e.target.value)}}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </div>
                    </Col>
                </Row>

                {this.displayPreviousAddresses()}

                <Row className="mt-4">
                    <Col>
                        <Checkbox
                            onChange={(e) => handleSameMailingAddress(e.target.checked)}
                            checked={samemailingaddress}
                        >
                            <span style={{ fontSize: 18, fontWeight: 400, color: '#222222' }}>
                                Mailing Address is same as my current address
                            </span>
                        </Checkbox>

                        {!samemailingaddress && (
                            <div className="mt-3">
                                <Form.Label className="mb-0">*What's your mailing address</Form.Label>
                                <AddressAutocomplete
                                    size="sm"
                                    required
                                    id="mailingaddressline"
                                    name="mailingaddressline"
                                    pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                    defaultValue={mailingaddressstreet}
                                    onChange={(e) => handleMailingAddressChange(e)}
                                    goodfeedback=""
                                    badfeedback="Please provide your mailing address."
                                    onCompletion={onMailingAddressCompletion}
                                    placeholder="No PO Box addresses are accepted"
                                />
                                <Form.Control
                                    className="mt-2"
                                    placeholder="Address line 2"
                                    name="mailingaddressunit"
                                    value={mailingaddressunit}
                                    onChange={(e) => handleMailingUnitChange(e)}
                                />
                                <Row className="mt-2">
                                    <Col md={3} xs={12} className="pl-0">
                                        <Form.Group>
                                            <Select
                                                style={{ width: '100%', height: 44 }}
                                                placeholder="Select state"
                                                value={mailingaddressstate}
                                                onChange={(e) => handleMailingStateChange(e)}
                                            >
                                                {getStates(this.state.loanLimits).map((x, index) => (
                                                    <Select.Option value={x.value} key={index}>
                                                        {x.label}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3} xs={12}>
                                        <Form.Group>
                                            <Select
                                                style={{ width: '100%', height: 44 }}
                                                placeholder="Select county"
                                                value={mailingaddresscounty}
                                                onChange={(e) => handleMailingCountyChange(e)}
                                            >
                                                {getCounties(this.state.loanLimits, mailingaddressstate).map((x, index) => (
                                                    <Select.Option value={x.value} key={index}>
                                                        {x.label}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3} xs={12}>
                                        <Form.Group>
                                            <Form.Control
                                                required
                                                size="sm"
                                                placeholder="City"
                                                value={mailingaddresscity}
                                                onChange={(e) => handleMailingCityChange(e)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3} xs={12} className="pr-0">
                                        <Form.Group>
                                            <Form.Control
                                                required
                                                size="sm"
                                                placeholder="Zip Code"
                                                value={mailingaddresszip}
                                                onChange={(e) => handleMailingZipChange(e)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </Col>
                </Row>
            </div>
        )
    }
}

Residence = connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(Residence)
