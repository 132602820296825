import React, { Component } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { ResizeObserver } from '@juggle/resize-observer';
import Helmet from 'react-helmet'
import Modal from 'react-bootstrap/Modal'

export default class SendRevalidate extends Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
        let searchparams = new URLSearchParams(window.location.search);
        this.customerid = searchparams.get("customerid")

        this.state = {
            email: "",
            modaltitle: "",
            show: false,
            message: "",
            validated: false
        };
        this.pattern = "^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$"
        if (this.state.signin)
            this.pattern = "^.+$"

        this.myObserver = new ResizeObserver(entries => {
            if (entries.length > 0) {
                let bod = entries[0]
    
                let _width = bod.contentRect.width
                let _height = bod.contentRect.height

                let height = Math.max( _height, document.body.clientHeight)
                let width = Math.max( _width, document.body.clientWidth)

                window.parent.postMessage({ source: "tools", payload: [width,  height] }, '*')
            
            }
        });            
        this.ref = React.createRef()
    }

    componentDidMount() {
        this.myObserver.observe(this.ref.current);
        if (window.drift) {
            window.drift.hide()
            window.drift = null;
        }
    }
    validateForm() {
        if (this.state.signin)
            return this.state.email.length > 0 && this.state.password.length > 8;
        else
            return this.state.firstName.length > 0 && this.state.lastName.length > 0 && this.state.email.length > 0 && this.state.password.length > 8
                && this.state.password === this.state.password1;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleClose = () => { this.setState({ show: false }) }


    reset = (event, data) => {
    
        let tosend =
            JSON.stringify({ email: data.get("email").toLowerCase(), customerid: this.customerid })

        fetch('/services/revalidate', {
            method: 'POST',
            body: tosend,
            headers: { 'Content-Type': 'application/json' },
        }).then(
            response => {
                if (response.status !== 200) {
                    this.setState({
                        message: 'Looks like there was a problem. Status Code: ' +
                            response.status, show: true
                    })
                    window.document.dispatchEvent(new Event('reauthenticate'), "");
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {
                    this.setState({ message: js.Text, show: true })
                });
            }
        ).catch((err) => {
            console.log('Fetch Error :', err);
        });
    }
    handleSubmit = event => {
        if (this.form.current.reportValidity() === false) {
            event.preventDefault();
            this.setState({ validated: true })
            return false
        }
        event.preventDefault();
        this.setState({ validated: false })
        event.stopPropagation();
        const data = new FormData(event.target);
        this.reset(event, data)
    }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true;
    }
    header = () => {

        return (        
                ""
        )

    }
    render() {
        return (
            <div ref={this.ref} className="Login m-0 p-0" >

                <Helmet>
                    <title>Zeitro Revalidate Account</title>
                </Helmet>
                <Modal show={this.state.show} onHide={this.handleClose} onShow={this.onShow}>
                    <Modal.Header closeButton>
                        <Modal.Title>Account Revalidate</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.message}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>Close</Button>
                    </Modal.Footer>
                </Modal>
                <div className="text-center" >
                    <div className="loginbox">
                    <div id="landing1" className=" container text-center my-4 pb-5" >
                        <div className="row">
                            <div className="col-sm mt-3  text-center">
                             
                                <Form style={{fontSize:'0.8em'}}  ref={this.form} onSubmit={this.handleSubmit} noValidate validated={this.state.validated}>
                                    <Row className=" mt-3 pt-4 ">
                                        <Col xs="3" className="text-right">Email:</Col>
                                        <Col className="text-left mr-4">
                                            <Form.Group controlId="email"  >
                                                <Form.Control
                                                    required
                                                    name="email"
                                                    size="sm"
                                                    autoFocus
                                                    autoComplete="email"
                                                    type="email"
                                                    defaultValue={this.state.email}
                                                    onChange={this.handleChange}
                                                    pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$"
                                                />
                                                
                                                <Form.Control.Feedback type="invalid" >
                                                    Please enter a valid email address!
                                            </Form.Control.Feedback>

                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className="pt-3 ">
                                        <Col className="text-center">
                                            <Button className="lpbutton"
                                                type="submit" variant="primary"
                                            > Click to revalidate account!</Button>
                                        </Col>


                                    </Row>
                                    <Row className="mt-3 ">
                                    </Row>
                                </Form>
                            </div>

                        </div>
                    </div>
                    </div>
                </div>
 
            </div>
        );
    }
}

