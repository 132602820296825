import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

const timedelta = 200

export default class UpDown extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        if(typeof this.state.minimum === "undefined")
            this.state.minimum = 0
        if(typeof this.state.step === "undefined")
            this.state.step = 100     
        this.intervalid = 0
        this.intervaldirecton = 0
        this.pressed = false
    }
    up = e => {
        let fs = window.document.getElementsByTagName("fieldset")
        if (fs.length > 0 && fs[0].disabled)
            return        
        this.props.onUp()        
    }
    down = e => {
        let fs = window.document.getElementsByTagName("fieldset")
        if (fs.length > 0 && fs[0].disabled)
            return            
        this.props.onDown()        
    }
    downUp = e => {
        let fs = window.document.getElementsByTagName("fieldset")
        if (fs.length > 0 && fs[0].disabled)
            return            
        this.intervalid = window.setInterval(this.up, timedelta)
        this.pressed = true
    }
    upUp = e => {
        window.clearInterval(this.intervalid)
        let fs = window.document.getElementsByTagName("fieldset")
        if (fs.length > 0 && fs[0].disabled)
            return            
        if(this.pressed)
            this.props.onDone()
        this.pressed = false
    }
    downDown = e => {
        let fs = window.document.getElementsByTagName("fieldset")
        if (fs.length > 0 && fs[0].disabled)
            return            
        this.intervalid = window.setInterval(this.down, timedelta)
        this.pressed = true
    }
    upDown = e => {
        window.clearInterval(this.intervalid)
        let fs = window.document.getElementsByTagName("fieldset")
        if (fs.length > 0 && fs[0].disabled)
            return            
        if(this.pressed)
            this.props.onDone()
        this.pressed = false

    }
    render() {
        return (
                    <div  className="" style={this.state.wrap}>
                    <b onClick={this.up} onMouseDown={this.downUp} onMouseUp={this.upUp} onMouseLeave={this.upUp} className="updown uspinbtnUp" >
                        <i style={{ fontFamily: 'Helvetica,Arial'}} className="spinarrowUp"></i>
                    </b>
                    <b onClick={this.down} onMouseDown={this.downDown} onMouseUp={this.upDown} onMouseLeave={this.upDown} className="updown uspinbtnDown">
                        <i style={{ fontFamily: 'Helvetica,Arial'}} className="spinarrowDown"></i>
                    </b>
                    </div>
        )
    }
}

