/**
 * Format a number to a USD currency string
 * @param number - The number to format
 * @returns The formatted currency string
 */
export const USDollarCurrencyLocale = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 0,
  style: "currency",
  currency: "USD",
});

/**
 * Check if an email is valid
 * @param email - The email to check
 * @returns true if the email is valid, false otherwise
 */
export const isEmailValid = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};
