import styled from 'styled-components'
import clsx from 'clsx'

import ButtonBase from './ButtonBase'

const ButtonRoot = styled(ButtonBase)`
  font-size: clamp(10px, 2vw, 14px);  
  font-weight: 500;
  letter-spacing: 0.02857em;
  line-height: 1.5;
  text-transform: uppercase;

  padding: ${(props) =>
    (props.size === 'large' && '7px 21px') ||
    (props.size === 'small' && '3px 9px') ||
    '5px 15px'};

  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.32);
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  color: rgba(0, 0, 0, 0.32);
  background-color: rgba(255, 255, 255, 0.08);

  &.selected {
    border: 0;
    color: ${(props) => props.color}EB;
    background-color:  #060948EB;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
  }

  &:not(.disabled):hover {
    text-decoration: none;
    border: 0;
    background-color:  #060948AB;
    color: rgba(255, 255, 255, 0.67);
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
  }

  &:not(.disabled):hover.products {
    color: ${(props) => props.color}AB;
  }

  &:not(.disabled):hover.selected {
    color: ${(props) => props.color};
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
    background-color:  #060948;
  }

  .disabled {
    color: rgba(0, 0, 0, 0.16);
    cursor: default;
    box-shadow: none;
    background-color: rgba(255, 255, 255, 0.08);
  }
}
`

const ButtonLabel = styled.span`
  width: 100%;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
`

const Button = ({
  children,
  className,
  color = '#FFFFFF',
  selected,
  size = 'small',
  onClick,
}) => {
  return (
    <ButtonRoot
      className={clsx(className, selected && 'selected')}
      color={color}
      size={size}
      onClick={onClick}
    >
      <ButtonLabel>{children}</ButtonLabel>
    </ButtonRoot>
  )
}

export default Button
