import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import * as com from '../../Common'
import { APPLICANT_FLOW_STEPS, APPLICANT_STEP_IDS, CO_BORROWER_STEP_IDS, CO_BORROWER_FLOW_STEPS } from '../constants/sidebar'

function StepApplication(props) {
    const progress = useSelector((state) => state.progress)
    const application = useSelector((state) => state.application)

    const step = useMemo(() => progress.interview.step, [progress])
    const isCoborrower = useMemo(() => step >= com.stageToNumberApplication.coborrower, [step])

    const stepIDs = useMemo(() => {
        if (isCoborrower) {
            return CO_BORROWER_STEP_IDS
        }
        return APPLICANT_STEP_IDS
    }, [isCoborrower])

    const flowSteps = useMemo(() => {
        if (isCoborrower) {
            return CO_BORROWER_FLOW_STEPS
        }
        return APPLICANT_FLOW_STEPS
    }, [isCoborrower])

    /*
     * Get the parent node ID corresponding to the step
     */
    const getParentNodeId = useCallback((step) => {
        const parentSteps = [
            {
                id: stepIDs.PERSONAL,
                nextId: stepIDs.SUBJECT_PROPERTY,
            },
            {
                id: stepIDs.SUBJECT_PROPERTY,
                nextId: stepIDs.INCOME,
            },
            {
                id: stepIDs.INCOME,
                nextId: stepIDs.FINANCE,
            },
            {
                id: stepIDs.FINANCE,
                nextId: stepIDs.DECLARATIONS,
            },
            {
                id: stepIDs.DECLARATIONS,
                nextId: Number.MAX_SAFE_INTEGER,
            },
        ]

        for (const { id, nextId } of parentSteps) {
            if (step > id && step < nextId) {
                return id
            }
        }

        return stepIDs.PERSONAL
    }, [stepIDs.DECLARATIONS, stepIDs.FINANCE, stepIDs.INCOME, stepIDs.PERSONAL, stepIDs.SUBJECT_PROPERTY])

    const [subMenuVisibility, setSubMenuVisibility] = useState(() => {
        if (step === stepIDs.SUBJECT_PROPERTY) {
            return {
            [stepIDs.PERSONAL]: false,
            [stepIDs.SUBJECT_PROPERTY]: false,
            [stepIDs.INCOME]: false,
            [stepIDs.FINANCE]: false,
            [stepIDs.DECLARATIONS]: false,
            }
        }
        const parentNodeId = getParentNodeId(step)
        const initialState = {
            [stepIDs.PERSONAL]: false,
            [stepIDs.SUBJECT_PROPERTY]: false,
            [stepIDs.INCOME]: false,
            [stepIDs.FINANCE]: false,
            [stepIDs.DECLARATIONS]: false,
            [parentNodeId]: true,
        }
        return initialState
    })

    const isStepCompleted = useCallback(
        (stepKey) => {
            if (isCoborrower) {
                const { [stepKey]: stepValue } = application.coborrower.completedsteps
                if (!stepValue) return false
            } else {
                const { [stepKey]: stepValue } = application.borrower.completedsteps
                if (!stepValue) return false
            }

            return true
        },
        [application.borrower.completedsteps, application.coborrower.completedsteps, isCoborrower]
    )

    const handleParentClick = useCallback((e, id) => {
        e.preventDefault()
        setSubMenuVisibility((prev) => ({ ...prev, [id]: !prev[id] }))
    }, [])

    const isYourInfoCompleted = useMemo(() => isStepCompleted('yourinfo'), [isStepCompleted])
    const isResidenceCompleted = useMemo(() => isStepCompleted('residence'), [isStepCompleted])
    const isPropertyCompleted = useMemo(() => isStepCompleted('property', false), [isStepCompleted])
    const isEmploymentCompleted = useMemo(() => isStepCompleted('employment'), [isStepCompleted])
    const isOtherIncomeCompleted = useMemo(() => isStepCompleted('otherincome'), [isStepCompleted])
    const isAssetsCompleted = useMemo(() => isStepCompleted('assets', false), [isStepCompleted])
    const isCreditCheckCompleted = useMemo(() => isStepCompleted('creditcheck', false), [isStepCompleted])
    const isLiabilitiesCompleted = useMemo(() => isStepCompleted('liabilities', false), [isStepCompleted])
    const isPropertyMoneyCompleted = useMemo(() => isStepCompleted('propertymoney', false), [isStepCompleted])
    const isFinancesCompleted = useMemo(() => isStepCompleted('finances', false), [isStepCompleted])

    const getStepStatus = useCallback((id, completedStates) => {
        const allCompleted = completedStates.every((state) => state)
        const someCompleted = completedStates.some((state) => state)

        if (allCompleted) {
            return {
                icon: (
                    <div className="step-green-circle">
                        <img
                            src="/images/sidebaricons/green-check-mark.svg"
                            alt="completed"
                        />
                    </div>
                ),
                textColor: '#2E605A',
            }
        }
        if (someCompleted) {
            return {
                icon: (
                    <div className="step-blue-circle">
                        <div className="step-center-dot"></div>
                    </div>
                ),
                textColor: '#222222',
            }
        }
        return {
            icon: <div className="step-gray-circle"></div>,
            textColor: '#586570',
        }
    }, [])

    const StepCircleIcon = useCallback(
        ({ id }) => {
            const stepStatusMap = {
                [stepIDs.PERSONAL]: [isYourInfoCompleted, isResidenceCompleted],
                [stepIDs.SUBJECT_PROPERTY]: [isPropertyCompleted],
                [stepIDs.INCOME]: [isEmploymentCompleted, isOtherIncomeCompleted],
                [stepIDs.FINANCE]: [isAssetsCompleted, isCreditCheckCompleted, isLiabilitiesCompleted],
                [stepIDs.DECLARATIONS]: [isPropertyMoneyCompleted, isFinancesCompleted],
            }

            const { icon } = getStepStatus(id, stepStatusMap[id] || [])
            return icon
        },
        [
            stepIDs.PERSONAL,
            stepIDs.SUBJECT_PROPERTY,
            stepIDs.INCOME,
            stepIDs.FINANCE,
            stepIDs.DECLARATIONS,
            isYourInfoCompleted,
            isResidenceCompleted,
            isPropertyCompleted,
            isEmploymentCompleted,
            isOtherIncomeCompleted,
            isAssetsCompleted,
            isCreditCheckCompleted,
            isLiabilitiesCompleted,
            isPropertyMoneyCompleted,
            isFinancesCompleted,
            getStepStatus,
        ]
    )

    const stepTextColor = useCallback(
        (id) => {
            const stepStatusMap = {
                [stepIDs.PERSONAL]: [isYourInfoCompleted, isResidenceCompleted],
                [stepIDs.SUBJECT_PROPERTY]: [isPropertyCompleted],
                [stepIDs.INCOME]: [isEmploymentCompleted, isOtherIncomeCompleted],
                [stepIDs.FINANCE]: [isAssetsCompleted, isCreditCheckCompleted, isLiabilitiesCompleted],
                [stepIDs.DECLARATIONS]: [isPropertyMoneyCompleted, isFinancesCompleted],
            }

            const { textColor } = getStepStatus(id, stepStatusMap[id] || [])
            return textColor
        },
        [
            stepIDs.PERSONAL,
            stepIDs.SUBJECT_PROPERTY,
            stepIDs.INCOME,
            stepIDs.FINANCE,
            stepIDs.DECLARATIONS,
            isYourInfoCompleted,
            isResidenceCompleted,
            isPropertyCompleted,
            isEmploymentCompleted,
            isOtherIncomeCompleted,
            isAssetsCompleted,
            isCreditCheckCompleted,
            isLiabilitiesCompleted,
            isPropertyMoneyCompleted,
            isFinancesCompleted,
            getStepStatus,
        ]
    )

    const menuTextStyle = useCallback(
        (id) => {
            const yourInfo = isCoborrower ? com.stageToNumberApplication.coborrower : com.stageToNumberApplication.applicant
            const currentResidence = isCoborrower ? com.stageToNumberApplication.coborrower + 1 : com.stageToNumberApplication.applicant + 1
            const property = isCoborrower ? com.stageToNumberApplication.coborrower + 2 : com.stageToNumberApplication.property
            const employment = isCoborrower ? com.stageToNumberApplication.coborrower + 3 : com.stageToNumberApplication.income
            const otherIncome = isCoborrower ? com.stageToNumberApplication.coborrower + 4 : com.stageToNumberApplication.income + 1
            const assets = isCoborrower ? com.stageToNumberApplication.coborrower + 5 : com.stageToNumberApplication.assets
            const creditCheck = isCoborrower ? com.stageToNumberApplication.coborrower + 6 : com.stageToNumberApplication.creditcheck
            const liabilities = isCoborrower ? com.stageToNumberApplication.coborrower + 7 : com.stageToNumberApplication.liabilities
            const propertyMoney = isCoborrower ? com.stageToNumberApplication.coborrower + 8 : com.stageToNumberApplication.propertymoney
            const finances = isCoborrower ? com.stageToNumberApplication.coborrower + 9 : com.stageToNumberApplication.finances

            const completedStepMap = {
                [stepIDs.YOUR_INFO]: step === yourInfo,
                [stepIDs.CURRENT_RESIDENCE]: step === currentResidence,
                [stepIDs.PROPERTY]: step === property,
                [stepIDs.EMPLOYMENT]: step === employment,
                [stepIDs.OTHER_INCOME]: step === otherIncome,
                [stepIDs.ASSETS]: step === assets,
                [stepIDs.CREDIT_CHECK]: step === creditCheck,
                [stepIDs.LIABILITIES]: step === liabilities,
                [stepIDs.PROPERTY_MONEY]: step === propertyMoney,
                [stepIDs.FINANCES]: step === finances,
            }

            return completedStepMap[id]
                ? { color: '#325CEB', fontWeight: 500 }
                : { color: '#586570', fontWeight: 400 }
        },
        [
            isCoborrower,
            step,
            stepIDs.ASSETS,
            stepIDs.CURRENT_RESIDENCE,
            stepIDs.EMPLOYMENT,
            stepIDs.FINANCES,
            stepIDs.LIABILITIES,
            stepIDs.CREDIT_CHECK,
            stepIDs.OTHER_INCOME,
            stepIDs.PROPERTY,
            stepIDs.PROPERTY_MONEY,
            stepIDs.YOUR_INFO,
        ]
    )

    return (
        <div className="step-menu-container">
            {flowSteps.map((item, index) => {
                const isSubjectPropertyActive = item.id === stepIDs.SUBJECT_PROPERTY && step === stepIDs.SUBJECT_PROPERTY
                return (
                    <div key={index}>
                        <div className="step-menu-item" style={{ marginBottom: '16px' }}>
                            <StepCircleIcon id={item.id} />
                            <div style={{ marginLeft: '12px' }}>
                                <Link
                                    className="step-menu-text"
                                    to={item.path}
                                    onClick={(e) => item.id !== stepIDs.SUBJECT_PROPERTY && handleParentClick(e, item.id)}
                                    style={{ color: isSubjectPropertyActive && !isPropertyCompleted ? '#325CEB' : stepTextColor(item.id) }}
                                >
                                    {item.type}
                                </Link>
                            </div>
                        </div>
                        {subMenuVisibility[item.id] && (
                            <div className="sub-menu-container">
                                {item.list.map((subItem, subIndex) => (
                                    <div key={subIndex} className="sub-menu-item">
                                        <Link
                                            to={subItem.path}
                                            style={{
                                                fontSize: '16px',
                                                fontWeight: menuTextStyle(subItem.id).fontWeight,
                                                color: menuTextStyle(subItem.id).color,
                                            }}
                                        >
                                            {subItem.menu}
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )
            })}
        </div>
    )
}

export default StepApplication
