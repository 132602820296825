import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import LandingMenu from "./Landing/LandingMenu.js";
import Helmet from "react-helmet";
import { withRouter } from "react-router-dom";
import Terms from "./Terms";
import Privacy from "./Privacy";
import JoinTeam from "./JoinTeam";
import Validation from "./Validation";
import Howitworks from "./Howitworks";
import Glossary from "./Glossary";
import LandingCopyright from "./Landing/LandingCopyright";
import { Switch, Route } from "react-router-dom";

import Tools from "./Tools";

export default class ResourcePage extends React.Component {
  render = () => {
    /*
    let path = this.props.location.pathname
    if (path.indexOf('/app/') >= 0) {
      document.body.style.marginBottom = "0px";
    } else {
      document.body.style.marginBottom = "209px";
    }
    document.body.style.marginBottom = "209px";
*/
    return (
      <div id="myresource" className="py-0 my-0">
        <LandingMenu />
        <div id="pane" className="row mx-0 px-0 pt-4">
          <div id="home" className="mx-0 px-0 col-lg my-0 text-center">
            <Switch>
              <Route exact path="/resources/validate">
                <Helmet>
                  <title>Zeitro Validate Account </title>
                </Helmet>
                <Validation />
              </Route>
              <Route exact path="/resources/termsofuse">
                <Helmet>
                  <title>Zeitro Terms Of Use </title>
                </Helmet>
                <Terms />
              </Route>
              <Route exact path="/resources/privacy">
                <Helmet>
                  <title>Zeitro Privacy Statement </title>
                </Helmet>
                <Privacy />
              </Route>
              <Route exact path="/resources/jointeam">
                <Helmet>
                  <title>Zeitro Join The Team </title>
                </Helmet>
                <JoinTeam />
              </Route>

              <Route path="/resources/tools/:tool/">
                <Tools />
              </Route>

              <Route path="/resources/glossary">
                <Helmet>
                  <title>Zeitro Glossary </title>
                </Helmet>
                <Glossary />
              </Route>
              <Route path="/resources/howitworks">
                <Helmet>
                  <title>Zeitro How It Works </title>
                </Helmet>
                <Howitworks />
              </Route>
              <Route>
                <Helmet>
                  <title>Zeitro Page Not Found </title>
                </Helmet>
                <h1>Error 404! Can't render this URL! </h1>
              </Route>
            </Switch>
          </div>
          <LandingCopyright />
        </div>
      </div>
    );
  };
}
ResourcePage = withRouter(ResourcePage);
