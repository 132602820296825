import React, { Component } from 'react';
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import * as com from "./Common.js"
import { connect } from 'react-redux'
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import {UpdateChangeProgressType} from "./Store/progress"
const mapStateToProps = (state) => {
    return {
        progress: state.progress,
        application: state.application,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateChangeProgressType: (step, stage) => {
        dispatch(UpdateChangeProgressType(step, stage))
    },      
});

const defMessage = "Please write a message to your Loan Officer. You will get a reply at "
const sendingMessage = "Sending email..."
const sentMessage = "Email has been sent succsessfully."

class ContactUs extends Component {

  handleSubmit = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
  };
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      body: "",
      progress: 0,
      message: defMessage + " " + this.props.application.borrower.email,
      loimage: "/avatar.png",
      loname: "",
      loaded: false,
      editorState: EditorState.createEmpty(),

    };
    this.loadLoanOfficer()
    this.myForm = React.createRef();
    this.textarea = React.createRef();
    this.editor = React.createRef();
  }
  isValidated() {
    if (!this.form.current.reportValidity()) {
      this.setState({ validated: true })
      return false
    };
    return true;
  }
  componentDidMount() {
    this.props.updateChangeProgressType("contact")
  } 
  loadLoanOfficer = () => {
    let token = com.getUserToken()

    fetch('/borrower/getloanofficer', {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      }
    }).then(
      response => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' +
            response.status);
          this.setState({ loaded: false })
          window.document.dispatchEvent(new Event('reauthenticate'), "");

          return;
        }
        // Examine the text in the response
        response.json().then(js => {

          if (js.Status !== "OK") {
            console.log("Error: " + js.Status)
          } else {
            this.setState({ loimage: js.Image, loname: js.Name, email: js.Email, pgone: js.Phone, loaded: true })
          }
          this.setState({ loaded: true })
        });
      }
    )
      .catch(function (err) {
        console.log('Fetch Error :', err);
        this.setState({ loaded: false })

      });
  }

  sendMail = (html) => {
    let id = com.getUserId()
    let token = com.getUserToken()
    this.setState({ progress: 1, message: sendingMessage })


    fetch('/borrower/sendmail', {
      method: 'POST',
      body: JSON.stringify({ id: parseInt(id), body: "<html><body>" + html + "</body></html>"}),
      headers: {
        'Content-Type': 'application/json',
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      },
    }).then(
      response => {
        if (response.status !== 200) {
          let m = <Alert variant='danger'>Looks like there was a problem sending email. Error {response.status}</Alert>
          this.setState({ progress: 0, message: m })

          return;
        }
        // Examine the text in the response
        response.json().then(js => {
          this.setState({ progress: 0 })
          if (js.Status !== "OK") {
            let m = <Alert variant='danger'>Looks like there was a problem sending email. {js.Error}</Alert>
            this.setState({ progress: 0, message: m })
          } else {
            this.setState( {editorState: EditorState.createEmpty()})
            this.setState({ progress: 0, body: "", message: sentMessage })
          }

        });
      }
    )
      .catch(function (err) {
        console.log('Fetch Error :', err);
      });
  }
  handleSubmit = (event) => {

    var html = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))

    event.preventDefault();
    this.setState({ validated: false })
    event.stopPropagation();
    this.sendMail(html)
    return false
  }
  changeText = e => {
    this.setState({ body: e.target.value.toString() })
  }
  renderLo = () => {
    if (this.state.loaded) {
      return (
        <div className="text-left sectionquestion">
          <img alt="Loan officer" style={{ width: "96px", height: "96px", borderRadius: "50%" }} src={this.state.loimage}></img> Your loan officer: {this.state.loname}
        </div>
      )
    } else {
      return ""
    }
  }
  onEditorStateChange = (e) => {

    this.setState({
      editorState: e,
    });
  };

  render = () => {
    let onEditorStateChange = e => {

      this.onEditorStateChange(e)
    }
    return (
      <div className="mx-2 p-2 whitebackground">
        {this.renderLo()}
        <Form id="myform" ref={this.myForm} noValidate validated={false} onSubmit={this.handleSubmit}>
          <Form.Row className="mt-3">
            <Col   >
              <Form.Group controlId="Sources" className="text-left" >
                <Form.Label className="text-left" ><Spinner className="position-relative" size="sm" variant="primary" animation={((this.state.progress > 0) && (this.state.progress < 100)) ? "border" : ""} />{this.state.message}</Form.Label>
                <Editor ref={this.editor}
          className="border-dark"
          editorState={this.state.editorState}
          placeholder="The message goes here..."
          onEditorStateChange={onEditorStateChange}

          toolbar={{
            options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
  
          }}          
        />
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row >
            <Col   >
              <Button id="submitme" variant="primary"  type="submit">Send</Button>
            </Col>
          </Form.Row>
        </Form>

      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ContactUs)
