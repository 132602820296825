import { Typography } from "antd";
const { Title } = Typography;

export const PrequalLetterHeader = () => {
  return (
    <div className="prequal-letter-history-header">
      <Title level={2}>Pre-qual letter</Title>
    </div>
  );
};
