import React, { Component } from "react"
import { connect } from 'react-redux'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Input, InputNumber, Select, Skeleton, Typography } from 'antd'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import { isEmpty, some } from 'lodash'
import NumericalInput from '../NumericalInput'
import * as act from "../Store/actions"
import * as st from "../State"
import * as com from "../Common.js"
import { Tips } from '../Common/Tooltip'
import CheckGroup, { CheckGroupWithImage } from "../Common/components/CheckGroup"
import AddressAutocomplete from '../Common/AddressAutocomplete'
import InputGroup from 'react-bootstrap/InputGroup'
import { DollarInput } from "../Common/components/DollarInput"
import Multiselect from '../Common/Multiselect'
import { OwnedPropertyMortgagesTable } from "./tables"
import EditableTable from "../Common/components/tables"
import PrequalProperty from "./PrequalProperty"
import { getStates, getCounties } from '../Tools/Address'
import { isMortgageLoan, calculateBalancesAndPayments } from "../Tools/Loan"
import { isNumericOrNumericString } from "../Tools/String"

const { Text } = Typography

const customstyle = {
    multiselectContainer: { // To change css for multiselect (Width,height,etc..)
        fontSize: '14px',
    },
    searchBox: { // To change search box element look
        fontSize: '.875rem',
        minHeight: 'calc(1.5em + .5rem + 2px)',
        padding: '.25rem .5rem',
        marginBottom: '0px !important',
    },
    inputField: { // To change input field position or margin
        margin: '0px'
    },
    chips: { // To change css chips(Selected options)
        background: 'rgba(0,48,116,1)',
        marginBottom: '2px !important',
        fontSize: '12px',
        padding: '2px 10px',
    },
    optionContainer: { // To change css for option container
        fontSize: '12px',
    }
}

const mapStateToProps = (state) => {
    return {
        property: state.application.property,
        application: state.application,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateSelectField: (input, verb) => {
        dispatch(act.UpdateSelectField(input, verb))
    },
    updateSameProperty: (event) => {
        dispatch(act.UpdateSameProperty(event.target.checked))
    },
    changeMainPropertyAttribute: (t, verb) => {
        dispatch(act.ChangeMainPropertyAttribute(t, verb))
    },
    changeMainPropertyDescription: (event) => {
        dispatch(act.ChangeMainPropertyDescription(event.target.value))
    },
    changeMainPropertyAddress: (event) => {
        dispatch(act.ChangeMainPropertyAddress(event.target.value))
    },
    changeMainPropertyOccupancy: (event) => {
        dispatch(act.ChangeMainPropertyOccupancy(event.target.value))
    },
    changeMainPropertyPurpose: (event) => {
        dispatch(act.ChangeMainPropertyPurpose(event.target.value))
    },
    changeMainPropertyUnits: (event) => {
        dispatch(act.ChangeMainPropertyUnits(event.target.value))
    },
    changeMainPropertyYearBuilt: (event) => {
        dispatch(act.ChangeMainPropertyYearBuilt(event.target.value))
    },
    changeMainPropertyInWhatName: (event) => {
        dispatch(act.ChangeMainPropertyInWhatName(event.target.value))
    },
    changeMainPropertyEstateHeldIn: (event) => {
        dispatch(act.ChangeMainPropertyEstateHeldIn(event.target.value))
    },
    changeMainPropertyInWhatManner: (event) => {
        dispatch(act.ChangeMainPropertyInWhatManner(event.target.value))
    },
    changeMainPropertySources: (event) => {
        dispatch(act.ChangeMainPropertySources(event.target.value))
    },
    changeMainPropertyRefinanceYearAcquired: (event) => {

        dispatch(act.ChangeMainPropertyRefinanceYearAcquired(event.target.value))
    },
    changeMainPropertyRefinanceOriginalCost: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceOriginalCost(event.target.value))
    },
    changeMainPropertyRefinancePurpose: (event) => {
        dispatch(act.ChangeMainPropertyRefinancePurpose(event.target.value))
    },
    changeMainPropertyRefinanceImprovementsDesc: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceImprovementsDesc(event.target.value))
    },
    changeMainPropertyRefinanceExistingLiens: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceExistingLiens(event.target.value))
    },
    changeMainPropertyRefinanceImprovementsStatus: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceImprovementsStatus(event.target.value))
    },
    changeMainPropertyRefinanceCost: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceCost(event.target.value))
    },
    changeMainPropertyConstructionYearAcquired: (event) => {
        dispatch(act.ChangeMainPropertyConstructionYearAcquired(event.target.value))
    },
    changeMainPropertyConstructionOriginalCost: (event) => {
        dispatch(act.ChangeMainPropertyConstructionOriginalCost(event.target.value))
    },
    changeMainPropertyConstructionExistingLiens: (event) => {
        dispatch(act.ChangeMainPropertyConstructionExistingLiens(event.target.value))
    },
    changeMainPropertyConstructionPresentValue: (event) => {
        dispatch(act.ChangeMainPropertyConstructionPresentValue(event.target.value))
    },
    changeMainPropertyConstructionCostOfImprovements: (event) => {
        dispatch(act.ChangeMainPropertyConstructionCostOfImprovements(event.target.value))
    },
    changeMainPropertyExpirationDate: (event) => {
        dispatch(act.ChangeMainPropertyExpirationDate(event.target.value))
    },
    changeMainPropertyPropertyType: (event) => {
        dispatch(act.ChangeMainPropertyPropertyType(event.target.value))
    },
    changeMainProperty: (value, verb) => {
        dispatch(act.ChangeMainProperty(value, verb))
    },
    changeMobileHomeInfo: (value, verb) => {
        dispatch(act.ChangeMobileHomeInfo(value, verb))
    },
    changeMainPropertySalesPrice: (event) => {
        dispatch(act.ChangeMainPropertySalesPrice(event))
    },
    changeMainPropertyAppraisal: (event) => {
        dispatch(act.ChangeMainPropertyAppraisal(event))
    },
    prependBorrowerOwnedProperty: () => {
        dispatch(act.PrependBorrowerOwnedProperty())
    },
    changeOwnedProperty: (t, i, v) => {
        dispatch(act.ChangeOwnedProperty(t, i, v))
    },
    removeBorrowerOwnedProperty: (i) => {
        dispatch(act.RemoveBorrowerOwnedProperty(i))
    },
    changeBorrowerInfo: (input, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(input, who, verb))
    },
    updateApplicationAttribute: (event, verb) => {
        dispatch(act.UpdateApplicationAttribute(event.target.value, verb))
    },
    addNewMortgageLoan: () => {
        dispatch(act.AddNewMortgageLoan())
    },
    removeNewMortgageLoan: (index) => {
        dispatch(act.RemoveNewMortgageLoan(index))
    },
    clearNewMortgageLoans: () => {
        dispatch(act.ClearNewMortgageLoans())
    },
    updateNewMortgageLoanAttribute: (value, index, verb) => {
        dispatch(act.UpdateNewMortgageLoanAttribute(value, index, verb))
    },
})

class Property extends Component {
    constructor(props) {
        super(props)

        const { loanbalance } = this.props.application

        this.state = {
            validated: false,
            innerWidth: 0,
            isMobileHomeOnly: false,
            propertyTypesLoading: true,
            loanLimits: {},
            useEstimated: false,
            mortgageLoans: [],
            loanbalance,
        }

        this.lastcompletion = 0

        this.isValidated = this.isValidated.bind(this)
        this.countyref = React.createRef()
        this.stateref = React.createRef()
    }

    componentDidMount() {
        this.fetchLoanLimits()
        this.getCompanyConfig()

        this.setState({ innerWidth: window.innerWidth })

        const { financingsameproperty, assetsandliabilities } = this.props.application
        const { isManufacturedHome, isMixedUse, purpose } = this.props.application.property
        const { units, salesprice } = this.props.property

        if (financingsameproperty === true) {
            if (purpose === st.POL_Refinance || purpose === st.POL_Cashoutrefinance) {
                this.props.changeMainPropertyOccupancy({ target: { value: "principal" } })
            }
            this.updateRefinanceAddressWithResidencyAddress()
        }
        if (financingsameproperty === null) {
            this.props.updateSameProperty({ target: { checked: true } })
            this.updateRefinanceAddressWithResidencyAddress()
        }

        if (isManufacturedHome === null) {
            this.props.changeMainProperty(false, "isManufacturedHome")
        }
        if (isMixedUse === null) {
            this.props.changeMainProperty(false, "isMixedUse")
        }
        if (units === "") {
            this.props.changeMainPropertyUnits({ target: { value: "2" } })
        }
        if (salesprice === "") {
            this.props.changeMainPropertySalesPrice(500000)
            this.props.changeMainPropertyAppraisal(500000)
        }

        if (!isEmpty(assetsandliabilities.loans)) {
            const mortgageLoans = assetsandliabilities.loans.filter((loan) => loan.correction !== 'duplicate').filter(isMortgageLoan)
            this.setState({ mortgageLoans })
            if (mortgageLoans.length === 1 && mortgageLoans[0].name === 'Estimated') {
                this.setState({ useEstimated: true })
                if (this.state.loanbalance === '') {
                    this.updateLoanBalance('0')
                    this.setState({ loanbalance: '0' })
                }
            }
        }

        this.completion('property')

        window.addEventListener('resize', () => {
            this.setState({
                innerWidth: window.innerWidth
            })
        })
    }

    updateLoanBalance = (val) => {
        this.props.updateApplicationAttribute({ target: { value: val } }, 'loanbalance')
    }

    fetchLoanLimits = async () => {
        try {
            const response = await fetch('/data/loanlimits')
            if (!response.ok) {
                throw Error(response.statusText)
            }
            const data = await response.json()
            this.setState({ loanLimits: data })
        } catch (err) {
            console.error(err)
        }
    }

    updateRefinanceAddressWithResidencyAddress = () => {
        const { who } = this.props
        const { borrower, coborrower, property } = this.props.application
        const presentAddressStreet = who === com.borrower ? borrower.presentaddressstreet : coborrower.presentaddressstreet
        if (property.address === "") {
            this.props.changeMainProperty(presentAddressStreet, "address")
            this.lookupAddress(presentAddressStreet)
        }
    }

    lookupAddress = (providedAddress) => {
        const now = Date.now()

        if (now - this.lastcompletion < 500)
            return

        let addr = this.props.application.property.address
        if (typeof providedAddress !== "undefined") {
            addr = providedAddress
        }
        if (this.isOccupancyHidden()) {
            addr = this.sameaddress()
            this.props.changeMainPropertyAddress({ target: { value: addr } })
        }

        if ("" === addr)
            return

        const GOOGLE_MAPS_API_KEY = 'AIzaSyBQFO5uQtyZ5EsbTsNEK7HOhEA00KS3le8'
        const GEOCODE_API_URL = 'https://maps.googleapis.com/maps/api/geocode/json'
        const target = encodeURI(`${GEOCODE_API_URL}?address=${addr}&key=${GOOGLE_MAPS_API_KEY}`)

        fetch(target, {
            method: 'GET',
        }).then(
            response => {
                if (response.status !== 200) {
                    console.warn(`Looks like there was a problem. Status Code: ${response.status}`)
                    return
                }

                // Examine the text in the response
                response.json().then(js => {
                    if (js.status === "OK") {
                        this.onStreetAddressCompletion(js.results[0].address_components)
                    }
                })
            }
        ).catch((err) => {
            console.error('Fetch Error :', err)
        })
    }

    isOccupancyHidden = () => {
        return this.props.application.financingsameproperty && (this.props.application.property.purpose !== st.POL_Purchase)
    }

    componentDidUpdate() {
        this.completion('property')
    }

    componentWillUnmount() {}

    completion = (stateKey) => {
        const keysToFilter = ['occupancy', 'propertytype'];
        const filteredObj = keysToFilter.reduce((obj, key) => {
            if (key.includes('.')) {
                const nestedKeys = key.split('.')

                let value = this.props[stateKey]
                for (const nestedKey of nestedKeys) {
                    if (value.hasOwnProperty(nestedKey)) {
                        value = value[nestedKey]
                    } else {
                        value = undefined
                        break
                    }
                }

                if (value !== undefined) {
                    obj[nestedKeys[nestedKeys.length - 1]] = value
                }
            } else if (this.props[stateKey].hasOwnProperty(key)) {
                obj[key] = this.props[stateKey][key]
            }

            return obj
        }, {})

        const emptyNum = Object.keys(filteredObj).filter((key) => {
            const value = filteredObj[key];
            return value === '' || value === null || value === undefined;
        }).length;
        const progressValue = Math.round(((keysToFilter.length - emptyNum) / keysToFilter.length) * 100);
        sessionStorage.setItem("progressValue", progressValue);
    }

    checkDollarInputs = (purpose, loanbalance) => {
        const { appraisal, salesprice } = this.props.property
        const isAppraisalValid = isNumericOrNumericString(appraisal) && appraisal !== '0'
        if (purpose === st.POL_Purchase && !isAppraisalValid) {
            return 'You need to provide the estimated home value.'
        }

        const { hascontract } = this.props.application.property
        const isSalesPriceValid = isNumericOrNumericString(salesprice) && salesprice !== '0'
        if (purpose === st.POL_Purchase && hascontract && !isSalesPriceValid) {
            return 'You need to provide the purchase price.'
        }

        const { needMortgageBalance } = this.needsToAskMortgageBalance(purpose, this.state.mortgageLoans)
        const isLoanBalanceValid = isNumericOrNumericString(loanbalance) && loanbalance !== '0'
        if (needMortgageBalance && !isLoanBalanceValid) {
            return 'You need to provide the current mortgage balance on the subject property.'
        }

        const { downpayment, paydown, cashout } = this.props.application.selection
        const isDownpaymentValid = isNumericOrNumericString(downpayment) && downpayment !== '0'
        if (purpose === st.POL_Purchase && !isDownpaymentValid) {
            return 'You need to provide the downpayment amount.'
        }
        const isPaydownValid = isNumericOrNumericString(paydown) && paydown !== '0'
        if (purpose === st.POL_Refinance && !isPaydownValid) {
            return 'You need to provide the paydown amount.'
        }
        const isCashoutValid = isNumericOrNumericString(cashout) && cashout !== '0'
        if (purpose === st.POL_Cashoutrefinance && !isCashoutValid) {
            return 'You need to provide the cashout amount.'
        }
    }

    handleSubmit = () => {
        const { otherliens } = this.props.application
        const { purpose } = this.props.application.property
        const { loans, propertyownedfreeandclear } = this.props.application.assetsandliabilities
        const { loanbalance } = this.state

        const error = this.checkDollarInputs(purpose, loanbalance)
        if (error) {
            alert(error)
            return false
        }

        const [totalmortgage, mortgagebalance, cashout] = calculateBalancesAndPayments(purpose, loans, otherliens)

        if (purpose === st.POL_Cashoutrefinance) {
            this.props.updateSelectField(cashout, 'mincashout')
        }

        if (purpose === st.POL_Refinance && totalmortgage === 0 && true === propertyownedfreeandclear) {
            alert("You can't refinance the property that you own free and clear!")
            return false
        }

        if (!isEmpty(loans) && loans[0].name !== 'Estimated') {
            this.updateLoanBalance(mortgagebalance)

            if (purpose === st.POL_Refinance) {
                const { paydown } = this.props.application.selection
                this.props.updateSelectField(com.safeParseInt(mortgagebalance) - com.safeParseInt(paydown), 'loansize')
            }
            if (purpose === st.POL_Cashoutrefinance) {
                const { cashout } = this.props.application.selection
                this.props.updateSelectField(com.safeParseInt(mortgagebalance) + com.safeParseInt(cashout), 'loansize')
            }
        } else {
            if (purpose === st.POL_Refinance) {
                if (propertyownedfreeandclear === false || propertyownedfreeandclear === null) {
                    const { loanbalance } = this.props.application
                    if (loanbalance === '0') {
                        alert('The current loan balance on the subject property cannot be 0, if you do not own the property free and clear.')
                        return false
                    }
                }
                const { paydown } = this.props.application.selection
                const value = Math.max(0, com.safeParseInt(loanbalance) - com.safeParseInt(paydown))
                this.props.updateSelectField(value, 'loansize')
            }
            if (purpose === st.POL_Cashoutrefinance) {
                const { cashout } = this.props.application.selection
                const value = com.safeParseInt(loanbalance) + com.safeParseInt(cashout)
                this.props.updateSelectField(value, 'loansize')
            }
            this.updateLoanBalance(loanbalance)
        }

        return true
    }

    onFinish = () => {
        const { who } = this.props
        const { completedsteps } = this.props.application[who] || {}

        if (!completedsteps?.property) {
            this.props.changeBorrowerInfo(
                { ...completedsteps, property: true },
                who,
                'completedsteps'
            )
        }
    }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        }

        return true
    }

    getWidth = () => {
        const innerWidth = this.state.innerWidth
        if (innerWidth < 1301 && innerWidth > 1041) {
            return 50
        } else {
            return '100%'
        }
    }

    changeMainPropertyPropertyType = e => {
        com.touch()

        const { who, financingsameproperty } = this.props
        const { purpose } = this.props.property
        if (purpose !== st.POL_Purchase && financingsameproperty)
            this.props.changeBorrowerInfo(e, who, "propertytype")

        this.props.changeMainPropertyPropertyType(e)
    }

    lookupProperty = (num, prefix, short, sfx, city, zip) => {
        const body = {
            number: num,
            prefix: prefix,
            street: short,
            suffix: sfx,
            city: city,
            zip: zip
        }

        const token = sessionStorage.getItem("ZeitroA")
        this.props.changeMainProperty(false, "lookupsuccessful")
        fetch('/borrower/findproperty', {
            method: 'POST',
            headers: {
                Cache: "no-cache",
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json',
                "X-Borrower": this.props.borrowerid,
            },
            body: JSON.stringify(body)
        }).then(
            response => {
                if (response.status !== 200) {
                    alert(`Looks like there was a problem. Status Code: ${response.status}`)
                    window.document.dispatchEvent(new Event('reauthenticate'), "")
                    return
                }

                // Examine the text in the response
                response.json().then(js => {
                    if (this.props.property.yearbuilt === "" && js.Yearbuilt !== "" && !this.props.property.lookupsuccessful) {
                        this.props.changeMainPropertyYearBuilt({ target: { value: js.Yearbuilt } })
                        this.props.changeMainPropertyUnits({ target: { value: js.Units } })
                        this.props.changeMainProperty(true, "lookupsuccessful")
                        if (this.props.application.property.purpose !== st.POL_Purchase) {
                            const dt = js.Lastsale
                            const dat = dt.split('T')[0].split('-')
                            const d = parseInt(dat[0])
                            this.props.changeMainPropertyRefinanceYearAcquired({ target: { value: d } })
                            const cost = js.Lastsaleprice.toString()
                            this.props.changeMainPropertyRefinanceOriginalCost({ target: { value: cost } })
                        }
                    }
                })
            }
        ).catch((err) => {
            console.error('Fetch Error :', err)
        })
    }

    changeMainPropertyAddress = e => {
        //this.onStreetAddressCompletion(address)
        const address = e.target.value
        if (address === "") {
            this.props.changeMainProperty(false, "lookupsuccessful")
        }

        this.props.changeMainPropertyAddress(e)
    }

    propertyKnown = () => {
        return !(this.props.property.purpose === st.POL_Purchase && false === this.props.application.property.hascontract)
    }

    getCompanyConfig =() => {
        const token = sessionStorage.getItem("ZeitroA")
        fetch('/borrower/get_company_config', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
                "X-Borrower": this.props.borrowerid,
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    return;
                }
                response.json().then(js => {
                    if (js.status !== "OK") {
                        return;
                    }

                    this.setState({
                        isMobileHomeOnly: js.configurations.isMobileHomeOnly,
                        propertyTypesLoading: false,
                    });
                });
            }
        ).catch((err) => {
            console.error('Fetch company config error :', err);
        })
    }

    onAddressBlur = (e) => {
        this.timer = setTimeout(this.lookupAddress, 200)
    }

    onStreetAddressCompletion = (address, forMobileHome = false) => {
        this.lastcompletion = Date.now()

        if ("undefined" !== typeof this.timer)
            clearTimeout(this.timer)

        const num = com.getSubaddress(address, "street_number", "long_name")
        const city = com.getSubaddress(address, "locality", "long_name")
        const zip = com.getSubaddress(address, "postal_code", "long_name")
        const street = com.getSubaddress(address, "route", "short_name")
        const county = com.getSubaddress(address, "administrative_area_level_2", "long_name")
        const longstate = com.getSubaddress(address, "administrative_area_level_1", "long_name")



        if (!forMobileHome) {
            if (county !== "")
                this.props.changeMainProperty(county, "county")
            this.props.changeMainProperty(longstate, "state")
            this.props.changeMainProperty(city, "city")
            this.props.changeMainProperty(zip, "zipcode")
        }

        const addr = [
            num,
            street,
            city,
            com.getSubaddress(address, "administrative_area_level_1", "short_name"),
            zip
        ].join(" ").replace(" ,", ",")

        if (num !== "") {
            if (forMobileHome) {
                this.props.changeMobileHomeInfo(addr, "parkAddress")
                return
            } else {
                this.props.changeMainPropertyAddress({ target: { value: addr } })
            }
        }

        this.props.changeMainPropertyAddress({ target: { value: addr } })
    }

    processAdditionalNewMortgages = (val, index) => {
        const { creditor, lientype, monthlyamount, loanamount, creditlimit, key } = val

        const changeNewLoanAttr1 = (verb) => {
            return (event) => {
                com.touch()
                this.props.updateNewMortgageLoanAttribute(event, index, verb)
            }
        }
        const changeNewLoanAttr = (verb) => {
            return (event) => {
                com.touch()
                this.props.updateNewMortgageLoanAttribute(event.target.value, index, verb)
            }
        }
        const changeNewLoanAttrs = (verb) => {
            return (event) => {
                com.touch()
                this.props.updateNewMortgageLoanAttribute(event, index, verb)
            }
        }
        const removeNewMortgageLoan = (e) => {
            com.touch()
            this.props.removeNewMortgageLoan(index)
        }

        const { newloans } = this.props.application.assetsandliabilities

        return (
            <div className="incard mt-2 mb-0" key={key}>
                <Row>
                    <Col>
                        <Form.Row className="finances-type-mobile">
                            <Col md={3} xs={12}>
                                <Form.Group controlId={'newcreditor' + index} className="text-left">
                                    <Form.Label className="text-left text-nowrap">
                                        Creditor
                                    </Form.Label>
                                    <Form.Control
                                        isValid={false}
                                        type="text"
                                        style={{ width: '100%' }}
                                        size="sm"
                                        value={creditor}
                                        onChange={changeNewLoanAttr('creditor')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3} xs={12}>
                                <Form.Group controlId={'lientype' + index} className="text-left">
                                    <Form.Label className="text-left">
                                        Lien type
                                    </Form.Label>
                                    <div>
                                        <Select
                                            style={{ height: 44, width: '100%' }}
                                            value={lientype}
                                            onChange={changeNewLoanAttr1('lientype')}
                                        >
                                            <Select.Option value="firstlien">First lien</Select.Option>
                                            <Select.Option value="subordinatelien">Subordinate lien</Select.Option>
                                        </Select>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col md={2} xs={12}>
                                <Form.Group controlId={'monthlyamount' + index} className="text-left">
                                    <Form.Label className="text-left text-nowrap">
                                        Monthly amount
                                    </Form.Label>
                                    <div>
                                        <DollarInput
                                            defaultValue={monthlyamount}
                                            value={monthlyamount}
                                            onChange={changeNewLoanAttrs('monthlyamount')}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col md={2} xs={12}>
                                <Form.Group controlId={'loanamount' + index} className="text-left">
                                    <Form.Label className="text-left text-nowrap">
                                        Loan amount
                                    </Form.Label>
                                    <div>
                                        <DollarInput
                                            value={loanamount}
                                            defaultValue={loanamount}
                                            onChange={changeNewLoanAttrs('loanamount')}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col md={2} xs={12}>
                                <Form.Group controlId={'creditlimit' + index} className="text-left">
                                    <Form.Label className="text-left text-nowrap">
                                        Credit limit<Tips content={ <div> Input 0 if N/A </div> } />
                                    </Form.Label>
                                    <div>
                                        <DollarInput
                                            defaultValue={creditlimit}
                                            value={creditlimit}
                                            onChange={changeNewLoanAttrs('creditlimit')}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                    </Col>
                    <Col xs="auto" as="div" className="text-right aligh-top">
                        <i hidden={index !== newloans.length - 1} className="far fahover fa-plus-square text-primary aligh-top fa-1x mr-1 divlink" onClick={this.props.addNewMortgageLoan} />
                        <i hidden={index === newloans.length - 1} className="far fahover fa-plus-square aligh-top fa-1x mr-1 transparent"> </i>
                        <i className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink" onClick={removeNewMortgageLoan} />
                    </Col>
                </Row>
            </div>
        )
    }

    renderWarningMessage = (purpose, useEstimated, hasMortgages, hasprimary, hassubject, propertyownedfreeandclear) => {
        // 定义警告消息常量
        const WARNING_MESSAGES = {
            MISSING_PRIMARY_RESIDENCE: (
                <div style={{ color: '#600' }}>
                    Important! Your credit report contains home loans, but none of them are checked to be for your current residence. Double check the loans above. If your mortgages for current residence are missing in the credit report, please specify them.
                </div>
            ),
            MISSING_SUBJECT_PROPERTY: (
                <div style={{ color: '#600' }}>
                    Important! Your credit report contains home loans, but none of them are checked to be for the property being refinanced. Double check the loans above. If your mortgages for subject property are missing in the credit report, please specify them.
                </div>
            ),
            CANNOT_REFINANCE_FREE_CLEAR: (
                <div style={{ color: '#600' }}>
                    Important! You can't refinance a property that is owned free and clear.{' '}
                </div>
            )
        }

        if (purpose === st.POL_Purchase) {
            if (!useEstimated && hasMortgages && !hasprimary && !propertyownedfreeandclear) {
                return WARNING_MESSAGES.MISSING_PRIMARY_RESIDENCE
            }
            return ''
        }

        if (useEstimated && propertyownedfreeandclear && purpose === st.POL_Refinance) {
            return WARNING_MESSAGES.CANNOT_REFINANCE_FREE_CLEAR
        }

        if (!useEstimated) {
            if (hasMortgages && !hassubject && !propertyownedfreeandclear) {
                return WARNING_MESSAGES.MISSING_SUBJECT_PROPERTY
            }

            if (purpose === st.POL_Refinance) {
                return WARNING_MESSAGES.CANNOT_REFINANCE_FREE_CLEAR
            }
        }

        return ''
    }

    needsToAskMortgageBalance = (purpose, mortgageLoans) => {
        // if (isEmpty(mortgageLoans) || loansreviewed < mortgageLoans.length) return <></>

        const { presentaddressownership } = this.props.application[this.props.who]
        if (purpose === st.POL_Purchase && presentaddressownership !== st.O_Own) {
            return <></>
        }

        let hasMortgages = false
        let hassubject = false
        let hasprimary = false
        for (let index = 0; index < mortgageLoans.length; index++) {
            const loan = mortgageLoans[index]
            const creditLoanType = loan.creditloantype.toLowerCase()
            if (some(['mortgage', 'homeequity', 'realestate'], keyword => creditLoanType.includes(keyword))) {
                hasMortgages = true
                if (loan.forsubjectproperty) {
                    if (loan.remainingbalance !== '')
                        hassubject = true
                }
                if (loan.forprimaryresidence) {
                    if (loan.remainingbalance !== '')
                        hasprimary = true
                }
            }
        }

        const needMortgageBalance = purpose !== st.POL_Purchase && !hassubject

        return { needMortgageBalance, hasMortgages, hassubject, hasprimary }
    }

    presentFreeAndClear = () => {
        const { purpose } = this.props.application.property
        const { mortgageLoans, loanbalance } = this.state
        const { needMortgageBalance, hasMortgages, hassubject } = this.needsToAskMortgageBalance(purpose, mortgageLoans)

        return needMortgageBalance && (
            <>
                <Row>
                    <Col className="text-wrap pl-0 pr-2 mt-4" xs={12}>
                        <Form.Label>
                            What's the current mortgage balance on the subject property?
                        </Form.Label>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-wrap pl-0 pr-3" md={6} xs={12}>
                        <Form.Group className="inputSection">
                            <DollarInput
                                disabled={hasMortgages && hassubject}
                                onChange={(e) => {
                                    let loanbalance = e
                                    //if input is empty, make it 0
                                    if ( e === '' || e === 0 || e === null) {
                                        loanbalance = 0
                                        this.updateLoanBalance(loanbalance)
                                    } else {
                                        this.updateLoanBalance(e)
                                    }
                                    this.setState({ loanbalance: loanbalance.toString() })
                                }}
                                value={loanbalance === 0 ? '' : loanbalance}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>

                {/* {this.renderWarningMessage(purpose, useEstimated, hasMortgages, hasprimary, hassubject, propertyownedfreeandclear)} */}
            </>
        )
    }

    render = () => {
        const changeMainPropertyUnits = e => {
            com.touch()
            this.props.changeMainPropertyUnits(e)
        }

        const changeOccupancy = e => {
            com.touch()
            this.props.changeMainProperty(e, "occupancy")
        }

        const changePropertyType = e => {
            com.touch()
            if (e === 'twotofourfamily') {
                this.props.changeMainPropertyUnits({ target: { value: "2" } })
            }
            if (e === "mobilehome") {
                this.props.changeMainProperty(true, "isManufacturedHome")
            } else {
                this.props.changeMainProperty(false, "isManufacturedHome")
            }
            this.props.changeMainProperty(e, "propertytype")
        }

        const saveHomeLoanDebts = e => {
            this.props.changeMobileHomeInfo(e, "debtsToBePaid")
        }

        const changeFirstTimeHomeBuyer = (e) => {
            com.touch()
            this.props.updateApplicationAttribute({ target: { value: e === 'yes' } }, 'firsttimebuyer')
        }

        const { firsttimebuyer, financingsameproperty } = this.props.application
        const {
            purpose,
            occupancy,
            propertytype,
            hascontract,
            isMixedUse,
            qualifiesForFHAsecondary,
            status,
            insurancemaintenancetaxes,
            netrentalincome
        } = this.props.application.property
        const {
            mobilehomeparkfullname,
            parkAddress,
            leasedSpaceRentAmount,
            widthft,
            widthinch,
            year,
            space,
            mobilehomedescription,
            mobilehometype,
            mobilehomelocation,
            originalpurchaseprice,
            manufacturerMake,
            manufacturerModel,
            cashOutRequested,
            debtsToBePaid
        } = this.props.property.mobilehomeinfo

        const { appraisal, salesprice, address, state, county, city, zipcode, mobilehomeinfo } = this.props.property
        const { downpayment, paydown, cashout } = this.props.application.selection
        const { newloans } = this.props.application.assetsandliabilities

        const isPreapproval = hascontract === false && purpose === st.POL_Purchase
        const isPurchase = this.props.property.purpose === st.POL_Purchase
        const isRefinance = this.props.property.purpose === st.POL_Refinance
        const isCashoutRefinance = this.props.property.purpose === st.POL_Cashoutrefinance

        const purposeLabel = () => {
            if (purpose === st.POL_Purchase) {
                return 'What is your down payment amount?'
            }
            return purpose === st.POL_Refinance ? 'What is the amount of your principal paydown?' : 'What is your cashout amount?'
        }

        return (
            <div className="text-left newInterviewPaneProperty mb-3">
                <div className="page-header">
                    <span className="page-header-primary">Subject property</span>
                </div>

                <Row>
                    <Col md={6} xs={12} className="mt-4 pl-0">
                        <Form.Group className="inputSection">
                            <Form.Label>
                                Are you a first-time homebuyer?
                                <Tips
                                    content={
                                        <div style={{ height: 400, overflow: 'auto' }}>
                                            A first time home buyer is someone who meets any of the following conditions:
                                            <ul>
                                                <li>An individual who has not owned a principal residence for three years. If you've owned a home but your spouse has not, then you can purchase a place together as first-time homebuyers.</li>
                                                <li>A single parent who has only owned a home with a former spouse while married.</li>
                                                <li>A displaced homemaker who has only owned with a spouse.</li>
                                                <li>An individual who has only owned a principal residence not permanently affixed to a permanent foundation in accordance with applicable regulations.</li>
                                                <li>An individual who has only owned a property that was not in compliance with state, local, or model building codes—and that cannot be brought into compliance for less than the cost of constructing a permanent structure.</li>
                                            </ul>
                                        </div>
                                    }
                                    placement="bottom"
                                    title="What is first time buyer?"
                                />
                            </Form.Label>
                            <CheckGroup
                                data={[
                                    { value: 'yes', label: 'Yes' },
                                    { value: 'no', label: 'No' }
                                ]}
                                onChangeSelect={changeFirstTimeHomeBuyer}
                                value={firsttimebuyer === true ? 'yes' : 'no'}
                                type='radio'
                                direction="horizontal" />
                        </Form.Group>
                    </Col>
                </Row>

                <div className="mt-4 " style={{ paddingRight: 10 }}>
                    <Form.Group className="inputSection">
                        <Form.Label>How do you plan to use the property?</Form.Label>
                        <CheckGroup
                            data={[
                                { value: 'principal', label: 'Primary residence' },
                                { value: 'secondhome', label: 'Secondary residence' },
                                { value: 'investment', label: 'Investment' }
                            ]}
                            onChangeSelect={changeOccupancy}
                            value={occupancy}
                            type='radio'
                            direction="horizontal" />
                    </Form.Group>
                </div>

                <div className="mt-4" style={{ paddingRight: 10 }}>
                    <Form.Group className="inputSection">
                        <Form.Label>
                            What is your expected property type?
                            <Tips content={
                                this.state.isMobileHomeOnly ? (
                                    <div>
                                        <div style={{ display: "inline-flex", gap: "20px" }}>
                                            <img src="/images/mobilehome.png" style={{ width: "52px", height: "38px" }} alt="mobilehome" />
                                            <div>
                                                <div style={{ fontWeight: "600" }}>Manufactured Home:</div>
                                                <div>A manufactured home is a prefabricated structure, built in a factory on a permanently attached chassis before being transported to site, used as a permanent or temporary residence.</div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div className="pb-3 check-group-wrap" style={{ display: "inline-flex", gap: "20px" }}>
                                            <img src="/images/singleFamily2.png" style={{ width: "52px", height: "38px" }} alt="singlefamily" />
                                            <div>
                                                <div style={{ fontWeight: "600" }}>Single Family Residence:</div>
                                                <div>A single dwelling unit that has no shared property and is built on its own parcel of land.</div>
                                            </div>
                                        </div>
                                        <div className="pb-3" style={{ display: "inline-flex", gap: "20px" }}>
                                            <img src="/images/condo2.png" style={{ width: "52px", height: "38px" }} alt="condo" />
                                            <div>
                                                <div style={{ fontWeight: "600" }}>Condo</div>
                                                <div>A housing or residential complex in which there are separate units, with each unit being owned by an individual.</div>
                                            </div>
                                        </div>
                                        <div className="pb-3" style={{ display: "inline-flex", gap: "20px" }}>
                                            <img src="/images/multiFamily2.png" style={{ width: "52px", height: "38px" }} alt="multifamily" />
                                            <div>
                                                <div style={{ fontWeight: "600" }}>Multi-Family (2 to 4 Unit):</div>
                                                <div>A single building that's divided to accommodate more than one family living separately.</div>
                                            </div>
                                        </div>
                                        <div style={{ display: "inline-flex", gap: "20px" }}>
                                            <img src="/images/mobilehome.png" style={{ width: "52px", height: "38px" }} alt="mobilehome" />
                                            <div>
                                                <div style={{ fontWeight: "600" }}>Manufactured Home:</div>
                                                <div>A manufactured home is a prefabricated structure, built in a factory on a permanently attached chassis before being transported to site, used as a permanent or temporary residence.</div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                placement="bottom"
                                title="What are property types?"
                            />
                        </Form.Label>
                        {this.state.propertyTypesLoading ? <Skeleton /> : (
                            <CheckGroupWithImage
                                data={this.state.isMobileHomeOnly ? [
                                    {
                                        value: 'mobilehome',
                                        label: <div style={{ width: this.getWidth(), }}>Manufactured home</div>,
                                        image: "/images/mobilehome.png",
                                        width: 38,
                                        height: 38,
                                    }
                                ] : [
                                    {
                                        value: 'singlefamilydetached',
                                        label: <div style={{ width: this.getWidth(), }}>Single family</div>,
                                        image: '/images/singleFamily2.png',
                                        width: 38,
                                        height: 38,
                                    },
                                    {
                                        value: 'singlefamilyattached',
                                        label: <div style={{ width: this.getWidth(), }}>Townhouse</div>,
                                        image: "/images/townhouse2.png",
                                        width: 38,
                                        height: 38,
                                    },
                                    {
                                        value: 'twotofourfamily',
                                        label: <div style={{ width: this.getWidth(), }}>Multi-family</div>,
                                        image: "/images/multiFamily2.png",
                                        width: 47,
                                        height: 47,
                                    },
                                    {
                                        value: 'condominium',
                                        label: 'Condo',
                                        image: "/images/condo2.png",
                                        width: 38,
                                        height: 38,
                                    },
                                    {
                                        value: 'mobilehome',
                                        label: <div style={{ width: this.getWidth(), }}>Manufactured home</div>,
                                        image: "/images/mobilehome.png",
                                        width: 38,
                                        height: 38,
                                    }
                                ]}
                                onChangeSelect={changePropertyType}
                                value={propertytype}
                                type='radio'
                                hideCircle={true}
                                direction="horizontal" />
                        )}
                    </Form.Group>
                </div>

                {propertytype === "twotofourfamily" &&
                    <Row className="pt-3">
                        <Col className="pl-0" md={6} xs={12}>
                            <Form.Group className="inputSection">
                                <Form.Label>
                                    What is the number of units?
                                </Form.Label>
                                <div>
                                    <Select
                                        onChange={(e) => changeMainPropertyUnits({ target: { value: e } })}
                                        value={this.props.property.units}
                                        className="w-100"
                                        style={{ height: 44 }}
                                    >
                                        <Select.Option value="1">1</Select.Option>
                                        <Select.Option value="2">2</Select.Option>
                                        <Select.Option value="3">3</Select.Option>
                                        <Select.Option value="4">4</Select.Option>
                                    </Select>
                                </div>
                                <Form.Control.Feedback type="invalid">Please provide number of units.</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                }

                {propertytype === "mobilehome" &&
                    <div>
                        <Row>
                            <Col className="pl-0 mt-4" md={12} xs={12}>
                                <Form.Group className="inputSection">
                                    <Form.Label>What is the full name of the manufactured home park?</Form.Label>
                                    <Form.Control
                                        name="units"
                                        as="input"
                                        size="sm"
                                        value={mobilehomeparkfullname}
                                        onChange={(e) => this.props.changeMobileHomeInfo(e.target.value, "mobilehomeparkfullname")}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide the full name of the manufactured home park
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pl-0 mt-4" md={12} xs={12}>
                                <Form.Group controlId="Adadaddress" className="text-left" >
                                    <AddressAutocomplete
                                        id="parkAddress"
                                        label="What's the address of the park?"
                                        autoComplete="off"
                                        onBlur={this.onAddressBlur}
                                        onCompletion={e => this.onStreetAddressCompletion(e, true)}
                                        placeholder="Address starting with the house number"
                                        required={true}
                                        name={"parkAddress"}
                                        defaultValue={parkAddress}
                                        onChange={e => this.props.changeMobileHomeInfo(e.target.value, "parkAddress")}
                                        pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                        goodfeedback=""
                                        badfeedback="Please provide address of the manufactured home park"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pl-0 mt-4" md={12} xs={12}>
                                <Form.Group className="inputSection">
                                    <Form.Label className="FormALabel" style={{ wordSpacing: 'nowrap' }}>
                                        What is the monthly space rent for the manufactured home park?
                                    </Form.Label>
                                    <DollarInput
                                        onChange={e => {
                                            this.props.changeMobileHomeInfo(e.toString(), "leasedSpaceRentAmount")
                                        }}
                                        defaultValue={leasedSpaceRentAmount}>
                                    </DollarInput>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row >
                            <Col className="pl-0 mt-4" md={6}>
                                <Form.Group className="inputSection">
                                    <Form.Label>Width of the manufactured home</Form.Label>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ width: "49%" }}>
                                            <Input
                                                value={widthft}
                                                onChange={(e) => this.props.changeMobileHomeInfo(e.target.value, "widthft")}
                                                suffix="ft"
                                                size="large"
                                            />
                                        </div>
                                        <div style={{ width: "49%" }}>
                                            <Input
                                                value={widthinch}
                                                onChange={(e) => this.props.changeMobileHomeInfo(e.target.value, "widthinch")}
                                                suffix="inch"
                                                size="large"
                                            />
                                        </div>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col className="pl-0 mt-4" md={6}>
                                <Form.Group className="inputSection">
                                    <Form.Label>Length of the manufactured home</Form.Label>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ width: "49%" }}>
                                            <Input
                                                value={mobilehomeinfo.lengthft}
                                                onChange={(e) => this.props.changeMobileHomeInfo(e.target.value, "lengthft")}
                                                suffix="ft"
                                                size="large"
                                            />
                                        </div>
                                        <div style={{ width: "49%" }}>
                                            <Input
                                                value={mobilehomeinfo.lengthinch}
                                                onChange={(e) => this.props.changeMobileHomeInfo(e.target.value, "lengthinch")}
                                                suffix="inch"
                                                size="large"
                                            />
                                        </div>
                                    </div>

                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="">
                            <Col className="pl-0 mt-4" md={6} xs={12}>
                                <Form.Group className="inputSection">
                                    <Form.Label>What year is the manufactured home built?</Form.Label>
                                    <div>
                                        <Input
                                            type="number"
                                            value={year}
                                            onChange={(e) => {
                                                if (e.target.value.length > 4) {
                                                    this.props.changeMobileHomeInfo(e.target.value.slice(0, 4), "year")
                                                } else {
                                                    this.props.changeMobileHomeInfo(e.target.value, "year")
                                                }
                                            }}
                                            size="large"
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col className="pl-0 mt-4" >
                                <Form.Group className="inputSection">
                                    <Form.Label>Space number in the manufactured home park</Form.Label>
                                    <Form.Control
                                        name="unyearits"
                                        as="input"
                                        size="sm"
                                        value={space}
                                        onChange={(e) => this.props.changeMobileHomeInfo(e.target.value, "space")}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide the space # of the manufactured home in park
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="">
                            <Col className="pl-0 mt-4" md={6} xs={12}>
                                <Form.Group className="inputSection">
                                    <Form.Label>Condition of manufactured home</Form.Label>
                                    <div>
                                        <Select
                                            className="w-100"
                                            style={{ height: 44 }}
                                            onChange={(e) => this.props.changeMobileHomeInfo(e, "mobilehomedescription")}
                                            value={mobilehomedescription}
                                        >
                                            <Select.Option value="New">New</Select.Option>
                                            <Select.Option value="Used">Used</Select.Option>
                                            <Select.Option value="Repo">Repo</Select.Option>
                                        </Select>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col className="pl-0 mt-4" md={6} xs={12}>
                                <Form.Group className="inputSection">
                                    <Form.Label>Type of manufactured home</Form.Label>
                                    <div>
                                        <Select
                                            onChange={(e) => this.props.changeMobileHomeInfo(e, "mobilehometype")}
                                            className="w-100"
                                            style={{ height: 44 }} value={mobilehometype}
                                        >
                                            <Select.Option value="SingleWide">Single Wide</Select.Option>
                                            <Select.Option value="DoubleWide">Double Wide</Select.Option>
                                            <Select.Option value="TripleWide">Triple Wide</Select.Option>
                                        </Select>
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="">
                            <Col className=" mt-4 pl-0" md={6} xs={12}>
                                <Form.Group className="inputSection">
                                    <Form.Label>Location of manufactured home</Form.Label>
                                    <div>
                                        <Select
                                            onChange={(e) => this.props.changeMobileHomeInfo(e, "mobilehomelocation")}
                                            className="w-100"
                                            style={{ height: 44 }} value={mobilehomelocation}>
                                            <Select.Option value="Park">Park</Select.Option>
                                            <Select.Option value="LandorHome">Land/home</Select.Option>
                                            <Select.Option value="CoopPark">Co-op park</Select.Option>
                                            <Select.Option value="SharedPark">Shared park</Select.Option>
                                        </Select>
                                    </div>
                                </Form.Group>
                            </Col>
                            {!isPurchase && (
                                <Col className="pl-0 mt-4" md={6} xs={12}>
                                    <Form.Group className="inputSection">
                                        <Form.Label>Original purchase price</Form.Label>
                                        <DollarInput
                                            onChange={e => {
                                                this.props.changeMobileHomeInfo(e.toString(), "originalpurchaseprice")
                                            }}
                                            defaultValue={originalpurchaseprice}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            Please provide the orinial purchase price
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            )}
                        </Row>
                        <Row>
                            <Col className="pl-0 mt-4" md={6} xs={12}>
                                <Form.Group className="inputSection">
                                    <Form.Label>Manufacturer make</Form.Label>
                                    <Form.Control
                                        name="units"
                                        as="input"
                                        size="sm"
                                        value={manufacturerMake}
                                        onChange={(e) => this.props.changeMobileHomeInfo(e.target.value, "manufacturerMake")}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className="pl-0 mt-4" md={6} xs={12}>
                                <Form.Group className="inputSection">
                                    <Form.Label>Manufacturer model</Form.Label>
                                    <Form.Control
                                        name="units"
                                        as="input"
                                        size="sm"
                                        value={manufacturerModel}
                                        onChange={(e) => this.props.changeMobileHomeInfo(e.target.value, "manufacturerModel")}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        {isCashoutRefinance && (
                            <Row>
                                <Col className="pl-0 mt-4" md={6} xs={12}>
                                    <Form.Group className="inputSection">
                                        <Form.Label>
                                            Cash out requested
                                        </Form.Label>
                                        <DollarInput
                                            onChange={e => {
                                                this.props.changeMobileHomeInfo(e.toString(), "cashOutRequested")
                                            }}
                                            defaultValue={cashOutRequested}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            Please provide the cash out requested
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                        )}

                        {!isPurchase && (
                            <Row className="">
                                <Col className="pl-0 mt-4" md={12} >
                                    <EditableTable dataSource={debtsToBePaid}
                                        title="Add debts to be paid"
                                        columns={[
                                            {
                                                title: "Description of the debt",
                                                dataIndex: "description",
                                                type: "input",
                                            },
                                            {
                                                title: "Remaining debt amount",
                                                dataIndex: "amount",
                                                type: "inputdollar",
                                            },
                                        ]}
                                        newDataTemplate={{ amount: "", description: "" }}
                                        saveFunc={saveHomeLoanDebts}
                                    />
                                </Col>
                            </Row>
                        )}
                    </div>
                }

                {purpose === st.POL_Purchase && (
                    <Row>
                        <Col md={6} xs={12} className="mt-4 pl-0" >
                            <Form.Group className="inputSection">
                                <Form.Label>
                                    Do you know the address of the property you are going to purchase?
                                    <Tips content={
                                        <div>
                                            <div>If you don't have a property address yet, you'll receive a prequalification letter that demonstrates your borrowing potential to sellers and real estate agents. This letter can be used to strengthen your offers when you do find a property you want to pursue.</div>
                                            <div className="mt-2">And when you have a specific property address, you can come back and get personalized interest rates based on your chosen property. At that point, you can formally apply for the loan and proceed with the purchase process.</div>
                                        </div>}
                                        placement="top"
                                        title="What if I don't know the property address yet?"
                                    />
                                </Form.Label>
                                <CheckGroup
                                    data={[
                                        { value: 'true', label: 'Yes' },
                                        { value: 'false', label: 'No' },
                                    ]}
                                    onChangeSelect={e => {
                                        if (e === "true") {
                                            this.props.changeMainPropertyAttribute(true, "hascontract")
                                        } else {
                                            this.props.changeMainPropertyAttribute(false, "hascontract")
                                            this.props.changeMainPropertyAttribute("", "address")
                                        }
                                    }}
                                    value={hascontract === true ? "true" : hascontract === false ? "false" : ""}
                                    type='radio'
                                    direction="horizontal" />
                            </Form.Group>
                        </Col>
                    </Row>
                )}

                {isRefinance || isCashoutRefinance ? (
                    <Row>
                        <Col md={6} xs={12} className="mt-4 pl-0">
                            <Form.Group className="inputSection">
                                <Form.Label>Are you refinancing your primary residence?</Form.Label>
                                <CheckGroup
                                    data={[
                                        { value: 'yes', label: 'Yes' },
                                        { value: 'no', label: "No" },
                                    ]}
                                    onChangeSelect={e => {
                                        com.touch()
                                        const checked = e === "yes"
                                        this.props.updateSameProperty({ target: { checked } })
                                        if (checked) {
                                            this.props.changeMainPropertyOccupancy({ target: { value: "principal" } })
                                            this.updateRefinanceAddressWithResidencyAddress()
                                        }
                                    }}
                                    value={financingsameproperty === true ? "yes" : "no"}
                                    type='radio'
                                    direction="horizontal"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                ) : (
                    <>
                        {hascontract ? (
                            <Row>
                                <Col xs={12} className="mt-4 mb-2 pl-0">
                                    <Form.Group className="inputSection">
                                        <AddressAutocomplete
                                            name="xyzuit"
                                            id="xyzuit"
                                            label="What's the address of the property you are going to purchase?"
                                            autoComplete="off"
                                            onBlur={this.onAddressBlur}
                                            onCompletion={this.onStreetAddressCompletion}
                                            placeholder="Address starting with the house number"
                                            defaultValue={address}
                                            onChange={this.changeMainPropertyAddress}
                                            pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                            badfeedback="Please provide address of the property"
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        ) : (
                            <Row>
                                <Col md = {12} xs = {12} className = "mt-4 pl-0">
                                    <Form.Group className="inputSection">
                                        <Form.Label>What's the address of the property you are going to purchase?</Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row >
                        )}
                        <Row>
                            <Col md={3} xs={12} className="pl-0">
                                <Form.Group >
                                    <Select
                                        onChange={(e) => this.props.changeMainProperty(e, "state")}
                                        className="w-100"
                                        style={{ height: 44 }}
                                        value={state}
                                    >
                                        {getStates(this.state.loanLimits).map(x => (
                                            <Select.Option value={x.value} key={x.value}>
                                                {x.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Group>
                            </Col>
                            <Col md={3} xs={12} className="pl-1">
                                <Form.Group >
                                    <Select
                                        onChange={(e) => this.props.changeMainProperty(e, "county")}
                                        className="w-100"
                                        style={{ height: 44 }}
                                        value={county}
                                    >
                                        {getCounties(this.state.loanLimits, state).map(x => (
                                            <Select.Option value={x.value} key={x.value}>
                                                {x.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Group>
                            </Col>
                            {hascontract && (
                                <>
                                    <Col md={3} xs={12} className="pl-1">
                                        <Form.Group >
                                            <Form.Control
                                                required
                                                size="sm"
                                                onChange={(e) => {
                                                    this.props.changeMainProperty(e.target.value, "city")
                                                }}
                                                value={city}
                                                placeholder="City"
                                            >
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3} xs={12} className="pl-1">
                                        <Form.Group >
                                            <Form.Control
                                                required
                                                size="sm"
                                                type="number"
                                                onChange={(e) => {
                                                    this.props.changeMainProperty(e.target.value, "zipcode")
                                                }}
                                                value={zipcode}
                                                placeholder="Zip Code"
                                            >
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </>
                            )}
                        </Row>
                    </>
                )}

                {financingsameproperty === false && (
                    <Row>
                        <Col className="mt-4 pl-0" xs={12}>
                            <Form.Group controlId="Adadaddress" className="text-left" >
                                <Form.Label>What's the address of the property you are going to refinance?</Form.Label>
                                <AddressAutocomplete id="xyzuit" label=""
                                    autoComplete="off"
                                    onBlur={this.onAddressBlur}
                                    onCompletion={this.onStreetAddressCompletion}
                                    placeholder="Address starting with the house number"
                                    required={true} name={"xyzuit"}
                                    defaultValue={address}
                                    onChange={this.changeMainPropertyAddress}
                                    pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                    goodfeedback=""
                                    badfeedback="Please provide address of the property"
                                />
                                <div className="mt-4">
                                    <div className="formLabel">Property status</div>
                                    <Select
                                        className="w-100"
                                        style={{ height: 44 }}
                                        defaultValue={status}
                                        options={[
                                            { value: "sold", label: "Sold" },
                                            { value: "pending", label: "Pending sale" },
                                            { value: "retained", label: "Retained" },
                                        ]}
                                        onChange={e => this.props.changeMainProperty(e, "status")}
                                    />
                                </div>
                                <div className="mt-4 mb-3">
                                    <div className="formLabel">
                                        Monthly insurance, taxes, association dues, etc.
                                    </div>
                                    <InputNumber
                                        prefix="$"
                                        className="w-100"
                                        defaultValue={insurancemaintenancetaxes}
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                        onChange={e => this.props.changeMainProperty(e, "insurancemaintenancetaxes")}
                                        size="large"
                                    />
                                </div>

                                {(occupancy === "investment" || propertytype === "twotofourfamily") && (
                                    <div className="mb-3">
                                        <div className="formLabel">
                                            Expected Monthly Rental Income
                                        </div>
                                        <InputNumber
                                            prefix="$"
                                            className="w-100"
                                            defaultValue={netrentalincome}
                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                            onChange={e => this.props.changeMainProperty(e, "netrentalincome")}
                                            size="large"
                                        />
                                    </div>
                                )}
                                <div>
                                    <OwnedPropertyMortgagesTable isSubjectProperty={true} />
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                )}

                {this.props.property.occupancy === "principal" && (
                    <Row className="mt-4">
                        <Col className="pl-0" md={6} xs={12}>
                            <Form.Group className="inputSection">
                                <Form.Label>Is part of this property going to be used for business?</Form.Label>
                                <CheckGroup
                                    data={[
                                        { value: 'yes', label: 'Yes' },
                                        { value: 'no', label: "No" },
                                    ]}
                                    onChangeSelect={e => {
                                        com.touch()
                                        this.props.changeMainProperty(e === 'yes', "isMixedUse")
                                    }}
                                    value={isMixedUse === true ? "yes" : "no"}
                                    type='radio'
                                    direction="horizontal" />
                            </Form.Group>
                        </Col>
                    </Row>
                )}

                {occupancy === "secondhome" && (
                    <Row className="mt-4">
                        <Col className="pl-0" md={6} xs={12}>
                            <Form.Group className="inputSection">
                                <Form.Label>
                                        Do you intend to use the second home for work related purposes?
                                        <Tips
                                            content={
                                                <div>
                                                    This is only important for <b>FHA loans</b> that have a separate category of FHA second home.
                                                    If you apply for FHA loan, you'll have to provide documentation that proves that your workplace
                                                    is within 100 miles from your second home. For applying non-FHA loans, please select No.
                                                </div>
                                            }
                                            placement="top"
                                            title="FHA loans only"
                                    />
                                </Form.Label>
                                <CheckGroup
                                    data={[
                                        { value: 'yes', label: 'Yes' },
                                        { value: 'no', label: "No" },
                                    ]}
                                    onChangeSelect={e => {
                                        com.touch()
                                        this.props.changeMainProperty(e === "yes", "qualifiesForFHAsecondary")
                                    }}
                                    value={qualifiesForFHAsecondary === true ? "yes" : "no"}
                                    type='radio'
                                    direction="horizontal" />
                            </Form.Group>
                        </Col>
                    </Row>
                )}

                {this.presentFreeAndClear()}

                <Row>
                    <Col md={6} xs={12} className="mt-4 pl-0">
                        <Form.Group className="inputSection">
                            <Form.Label>What's your estimated home value?</Form.Label>
                            <DollarInput
                                value={appraisal}
                                onChange={e => {
                                    com.touch()
                                    this.props.changeMainPropertyAppraisal(e)
                                }}
                                required
                            />
                        </Form.Group>
                    </Col>
                    {isPurchase && hascontract && (
                        <Col md={6} xs={12} className="mt-4">
                            <Form.Group className="inputSection">
                                <Form.Label>What is your purchase price?</Form.Label>
                                <DollarInput
                                    value={salesprice}
                                    onChange={e => {
                                        com.touch()
                                        this.props.changeMainPropertySalesPrice(e)
                                    }}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    )}
                    <Col md={6} xs={12} className="mt-4 pl-0">
                        <Form.Group className="inputSection">
                            <Form.Label>{purposeLabel()}</Form.Label>
                            <DollarInput
                                value={purpose === st.POL_Purchase ? downpayment : purpose === st.POL_Refinance ? paydown : cashout}
                                onChange={(e) => {
                                    com.touch()
                                    const field = purpose === st.POL_Purchase ? 'downpayment' : purpose === st.POL_Refinance ? 'paydown' : 'cashout'
                                    this.props.updateSelectField(com.safeParseInt(e), field)
                                    if (purpose === st.POL_Purchase) {
                                        this.props.updateSelectField(com.safeParseInt(salesprice) - com.safeParseInt(e), "loansize")
                                    }
                                }}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>

                {this.props.who === com.borrower && !isPreapproval && (
                    <>
                        <Row>
                            <Col md={12} xs={12} className="mt-4 pl-0">
                                <Form.Label className="d-flex justify-content-between text-wrap">
                                    Do you have any new mortgage loans for the property that you're applying for now?
                                </Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} xs={12} className="pl-0">
                                <Form.Group className="inputSection">
                                    <CheckGroup
                                        required
                                        data={[
                                            { value: 'true', label: 'Yes' },
                                            { value: 'false', label: 'No' },
                                        ]}
                                        onChangeSelect={(e) => {
                                            if (e === 'true') {
                                                this.props.addNewMortgageLoan()
                                            } else {
                                                this.props.clearNewMortgageLoans()
                                            }
                                        }}
                                        value={null != newloans && newloans.length !== 0 ? 'true' : 'false'}
                                        type="radio"
                                        direction="horizontal"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {!isEmpty(newloans) && newloans.map((loan, index) => this.processAdditionalNewMortgages(loan, index))}
                    </>
                )}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Property)

const options = (() => {
    const out = []
    com.downpaymentsources.forEach(x => out.push(x.id))
    return out
})()

const mapStateToPropsMore = (state) => {
    return {
        property: state.application.property,
        application: state.application,
    }
}

const mapDispatchToPropsMore = (dispatch) => ({
    changeMainPropertyDescription: (event) => {
        dispatch(act.ChangeMainPropertyDescription(event.target.value))
    },
    changeMainPropertyAddress: (event) => {
        dispatch(act.ChangeMainPropertyAddress(event.target.value))
    },
    changeMainPropertyOccupancy: (event) => {
        dispatch(act.ChangeMainPropertyOccupancy(event.target.value))
    },
    changeMainPropertyPurpose: (event) => {
        dispatch(act.ChangeMainPropertyPurpose(event.target.value))
    },
    changeMainPropertyUnits: (event) => {
        dispatch(act.ChangeMainPropertyUnits(event.target.value))
    },
    changeMainPropertyYearBuilt: (event) => {
        dispatch(act.ChangeMainPropertyYearBuilt(event.target.value))
    },
    changeMainPropertyInWhatName: (event) => {
        dispatch(act.ChangeMainPropertyInWhatName(event.target.value))
    },
    changeMainPropertyEstateHeldIn: (event) => {
        dispatch(act.ChangeMainPropertyEstateHeldIn(event.target.value))
    },
    changeMainPropertyInWhatManner: (event) => {
        dispatch(act.ChangeMainPropertyInWhatManner(event.target.value))
    },
    changeMainPropertySources: (event) => {
        dispatch(act.ChangeMainPropertySources(event.target.value))
    },
    changeMainPropertyRefinanceYearAcquired: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceYearAcquired(event.target.value))
    },
    changeMainPropertyRefinanceOriginalCost: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceOriginalCost(event.target.value))
    },
    changeMainPropertyRefinancePurpose: (event) => {
        dispatch(act.ChangeMainPropertyRefinancePurpose(event.target.value))
    },
    changeMainPropertyRefinanceImprovementsDesc: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceImprovementsDesc(event.target.value))
    },
    changeMainPropertyRefinanceExistingLiens: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceExistingLiens(event.target.value))
    },
    changeMainPropertyRefinanceImprovementsStatus: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceImprovementsStatus(event.target.value))
    },
    changeMainPropertyRefinanceCost: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceCost(event.target.value))
    },
    changeMainPropertyConstructionYearAcquired: (event) => {
        dispatch(act.ChangeMainPropertyConstructionYearAcquired(event.target.value))
    },
    changeMainPropertyConstructionOriginalCost: (event) => {
        dispatch(act.ChangeMainPropertyConstructionOriginalCost(event.target.value))
    },
    changeMainPropertyConstructionExistingLiens: (event) => {
        dispatch(act.ChangeMainPropertyConstructionExistingLiens(event.target.value))
    },
    changeMainPropertyConstructionPresentValue: (event) => {
        dispatch(act.ChangeMainPropertyConstructionPresentValue(event.target.value))
    },
    changeMainPropertyConstructionCostOfImprovements: (event) => {
        dispatch(act.ChangeMainPropertyConstructionCostOfImprovements(event.target.value))
    },
    changeMainPropertyExpirationDate: (event) => {
        dispatch(act.ChangeMainPropertyExpirationDate(event.target.value))
    },
    changeMainPropertyRefinanceExplainOther: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceExplainOther(event.target.value))
    },
    changeMainPropertyPropertyType: (event) => {
        dispatch(act.ChangeMainPropertyPropertyType(event.target.value))
    },
    updateTransaction: (event, verb) => {
        dispatch(act.UpdateTransaction(event.target.value, verb))
    },
    changeMainPropertyRefinanceAttribute: (value, verb) => {
        dispatch(act.ChangeMainPropertyRefinanceAttribute(value, verb))
    },
})

export class MoreProperty extends Component {
    constructor(props) {
        super(props)

        this.state = {
            validated: false,
        }

        this.isValidated = this.isValidated.bind(this)
        this.inwhat = React.createRef()
        this.selected = this.parseBareSources(this.props.property.sources)
        this.other = this.getOther()

        const { borrower, coborrower, hascoborrower, married } = this.props.application
        const borrowerName = `${borrower.firstname} ${borrower.lastname}`
        const coborrowerName = `${coborrower.firstname} ${coborrower.lastname}`
        if (this.props.property.inwhatname === "") {
            let name = borrowerName
            if (hascoborrower === "withcoborrower" || (hascoborrower === "multiple" && married)) {
                name += ", " + coborrowerName
            }
            this.props.changeMainPropertyInWhatName({ target: { value: name } })
        }
    }

    handleSubmit = (e) => {
        // TBD
        // let inw = this.props.property.inwhatname
        // if(inw.toUpperCase().indexOf(this.props.application["borrower"].firstname.toUpperCase()) < 0 || inw.toUpperCase().indexOf(this.props.application["borrower"].lastname.toUpperCase()) < 0  ) {
        //     this.inwhat.current.setCustomValidity("Borrower must be on the title!")
        //     this.setState({ validated: true })
        //     return false
        // }
        return true
    }

    componentDidMount() { }

    componentWillUnmount() { }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        }
        return true
    }

    changeMainPropertyPropertyType = e => {
        com.touch()

        const { who, financingsameproperty } = this.props
        const { purpose } = this.props.property
        if (purpose !== "purchase" && financingsameproperty)
            this.props.changeBorrowerInfo(e, who, "propertytype")

        this.props.changeMainPropertyPropertyType(e)
    }

    renderRefinance = () => {
        const changeMainPropertyRefinancePurpose = e => {
            com.touch()
            this.props.changeMainPropertyRefinancePurpose(e)
        }
        const changeMainPropertyRefinanceImprovementsStatus = e => {
            com.touch()
            this.props.changeMainPropertyRefinanceImprovementsStatus(e)
        }
        const changeMainPropertyRefinanceCost = e => {
            com.touch()
            this.props.changeMainPropertyRefinanceCost(e)
        }
        const changeMainPropertyRefinanceExplainOther = e => {
            com.touch()
            this.props.changeMainPropertyRefinanceExplainOther(e)
        }
        const changeMainPropertyRefinanceImprovementsDesc = e => {
            com.touch()
            this.props.changeMainPropertyRefinanceImprovementsDesc(e)
        }

        const { purpose, improvementsstatus, cost, explainotherpurpose, improvementsdesc } = this.props.property.refinance

        return (
            <div className="">
                <Form.Row>
                    <Col>
                        <h4 className="ml-0 heading"><div style={{ fontSize: '0.9em' }} >Cash Out Refinance Loan Details:</div></h4>
                    </Col>
                </Form.Row>
                <Form.Row>

                    <Col md="auto">
                        <Form.Group controlId="purpose" className="text-left" >
                            <Form.Label className="text-left" >Purpose of Refinance:</Form.Label>
                            <Form.Control name="purpose" as="select" size="sm"
                                defaultValue={purpose}
                                onChange={changeMainPropertyRefinancePurpose}
                            >
                                <option value="debtconsolidation">Debt consolidation by paying consumer debts such as credit cards or installment loans</option>
                                <option value="homeimprovement">Home Improvement</option>
                                <option value="paytorelatives">Paying off part of the equity to relatives</option>
                                <option value="cashforreserves">Obtain cash for reserves</option>
                                <option value="paidcashforsubjectproperty">Paid cash for subject property and want to replenish reserves</option>
                                <option value="payingoffheloc">Paying off second mortgage or a HELOC balance</option>
                                <option value="payingotherproperties">Paying off balances on other owned properties</option>
                                <option value="payingstudentloans">Paying off student loans only</option>
                                <option value="other">Other</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col></Col>
                    <Col></Col>
                </Form.Row>
                <Form.Row>
                    <Col >
                        <Form.Group hidden={purpose !== "homeimprovement"} controlId="improv" className="text-left" >
                            <Form.Label className="text-left" >Improvements:</Form.Label>
                            <Form.Control name="improve" as="select" size="sm"
                                defaultValue={improvementsstatus}
                                onChange={changeMainPropertyRefinanceImprovementsStatus}
                            >
                                <option value="made">Made</option>
                                <option value="tobemade">To be made</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col >
                        {(improvementsstatus !== "noimprovements" && purpose === "homeimprovement") &&
                            <Form.Group controlId="refcost" className="text-left" hidden={improvementsstatus === "none"}>
                                <Form.Label className="text-left" >Their Cost:</Form.Label>
                                <NumericalInput
                                    required={improvementsstatus !== "noimprovements"}
                                    name="refcost"
                                    size="sm"
                                    defaultValue={cost}
                                    onChange={changeMainPropertyRefinanceCost}
                                    min={0}
                                />
                                <Form.Control.Feedback type="invalid" >
                                    Please provide cost of improvements.
                                </Form.Control.Feedback>
                            </Form.Group>
                        }
                    </Col>
                    <Col>
                    </Col>
                </Form.Row>
                {purpose === "other" &&
                    <Form.Row hidden={purpose !== "other"}>
                        <Col xs="auto">
                            <Form.Group controlId="explain" className="text-left" >
                                <Form.Label className="text-left" >Please write an explanation:</Form.Label>
                                <Form.Control
                                    required
                                    name="explain"
                                    type="text"
                                    as="textarea"
                                    defaultValue={explainotherpurpose}
                                    onChange={changeMainPropertyRefinanceExplainOther}
                                    pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                />
                                <Form.Control.Feedback type="invalid" >
                                    Please provide your estimated liabilities.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Form.Row>
                }
                <Form.Row hidden={purpose !== "homeimprovement" || improvementsstatus === "noimprovements"}>
                    <Col xs="auto">
                        <Form.Group controlId="descimp" className="text-left" >
                            <Form.Label className="text-left" >Describe Improvements:</Form.Label>
                            <Form.Control
                                name="descimp"
                                type="text"
                                as="textarea"
                                defaultValue={improvementsdesc}
                                onChange={changeMainPropertyRefinanceImprovementsDesc}
                                pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                            />
                            <Form.Control.Feedback type="invalid" >
                                Please provide your estimated liabilities.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Form.Row>
            </div>
        )
    }

    onSelect = e => {
        const ar = []
        const adj = []

        e.forEach(x => {
            if (x.id !== "Other") {
                ar.push(x.id)
                adj.push(x)
            } else {
                const other = " " + this.other
                ar.push(other)
                adj.push({ id: other, name: other })
            }

        })

        const out = ar.join(",")
        this.selected = adj
        com.touch()
        this.props.changeMainPropertySources({ target: { value: out } })
    }

    parseSources = v => {
        const s = []
        if (v === "") {
            return s
        }

        const ar = v.split(',')
        ar.forEach(x => {
            if (options.includes(x)) {
                s.push({ id: x, name: x })
            } else {
                if (x === '') return
                s.push({ id: "Other", name: "Other" })
            }
        })

        return s
    }

    parseBareSources = v => {
        const s = []
        if (v === "") return s
        const ar = v.split(',')
        ar.forEach(x => {
            s.push({ id: x, name: x })
        })
        return s
    }

    getOther = () => {
        const ar = this.props.property.sources.split(',')
        let o = ""
        ar.forEach(x => {
            if (x === "") return
            if (!options.includes(x)) {
                o = x.slice(1, x.length)
                o = o.replaceAll(';', ',')

            }
        })

        return o
    }

    render = () => {
        const aupdateTransaction = (verb) => {
            return e => {
                const event = {
                    target: {
                        value: e
                    }
                }
                com.touch()
                this.props.updateTransaction(event, verb)
            }
        }

        const updateTransaction = (verb) => {
            return e => {
                com.touch()
                this.props.updateTransaction(e, verb)
            }
        }

        const onSelect = e => {
            com.touch()
            this.props.changeMainPropertyInWhatManner({ target: { value: e } })
        }


        const changeMainPropertyInWhatName = e => {
            com.touch()
            this.props.changeMainPropertyInWhatName(e)
        }

        const hasOther = () => {
            const ar = this.props.property.sources.split(',')

            let has = false
            ar.forEach(x => {
                if (x === '') return

                if (!options.includes(x)) {
                    has = true
                }
            })

            return has
        }

        const changeOther = e => {
            const ar = []
            const adj = []

            this.selected.forEach(x => {
                if (!options.includes(x.id)) {
                    const r = " " + e.target.value.replaceAll(',', ';')
                    ar.push(r)
                    adj.push({ id: r, name: r })
                } else {
                    ar.push(x.id,)
                    adj.push({ id: x.id, name: x.name })
                }
            })

            const out = ar.join(",")
            this.selected = adj

            com.touch()
            this.other = e.target.value
            this.props.changeMainPropertySources({ target: { value: out } })

        }

        const { purpose, inwhatmanner, inwhatname, sources } = this.props.property
        const { cashdepositedby, depositamount } = this.props.application.transaction

        this.parseSources(sources)

        return (
            <div className="text-left  p-4">
                <div className=" prequalInterviewTitle">
                    Transactions
                </div>
                <div className="mt-4 ">
                    <Form.Row >
                        <Col xs="auto">
                            <Form.Group controlId="InName" className="text-left" >
                                <Form.Label className="text-left" >
                                    Title will be held in what name(s)
                                    <Tips
                                        content={
                                            <div>
                                                Specify the names of individual, or individuals, or legal entities that will hold the title. For example, husband and wife in case of joint tenancy.
                                                <div className="mt-2">Borrower <b>must</b> be on the title</div>
                                            </div>
                                        }
                                        placement="bottom"
                                    />
                                </Form.Label>
                                <Form.Control isValid={false} size="sm"
                                    ref={this.inwhat}
                                    required
                                    name="inname"
                                    defaultValue={inwhatname}
                                    onChange={changeMainPropertyInWhatName}
                                    pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                />
                                <Form.Control.Feedback type="invalid" >
                                    Please specify in what name the title will be held.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group controlId="manner" className="text-left" >
                                <Form.Label className="text-left" >
                                    Manner in which title will be held
                                    <Tips
                                        content={
                                            <div>
                                                There are several ways that people can take title to a property. Some of the common forms are:
                                                <ul>
                                                    <li><b>Joint Tenancy:</b> Each person has an undivided interest in the property.</li>
                                                    <li><b>Tenants in Common:</b> Indicates the percentage of ownership in the property. It is not an undivided interest as in Joint Tenancy; for example, one party may own 30% and another party may own 70%.</li>
                                                    <li><b>Sole and Separate:</b> Allows an individual to have 100% title to the property. A single or unmarried person may have this type of title.</li>
                                                </ul>
                                                It is recommended that borrowers seek legal advice before choosing an appropriate form of ownership.
                                            </div>
                                        }
                                        placement="bottom"
                                    />
                                </Form.Label>
                                <div>
                                    <Select
                                        style={{ width: "100%", height: 44 }}
                                        value={inwhatmanner}
                                        onSelect={(e) => { onSelect(e) }}
                                    >
                                        {com.mannerthetitleheld.map((st) => (
                                            <Select.Option value={st}>{st}</Select.Option>
                                        ))}
                                    </Select>
                                </div>
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row className="mt-4">
                        <Col>
                            <Form.Group controlId="Sources" className="text-left" >
                                <Form.Label className="text-left" >
                                    Sources of Downpayment,  Settlement Charges, and/or Subordinate Financing (check all that apply)
                                    <Tips
                                        content={
                                            <div>
                                                If you are purchasing a property, there will generally be a down payment and various costs to close the loan. The funds may come from a savings account, sale of stocks or bonds, proceeds from the sale of another property, etc. Please indicate the source of the funds. If there will be other financing or subordinate financing on the property, indicate the source of funds. For example, subordinate financing may be a loan from a seller or from a 3rd party source such as a realtor.
                                            </div>
                                        }
                                        placement="bottom"
                                    />
                                </Form.Label>
                                <Multiselect
                                    showCheckbox={true}
                                    required={this.parseSources(sources).length === 0}
                                    closeIcon="close"
                                    style={customstyle}
                                    options={com.downpaymentsources} // Options to display in the dropdown
                                    selectedValues={this.parseSources(sources)} // Preselected value to persist in dropdown
                                    onSelect={this.onSelect} // Function will trigger on select event
                                    displayValue="name" // Property name to display in the dropdown options
                                />
                                {hasOther() ?
                                    <InputGroup className="pl-5">
                                        <Form.Control isValid={false} size="sm"
                                            required
                                            name="sources"
                                            value={this.getOther()}
                                            onChange={changeOther}
                                            placeholder="List other sources"
                                            pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            Please provide other sources of downpayment, settlement charges, and/or subordinate financing.
                                        </Form.Control.Feedback>
                                    </InputGroup> : ""
                                }
                            </Form.Group>
                        </Col>
                    </Form.Row>
                </div>
                <div className="mt-4">
                    {(purpose === "cashoutrefinance") && this.renderRefinance()}
                    {purpose === "purchase" &&
                        <div>
                            <Form.Row><Col><Form.Label>Cash deposit towards purchase</Form.Label></Col></Form.Row>
                            <Form.Row className="pt-1 mb-1">
                                <Col xs="auto">
                                    <Form.Group controlId="Heldby" className="text-left" size="sm">
                                        <Form.Label className="text-left" >
                                            Held by
                                            <Tips
                                                content={
                                                    <div>
                                                        Write the name of the escrow or title company to whom you made the earnest money deposit check that was submitted along with your offer.<br /> If not sure, write "I don't know yet"
                                                    </div>
                                                }
                                            />
                                        </Form.Label>
                                        <Form.Control
                                            isValid={false}
                                            size="sm"
                                            required
                                            name="heldby"
                                            type="text"
                                            defaultValue={cashdepositedby}
                                            onChange={updateTransaction("cashdepositedby")}
                                            pattern="^[a-zA-Z0-9 !-:&()'?#+/@]+$"
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            Provide name of the escrow or title company.
                                        </Form.Control.Feedback>

                                    </Form.Group>
                                </Col>
                                <Col xs="auto">
                                    <Form.Group controlId="Amount" className="text-left" size="sm">
                                        <Form.Label className="text-left" >Amount</Form.Label>
                                        <div>
                                            <InputNumber
                                                prefix='$'
                                                min={1}
                                                size='large'
                                                style={{ width: 150 }}
                                                onChange={aupdateTransaction("depositamount")}
                                                value={depositamount}
                                            />
                                        </div>
                                        <Form.Control.Feedback type="invalid" >
                                            Please provide the deposit amount.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

MoreProperty = connect(mapStateToPropsMore, mapDispatchToPropsMore, null, { forwardRef: true })(MoreProperty)

export class DetailProperty extends Component {
    constructor(props) {
        super(props)

        this.form = React.createRef()

        this.state = {
            loanLimits: {}
        }

        this.lastcompletion = 0

        fetch("/data/loanlimits")
            .then((res) => {
                if (!res.ok) {
                    console.warn(`Looks like there was a problem. Status Code: ${res.status}`)
                    return
                }
                return res.json()
            })
            .then((res) => {
                this.setState({ loanLimits: res })
            })
            .catch((err) => {
                console.error(err)
            })
    }

    updateRefinanceAddressWithResidencyAddress = () => {
        const { who } = this.props
        const { borrower, coborrower, property } = this.props.application
        const presentAddressStreet = who === com.borrower ? borrower.presentaddressstreet : coborrower.presentaddressstreet
        if (property.address === "") {
            this.props.changeMainProperty(presentAddressStreet, "address")
            this.lookupAddress(presentAddressStreet)
        }
    }

    componentDidMount() {
        const { financingsameproperty, property } = this.props.application
        if (financingsameproperty === true) {
            if (property.purpose === 'refinance' || property.purpose === 'cashoutrefinance') {
                this.props.changeMainPropertyOccupancy({ target: { value: "principal" } })
            }

            this.updateRefinanceAddressWithResidencyAddress()
        }
        if (financingsameproperty === null) {
            this.props.updateSameProperty({ target: { checked: true } })
            this.updateRefinanceAddressWithResidencyAddress()
        }
        this.completion('property')
    }

    componentDidUpdate() {
        this.completion('property')
    }

    componentWillUnmount() { }

    completion = (stateKey) => {
        const keysToFilter = ['address', 'appraisal', 'salesprice', 'isManufacturedHome']
        const { propertytype, occupancy } = this.props[stateKey]
        if (propertytype === "twotofourfamily") {
            keysToFilter.push('units')
        }
        if (occupancy === "principal") {
            keysToFilter.push('isMixedUse')
        }
        if (occupancy === "investment" || (occupancy === "principal" && this.props.application[stateKey].propertytype === "twotofourfamily")) {
            keysToFilter.push('purchase.expectedgrossmonthlyrentalincome')
        }
        const filteredObj = keysToFilter.reduce((obj, key) => {
            if (key.includes('.')) {
                const nestedKeys = key.split('.')
                let value = this.props[stateKey]

                for (const nestedKey of nestedKeys) {
                    if (value.hasOwnProperty(nestedKey)) {
                        value = value[nestedKey]
                    } else {
                        value = undefined
                        break
                    }
                }

                if (value !== undefined) {
                    obj[nestedKeys[nestedKeys.length - 1]] = value
                }
            } else if (this.props[stateKey].hasOwnProperty(key)) {
                obj[key] = this.props[stateKey][key]
            }

            return obj
        }, {})

        const emptyNum = Object.keys(filteredObj).filter((key) => {
            const value = filteredObj[key]
            return value === '' || value === null || value === undefined
        }).length
        const progressValue = Math.round(((keysToFilter.length - emptyNum) / keysToFilter.length) * 100)
        sessionStorage.setItem("progressValue", progressValue)
    }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        }
        return true
    }

    onAddressBlur = (e) => {
        this.timer = setTimeout(this.lookupAddress, 200)
    }

    isoccupancyHidden = () => {
        return this.props.application.financingsameproperty && (this.props.application.property.purpose !== st.POL_Purchase)
    }

    sameaddress = () => {
        const { who } = this.props
        const addr = com.formatAddress(this.props.application[who])
        return addr
    }

    changeMainPropertyAddress = e => {
        //this.onStreetAddressCompletion(address)
        const address = e.target.value
        if (address === "") {
            this.props.changeMainProperty(false, "lookupsuccessful")
        }

        this.props.changeMainPropertyAddress(e)
    }


    lookupAddress = (providedAddress) => {
        const now = Date.now()

        if (now - this.lastcompletion < 500)
            return

        let addr = this.props.application.property.address
        if (typeof providedAddress !== "undefined") {
            addr = providedAddress
        }
        if (this.isoccupancyHidden()) {
            addr = this.sameaddress()
            this.props.changeMainPropertyAddress({ target: { value: addr } })

        }

        if ("" === addr)
            return

        const GOOGLE_MAPS_API_KEY = 'AIzaSyBQFO5uQtyZ5EsbTsNEK7HOhEA00KS3le8'
        const GEOCODE_API_URL = 'https://maps.googleapis.com/maps/api/geocode/json'
        const target = encodeURI(`${GEOCODE_API_URL}?address=${addr}&key=${GOOGLE_MAPS_API_KEY}`)

        fetch(target, {
            method: 'GET',
        }).then(
            response => {
                if (response.status !== 200) {
                    console.warn(`Looks like there was a problem. Status Code: ${response.status}`)
                    return
                }

                // Examine the text in the response
                response.json().then(js => {
                    if (js.status === "OK") {
                        this.onStreetAddressCompletion(js.results[0].address_components)
                    }
                })
            }
        ).catch((err) => {
            console.error('Fetch Error :', err)
        })
    }

    onStreetAddressCompletion = (address, forMobileHome = false) => {
        this.lastcompletion = Date.now()

        if ("undefined" !== typeof this.timer)
            clearTimeout(this.timer)

        const num = com.getSubaddress(address, "street_number", "long_name")
        const city = com.getSubaddress(address, "locality", "long_name")
        const zip = com.getSubaddress(address, "postal_code", "long_name")
        const street = com.getSubaddress(address, "route", "short_name")
        const county = com.getSubaddress(address, "administrative_area_level_2", "long_name")
        const longstate = com.getSubaddress(address, "administrative_area_level_1", "long_name")

        if (county !== "")
            this.props.changeMainProperty(county, "county")

        this.props.changeMainProperty(longstate, "state")
        this.props.changeMainProperty(city, "city")
        this.props.changeMainProperty(zip, "zipcode")

        const addr = [
            num,
            street,
            city,
            com.getSubaddress(address, "administrative_area_level_1", "short_name"),
            zip
        ].join(" ").replace(" ,", ",")

        if (num !== "") {
            if (forMobileHome) {
                this.props.changeMobileHomeInfo(addr, "parkAddress")
                return
            } else {
                this.props.changeMainPropertyAddress({ target: { value: addr } })
            }
        }

        let sfx = ""
        let short = com.getSubaddress(address, "route", "short_name")
        let shorts = short.split(' ')

        if (com.streetsuffixes.includes(shorts[shorts.length - 1].toUpperCase())) {
            sfx = shorts.pop()

            if (typeof com.streetTypeAbbreviations[sfx.toLowerCase()] !== "undefined")
                sfx = com.streetTypeAbbreviations[sfx.toLowerCase()].toUpperCase()

            short = shorts.join(' ')
        }

        const s = shorts[0].toUpperCase()
        if (com.prefixes.includes(s)) {
            shorts = short.split(' ')
            short = shorts.slice(1, shorts.length).join(' ')
        }

        this.props.changeMainPropertyAddress({ target: { value: addr } })
    }

    getStates = () => {
        const options = []
        const keys = Object.keys(this.state.loanLimits)
        options.push(<option value="">Select property state</option>)
        keys.forEach((x) => {
            options.push(<option value={com.capitalize(x)}> {com.capitalize(x)}</option>)
        })
        return options
    }

    getCounties = () => {
        const options = []
        options.push(<option value="">Select property county</option>)
        const st = this.props.property.state
        try {
            if (this.state.loanLimits[st.toUpperCase()]) {
                const keys = Object.keys(this.state.loanLimits[st.toUpperCase()])
                keys.forEach((x) => {
                    options.push(<option value={com.capitalize(x)}> {com.capitalize(x)}</option>)
                })
            }
        } catch (error) {
            console.error(error)
        }

        return options
    }

    render() {
        const changeMainPropertyUnits = e => {
            com.touch()
            this.props.changeMainPropertyUnits(e)
        }
        const onAppraisal = e => {
            com.touch()
            this.props.changeMainPropertyAppraisal(e)
        }
        const onSalesPrice = e => {
            com.touch()
            this.props.changeMainPropertySalesPrice(e)
        }

        const {
            status,
            insurancemaintenancetaxes,
            occupancy,
            propertytype,
            netrentalincome,
            hascontract,
            purpose,
            isMixedUse,
            qualifiesForFHAsecondary,
        } = this.props.application.property

        const displayOwnedProperty = () => {
            return <div>
                <div className="mt-4">
                    <div className="formLabel">
                        Property status
                    </div>
                    <Select
                        className="w-100"
                        style={{ height: 44 }}
                        defaultValue={status}
                        options={[
                            { value: "sold", label: "Sold" },
                            { value: "pending", label: "Pending sale" },
                            { value: "retained", label: "Retained" },
                        ]}
                        onChange={e => this.props.changeMainProperty(e, "status")}
                    />

                </div>
                <div className="mt-4 mb-3">
                    <div className="formLabel">
                        Monthly insurance, taxes, association dues, etc.
                    </div>
                    <InputNumber
                        prefix="$"
                        className="w-100"
                        defaultValue={insurancemaintenancetaxes}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        onChange={e => this.props.changeMainProperty(e, "insurancemaintenancetaxes")}
                        size="large"
                    />
                </div>

                {(occupancy === "investment" || propertytype === "twotofourfamily") && <div className="mb-3">
                        <div className="formLabel">
                            Expected Monthly Rental Income
                        </div>
                        <InputNumber
                            prefix="$"
                            className="w-100"
                            defaultValue={netrentalincome}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={e => this.props.changeMainProperty(e, "netrentalincome")}
                            size="large"
                        />
                    </div>}
                <div>
                    <OwnedPropertyMortgagesTable isSubjectProperty={true} />
                </div>
            </div>
        }

        if (!hascontract && purpose === "purchase") {
            return <PrequalProperty />
        }

        const { financingsameproperty } = this.props.application
        const { address, appraisal, units, salesprice } = this.props.property
        const { downpayment, paydown, cashout } = this.props.application.selection

        return (
            <div className="text-left newInterviewPaneProperty">
                <div className="page-header">
                    <span className="page-header-primary">Subject property</span>
                    <span className="page-header-separator">/</span>
                    <span className="page-header-secondary">Detail</span>
                </div>
                {
                    (this.props.property.purpose === st.POL_Refinance || this.props.property.purpose === st.POL_Cashoutrefinance) ?
                        <Row className="mt-4">
                            <Col className="pl-0" md={6} xs={12}>
                                <Form.Group className="inputSection">
                                    <Form.Label>
                                        Are you refinancing your primary residence?
                                    </Form.Label>
                                    <CheckGroup
                                        data={[
                                            {
                                            value: 'no',
                                            label: "No",
                                        },
                                        {
                                            value: 'yes',
                                            label: 'Yes',
                                        },
                                        ]}
                                        onChangeSelect={e => {
                                            com.touch()
                                            const out = e === "yes"
                                            this.props.updateSameProperty({ target: { checked: out } })
                                            if (out) {
                                                this.props.changeMainPropertyOccupancy({ target: { value: "principal" } })
                                                this.updateRefinanceAddressWithResidencyAddress()
                                            }
                                        }}
                                        value={financingsameproperty === true ? "yes" : "no"}
                                        type='radio'
                                        direction="horizontal" />
                                </Form.Group>
                            </Col>
                        </Row> :
                        <div>
                            <Row className="pt-3">
                                <Col className="pl-0" >
                                    <Form.Group controlId="address" className="text-left" >
                                        <Form.Label>What's the address of the property you are going to purchase?</Form.Label>
                                        <AddressAutocomplete id="xyzuit" label=""
                                            autoComplete="off"
                                            onBlur={this.onAddressBlur}
                                            onCompletion={this.onStreetAddressCompletion}
                                            placeholder="Address starting with the house number"
                                            required={true} name={"xyzuit"}
                                            defaultValue={this.props.property.address}
                                            onChange={this.changeMainPropertyAddress}
                                            pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                            goodfeedback=""
                                            badfeedback="Please provide address of the property"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                }

                {
                    financingsameproperty === false && <Row className="pt-3">
                        <Col className="pl-0" md={6} xs={12}>
                            <Form.Group controlId="Adadaddress" className="text-left" >
                                <Form.Label>What's the address of the property you are going to refinance?</Form.Label>
                                <AddressAutocomplete id="xyzuit" label=""
                                    autoComplete="off"
                                    onBlur={this.onAddressBlur}
                                    onCompletion={this.onStreetAddressCompletion}
                                    placeholder="Address starting with the house number"
                                    required={true} name={"xyzuit"}
                                    defaultValue={address}
                                    onChange={this.changeMainPropertyAddress}
                                    pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                    goodfeedback=""
                                    badfeedback="Please provide address of the property"
                                />
                                {displayOwnedProperty()}
                            </Form.Group>
                        </Col>
                    </Row>
                }


                {propertytype === "twotofourfamily" &&
                    <Row className="pt-3">
                        <Col className="pl-0" md={6} xs={12}>
                            <Form.Group className="inputSection">
                                <Form.Label>
                                    What is the number of units?
                                </Form.Label>
                                <div>
                                    <Select
                                        onChange={(e) => changeMainPropertyUnits({ target: { value: e } })}
                                        value={units}
                                        className="w-100" style={{ height: 44 }}>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                    </Select>
                                </div>
                                <Form.Control.Feedback type="invalid" >
                                    Please provide number of units.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>}


                {this.props.property.occupancy === "principal" &&
                    <Row className="mt-4">
                        <Col className="pl-0" md={6} xs={12}>
                            <Form.Group className="inputSection">
                                <Form.Label>
                                    Will you use any part of this property for business?
                                </Form.Label>
                                <CheckGroup
                                    data={[
                                        { value: 'yes', label: 'Yes' },
                                        { value: 'no', label: "No" },
                                    ]}
                                    onChangeSelect={e => {
                                        com.touch()
                                        let value = e === "yes"
                                        this.props.changeMainProperty(value, "isMixedUse")
                                    }}
                                    value={isMixedUse === true ? "yes" : "no"}
                                    type='radio'
                                    direction="horizontal" />
                            </Form.Group>
                        </Col>
                    </Row>
                }
                {
                    occupancy === "secondhome" &&
                    <Row className="mt-4">
                        <Col className="pl-0" md={6} xs={12}>
                            <Form.Group className="inputSection">
                                <Form.Label>
                                        Do you intend to use the second home for work related purposes?
                                        <Tips
                                            content={
                                                <div>
                                                    This is only important for <b>FHA loans</b> that have a separate category of FHA second home.
                                                    If you apply for FHA loan, you'll have to provide documentation that proves that your workplace
                                                    is within 100 miles from your second home. For applying non-FHA loans, please select No.
                                                </div>
                                            }
                                            placement="top"
                                            title="FHA loans only"
                                    />
                                </Form.Label>
                                <CheckGroup
                                    data={[
                                        {
                                            value: 'no',
                                            label: "No",
                                        },
                                        {
                                            value: 'yes',
                                            label: 'Yes',
                                        },
                                    ]}
                                    onChangeSelect={e => {
                                        com.touch()
                                        let value = e === "yes"
                                        this.props.changeMainProperty(value, "qualifiesForFHAsecondary")
                                    }}
                                    value={qualifiesForFHAsecondary === true ? "yes" : "no"}
                                    type='radio'
                                    direction="horizontal" />
                            </Form.Group>
                        </Col>
                    </Row>
                }

                <Row >
                    <Col className="pl-0 mt-4" md={6} xs={12}>
                        <Form.Label>What is the estimated home value?</Form.Label>
                        <div>
                            <DollarInput value={appraisal} onChange={e => onAppraisal(e)} />
                        </div>
                    </Col>
                    {(this.props.property.purpose === st.POL_Purchase && true === hascontract) &&
                        <Col className="pl-0 mt-4" md={6} xs={12}>
                            <Form.Label>What is the sales price?</Form.Label>

                            <div>
                                <DollarInput value={salesprice} onChange={e => onSalesPrice(e)} />
                            </div>
                        </Col>}
                </Row>
                <Row className=" mt-4">
                    <Col className="pl-0 " md={6} xs={12}>
                        <Form.Label>{purpose === st.POL_Purchase ? "What is the amount of your down payment?" : (purpose === st.POL_Refinance ? "What is the amount of your principal paydown?" : "What is your cashout amount?")}</Form.Label>
                        <div>
                            <DollarInput
                                value={purpose === st.POL_Purchase ? downpayment : (purpose === st.POL_Refinance ? paydown : cashout)}
                                onChange={(e) => {
                                    this.props.updateSelectField(com.safeParseInt(e), purpose === st.POL_Purchase ? "downpayment" : (purpose === st.POL_Refinance ? "paydown" : "cashout"))
                                    if (purpose === st.POL_Purchase) {
                                        this.props.updateSelectField(com.safeParseInt(salesprice) - com.safeParseInt(e), "loansize")
                                    }
                                }}
                            />
                        </div>
                    </Col>
                    <Col className="pl-0 " md={6} xs={12}>
                    </Col>
                </Row>
            </div>
        )
    }
}

DetailProperty = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DetailProperty)