import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import { Redirect } from 'react-router-dom'
import {
    UpdateChangeProgressLastVisited,
    UpdateChangeProgressType,
} from './Store/progress'
import { saveState } from './Store/index'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as com from './Common'
import dayjs from 'dayjs'
import {
    Modal,
    Button,
    Row,
    Col,
    Skeleton,
    Upload,
    message,
    Popconfirm,
    Collapse,
    Progress,
} from 'antd'
import { UploadOutlined, CloseOutlined, DownOutlined } from '@ant-design/icons'
import PDFPreview from './Application/PDFPreview'
import './Tasks.css'

const { Dragger } = Upload

const mapStateToProps = (state) => {
    return {
        progress: state.progress,
        application: state.application,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateChangeProgressLastVisited: (step) => {
        dispatch(UpdateChangeProgressLastVisited(step))
    },
    updateChangeProgressType: (step, stage) => {
        dispatch(UpdateChangeProgressType(step, stage))
    },
})
class Tasks extends Component {
    constructor(props) {
        super(props)

        this.state = {
            documents: [],
            url: '',
            download: '',
            redirect: '',
            show: false,
            modalTitle: '',
            index: 0,
            id: 0,
            taskid: '',
            forwhom: '',
            loading: false,
            submitLoading: false,
            uploadLoading: false,
            showclosed: false,
            expandedRowKeys: [],
            showUploadModal: false,
            docID: '',
            uploadFileList: [],
            pdfVisible: false,
            pdfFile: '',
            fileName: '',
            signMarks: [],
            activeKeys: [],
        }

        this.aref = React.createRef()
    }

    componentDidMount() {
        this.props.updateChangeProgressType('tasks')
        const now = new Date()
        const snow = now.toUTCString()
        this.props.updateChangeProgressLastVisited(snow)
        saveState()
        this.reload()
    }

    mergeTasks = (list) => {
        const updatedTaskList = []
        const tasksByType = {}
        list.forEach((task) => {
            if (!(task.TaskType in tasksByType)) {
                tasksByType[task.TaskType] = []
            }
            tasksByType[task.TaskType].push(task)
        })
        for (const taskType in tasksByType) {
            const tasks = tasksByType[taskType]

            tasks.sort((a, b) => b.ID - a.ID)

            for (let i = 0; i < tasks.length; i++) {
                const currentTask = tasks[i]
                const mergedTask = { ...currentTask }

                if (taskType === 'action') {
                    for (let j = i + 1; j < tasks.length; j++) {
                        const nextTask = tasks[j]
                        mergedTask.Items.push(...nextTask.Items)
                    }
                } else {
                    for (let j = i + 1; j < tasks.length; j++) {
                        const nextTask = tasks[j]
                        mergedTask.Documents.push(...nextTask.Documents)
                    }
                }
                updatedTaskList.push(mergedTask)
                i = tasks.length
            }
        }
        return updatedTaskList
    }

    reload = () => {
        this.setState({ loading: true })

        const token = sessionStorage.getItem('ZeitroA')
        fetch('/auth/tasks', {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
                Cache: 'no-cache',
            },
        })
            .then((response) => {
                if (response.status !== 200) {
                    console.warn('Looks like there was a problem. Status Code: ' + response.status)
                    window.document.dispatchEvent(
                        new Event('reauthenticate'),
                        ''
                    )
                    this.setState({ loading: false })
                    return
                }
                // Examine the text in the response
                response.json().then((js) => {
                    if (js.Status !== 'OK') {
                        console.warn('Error: ' + js.Text)
                        window.document.dispatchEvent(
                            new Event('reauthenticate'),
                            ''
                        )
                    } else {
                        const data = js.Tasks
                        const filterData = data.filter((item) => item.TaskType !== 'upload')
                        const tasks = filterData.length > 0 ? this.mergeTasks(filterData) : []
                        const taskTypes = ['action', 'sign']
                        const missingTaskTypes = taskTypes.filter(
                            (type) =>
                                !tasks.some((task) => task.TaskType === type)
                        )
                        missingTaskTypes.forEach((type) => {
                            tasks.push({
                                Created: '',
                                Deadline: '',
                                Documents: [],
                                ForWhom: '',
                                ID: '',
                                Items: [],
                                Status: 'open',
                                TaskID: 'other',
                                TaskType: type,
                                name: `New ${type} Task`,
                            })
                        })

                        const docs = tasks.map((s) => {
                            let dd = com.timeGoToJS(s.Created)
                            const dt = new Date(dd)
                            dd = com.timeGoToJS(s.Deadline)
                            const deadline = new Date(dd)
                            return {
                                rand: Math.random(),
                                id: s.ID,
                                name: s.TaskName,
                                created: dt.toLocaleString('en-US'),
                                status: s.Status,
                                forwhom: s.ForWhom,
                                tasktype: s.TaskType,
                                taskid: s.TaskID,
                                deadline: deadline,
                                documents: s.Documents,
                                items: s.Items,
                            }
                        })
                        this.rawdocs = docs
                        this.loaddocs(docs, this.state.showclosed)
                    }
                    this.setState({ loading: false })
                })
            })
            .catch((err) => {
                this.setState({ loading: false })
                console.error('Fetch Error :', err)
            })
    }

    loaddocs = (docs, showclosed) => {
        if (!showclosed) {
            // let newdocs = []
            // docs.forEach( doc => {
            //     if(doc.status !== "open")
            //         return
            //     newdocs.push(doc)
            // } )
            // docs = newdocs
        }
        this.setState({ documents: docs })
    }

    forWhomDecoded = (_forwhom) => {
        const { borrower, coborrower } = this.props.application

        let forwhom = ''
        if (_forwhom === 'joint' && coborrower.firstname === '' && coborrower.lastname === '') {
            _forwhom = 'borrower'
        }

        try {
            switch (_forwhom) {
                case 'borrower':
                    forwhom = borrower.firstname + ' ' + borrower.lastname
                    break
                case 'coborrower':
                    forwhom = coborrower.firstname + ' ' + coborrower.lastname
                    break
                case 'joint':
                    forwhom = borrower.firstname + ' ' + borrower.lastname + ' and ' + coborrower.firstname + ' ' + coborrower.lastname
                    break
                default:
                    forwhom = _forwhom
                    break
            }
        } catch (x) {
            forwhom = _forwhom
        }

        return forwhom
    }

    showSign = (index) => {
        const { taskid, id } = this.state.documents[index]
        this.setState({
            redirect: (
                <Redirect to={'/app/sign/' + taskid + '/' + id} />
            ),
        })
    }

    documentsJSON = (document, index) => {
        const { name, deadline, status, id, tasktype, documents, items } = document
        const forwhom = this.forWhomDecoded(document.forwhom)

        return {
            action: name,
            forwhom,
            _forwhom: document.forwhom,
            duedate: deadline.toLocaleDateString(),
            deadline: deadline,
            status: status,
            id: id,
            index: index,
            tasktype: tasktype,
            documents: documents,
            items: items,
        }
    }

    onshowclosed = (checked) => {
        this.setState({ showclosed: !checked })
        this.loaddocs(this.rawdocs, !checked)
    }

    handleExpand = (record) => {
        const expandedRowKey = [...this.state.expandedRowKeys]
        const spliceIndex = expandedRowKey.findIndex((item) => record.id === item)
        if (spliceIndex > -1) {
            expandedRowKey.splice(spliceIndex, 1)
        } else {
            expandedRowKey.push(record.id)
        }
        this.setState({
            expandedRowKeys: expandedRowKey,
        })
    }

    documentName = (task, doc) => {
        let type = ''
        if (task.tasktype === 'upload') {
            type = 'Document upload'
        } else if (task.tasktype === 'action') {
            type = 'Action items'
        } else {
            type = 'Document sign'
        }

        return (
            <span style={{ fontSize: 16, color: '#222' }}>
                {type}{' '}
                <span style={{ fontWeight: 500 }}>
                    |{' '}
                    {task.tasktype === 'action' ? doc.Description : doc.FileName}
                </span>
            </span>
        )
    }

    showUpload = (doc) => {
        this.setState({
            showUploadModal: true,
            uploadFileList: [],
            modalTitle: doc.FileName,
            docID: doc.ID,
            uploadLoading: false,
        })
    }

    base64ToFile = (base64String, fileName, contentType) => {
        const byteCharacters = atob(base64String)
        const byteArrays = []
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512)
            const byteNumbers = new Array(slice.length)

            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i)
            }

            const byteArray = new Uint8Array(byteNumbers)
            byteArrays.push(byteArray)
        }
        const file = new File(byteArrays, fileName, { type: contentType })
        return file
    }

    uploadSave = () => {
        const token = sessionStorage.getItem('ZeitroA')
        const formData = new FormData()
        for (let i = 0; i < this.state.uploadFileList.length; i++) {
            formData.append(
                'upload',
                this.state.uploadFileList[i].originFileObj
            )
        }
        this.setState({ uploadLoading: true })
        fetch('/docs/uploaddocumentfortask', {
            cache: 'no-cache',
            method: 'POST',
            body: formData,
            headers: {
                Authorization: 'Bearer ' + token,
                Cache: 'no-cache',
                'X-DocID': this.state.docID,
            },
        })
            .then((response) => {
                this.setState({ uploadLoading: true })
                if (response.status !== 200) {
                    console.warn('Looks like there was a problem. Status Code: ' + response.status)
                    return
                }
                // Examine the text in the response
                response.json().then((js) => {
                    if (js.Status === 'OK') {
                        this.uploadCancel()
                        this.reload()
                        message.success(js.Text)
                    } else {
                        message.error(js.Text)
                    }
                })
            })
            .catch((error) => {
                // Not Production ready! Do some error handling here instead...
                this.setState({ uploadLoading: true })
            })
    }

    uploadCancel = () => {
        this.setState({ showUploadModal: false })
    }

    deleteDoc = (id) => {
        const token = sessionStorage.getItem('ZeitroA')
        fetch('/docs/deletedocumentfortask', {
            cache: 'no-cache',
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token,
                Cache: 'no-cache',
                'X-DocID': id,
            },
        })
            .then((response) => {
                if (response.status !== 200) {
                    console.warn('Looks like there was a problem. Status Code: ' + response.status)
                    return
                }
                // Examine the text in the response
                response.json().then((js) => {
                    if (js.Status === 'OK') {
                        this.reload()
                        message.success(js.Text)
                    } else {
                        message.error(js.Text)
                    }
                })
            })
            .catch((error) => {
                // Not Production ready! Do some error handling here instead...
            })
    }

    handleDelete = (index) => {
        const updatedTaskDocuments = [...this.state.uploadFileList]
        updatedTaskDocuments.splice(index, 1)
        this.setState({
            uploadFileList: updatedTaskDocuments,
        })
    }

    submitTask = (task) => {
        if (task.tasktype === 'upload') {
            const uploadAll = task.documents.every((item) => item.Data)
            if (!uploadAll) {
                message.error('Please upload all the required documents')
                return
            }
        }
        if (task.tasktype === 'sign') {
            const signatureAll = task.documents.every((item) => item.Uploaded !== '')
            if (!signatureAll) {
                message.error('Please signature all the required documents')
                return
            }
        }
        this.setState({ submitLoading: true })
        const token = sessionStorage.getItem('ZeitroA')
        fetch('/auth/submittask', {
            cache: 'no-cache',
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
                Cache: 'no-cache',
                'X-TaskID': task.id,
            },
        })
            .then((response) => {
                this.setState({ submitLoading: false })
                // if (response.status !== 200) {
                //     console.log('Looks like there was a problem. Status Code: ' +
                //         response.status);
                //     return;
                // }
                // Examine the text in the response
                response
                    .json()
                    .then((js) => {
                        if (js.Status === 'OK') {
                            this.reload()
                            Modal.success({
                                content: 'Thank you for successfully completing your assigned task. We appreciate your promptness. Please be informed that your loan officer has been notified of your completion. They will be in touch shortly to guide you through the next steps of your loan process.',
                                icon: null,
                            })
                        } else {
                            message.error(js.Text)
                        }
                    })
                    .catch((error) => {
                        this.reload()
                        Modal.success({
                            content: 'Thank you for successfully completing your assigned task. We appreciate your promptness. Please be informed that your loan officer has been notified of your completion. They will be in touch shortly to guide you through the next steps of your loan process.',
                            icon: null,
                        })
                    })
            })
            .catch((error) => {
                // Not Production ready! Do some error handling here instead...
                this.setState({ submitLoading: false })
            })
    }

    convertBytes = (bytes) => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
        if (bytes === 0) return '0 Byte'
        const i = Math.floor(Math.log(bytes) / Math.log(1024))
        const size = parseFloat((bytes / Math.pow(1024, i)).toFixed(2))
        return `${size} ${sizes[i]}`
    }

    fileType = (type) => {
        const fileType = type.split('/')[1]
        return fileType
    }

    fileIcon = (type) => {
        const fileType = type.split('/')[1]
        if (fileType === 'zip') {
            return '/images/zip-icon.svg'
        } else if (fileType === 'pdf') {
            return '/images/pdf-icon.svg'
        } else {
            return '/images/image-icon.svg'
        }
    }

    base64ToArrayBuffer = (base64) => {
        const binaryString = atob(base64)
        const len = binaryString.length
        const bytes = new Uint8Array(len)

        for (let i = 0; i < len; ++i) {
            bytes[i] = binaryString.charCodeAt(i)
        }

        return bytes.buffer
    }

    signature = (doc) => {
        const extraInfo = JSON.parse(doc.ExtraInfo)
        const scale = +extraInfo.signData.scale
        const pdf = this.base64ToArrayBuffer(doc.Data)
        const transformedMarkList = extraInfo.signData.markList.flatMap(
            (item) =>
                Object.entries(item).flatMap(([key, value]) =>
                    Array.isArray(value)
                        ? value.map((style, index) => ({
                              page: item.page,
                              signid: `page${item.page + 1}signature`,
                              pageName: `page${item.page + 1}`,
                              type: key,
                              key: `borrower.signature${
                                  key === 'date' ? '.date' : ''
                              }`,
                              style: {
                                  left: style.x / scale,
                                  top: style.y / scale,
                                  width: style.width / scale,
                                  height: style.height / scale,
                              },
                          }))
                        : []
                )
        )
        this.setState({
            signMarks: transformedMarkList,
            pdfFile: pdf,
            fileName: doc.FileName,
            docID: doc.ID,
            pdfVisible: true,
        })
    }

    onSubmit = (pdfBlob) => {
        const pdfFile = new File([pdfBlob], this.state.fileName, {
            type: 'application/pdf',
        })
        const token = sessionStorage.getItem('ZeitroA')
        const formData = new FormData()
        formData.append('upload', pdfFile)
        fetch('/docs/uploaddocumentfortask', {
            cache: 'no-cache',
            method: 'POST',
            body: formData,
            headers: {
                Authorization: 'Bearer ' + token,
                Cache: 'no-cache',
                'X-DocID': this.state.docID,
            },
        })
            .then((response) => {
                if (response.status !== 200) {
                    console.warn('Looks like there was a problem. Status Code: ' + response.status)
                    return
                }
                // Examine the text in the response
                response.json().then((js) => {
                    if (js.Status === 'OK') {
                        this.setState({ pdfVisible: false })
                        this.reload()
                        message.success(js.Text)
                    } else {
                        message.error(js.Text)
                    }
                })
            })
            .catch((error) => {
                // Not Production ready! Do some error handling here instead...
                message.error(error)
            })
    }

    expandedContent = (record) => {
        const documents = record.tasktype === 'action' ? record.items : record.documents

        return (
            <div className="expanded-content">
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: 8,
                    }}
                >
                    <span style={{ fontSize: 16, color: '#6E6E70' }}>
                        Required actions({documents.length})
                    </span>
                    {record.status !== 'closed' && record.tasktype === 'action'
                        ? record.items.length > 0
                        : record.documents.length > 0 && (
                              <Button
                                  className="submit-btn"
                                  loading={this.state.submitLoading}
                                  onClick={() => this.submitTask(record)}
                              >
                                  {record.tasktype === 'action' ? 'Mark as done' : 'Submit'}
                              </Button>
                          )}
                </div>
                <div className="item-table">
                    {record.tasktype !== 'sign'
                        ? documents.map((item) => (
                              <div
                                  className="document-item"
                                  style={{
                                      padding:
                                          record.tasktype === 'upload' &&
                                          item.Uploaded === ''
                                              ? '16px 42px 16px 22px'
                                              : '16px 20px 16px 22px',
                                  }}
                                  key={item.ID}
                              >
                                  <div className="document-info">
                                      <div className="info-top">
                                          <div>
                                              {this.documentName(record, item)}
                                          </div>
                                          {record.tasktype === 'upload' &&
                                              item.Uploaded === '' && (
                                                  <span>Not upload</span>
                                              )}
                                      </div>
                                      {record.status !== 'closed' &&
                                          item.Data && (
                                              <div className="doc-file">
                                                  <div className="file">
                                                      <img
                                                          style={{ width: 22, height: 22 }}
                                                          className="file-icon"
                                                          src={this.fileIcon(item.MimeType)}
                                                          alt='file'
                                                      />
                                                      <div className="file-info">
                                                          <div className="file-name">
                                                              {item.FileName}.
                                                              {this.fileType(item.MimeType)}
                                                          </div>
                                                          <div className="file-size">
                                                              {this.convertBytes(
                                                                  this.base64ToFile(
                                                                      item.Data,
                                                                      item.FileName,
                                                                      item.MimeType
                                                                  ).size
                                                              )}
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <Popconfirm
                                                      title="Delete the document"
                                                      description="Are you sure to delete this document?"
                                                      onConfirm={() => this.deleteDoc(item.ID)}
                                                      okText="Yes"
                                                      cancelText="No"
                                                  >
                                                      <div className="file-del">
                                                          <img
                                                              style={{ cursor: 'pointer' }}
                                                              src="/images/delete-icon.svg"
                                                              alt='delete'
                                                          />
                                                      </div>
                                                  </Popconfirm>
                                              </div>
                                          )}
                                  </div>
                                  {record.tasktype === 'upload' &&
                                      item.Uploaded === '' && (
                                          <UploadOutlined
                                              style={{ fontSize: 20, cursor: 'pointer' }}
                                              onClick={() => this.showUpload(item)}
                                          />
                                      )}
                                  {record.status === 'closed' && item.Data && (
                                      <div className="upload-file">
                                          <img
                                              style={{ width: 22, height: 22 }}
                                              className="file-icon"
                                              src={this.fileIcon(item.MimeType)}
                                              alt='file'
                                          />
                                          <span>
                                              {item.FileName}.
                                              {this.fileType(item.MimeType)}
                                          </span>
                                      </div>
                                  )}
                              </div>
                          ))
                        : documents.map((item) => (
                              <div className="sign-document">
                                  <div className="sign-name">
                                      {this.documentName(record, item)}
                                  </div>
                                  <div className="sign-duedate">
                                      {dayjs(item.DueDate).format('MM/DD/YYYY')}
                                  </div>
                                  <div className="sign-file">
                                      <div className="upload-file">
                                          <img
                                              style={{ width: 22, height: 22 }}
                                              className="file-icon"
                                              src="/images/pdf-icon.svg"
                                              alt='file'
                                          />
                                          <span>{item.FileName}</span>
                                      </div>
                                      {item.Uploaded === '' && (
                                          <Button
                                              className="sign-btn"
                                              onClick={() =>
                                                  this.signature(item)
                                              }
                                          >
                                              Sign
                                          </Button>
                                      )}
                                  </div>
                              </div>
                          ))}
                </div>
            </div>
        )
    }

    mapType = (type) => {
        const map = {
            upload: 'Document upload',
            sign: 'Document sign',
            action: 'Customized task',
        }
        return map[type]
    }

    noTasks = (item) => {
        if (item.tasktype === 'action') {
            return item.items.length === 0
        } else {
            return item.documents.length === 0
        }
    }

    changeCollapse = (e) => {
        this.setState({ activeKeys: e })
    }

    customHeader = (item) => {
        let percent = 0
        let completed = 0
        if (item.tasktype === 'upload') {
            completed = item.documents.filter((file) => file.Data).length
            percent = (completed / item.documents.length) * 100
        } else {
            if (item.tasktype === 'action') {
                completed = item.status === 'closed' ? item.items.length : 0
            } else {
                completed = item.status === 'closed' ? item.documents.length : 0
            }
            percent = item.status === 'closed' ? 100 : 0
        }

        return (
            <div className="task-collapse-header">
                <div className="task-type">{this.mapType(item.tasktype)}</div>
                <div
                    className={`task-update ${
                        this.noTasks(item) ? 'no-tasks-text' : ''
                    }`}
                >
                    Latest update on{' '}
                    {this.noTasks(item) ? (
                        <span>--</span>
                    ) : (
                        dayjs(item.deadline).format('MM/DD/YYYY')
                    )}
                </div>
                <div className="task-completed">
                    <Progress
                        className="task-progress"
                        type="circle"
                        percent={percent}
                        size={36}
                        strokeWidth={14}
                        trailColor="#E3EAFD"
                        strokeColor="#325CEB"
                        format={() => (
                            <span style={{ fontSize: 14, color: '#325CEB', fontWeight: 700 }} >
                                {completed}
                            </span>
                        )}
                    />
                    <span className="task-completed-num">
                        {this.noTasks(item) ? (
                            <span className="no-tasks-text">No tasks</span>
                        ) : (
                            `${completed} / ${ item.tasktype === 'action' ? item.items.length : item.documents.length } COMPLETED`
                        )}
                    </span>
                </div>
            </div>
        )
    }
    render() {
        const documentTotal = () => {
            let totalLength = 0
            if (this.state.documents.length > 0) {
                this.state.documents.map(this.documentsJSON).forEach((task) => {
                    if (task.tasktype === 'action') {
                        totalLength += task.items.length
                    } else {
                        totalLength += task.documents.length
                    }
                })
            }
            return totalLength
        }

        const showTaskTable = () => {
            return (
                <div className="currentTasksWrap mt-3">
                    <div className="task-content">
                        <Collapse
                            className="task-collapse"
                            activeKey={this.state.activeKeys}
                            collapsible="icon"
                            expandIcon={({ isActive }) => (
                                <DownOutlined rotate={isActive ? 180 : 0} />
                            )}
                            expandIconPosition="end"
                            onChange={this.changeCollapse}
                        >
                            {this.state.documents
                                .map(this.documentsJSON)
                                .map((item) => (
                                    <Collapse.Panel
                                        className="task-collapse-item"
                                        header={this.customHeader(item)}
                                        key={item.id}
                                    >
                                        <div className="table-wrap">
                                            {this.expandedContent(item)}
                                        </div>
                                    </Collapse.Panel>
                                ))}
                        </Collapse>
                    </div>
                </div>
            )
        }

        const draggerProps = {
            name: 'file',
            className: 'file-upload',
            // maxCount: 1,
            fileList: this.state.uploadFileList,
            showUploadList: false,
            onChange: (info) => {
                const { status } = info.file
                if (status !== 'uploading') {
                    console.log(info.file, info.fileList)
                }
                if (status === 'done') {
                    console.log(info.file.name)
                } else if (status === 'error') {
                    console.log(`${info.file.name} file upload failed.`)
                }
                const fileList = [...info.fileList]
                this.setState({ uploadFileList: fileList })
            },
            onDrop: (e) => {
                console.log('Dropped files', e.dataTransfer.files)
            },
            beforeUpload: (file) => {
                const allowedFileTypes = [
                    'image/jpeg',
                    'image/png',
                    'application/pdf',
                ]
                const fileType = file.type
                const isAllowed = allowedFileTypes.includes(fileType)
                if (!isAllowed) {
                    message.warning('You can only upload image and PDF files.')
                    return Upload.LIST_IGNORE
                }
                return false
            },
        }

        return (
            <div className="text-left mb-3 newInterviewPaneEmployment">
                {this.state.redirect}
                <Modal
                    className="upload-file-modal"
                    width={715}
                    centered={true}
                    closable={false}
                    open={this.state.showUploadModal}
                    title=""
                    footer={[
                        <Button
                            className="upload-btn-cancel"
                            onClick={this.uploadCancel}
                        >
                            Cancel
                        </Button>,
                        <Button
                            className="upload-btn-save"
                            type="primary"
                            loading={this.state.uploadLoading}
                            disabled={this.state.uploadFileList.length === 0}
                            onClick={this.uploadSave}
                        >
                            Add files
                        </Button>,
                    ]}
                >
                    {' '}
                    <div
                        style={{
                            padding: '40px 40px 8px',
                            borderBottom: '1px solid #ECECEC',
                            marginBottom: '30px',
                        }}
                    >
                        <CloseOutlined
                            style={{
                                fontSize: 18,
                                marginBottom: 16,
                                color: '#6E6E70',
                                cursor: 'pointer',
                            }}
                            onClick={this.uploadCancel}
                        />
                        <div className="upload-modal-title">
                            Upload files | <span>{this.state.modalTitle}</span>
                        </div>
                    </div>
                    <div style={{ padding: '0 40px' }}>
                        <Dragger {...draggerProps}>
                            <div
                                style={{
                                    height: 88,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}
                            >
                                <div className="ant-upload-text">
                                    <UploadOutlined
                                        style={{
                                            fontSize: 24,
                                            color: '#6E6E70',
                                        }}
                                    />
                                </div>
                                <div className="ant-upload-hint">
                                    Drag & Drop or{' '}
                                    <span
                                        style={{
                                            fontWeight: 500,
                                            color: '#325CEB',
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        Choose File
                                    </span>{' '}
                                    to upload
                                </div>
                            </div>
                        </Dragger>
                    </div>
                    <div className="upload-file-list">
                        <Row gutter={20}>
                            {this.state.uploadFileList.map((item, index) => (
                                <Col md={12} span={24} key={item.uid}>
                                    <div className="upload-file-item">
                                        <div className="file">
                                            <img
                                                className="file-icon"
                                                src={
                                                    item.type ===
                                                    'application/pdf'
                                                        ? '/images/pdf-icon.svg'
                                                        : '/images/image-icon.svg'
                                                }
                                                alt="file"
                                            />
                                            <div className="file-info">
                                                <div className="file-name">
                                                    {item.name}
                                                </div>
                                                <div className="file-size">
                                                    {this.convertBytes(
                                                        item.size
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="file-del">
                                            <CloseOutlined
                                                style={{
                                                    fontSize: 16,
                                                    color: '#222',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() =>
                                                    this.handleDelete(index)
                                                }
                                            />
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </Modal>

                <a
                    hidden
                    ref={this.aref}
                    href={this.state.url}
                    download={this.state.download}
                >
                    &nbsp;
                </a>
                <Row>
                    <Col>
                        <div className="page-header">
                            <span className="page-header-primary">
                                {documentTotal()} Task
                            </span>
                        </div>
                    </Col>
                </Row>
                {this.state.pdfVisible ? (
                    <PDFPreview
                        pdf={this.state.pdfFile}
                        fileName={this.state.fileName}
                        signMarks={this.state.signMarks}
                        visible={this.state.pdfVisible}
                        onSubmit={this.onSubmit}
                        onClose={() => this.setState({ pdfVisible: false })}
                    />
                ) : null}
                {this.state.loading ? (
                    <Skeleton className="mt-3" active />
                ) : (
                    <div>
                        {this.state.documents.length === 0 ? (
                            <div className="infoTip mt-3">
                                There are currently no outstanding tasks
                            </div>
                        ) : (
                            <div>{showTaskTable()}</div>
                        )}
                    </div>
                )}
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tasks))
