import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import LandingMenu from './Landing/LandingMenu'
import LandingCopyright from './Landing/LandingCopyright'
import { withRouter } from "react-router-dom";
import * as com from './Common'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'
import { Link } from 'react-router-dom'


import Helmet from 'react-helmet'
import Modal from 'react-bootstrap/Modal'

import Recaptcha from './Recaptcha'


class CoborrowerExists extends Component {
    constructor(props) {
        super(props)
        this.form = React.createRef();
        this.password = React.createRef();

        this.captcha = React.createRef();        
        this.state = {
            show: false,
            password: "",
            password1: "",
            message: "",
            validated: false,
            captchasolved: true,
            passwordCheckReady: false,
            text: "",
            bgcolor: "rgb(232,235,239)",
            text2: "",
            bgcolor2: "rgb(232,235,239)"
        }
        window.scrollTo(0, 0);
        this.token = this.props.match.params["token"]
        this.email = com.hex2ascii(this.props.match.params["email"])
        let names = com.hex2ascii(this.props.match.params["names"])
        this.names = names.split('|')
        this.you = this.names[0] + " " + this.names[1]
        this.borrower = this.names[2] + " " + this.names[3]
    }
    componentDidMount() {
        com.loadPasswordCheck(() => {
            // Work to do after the library loads.
      
            this.setState({ passwordCheckReady: true });
          });           
        this.timer = window.setInterval(this.checkCaptcha, 1000)
    }
    componentWillUnmount() {
        window.clearInterval(this.timer)
    }    
    validateForm() {

        return this.state.password.length > 8;
    }
    handleSubmit = event => {
        this.buttonpressed = true;

        let captcha = (this.local) ? "XXXX" : window.grecaptcha.getResponse()

        if (!captcha || captcha === "") {
            this.setState({ captchasolved: false })
            this["state"].captchasolved = false
            console.log("captcha invalid!")
            this.setState({ validated: true })
            this.captcha.current.setCustomValidity("Please solve the Captcha")
            event.preventDefault();
            this.captcha.current.required = true
            return false
        } else {
            this.setState({ captchasolved: true })
            this["state"].captchasolved = true
            this.captcha.current.setCustomValidity("")
            this.captcha.current.required = false
        }

        if (this.form.current.reportValidity() === false) {
            event.preventDefault();
            this.setState({ validated: true })

            return false
        }
        event.preventDefault();
        this.setState({ validated: false })
        event.stopPropagation();

        const data = new FormData(event.target);
        this.signin(event, data)
    }
    signin = (event, data) => {
        let captcha = this.local ? "XXXX" : window.grecaptcha.getResponse()
        if (!captcha || "" === captcha) {
            this.setState({ captchasolved: false })
            this["state"].captchasolved = false
            console.log("captcha invalid!")
            this.setState({ validated: true })
            this.captcha.current.setCustomValidity("Please solve the Captcha")
            return
        }
        let tosend = 
            JSON.stringify({
                captcha: captcha, email: this.email, token: this.token, password: data.get("password")
            })

        fetch('/auth/signincoborrower', {
            method: 'POST',
            body: tosend,
            headers: { 'Content-Type': 'application/json' },
        }).then(
            response => {
                if (response.status !== 200) {
                    this.setState({ message: 'Looks like there was a problem. Status Code: ' +
                    response.status, show: true })
                    window.grecaptcha.reset()
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                        this.setState({ message: js.Text, show: true })
                        sessionStorage.removeItem("ZeitroA")
                        sessionStorage.removeItem("state")
                        if(!this.local && window.grecaptcha)
                            window.grecaptcha.reset()
                    } else {
                        sessionStorage.setItem("ZeitroA", js.Token)

                        sessionStorage.setItem("state", js.State)
                        sessionStorage.setItem("originalstate", js.State)

                        window.location.href = "/app/interview/main"
                    }
                });
            }
        ).catch(  (err) => {
            console.log('Fetch Error :', err);
            this.setState({ message: "Our servers are down. Please check later!", show: true })

            if(window.grecaptcha) {
                window.grecaptcha.reset()
            }
        });        
    }
    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }
    validatePassword = () => {

    }
    onPassword = e => {
        this.setState({ password: e.target.value })
        if (this.state.signin)
            return

        if(this.state.passwordCheckReady) {
       
            let ret = window.zxcvbn(e.target.value)

            switch(ret.score) {
                case 0:
                    this.setState({text: "Insecure", bgcolor: "rgb(255,64,64)"})
                    break;
                case 1:
                    this.setState({text: "Too Weak", bgcolor: "rgb(255,128,64)"})
                    break;
                case 2:
                    this.setState({text: "Breakable", bgcolor: "rgb(160,160,64)"})
                    break;
                case 3:
                    this.setState({text: "Good enough", bgcolor: "rgb(128,180,64)"})
                    break;
                case 4:
                    this.setState({text: "Strong!!!", bgcolor: "rgb(64,180,64)"})
                    break;
                default:
                    break;            
            }
            if(this.state.password1  && this.state.password1 !== "") {
                if (e.target.value !== this.state.password1 ) {
                    this.password1.current.setCustomValidity("Passwords should match!")
                    this.setState({text2: "Mismatch", bgcolor2: "rgb(255,64,64)"})
                } else {
                    this.setState({text2: "Identical!", bgcolor2: "rgb(64,180,64)"})
                }
            }
            if(ret.score < 3) {
                this.password.current.setCustomValidity("Password is too weak. Try longer mix of letters, numbers and special characters")
                console.log("Weak score " + ret.score)
            } else {
                this.password.current.setCustomValidity("")
            }
        }
    }
    onPassword1 = e => {
        this.setState({ password1: e.target.value })
        if (e.target.value !== this.state.password) {
            this.setState({ validated: true })            
            this.password1.current.setCustomValidity("Passwords should match!")
            this.setState({text2: "Mismatch", bgcolor2: "rgb(255,64,64)"})
        } else {
            this.password1.current.setCustomValidity("")
            this.setState({text2: "Identical!", bgcolor2: "rgb(64,180,64)"})
        }
    
    }

    checkCaptcha = (event) => {
        if (!window.grecaptcha || !this.buttonpressed)
            return false;

        let captcha = this.local ? "XXX" : window.grecaptcha.getResponse()
        if (!captcha || captcha === "") {
            this.setState({ captchasolved: false })
            this["state"]["captchasolved"] = false
 
            this.setState({ validated: true })
            this.captcha.current.setCustomValidity("Please solve the Captcha")
            if (event) {
                event.preventDefault();
                event.stopPropagation();
            }
            this.captcha.current.required = true
            return false
        } else {
            this.setState({ captchasolved: true })
            this["state"].captchasolved = true
            this.captcha.current.setCustomValidity("")
            this.captcha.current.required = false
        }
        return true;
    }    
    handleClose = () => { this.setState({ show: false }) }
    render = () => {
        let dummy = (e) => {

        }

        return (
            <div>
                <LandingMenu />
                <div id="landing1" className="text-center my-4 pb-5" >
                    <div className="text-center ">
                        <div className="loginbox mt-3  text-center"  >
                            <div className="pt-3 mb-3 sectionquestion">Please log into your existing account to start co-applicant application.</div>

                            <Helmet>
                                <title>Sign In</title>
                            </Helmet>
                            <Modal show={this.state.show} onHide={this.handleClose} onShow={this.onShow}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{'Sign In Failed'}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>{this.state.message}</Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.handleClose}>Close</Button>
                                </Modal.Footer>
                            </Modal>

                            <div className="text-center px-5 pb-5">
                                <Form autoComplete="off" ref={this.form} onSubmit={this.handleSubmit} noValidate validated={this.state.validated}>
                                    <Row className="mt-0 pt-4 ">
                                        <Col className="text-left">
                                            <Form.Group controlId="email"  >
                                                <Form.Label className="text-left" >Login as:</Form.Label>
                                                <div style={{fontWeight: 'bold', fontSize:'1.1em'}} >{this.email}</div>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className="  text-left">
                                            <Form.Group controlId="password"  >
                                                <Form.Label className="text-left" >Password:</Form.Label>

                                                <InputGroup size="sm" >
                                                    <Form.Control
                                                        size="sm"
                                                        name="password"
                                                        ref={this.password}
                                                        required
                                                        autoComplete="new-password"
                                                        value={this.state.password}
                                                        onChange={this.onPassword}
                                                        type="password"
                                                        pattern="^.+$"
                                                    //pattern={this.pattern}
                                                    />
                                                    
                                                    <Form.Control.Feedback type="invalid" >
                                                        Please enter password
                                                    </Form.Control.Feedback>

                                                </InputGroup >

                                            </Form.Group>
                                        </Col>
                                    </Row>


                                    <Row className="text-center mt-2  ">
                                        <Col className="text-left">
                                            {(this.local) ? "" : <Recaptcha />}
                                            <Form.Group controlId="hackid" >
                                                <Form.Control
                                                    size="sm"
                                                    ref={this.captcha}
                                                    required={!this.state.captchasolved}
                                                    value=""
                                                    name="hack"
                                                    hidden={true}
                                                    type="text"
                                                    onChange={dummy}

                                                />
                                                
                                                <Form.Control.Feedback type="invalid" >
                                                    Please solve the Captcha!
                                </Form.Control.Feedback>

                                            </Form.Group>

                                        </Col>
                                    </Row>
                                    <Row className="pt-3 ">
                                        <Col >
                                            <Button
                                                type="submit" className="lpbutton" variant="primary"
                                            > Sign Up </Button>
                                        </Col>


                                    </Row>
                                    <Row className="mt-3 ">
                                    </Row>
                                    <div className="pt-4" >
                                    <Link to="/services/forgot-password" >Forgot your password? Click here to reset it, then use the invitation email again.</Link>
                                    </div>

                                </Form>
                            </div>


                        </div>
                    </div>
                </div>
                <LandingCopyright />
            </div>
        )
    }
}

export default withRouter(CoborrowerExists);


