import ReactInputVerificationCode from "react-input-verification-code";

import "./CodeVerification.css";


const CodeVerification = (props) => (
  <div className={props.className}>
    <ReactInputVerificationCode
      autoFocus
      placeholder=""
      onChange={props.onChange}
      value={props.value}
      length={props.length}
    />
  </div>
);

export default CodeVerification;
