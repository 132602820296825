import styled from 'styled-components'

const BaseButtonRoot = styled.button.attrs({
  type: 'button',
})`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  borderradius: 0;
  padding: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  text-decoration: none;
  color: inherit;
  &::-moz-focus-inner: {
    border-style: none;
  }
`

const ButtonBase = (props) => {
  /* eslint-disable-next-line no-unused-vars */
  const { className, children, onClick, ...other } = props
  /* eslint-enable no-unused-vars */
  return (
    <BaseButtonRoot className={className} onClick={onClick}>
      {children}
    </BaseButtonRoot>
  )
}

export default ButtonBase
