import React, { Component } from "react"
import 'bootstrap/dist/css/bootstrap.min.css'

import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import { Row } from "react-bootstrap"
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import * as com from "../Common.js"
import CheckGroup from "../Common/components/CheckGroup"
import { isEmpty } from 'lodash'
import { Select } from "antd"
import { DollarInput } from "../Common/components/DollarInput"

export function getTotalExpenses(who) {
    const getExpence = (value, monthly) => {
        value = parseFloat(value)
        if (monthly === "monthly")
            return value
        else
            return value / 12
    }

    const { purpose } = this.props.application.property
    const { presentaddressownership, expenses } = this.props.application[who]

    let total = 0
    if (purpose === "purchase" && presentaddressownership === "rent") {
        total += getExpence(expenses.currentrent, expenses.currentrentperiod)
    }

    if ((presentaddressownership === "own" && purpose === "purchase") || purpose !== "purchase") {
        total += getExpence(expenses.currentfirstmortgage, expenses.currentfirstmortgageperiod)
        total += getExpence(expenses.currentotherfinancing, expenses.currentotherfinancingperiod)
        total += getExpence(expenses.currenthazardinsurance, expenses.currenthazardinsuranceperiod)
        total += getExpence(expenses.currentmortgageinsurance, expenses.currentmortgageinsuranceperiod)
        total += getExpence(expenses.currentrealestatetaxes, expenses.currentrealestatetaxesperiod)
        total += getExpence(expenses.currentdues, expenses.currentduesperiod)
        total += getExpence(expenses.currentother, expenses.currentotherperiod)
    }

    return com.commaizeFloat(total.toFixed(2))
}

const mapStateToProps = (state) => {
    return {
        application: state.application,
        hascoborrower: state.application.hascoborrower,
    }
}

const mapDispatchToProps = (dispatch) => ({
    changeBorrowerInfoByValue: (val, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(val, who, verb))
    },
    updateExpenses: (event, who, verb) => {
        dispatch(act.UpdateExpenses(event.target.value, who, verb))
    },
    addAlimony: (event, who) => {
        dispatch(act.AddAlimony(event, who))
    },
    removeAlimony: (i, who) => {
        dispatch(act.RemoveAlimony(i, who))
    },
    clearAlimonies: (event, who) => {
        dispatch(act.ClearAlimonies(event, who))
    },
    changeAlimony: (event, i, who) => {
        dispatch(act.ChangeAlimony(event, i, who))
    },
    changeAlimonyField: (value, verb, who, i) => {
        dispatch(act.ChangeAlimonyField(value, verb, who, i))
    },
    changePaymentType: (event, i, who) => {
        dispatch(act.ChangePaymentType(event.target.value, i, who))
    },
})

class Expenses extends Component {
    constructor(props) {
        super(props)

        this.state = {
            validated: false,
        }

        this.isValidated = this.isValidated.bind(this)

        const { who } = this.props
        const { otherloans, borrower, coborrower } = this.props.application
        const { loans } = this.props.application.assetsandliabilities
        const { currentfirstmortgage, currentotherfinancing } = this.props.application[who].expenses

        // see if you need to seed the defaults for
        let firstmortgage = 0
        let otherfinancing = 0

        if (who === com.borrower) {
            if (loans !== null) {
                for (let index = 0; index < loans.length; index++) {
                    const loan = loans[index]
                    // don't want to do this for a coborrower until we see it
                    //if( (loan.attribution & 1) === 0)
                    //    continue
                    if (!loan.creditloantype) continue
                    if (loan.creditloantype.toLowerCase().indexOf("mortgage") >= 0) {
                        if (loan.forsubjectproperty === true) {
                            if (firstmortgage === 0)
                                firstmortgage += parseInt(loan.monthlypayment)
                            else
                                otherfinancing += parseInt(loan.monthlypayment)
                        }
                    } else {
                        if (loan.creditloantype.toLowerCase().indexOf("homeequity") >= 0 ||
                            loan.creditloantype.toLowerCase().indexOf("realestate") >= 0) {
                            if (loan.forsubjectproperty === true)
                                otherfinancing += parseInt(loan.monthlypayment)
                        }
                    }
                }
            }

            for (let i = 0; (otherloans != null) && (i < otherloans.length); i++) {
                const loan = otherloans[i]
                if (loan.forsubjectproperty === true && loan.satisfiedupon === "withproceeds") {
                    if (firstmortgage === 0)
                        firstmortgage += parseInt(loan.monthlypayment)
                    else
                        otherfinancing += parseInt(loan.monthlypayment)
                }
            }

            setTimeout(() => {
                if (currentfirstmortgage === "") {
                    this.props.updateExpenses({ target: { value: firstmortgage.toString() } }, com.borrower, "currentfirstmortgage")
                    this.props.updateExpenses({ target: { value: "monthly" } }, com.borrower, "currentfirstmortgageperiod")
                    if (currentotherfinancing === "") {
                        this.props.updateExpenses({ target: { value: otherfinancing.toString() } }, com.borrower, "currentotherfinancing")
                        this.props.updateExpenses({ target: { value: "monthly" } }, com.borrower, "currentotherfinancingperiod")
                    }
                }
            }, 100)
        }

        if (null === borrower.finances.alimony) {
            this.props.clearAlimonies("", "borrower")
        }
        if (null === coborrower.finances.alimony) {
            this.props.clearAlimonies("", "coborrower")
        }
    }

    componentDidMount() {
        this.completion()
    }

    componentDidUpdate() {
        this.completion()
    }

    componentWillUnmount() { }

    completion = () => {
        const form = this.props.self.current
        const formElements = form?.elements
        if (formElements && formElements.length === 0) {
            sessionStorage.setItem("progressValue", 100)
            return
        }
        if (formElements && formElements.length !== 0) {
            let totalCount = 0
            let fillNum = 0
            for (let i = 0; i < formElements.length; i++) {
                const element = formElements[i]
                if (element.type !== 'button' && element.type !== 'fieldset' && element.type !== 'submit') {
                    totalCount++
                    if (!isEmpty(element.value)) {
                        fillNum++
                    }
                }
            }
            const progressValue = Math.round((fillNum / totalCount) * 100)
            sessionStorage.setItem("progressValue", progressValue)
        }
    }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        }
        return true
    }

    showCoborrower = () => {
        if (this.props.hascoborrower === "withcoborrower" && this.props.application.married)
            return true
        if (this.props.hascoborrower === "multiple" && this.props.application.married)
            return true
        return false
    }

    totalExpenses = getTotalExpenses.bind(this)

    getHeader = () => {
        const { hascoborrower, married } = this.props.application

        if (hascoborrower === "alone") {
            return "Expenses"
        }

        if (hascoborrower === "withcoborrower" && !married) {
            const { firstname, lastname } = this.props.application[this.props.who]
            return (
                <div>
                    <span style={{ color: '#325CEB' }}>
                        {firstname + ' ' + lastname}
                    </span>
                    's Expenses
                </div>
            )
        }

        return "Expenses"
    }

    handleSubmit = e => {
        if (this.props.application[this.props.who].presentaddressownership == null) return false
        return true
    }

    processAlimonies = (val, index) => {
        if (null === val)
            return ""

        const { amount, owedto, whatisit, lessthan10, monthslefttopay, key } = val

        return this.generateAlimony(index, amount, owedto, lessthan10, whatisit, monthslefttopay, key)
    }

    generateAlimony = (index, amount, owedto, lessthan10, whatisit, monthslefttopay, key) => {
        index = parseInt(index)
        const removeAlimony = (event) => {
            com.touch()
            this.props.removeAlimony(index, this.props.who)
        }
        const changeAlimony = (event) => {
            com.touch()
            this.props.changeAlimony(event, this.props.who, index)
        }
        const addAlimony = (event) => {
            com.touch()
            return this.props.addAlimony(event, this.props.who)
        }
        const updateWhat = (event) => {
            com.touch()
            this.props.changePaymentType(event, this.props.who, index)
        }

        const { alimony } = this.props.application[this.props.who].finances

        return (
            <div key={key} className="incard mt-2">
                <Row>
                    <Col xs={12} as="div" className="text-right aligh-top rightmobile">
                        <i hidden={index !== alimony.length - 1} className="far fahover fa-plus-square text-primary aligh-top fa-1x mr-1 divlink" onClick={addAlimony} ></i>
                        <i hidden={index === alimony.length - 1} className="far fahover fa-plus-square aligh-top fa-1x mr-1 transparent"> </i>

                        <i className="far fahover fa-minus-square text-primary aligh-top fa-1x divlink" onClick={removeAlimony} ></i>
                    </Col>
                </Row>
                <Row className="p-0">
                    <Col className="p-0 px-1">
                        <Row >
                            <Col md={6} xs={12} >
                                <Form.Group controlId="type" className="text-left" size="sm" >
                                    <Form.Label className="text-left" >Payment type</Form.Label>
                                    <div>
                                        <Select
                                            style={{ width: "100%", height: 44 }}
                                            value={whatisit}
                                            onChange={(e) => { updateWhat({ target: { value: e } }) }}
                                        >
                                            <option value="alimony">Alimony</option>
                                            <option value="childsupport">Child Support</option>
                                            <option value="maintenancepayments">Separate Maintenance</option>
                                            <option value="jobrelatedexpenses">Job Related Expenses</option>
                                            <option value="other">Other</option>
                                        </Select>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col md={6} xs={12} style={{ size: "4em" }}>
                                <Form.Group controlId="Amount" className="text-left" size="sm" >
                                    <Form.Label className="text-left" >Monthly amount</Form.Label>
                                    <div>
                                        <DollarInput
                                            value={amount}
                                            onChange={(e) => { changeAlimony(e) }}
                                            pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                        />
                                    </div>
                                    <Form.Control.Feedback type="invalid" >
                                        Please provide the name of the bank/credit union.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div >
        )
    }

    render() {
        const { who } = this.props

        const clearAlimony = (event) => {
            com.touch()
            this.props.clearAlimonies(event, who)
        }

        const addAlimony = (event) => {
            com.touch()
            return this.props.addAlimony(event, who)
        }

        const { alimony } = this.props.application[this.props.who].finances

        return (
            <div className=" mb-4 text-left newInterviewPaneEmployment ">
                <div className="prequalInterviewTitle">{this.getHeader()}</div>
                <div className="pt-4  w-600px">
                    <Form.Group className="inputSection">
                        <Form.Label className="d-flex text-wrap">Do you have any additional expenses, including alimony, child support, separate maintenance, and job-related expenses?</Form.Label>
                        <CheckGroup
                            data={[{
                                value: 'false',
                                label: 'No',
                            },
                            {
                                value: 'true',
                                label: 'Yes',
                            },
                            ]}
                            onChangeSelect={e => {
                                if (e === "true") {
                                    addAlimony()
                                } else {
                                    clearAlimony()
                                }
                            }}
                            value={
                                (null != alimony && alimony.length > 0) ? "true" : "false"
                            }
                            type='radio'
                            direction="horizontal" />

                    </Form.Group>
                </div>
                <div>
                    {alimony != null && alimony.map(this.processAlimonies)}
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Expenses)
