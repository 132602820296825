import React, { useState, useLayoutEffect, useRef } from 'react';
import { Modal, Pagination, Button, Image, Row, Col, Select, Dropdown } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Document, Page, pdfjs } from 'react-pdf';
import './PreviewModal.css';

export default function PreviewModal(props) {
    pdfjs.GlobalWorkerOptions.workerSrc =
        `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const {
        mode, open, data, docmentList, file, numPages, width, scale, change, moveFile,
        handleDocument, onCancel, handleZoom,
        loadSuccess
    } = props;
    const [pageWidth, setPageWidth] = useState(0)
    const [selectCategory, setSelectCategory] = useState(undefined)
    const [selectType, setSelectType] = useState(undefined)
    const containerRef = useRef(null)
    const [currentPage, setCurrentPage] = useState(1)
    const isManualChange = useRef(false);
    useLayoutEffect (() => {
        if (open) {
            setCurrentPage(1)
            if (mode === 'move') {
                setSelectCategory(undefined)
                setSelectType(undefined)
            }
            const handleResize = () => {
                if (containerRef.current) {
                    setPageWidth(containerRef.current.offsetWidth - 8)
                }
            }
            setTimeout(handleResize, 0)
            window.addEventListener('resize', handleResize)
            return () => {
                window.removeEventListener('resize', handleResize)
            }
        }
    }, [open, mode])

    const previewCancel = () => {
        onCancel()
    }

    const onDocumentLoadSuccess = (page) => {
        loadSuccess(page)
    }

    const previewDownload = () => {
        const modifiedPdfBlob = new Blob([file], { type: 'application/pdf' })
        const downloadLink = document.createElement('a')
        downloadLink.href = URL.createObjectURL(modifiedPdfBlob)
        downloadLink.download = data?.FileName
        downloadLink.click()
    }
    const onChangePage = (page, pageSize) => {
        isManualChange.current = true
        setCurrentPage(page)
        const pdfTargetElement = document.querySelector(`#pdfPage-${page}`)

        if (pdfTargetElement) {
            containerRef.current.scrollTo({
                top: pdfTargetElement.offsetTop,
                behavior: 'smooth',
            })
        }

        setTimeout(() => {
            isManualChange.current = false
        }, 500)
    }

    const handleScroll = () => {
        if (isManualChange.current) return

        const containerHeight = containerRef.current.offsetHeight
        const anchors = document.querySelectorAll('.pdf-anchor')

        let closestPage = currentPage
        let minDistance = Infinity

        anchors.forEach(anchor => {
            const { top } = anchor.getBoundingClientRect()
            const distance = Math.abs(top - containerHeight / 2)

            if (distance < minDistance) {
                minDistance = distance;
                closestPage = parseInt(anchor.id.split('-')[1])
            }
        })

        if (closestPage !== currentPage) {
            setCurrentPage(closestPage)
        }
    }

    const changeZoom = (type) => {
        if (type === 'add') {
            handleZoom(+(Math.min(scale + 0.1, 3).toFixed(1)));
        } else {
            handleZoom(+(Math.max(scale - 0.1, 0.5).toFixed(1)));
        }
    }

    const removeFileExtension = (fileName) => {
        return fileName && fileName.replace(/\.[^/.]+$/, "")
    }
    
    const arrayBufferToBlob = (buffer, type) => {
        return new Blob([buffer], { type })
    }

    const createImageURLFromBase64 = (arrayBuffer, type) => {
        const blob = arrayBufferToBlob(arrayBuffer, type)
        return URL.createObjectURL(blob)
    }

    const changeFile = (type) => {
        setCurrentPage(1)
        if (type === 'prev' && data && data?.num !== 1) {
            change(data?.num - 1)
        } else if (type === 'next' && data && data?.num !== data?.total) {
            change(data?.num + 1)
        }
    }

    const handleDoc = (e) => {
        handleDocument(e.key)
    }

    const dropdownItems = [
        {
            label: 'Download',
            key: 'download',
            icon: <img src='/images/document-download-grey.svg' alt='download' />,
        },
        {
            type: 'divider',
        },
        {
            label: 'Reupload',
            key: 'upload',
            icon: <img src='/images/document-upload-grey.svg' alt='upload' />,
        }
    ]

    const categoryOptions = [
        {
            label: 'Identification',
            value: 'identification'
        },
        {
            label: 'Income',
            value: 'income'
        },
        {
            label: 'Assets & debts',
            value: 'assets'
        },
        {
            label: 'Property',
            value: 'property'
        },
        {
            label: 'Miscellaneous',
            value: 'miscellaneous'
        },
        // {
        //     label: 'Process documents',
        //     value: 'internalDocuments'
        // }
    ]

    const getCategoryOptions = () => {
        const filtered = categoryOptions.filter(option => {
            const category = docmentList.find(doc => doc.type === option.value)
            return category && category.list.length > 0
        })
        return filtered
    }

    const getTypeOptions = () => {
        const selectedDocument = docmentList.find(item => item.type === selectCategory)
        const options = selectedDocument ? selectedDocument.list.map(doc => ({ label: doc.TaskName, value: doc.ID })) : []
        return options
    }

    const changeCategory = (value) => {
        setSelectType(undefined)
        setSelectCategory(value)
    }

    const changeType = (value) => {
        setSelectType(value)
    }

    const changeMove = () => {
        moveFile(selectType)
    }

    const computedWidth = mode === 'preview' ? width : pageWidth

    return (
        <>
            <Modal
                className="document-preview-modal check-list"
                maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.85)' }}
                destroyOnClose
                maskClosable={true}
                width='90%'
                open={open}
                title=""
                onCancel={previewCancel}
                footer={null}
            >
                <div className='preview-modal-top'>Document review</div>
                <div className='file-action'>
                    <div className='download-directory'>{mode === 'move' && <div style={{marginLeft: 46}}>UNCATEGORIZED</div>}</div>
                    <div className='change-file'>
                        <img className='prev-file' src='/images/document-arrow-left.svg' alt='prev' onClick={() => changeFile('prev')}/>
                        <div className='flie-total'>File <span>{data?.num} / {data?.total}</span></div>
                        <div className='separate'></div>
                        <div className='file-name'>
                            <img
                                src={data?.MimeType === "application/pdf" ? '/images/pdf-icon-blue.svg' : '/images/img-icon.svg'}
                                alt='file'
                            />
                            <div>{removeFileExtension(data?.FileName)}</div>
                        </div>
                        <img className='next-file' src='/images/document-arrow-right.svg' alt='next' onClick={() => changeFile('next')}/>
                    </div>
                    <div className='right-action'>
                        {
                            mode === 'move' ? (
                                <Dropdown
                                    overlayClassName="document-checklist-dropdown"
                                    menu={{items: dropdownItems, onClick: (e) => handleDoc(e)}}
                                    placement="bottomRight"
                                    trigger={['click']}
                                >
                                    <img style={{cursor: 'pointer', marginRight: 24}} src='/images/verify-menu.svg' alt='menu' />
                                </Dropdown>
                            ) : <img
                                    className='download-file'
                                    style={{ cursor: 'pointer' }}
                                    onClick={previewDownload}
                                    src="/images/document-download-black.svg"
                                    alt='download'
                                />
                        }
                    </div>
                </div>
                <Row style={{flex: 1}}>
                    <Col style={{position: 'relative'}} span={mode === 'preview' ? 24 : 14}>
                        <div className='document-preview-content' ref={containerRef} onScroll={handleScroll}>
                            <div style={{ width: computedWidth * scale }} className='preview-box'>
                                {data?.MimeType === "application/pdf" ? (
                                    <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                                        {Array.from(new Array(numPages), (el, index) => (
                                            <div
                                                id={`pdfPage-${index + 1}`}
                                                    key={`page_${index + 1}`}
                                                    style={{ width: computedWidth * scale }}
                                                    className="pdf-anchor"
                                                >
                                                    <Page pageNumber={index + 1} width={ computedWidth * scale } />
                                                </div>
                                            ))}
                                        </Document>
                                    ) : (
                                        <Image
                                            preview={false}
                                            width={computedWidth * scale}
                                            src={createImageURLFromBase64(file, data?.MimeType )}
                                        />
                                    )
                                }
                            </div>
                        </div>
                        <div className='document-preview-bottom-bar'>
                            <div className="document-preview-pagination">
                                <Pagination
                                    size='small'
                                    simple
                                    current={data?.MimeType === "application/pdf" ? currentPage : 1}
                                    pageSize={1}
                                    total={data?.MimeType === "application/pdf" ? numPages : 1}
                                    onChange={onChangePage}
                                />
                            </div>
                            <div className="document-preview-scale">
                                <div className='zoom-min'>
                                    <MinusOutlined style={{ fontSize: 16 }} onClick={() => changeZoom('min')} />
                                </div>
                                <div className='zoom-value'>{Math.round(scale * 100)}%</div>
                                <div className='zoom-add'>
                                    <PlusOutlined style={{ fontSize: 16 }} onClick={() => changeZoom('add')} />
                                </div>
                            </div>
                        </div>
                    </Col>
                    {mode === 'move' && (
                            <Col span={10}>
                                <div className='move-content'>
                                    <div style={{width: '100%'}}>
                                        <div className='move-form-item'>
                                            <div className='move-form-label'>Document category</div>
                                            <Select
                                                className='moveFile-select'
                                                style={{
                                                    width: '100%',
                                                }}
                                                value={selectCategory}
                                                allowClear
                                                onChange={changeCategory}
                                                placeholder="Choose category"
                                                options={getCategoryOptions()}
                                            />
                                        </div>
                                        <div className='move-form-item'>
                                            <div className='move-form-label'>Document title</div>
                                            <Select
                                                className='moveFile-select'
                                                style={{
                                                    width: '100%',
                                                }}
                                                value={selectType}
                                                allowClear
                                                onChange={changeType}
                                                placeholder="Choose document title"
                                                options={getTypeOptions()}
                                            />
                                        </div>
                                    </div>
                                    <Button
                                        className="move-btn-confirm"
                                        type="primary"
                                        disabled={selectCategory === undefined || selectType === undefined}
                                        onClick={changeMove}
                                    >
                                        Confirm
                                    </Button>
                                </div>
                            </Col>
                        )
                    }
                </Row>
            </Modal>
        </>
    );
}
