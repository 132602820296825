import { IS_HARD_MONEY_LOAN, UPDATE_LEAD_SOURCE } from "../Defs/accountinfodefs";

const initialState = {};

const accountInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_HARD_MONEY_LOAN:
      return {
        ...state,
        isHardMoneyLoan: action.payload,
      };
    case UPDATE_LEAD_SOURCE:
      return {
        ...state,
        leadSource: action.payload,
      };
    default:
      return state;
  }
};

export default accountInfoReducer;
