import { useEffect, useState } from "react";
import { Input, Space } from 'antd'
import './DocumentsReceived.css'
import HardMoneyLoanDocManager from "./HardMoneyLoanDocManager";
import { SearchOutlined } from "@ant-design/icons"
const { Search } = Input

export default function(props) {
    const [searchInput, setSearchInput] = useState('')
    return <div>
        <div className='header'>
            <h3 className='title'>
                <span className='title-doc'>Documents | </span><span>Received</span>
            </h3>
            <Input
                placeholder="Search Documents"
                allowClear
                onChange={(e) => setSearchInput(e.target.value)}
                prefix={<SearchOutlined />}
                size="large"
                style={{ width: 250, marginRight: 20, borderRadius: 0 }}
            />
        </div>
        <HardMoneyLoanDocManager
            type="received"
            searchInput={searchInput}
        />
    </div>
}
