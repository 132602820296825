import { useState } from 'react'
import { Col, Form, Row } from "react-bootstrap"
import "../../App.css"
import "./CheckGroup.css"


export default function SecondaryButton(props) {
    const { type, value, data, onChangeSelect, style, fixedWidth, mode, className } = props
    const [setCheckValue] = useState([])

    const achange = ev => {
        // multi select
        if (type === 'checkbox') {
            const chooseArr = value.map(res => res.value)
            setCheckValue(chooseArr)
            if (chooseArr.includes(ev.value)) {
                let result = chooseArr.findIndex(item => {
                    return item === ev.value
                })
                value.splice(result, 1)
            } else {
                value.push({ label: ev.label, value: ev.value })
            }
            onChangeSelect(value)
        } else {
            // single select
            onChangeSelect(ev.value)
        }
    }

    let clsname = typeof className === "undefined" ? "" : className
    if (props.direction === "horizontal") {
        clsname = "check-group-wrap " + clsname
    }
    if (typeof fixedWidth !== "undefined") {
        if (mode === 'flex') {
            return (
                <Row className={clsname} style={style}>
                    {data.map(ele => (
                        <Col key={ele.value} className='check-group-box flex-box' style={{ border: value === ele.value ? '1px solid #325CEB' : '', minWidth: fixedWidth, maxWidth: fixedWidth }} onClick={() => achange(ele)}>
                            <div className="check-group-content-flex">
                                <div className="circle" style={{ border: value === ele.value ? '5px solid #325CEB' : '' }}></div>
                                <div className="dec" style={{ color: value === ele.value ? '' : '#6E6E70' }}>{ele.label}</div>
                            </div>
                        </Col>
                    ))}
                </Row>
            )
        } else {
            return (
                <Row className={clsname} style={style}>
                    {data.map(ele => (
                        <Col key={ele.value} className='check-group-box' style={{ border: value === ele.value ? '1px solid #325CEB' : '', minWidth: fixedWidth, maxWidth: fixedWidth }} onClick={() => achange(ele)}>
                            <div>
                                <div className="circle" style={{ border: value === ele.value ? '5px solid #325CEB' : '' }}></div>
                                <div className="dec" style={{ color: value === ele.value ? '' : '#6E6E70' }}>{ele.label}</div>
                            </div>
                        </Col>
                    ))}
                </Row>
            )
        }
    }

    return (
        <div className={clsname} style={style}>
            {type === 'checkbox' ? (
                data.map(ele => (
                    <div key={ele.value} className='check-group-box' style={{ border: value.some(e => e.value === ele.value) ? '1px solid #325CEB' : '' }} onClick={() => achange(ele)}>
                        <div>
                            <div className="circle" style={{ border: value === ele.value ? '5px solid #325CEB' : '' }}></div>
                            <div className="dec" style={{ color: value === value.some(e => e.value === ele.value) ? '' : '#6E6E70' }}>{ele.label}</div>
                        </div>
                        <Form.Control required />
                    </div>
                ))
            ) : (
                data.map(ele => (
                    <div key={ele.value} className='check-group-box' style={{ border: value === ele.value ? '1px solid #325CEB' : '' }} onClick={() => achange(ele)}>
                        <div className="check-group-box-l">
                            <div className="circle" style={{ border: value === ele.value ? '5px solid #325CEB' : '' }}></div>
                            <div className="dec" style={{ color: value === ele.value ? '' : '#6E6E70' }}>{ele.label}</div>
                        </div>
                        {ele.tag && ele.tag !== '' && ele.tag}
                    </div>
                ))
            )
            }
        </div>
    )
}

export function CheckGroupWithImage(props) {
    const { type, value, data, onChangeSelect, style, className } = props
    const [setCheckValue] = useState([])

    const achange = ev => {
        // multi-select
        if (type === 'checkbox') {
            const chooseArr = value.map(res => res.value)
            setCheckValue(chooseArr)
            if (chooseArr.includes(ev.value)) {
                let result = chooseArr.findIndex(item => {
                    return item === ev.value
                })
                value.splice(result, 1)
            } else {
                value.push({ label: ev.label, value: ev.value })
            }
            onChangeSelect(value)
        } else {
            // single-select
            onChangeSelect(ev.value)
        }
    }

    let clsname = ""
    if (typeof className !== "undefined") {
        clsname = className
    } else {
        if (props.direction === "horizontal") {
            clsname = "check-group-wrap"
        }
    }
    return (
        <div className={clsname} style={style}>
            {type === 'checkbox' ? (
                data.map(ele => (
                    <div key={ele.value} className='check-group-box' style={{ border: value.some(e => e.value === ele.value) ? '1px solid #325CEB' : '' }} onClick={() => achange(ele)}>
                        <div className="circle" style={{ background: value.some(e => e.value === ele.value) ? '#325CEB' : '', opacity: props.hideCircle ? 0 : 1 }}></div>
                        <div className="dec" style={{ border: value === value.some(e => e.value === ele.value) ? '' : 'color:#6E6E70',color:value=== value.some(e => e.value === ele.value) ? '' : ' #6e6e70' }}>{ele.label}</div>
                    </div>
                ))
            ) : (
                data.map(ele => (
                    <div
                        key={ele.value}
                        className='check-group-box-with-image'
                        style={{
                            border: value === ele.value ? '1px solid #325CEB' : '',
                            // maxWidth: '200px',
                            wordWrap: 'break-word',
                            overflow: 'hidden',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '8px'
                        }}
                        onClick={() => achange(ele)}
                    >
                        <div style={{ textAlign: 'center' }}>
                            <img
                                src={ele.image}
                                alt={ele.label}
                                style={{ width: ele.width ? ele.width + 'px' : '46px', height: ele.height ? ele.height + 'px' : '46px' }}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: 'center', width: '100%' }}>
                            <div
                                className="circle"
                                style={{ background: value === ele.value ? '#325CEB' : '', opacity: props.hideCircle ? 0 : 1 }}>
                            </div>
                            <div
                                className="dec"
                                style={{
                                    border: value === ele.value ? '' : 'color:#6E6E70',
                                    color: value === ele.value ? '' : '#6e6e70',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'normal',
                                    wordBreak: 'break-word',
                                    width: '100%',
                                    textAlign: 'center'
                                }}
                            >
                                {ele.label}
                            </div>
                        </div>
                    </div>
                ))
            )
            }
        </div>
    )
}
