import { isEmpty } from 'lodash'
import * as com from '../Common'

/**
 * Get states from loan limits
 * @param {*} loanLimits
 * @returns
 */
export const getStates = (loanLimits) => {
    const options = []
    options.push({ value: '', label: 'Select state' })

    // Handle cases where loanLimits is undefined, null or empty
    if (isEmpty(loanLimits)) {
        return options
    }

    const keys = Object.keys(loanLimits)
    keys.forEach((x) => {
        options.push({ value: com.capitalize(x), label: com.capitalize(x) })
    })

    return options
}

/**
 * Get counties from loan limits with a given state
 * @param {*} loanLimits
 * @param {*} state
 * @returns
 */
export const getCounties = (loanLimits, state) => {
    const options = []
    options.push({ value: '', label: 'Select county' })

    // Handle cases where loanLimits or state is undefined, null or empty
    if (isEmpty(loanLimits) || isEmpty(state)) {
        return options
    }

    const stateData = loanLimits[state.toUpperCase()]
    if (!isEmpty(stateData)) {
        const keys = Object.keys(stateData)
        keys.forEach((x) => {
            options.push({ value: com.capitalize(x), label: com.capitalize(x) })
        })
    }

    return options
}
