import React, { Component } from 'react'
import '../App.css'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import LandingMenu from './LandingMenu'
import LandingCopyright from './LandingCopyright'

class FirstPage extends Component {
  render = () => {
    return (
      <div className="pb-3">
        <Row id="how" style={{ backgroundColor: '#F7F9FC' }}>
          <Col className="pt-5 pb-5 mt-2 text-center">
            <h3>OUR</h3>
            <h2 className="heavyspaced mb-5"
            >STORY</h2>
            <div className="text-left px-2" style={{ marginBottom: '1em', marginLeft: 'auto', marginRight: 'auto', fontSize: '1.1em', maxWidth: '800px' }}>
              The name “Zeitro” is derived from the German word “Zeitgeist,” literally meaning “spirit of the age.” Or more precisely, a driving characteristic of the time. And the zeitgeist of the 21st century has to be technology’s impact on our everyday lives. Think video tutorials, online shopping and banking, rideshare apps, or even GPS navigation systems.
                        </div>
            <div className="text-left px-2" style={{ marginBottom: '1em', marginLeft: 'auto', marginRight: 'auto', fontSize: '1.1em', maxWidth: '800px' }}>

              Automated software and data-driven technology have done much to simplify what used to be overly complicated and time consuming—exactly what Zeitro aims to do for the mortgage acquisition process.
</div>
            <div className="text-left px-2" style={{ marginBottom: '1em', marginLeft: 'auto', marginRight: 'auto', fontSize: '1.1em', maxWidth: '800px' }}>

              Since its establishment, the mortgage industry has been dominated by a complicated documentation system. And little has changed in the decades since. Acquiring a mortgage may still be one of the most significant financial milestones, but modern homeowners’ priorities have evolved. We want a personalized, fast, and uncomplicated system that operates with borrowers’ interests at the forefront.
</div>
            <div className="text-left px-2" style={{ marginBottom: '1em', marginLeft: 'auto', marginRight: 'auto', fontSize: '1.1em', maxWidth: '800px' }}>

              Zeitro has reimagined the mortgage industry within the context of 21st century technology and to accommodate 21st century needs. Utilizing artificial intelligence and data analytics, our system connects borrowers, lenders, and other stakeholders within a virtual ecosystem.                         </div>
            <div className="text-left px-2" style={{ marginLeft: 'auto', marginRight: 'auto', fontSize: '1.1em', maxWidth: '800px' }}>
              Our mission is to simplify the mortgage acquisition process for borrowers by identifying the best mortgage for their needs and supporting them through closing the loan.
                    </div>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col className=" text-center">
            <h3>MEET</h3>
            <h2 className="heavyspaced mb-5"
            >OUR TEAM</h2>
          </Col>
        </Row>
        <Row style={{ marginBottom: '3em' }}>
          <Col md={1}>
          </Col>
          <Col  className=" text-center">
            <a href="https://www.linkedin.com/in/bochenw/" target="about:blank">
              <img alt="" src="/bochen.jpg" className="teamicon" /></a>
            <h4>Bochen Wang</h4>
            <h5>FOUNDER | CHIEF EXECUTIVE </h5>
            <div className="mt-3">
              Former Investment Analyst at Rosefinch Investment
                  </div>
            <div>
              M.S in Business Analytics
                  </div>
          </Col>
          <Col className=" text-center">
            <a href="https://www.linkedin.com/in/dantrepanier/" target="about:blank">
              <img alt="" src="/dan.jpg" className="teamicon" />
            </a>
            <h4>Daniel Trepanier</h4>
            <h5>COFOUNDER | EXECUTIVE CHAIRMAN</h5>
            <div className="mt-3">
              Director of SCU’s MS in Business Analytics Program

                  </div>
            <div>
              Former President and CEO at Xambala and Quake Technologies

                  </div>

          </Col>
          <Col md={1}>
          </Col>
        </Row>
        <Row className="mt-3" style={{ marginBottom: '3em' }}>
          <Col md={1}>
          </Col>
          <Col className=" text-center">
            <img alt="" src="/yingjie.png" className="teamicon" />

            <h4>Yingjie Yu</h4>
            <h5>COFOUNDER | STAFF DATA SCIENTIST</h5>
            <div className="mt-3">
              Former Data Scientist at Alibaba Inc.
                  </div>
            <div>
              M.S in Business Analytics
                  </div>
          </Col>
          <Col className=" text-center">
            <a href="https://www.linkedin.com/in/igorplotnikov/" target="about:blank">
              <img alt="" src="/igor.jpg" className="teamicon" />
            </a>
            <h4>Igor Plotnikov</h4>
            <h5>VP OF ENGINEERING</h5>
            <div className="mt-3">
              Co-founder of uRoam and MI Secure (acquired by F5 Networks and Cisco Systems)
                  </div>
            <div>
              Ph.D. in Physics
                  </div>

          </Col>
          <Col md={1}>
          </Col>
        </Row>
        <Row className="mt-3" style={{ marginBottom: '3em' }}>
          <Col md={1}>
          </Col>
          <Col className=" text-center">
            <a href="https://www.linkedin.com/in/valentyn-kamyshenko/" target="about:blank">
              <img alt="" src="/valya.jpg" className="teamicon" />
            </a>

            <h4>Valentyn Kamyshenko</h4>
            <h5>CHIEF TECHNOLOGY OFFICER</h5>
            <div className="mt-3">
              Former Chief Architect at Xambala Inc. and Tech Lead at Cisco.
                  </div>
            <div>
              Ph.D. in Theoretical Physics
                  </div>
          </Col>
          <Col className=" text-center">
            <a href="https://www.linkedin.com/in/atul-vijaykar-52a18519/" target="about:blank">
              <img alt="" src="/atul.jpg" className="teamicon" />
            </a>
            <h4>Atul Vijaykar</h4>
            <h5>VP OF BUSINESS DEVELOPMENT	, FINANCE AND ADMINISTRATION
                  </h5>
            <div className="mt-3">
              Former Director of Market Development at Intel                   </div>
            <div>
              Consulted for multiple start-ups
                  </div>

          </Col>
          <Col md={1}>
          </Col>
        </Row>
        <Row className="mt-5 mb-5" style={{ marginBottom: '7em' }}>
          <Col md={1}>
          </Col>
          <Col className=" text-center">
            <a href="https://www.linkedin.com/in/anabellelittle/" target="about:blank">
              <img alt="" src="/anabelle.jpg" className="teamicon" />
            </a>
            <h4>Anabelle Little</h4>
            <h5>DIRECTOR OF LOAN OPERATIONS</h5>
            <div className="mt-3">
              20 years of experience in Home Loan Underwriting.
            </div>
            <div>
              <a href="https://www.nmlsconsumeraccess.org/EntityDetails.aspx/INDIVIDUAL/307748" target="about:blank">NMLS #307748</a>
            </div>
            <div>

            </div>

          </Col>

          <Col md={1}>
          </Col>
        </Row>
        <div className="py-5 my-5">&nbsp;</div>

        <LandingCopyright />
      </div>
    )
  }
}

export default class LandingAbout extends Component {

  render = () => {
    return (
      <div style={{ minHeight: '1000px' }}>
        <LandingMenu />
        <FirstPage />
      </div>
    )
  }
}