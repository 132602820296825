import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Flex, Row, Col, Button, Card, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { cloneDeep } from 'lodash/cloneDeep'
import './styles/loans.css'
import { getLoans, createNewApplication, Switch } from '../api/api'
import * as com from '../../Common'
import { State } from '../../State'
import { getCurrentState } from '../../Store'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as act from '../../Store/actions'
import * as prgr from '../../Store/progress'
import TopNavBar from './TopNavbar'
import { camelToTitleCase } from '../../Tools/String'
import { getLoanAddress, getLoanAmount } from '../../Tools/Loan'

const mapStateToProps = (state) => {
    return {
        progress: state.progress,
        application: state.application,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateChangeApplication: (application) => {
        dispatch(act.updateApplication(application))
    },
    updateChangeProgressType: (step, stage) => {
        dispatch(prgr.UpdateChangeProgressType(step, stage))
    },
    updateChangeProgress: (tab, index) => {
        dispatch(prgr.UpdateChangeProgress(tab, index))
    },
    updateChangeProgressType: (tab) => {
        dispatch(prgr.UpdateChangeProgressType(tab))
    },
    updateChangeVisitedStage: (step, stage) => {
        dispatch(prgr.UpdateChangeVisitedStage(step, stage))
    },
    updateProduct: (v, verb) => {
        dispatch(act.UpdateProduct(v, verb))
    },
})

const loanPurposeMap = {
    purchase: 'Purchase',
    purchaserehab: 'Purchase and Rehab',
    refinance: 'Refinance',
    cashoutrefinance: 'Cash-out Refinance',
}

function Loans(props) {
    const [dataLoans, setDataLoans] = useState({
        loans: [],
        loanid: '',
    })

    const isMobile = window.innerWidth < 768
    const customerId = com.getCustomerId()

    const subscription = useSelector((state) => {
        return state.application
    })

    const history = useHistory()
    const isHardMoney = sessionStorage.getItem('isHardMoneyLoanCustomer') === 'true'

    useEffect(() => {
        GetLoans()
    }, [])

    return (
        <div style={{ background: 'white' }}>
            {/* <CustomerHeader /> */}
            <TopNavBar></TopNavBar>
            <Flex gap="middle" wrap>
                <Row className="w-100">
                    <Col span={24}>
                        <div className="LoansContent">
                            <div className="LoansTitle text-left">
                                Welcome back, {subscription.borrower.firstname}
                            </div>
                            <div
                                className={
                                    isMobile
                                        ? 'w-100'
                                        : 'w-100 d-flex justify-content-between text-left mt-4 align-items-center'
                                }
                            >
                                <div>
                                    <div className="YourLoans">Your loans</div>
                                    <div className="loansIntroduce ">
                                        Here is an overview of your current and past loans. Select an application to continue.
                                    </div>
                                </div>
                                <div className={isMobile ? 'mt-3' : ''}>
                                    <Button
                                        style={{
                                            width: '152px',
                                            height: '46px',
                                            borderRadius: '6px',
                                            border: '1px solid #E3EAFD',
                                            backgroundColor: '#F7F8FF',
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            color: '#325CEB'
                                        }}
                                        onClick={() => openNewApplication()}>
                                        New application
                                    </Button>
                                </div>
                            </div>
                            <Col span={24}>
                                {dataLoans.loans
                                    .sort((a, b) => {
                                        if (!a.state.progress || a.state.progress.lasttouch == null)
                                            return 1
                                        if (!b.state.progress || b.state.progress.lasttouch == null)
                                            return -1
                                        return (
                                            dayjs(b.state.progress.lasttouch).valueOf() - dayjs(a.state.progress.lasttouch).valueOf()
                                        )
                                    })
                                    .sort((a, b) => {
                                        return dataLoans.loanid === a.loanid ? -1 : 1
                                    })
                                    .map((item) => {
                                        const { propertytype, address, salesprice, purpose, hascontract, appraisal } = item.state.application.property
                                        const { loansize, downpayment } = item.state.application.selection
                                        const { mortgageapplied } = item.state.application.product
                                        const { initialloanamount, refinanceloanamount } = item.state.application.hardmoneyloan

                                        return (
                                            <Card key={item.loanid} className="mt-4 " type="inner" title={LoansTitleDom(item)}>
                                                <Row className="align-items-center justify-content-between">
                                                    {!isMobile && (
                                                        <Row className="justify-content-between" style={{ width: '100%' }}>
                                                            {/* Left Section - Property Info */}
                                                            <div style={{ width: '30%' }}>
                                                                <Row className="justify-content-between align-items-start">
                                                                    <Col>
                                                                        <div style={{ color: '#6E6E70', fontWeight: 500, fontSize: 14 }}>
                                                                            Property address
                                                                        </div>
                                                                        <Tooltip placement="top" title={getLoanAddress(item)}>
                                                                            <div className="dataLoansText overflowH" style={{ width: 200 }}>
                                                                                {getLoanAddress(item)}
                                                                            </div>
                                                                        </Tooltip>
                                                                    </Col>
                                                                    <Col>
                                                                        <div style={{ color: '#6E6E70', fontWeight: 500, fontSize: 14 }}>
                                                                            Property type
                                                                        </div>
                                                                        <div className="dataLoansText">
                                                                            {propertytype === '' ? '--' : com.getPropertyTypeName(propertytype)}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>

                                                            {/* Middle Section - Loan Info */}
                                                            <div style={{ width: '30%' }}>
                                                                <Row className="justify-content-between align-items-start">
                                                                    <Col>
                                                                        <div style={{ color: '#6E6E70', fontWeight: 500, fontSize: 14 }}>
                                                                            Loan officer
                                                                        </div>
                                                                        <div className="dataLoansText">
                                                                            {item.loanofficername === '' ? 'N/A' : item.loanofficername}
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <div style={{ color: '#6E6E70', fontWeight: 500, fontSize: 14 }}>
                                                                            Loan amount
                                                                        </div>
                                                                        <div className="dataLoansText">
                                                                            {getLoanAmount(
                                                                                purpose,
                                                                                mortgageapplied,
                                                                                salesprice,
                                                                                downpayment,
                                                                                initialloanamount,
                                                                                refinanceloanamount,
                                                                                loansize,
                                                                                hascontract,
                                                                                appraisal
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>

                                                            {/* Right Section - Button */}
                                                            <Col className="d-flex justify-content-end align-items-center">
                                                                <Button
                                                                    style={{ height: 40, width: 93, fontSize: 16, fontWeight: 600, borderRadius: 6 }}
                                                                    onClick={() => {
                                                                        onSwitch(dataLoans.loanid === item.loanid, item)
                                                                    }}
                                                                    type="primary"
                                                                >
                                                                    {dataLoans.loanid === item.loanid ? 'Continue' : 'View'}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                    {isMobile && (
                                                        <>
                                                            <div style={{ width: '100%' }}>
                                                                <Col className="d-flex align-items-center justify-content-between borderBottom">
                                                                    <div style={{ color: '#6E6E70', width: '60%', fontWeight: 500, fontSize: 14 }}>
                                                                        Property address
                                                                    </div>
                                                                    <div className="MDataLoansText" style={{ color: '' }}>
                                                                        {address === '' ? '--' : address}
                                                                    </div>
                                                                </Col>
                                                                <Col className="d-flex align-items-center justify-content-between mt-3 borderBottom">
                                                                    <div style={{ color: '#6E6E70', width: '60%', fontWeight: 500, fontSize: 14 }}>
                                                                        Property type
                                                                    </div>
                                                                    <div className="MDataLoansText">
                                                                        {propertytype === '' ? '--' : com.getPropertyTypeName(propertytype)}
                                                                    </div>
                                                                </Col>
                                                            </div>
                                                            <div style={{ width: '100%' }}>
                                                                <Col className="d-flex align-items-center justify-content-between mt-3 borderBottom">
                                                                    <div style={{ color: '#6E6E70', width: '60%', fontWeight: 500, fontSize: 14 }}>
                                                                        Loan officer
                                                                    </div>
                                                                    <div className="MDataLoansText">
                                                                        {item.loanofficername}
                                                                    </div>
                                                                </Col>
                                                                <Col className="d-flex align-items-center justify-content-between mt-3 borderBottom">
                                                                    <div style={{ color: '#6E6E70', width: '60%', fontWeight: 500, fontSize: 14 }}>
                                                                        Loan amount
                                                                    </div>
                                                                    <div className="MDataLoansText">
                                                                        {getLoanAmount(
                                                                            purpose,
                                                                            mortgageapplied,
                                                                            salesprice,
                                                                            downpayment,
                                                                            initialloanamount,
                                                                            refinanceloanamount,
                                                                            loansize,
                                                                            hascontract,
                                                                            appraisal
                                                                        )}
                                                                    </div>
                                                                </Col>
                                                                <Col className="mt-3 ">
                                                                    <Button
                                                                        style={{ height: 40, width: '100%' }}
                                                                        onClick={() => {
                                                                            onSwitch(dataLoans.loanid === item.loanid, item)
                                                                        }}
                                                                        type="primary"
                                                                    >
                                                                        {dataLoans.loanid === item.loanid ? 'Continue' : 'View'}
                                                                    </Button>
                                                                </Col>
                                                            </div>
                                                        </>
                                                    )}
                                                </Row>
                                            </Card>
                                        )
                                    })}
                            </Col>
                        </div>
                    </Col>
                </Row>
            </Flex>
        </div>
    )

    async function GetLoans() {
        const { loanid, loans } = await getLoans()
        loans.forEach((loan) => {
            loan.state = JSON.parse(loan.state)
            loan.lostate = JSON.parse(loan.lostate)
        })
        setDataLoans({ loans, loanid })
    }

    async function openNewApplication() {
        try {
            const newS = JSON.parse(
                (() => {
                    const a = new State()
                    return a.toJson()
                })()
            )

            const data = {
                LoanID: dataLoans.loanid,
                NewState: com.ascii2hex(JSON.stringify(newS)),
            }

            const { Status, State: ApplicationState, Text } = await createNewApplication(data)
            if (Status !== 'OK') {
                console.log(Text)
                alert('Looks like there was a problem. Please contact your loan officer for help.')
            } else {
                const st = JSON.parse(ApplicationState)
                props.updateChangeApplication(st.application)
                // dispatch({ type: interview.CHANGE_APPLICATION, data: st.application })
                getCurrentState()
                sessionStorage.removeItem('state')
                sessionStorage.removeItem('originalstate')
                let url = '/services/preapp?customerid=' + customerId
                if (isHardMoney) {
                    const newState = cloneDeep(st)
                    newState.application.product.mortgageapplied = 'hardmoneyloan'
                    sessionStorage.setItem('state', JSON.stringify(newState))
                    sessionStorage.setItem('originalstate', JSON.stringify(newState))
                    url = '/application/hardmoneyloan/interview/main/borrower'
                }
                window.location.href = url
            }
        } catch (error) {
            window.document.dispatchEvent(new Event('reauthenticate'), '')
        }
    }

    async function onSwitch(isCurrent, loan) {
        const { currentStage, currentStatus } = loan.state.application
        const address = getLoanAddress(loan)
        localStorage.setItem('LOAN_OFFICER_NAME', loan.loanofficername)
        localStorage.setItem('LOAN_CURRENT_STAGE', currentStage)
        localStorage.setItem('LOAN_CURRENT_STATUS', currentStatus)
        localStorage.setItem('LOAN_ADDRESS', address)

        const js = { LoanID: loan.loanid }
        if (isCurrent) {
            const interviewIndex = Math.min(
                com.stageToNumberApplication.overview,
                parseInt(props.progress.interview.step)
            )
            // depreciate this later
            let url = '/application/interview/main/' + interviewIndex
            try {
                const { Status, State: StateSwitch } = await Switch(js)
                if (Status === 'OK') {
                    const st = JSON.parse(StateSwitch)
                    const { mortgageapplied } = st.application.product
                    props.updateChangeApplication(st.application)
                    getCurrentState()
                    url = '/application/interview/main/' + interviewIndex
                    if (mortgageapplied === 'hardmoneyloan') {
                        url = '/application/hardmoneyloan/interview/main/' + interviewIndex
                    }
                    history.push(url)
                } else {
                    alert('Something went wrong. Please try later or contact your loan officer for help.')
                }
            } catch (error) {
                window.document.dispatchEvent(new Event('reauthenticate'), '')
            }
        } else {
            try {
                const { Status, State: StateSwitch } = await Switch(js)
                if (Status === 'OK') {
                    const st = JSON.parse(StateSwitch)
                    const { mortgageapplied } = st.application.product
                    const isHardMoneyLoan = mortgageapplied === 'hardmoneyloan'
                    props.updateChangeApplication(st.application)
                    getCurrentState()
                    let u = '/application/interview/main'
                    if (isHardMoneyLoan) {
                        u = '/application/hardmoneyloan/interview/main/overview'
                    }

                    window.location.href = u
                } else {
                    alert('Something went wrong. Please try later or contact your loan officer for help.')
                }
            } catch (error) {
                window.document.dispatchEvent(new Event('reauthenticate'), '')
            }
        }
    }

    function LoansTitleDom(item) {
        const currentStatus = item.state.application?.currentStatus
        const { purpose } = item.state.application.property
        const { mortgageapplied } = item.state.application.product

        const getLoanPurpose = () => {
            if (purpose == null) return 'Loan Purpose TBD'
            if (mortgageapplied === 'hardmoneyloan')
                return 'Hard Money - ' + loanPurposeMap[purpose]
            if (mortgageapplied === 'nonqm')
                return 'Non QM - ' + loanPurposeMap[purpose]
            return `Home ${loanPurposeMap[purpose]}`
        }

        const { progress } = item.state

        return (
            <div style={{ maxWidth: '100%', width: '100%' }} className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <div>
                        {purpose !== 'purchase' ? (
                            <img src="/money.svg" alt="money" />
                        ) : (
                            <img src="/house.svg" alt="house" />
                        )}
                    </div>
                    <div className="ml-2" style={{ fontWeight: 500, color: '#333', fontSize: 16 }}>
                        {getLoanPurpose()}
                        <span className="ml-1"> {!isMobile ? '·' : ''} </span>
                        <span
                            style={
                                isMobile
                                    ? { display: 'block', color: '#6E6E70', fontWeight: 400, fontSize: 16 }
                                    : { color: '#6E6E70', fontWeight: 400, fontSize: 16 }
                            }
                        >
                            {progress && progress.lasttouch
                                ? dayjs(progress.lasttouch).format('MM/DD/YYYY HH:mm A')
                                : '--'}
                        </span>
                    </div>
                </div>
                {currentStatus && (
                    <div
                        style={{
                            height: '30px',
                            padding: '5px 10px',
                            borderRadius: '15px',
                            backgroundColor: '#D7E2FB',
                            color: '#1630AC',
                            fontSize: '14px',
                            fontWeight: 600,
                            whiteSpace: 'nowrap'
                        }}
                    >
                        {camelToTitleCase(currentStatus)}
                    </div>
                )}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(Loans)
