import * as def from '../Defs/accountinfodefs'

export const updateIsHardMoneyLoan = (payload) => ({
    type: def.IS_HARD_MONEY_LOAN,
    payload: payload,
  });

export const updateLeadSource = (payload) => ({
  type: def.UPDATE_LEAD_SOURCE,
  payload: payload,
});
