import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Signature from '../Common/Signature'
import "@fortawesome/fontawesome-free/css/all.min.css";
import trimCanvas from 'trim-canvas'
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux'
import SignHere from '../Common/SignHere.svg'
import * as com from '../Common';
const cloneDeep = require('lodash/cloneDeep');

const prefix = "data:image/png;base64,"
const mapStateToProps = (state) => {
    return {
        application: state.application,
        progress: state.progress,
        borrower: state.borrower,
    }
}

const mapDispatchToProps = (dispatch) => ({
});
const spinner = (
    <div id="spinner" className="pt-5 mt-5">
        <img className="loader" alt="spinner" src={com.logo ? `data:image/png;base64, ${com.logo}` : "/logo.png"}></img>
    </div>    
)

class Form1003 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            target: {},
            showplacementpad: false,
            index: 0,
            sigpage: 0,
            sigindex: 0,
            sigx: 0,
            sigy: 0,
            datex: 0,
            datey: 0,
            signer: 0,
            clippath: 'rect(0px, 0px, 200px, 600px)',
            signers: "",
            show: false,
            zoom: { width: '100%', height: 'auto' },
            spinner: spinner,
            renderer: spinner,
            sending: false,
            preview: [],
            signed: true
        }
        this.json = ""
        this.signzoom = 1.0
        this.ref = React.createRef()
        this.sigRef = React.createRef()
        this.createDateCanvas()

    }
    componentWillReceiveProps = (newProps) => {

    }
    componentDidMount() {
        this.poke()
    }
    createDateCanvas = () => {
        this.dateCanvas = document.createElement('canvas')
        var ctx = this.dateCanvas.getContext("2d");

        ctx.font = "28px Times New Roman";

        let d = new Date()
        ctx.fillText(d.toLocaleDateString(), 10, 50);

        trimCanvas(this.dateCanvas)
    }
    sign = (e) => {
        let token = sessionStorage.getItem("ZeitroA")
        let body = JSON.stringify(this.json)
        //sessionStorage.setItem("f1003", body)
        fetch(this.props.output, {
            method: 'POST',
            body: body,
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
                ContentType: "application/json"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    alert('Looks like there was a problem. Status Code: ' +
                        response.status);

                } else {
                    // Examine the text in the response
                    response.text().then(text => {

                    });
                }
                this.setState({ sending: false, show: false, signed: true, renderer: spinner })
                if("undefined" != typeof this.props.onSigned)
                this.props.onSigned()
                this.poke()
            }
        ).catch((err) => {
            console.log('Fetch Error :', err);
        });
    }
    handleSubmit = (event) => {
        if (!this.state.signed) {
            this.handleOpen()
            return false;
        }
        return true
    }
    update = (i) => {
        this.setState({ index: i })
         // eslint-disable-next-line react/no-direct-mutation-state
        this.state.index = i
        this.updateRenderer()
        this.forceUpdate()
    }
    zoomin = () => {
        this.setState({ zoom: {} })
         // eslint-disable-next-line react/no-direct-mutation-state
        this.state.zoom = {}
        this.updateRenderer()
    }
    zoomout = () => {
        this.setState({ zoom: { width: '100%', height: 'auto' } })
         // eslint-disable-next-line react/no-direct-mutation-state
        this.state.zoom = { width: '100%', height: 'auto' }
        this.updateRenderer()
    }
    updateRenderer = () => {
        this.setState({
            renderer:
                (
                    <div className="text-center mt-4" key={this.state.index} alt={this.state.index} >
                        {this.state.signed ? this.props.signedtitle : this.props.title}

                        <Row className="mb-2">
                            <Col className="text-left my-1 pt-1 pl-5">
                                {this.state.signed ?
                                    <a className="ml-3 deepblue" download={this.props.filename} href={"data:application/pdf;base64," + this.state.pdf}>Download the signed form</a>
                                    :
                                    <a className="ml-3 deepblue" download={this.props.filename} href={"data:application/pdf;base64," + this.state.pdf}>Download the filled out form</a>
                                }
                            </Col>
                            <Col>
                                <div className="rectangular">
                                    {this.state.preview ? this.state.preview.map((data, index) => {
                                        return (<Button key={"bbb" + index} className="rectangular" onClick={
                                            e => {
                                                this.update(index)
                                            }
                                        } variant={this.state.index === index ? "primary" : "outline-primary"}>{index + 1}</Button>)
                                    }) : ""}
                                </div>
                            </Col>
                            <Col>
                                {this.state.signed ? ""
                                    :
                                    <Button variant="primary" onClick={this.handleOpen} className="ml-4 pt-1 justify-content-center align-self-center">Click when ready to sign!</Button>
                                }
                            </Col>
                        </Row>
                        <img className="ml-1 mb-2 pl-0 text-left border" alt={this.state.index} style={this.state.zoom} key={this.state.index} src={prefix + this.state.preview[this.state.index]}
                        />
                    </div>
                )
        })
    }
    poke = () => {
        let token = sessionStorage.getItem("ZeitroA")

        fetch(this.props.poke, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
                "X-Borrower": this.props.borrowerid
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    this.reload()
                    return;
                }
                // Examine the text in the response
                response.json().then(json => {
      
                    if (this.props.checkValidity(json.Validity)) {

                        let doc = json.Document
                        this.setState({
                            pdf: doc.documentBase64, preview: doc.pngPagesBase64
                        })
                        this.updateRenderer()
                    } else {
                        console.log("Invalid doc, needs resigning")
                        this.reload()
                    }
                    return
                });
            }
        ).catch((err) => {
            console.log('Fetch Error :', err);
        });
    }
    reload = () => {
        let token = sessionStorage.getItem("ZeitroA")
        this.setState({ signed: false })
        fetch(this.props.input, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    this.updateRenderer()
                    return;
                }
                // Examine the text in the response
                response.json().then(json => {
                    this.json = json
                    this.originaljson = cloneDeep(json)

                    let text = json.documents[0].documentBase64
                    this.setState({
                        pdf: text, preview: json.documents[0].pngPagesBase64,
                        signers: json.recipients.signers,
                        name: json.recipients.signers[0].name
                    })
                    this.updateRenderer()
                    return
                });
            }
        ).catch((err) => {
            console.log('Fetch Error :', err);
        });
    }
    handleClose = () => {
        this.setState({ show: false })
    }
    handleOpen = () => {
        this.setState({ show: true })
    }
    doneSigning = () => {
        this.setState({ sending: true })
        this.sign()
    }

    moveSig = () => {
        let mx = this.state.signers[this.state.signer].tabs.length - 1

        if (this.state.sigindex >= mx
        ) {
            if (this.state.signer + 1 >= this.state.signers.length) {
                this.doneSigning()
                return false
            }
            let page = parseInt(this.state.signers[this.state.signer + 1].tabs[0].pageNumber) - 1
            this.setState({
                sigindex: 0, signer: this.state.signer + 1,
                sigpage: page,
                name: this.state.signers[this.state.signer + 1].name,
                showplacementpad: false
            })
             // eslint-disable-next-line react/no-direct-mutation-state
            this.state.signer = this.state.signer + 1
             // eslint-disable-next-line react/no-direct-mutation-state
            this.state.sigindex = 0
            let sigel = document.getElementById(this.cvs.id)
            if (sigel) {
                sigel.remove()
            }
            this.dateCanvas.style.visibility = 'hidden'
            this.sigRef.current.clear()
        } else {
            let page = parseInt(this.state.signers[this.state.signer].tabs[this.state.sigindex + 1].pageNumber) - 1
            this.setState({
                sigindex: this.state.sigindex + 1,
                sigpage: page,
            })
             // eslint-disable-next-line react/no-direct-mutation-state
            this.state.sigindex = this.state.sigindex + 1
        }

        this.doSig()
    }

    onShow = () => {
        this.json = cloneDeep(this.originaljson)
        this.initSig()
        this.setState({
            sigpage: parseInt(this.state.signers[0].tabs[0].pageNumber) - 1,
            signer: 0,
            signers: this.json.recipients.signers,
            showplacementpad: false,
            name: this.state.signers[0].name,
        })
    }
    rethinkSigGeometry = () => {

        this.cvs.style.zIndex = 10000
        this.cvs.style.position = 'absolute'
        this.cvs.style.zoom = 50 / this.cvs.offsetHeight

        this.cvs.style.left = 50 / this.cvs.style.zoom + 'px' // 150/cvs.style.zoom + 'px'
        this.cvs.style.top = (this.ref.current.offsetHeight / 2 - 50) / this.cvs.style.zoom + 'px'
        let nm = "datecanvas"
        let dateel = document.getElementById(nm)
        if (!dateel) {
            this.ref.current.appendChild(this.dateCanvas)
            this.dateCanvas.id = nm
            this.dateCanvas.style.top = ((-this.state.sigy + this.state.datey) * 150 / 96.0 + 100 - this.dateCanvas.offsetHeight) + 'px'
            this.dateCanvas.style.left = ((-this.state.sigx + this.state.datex) * 150 / 96.0 + 50) + 'px'
            this.dateCanvas.style.zIndex = 10000
            this.dateCanvas.style.position = 'absolute'
        }

    }
    doSig = () => {
        this.fields = this.state.signers[this.state.signer].tabs[this.state.sigindex].fields
        let js = this.state.signers[this.state.signer].tabs[this.state.sigindex].fields.signature
        let dt = this.state.signers[this.state.signer].tabs[this.state.sigindex].fields.date

        this.setState({
            clippath: 'rect(0px, 0px, 200px, 600px)',
            sigx: parseInt(js.xPosition),
            sigy: parseInt(js.yPosition),
            datex: parseInt(dt.xPosition),
            datey: parseInt(dt.yPosition),
        })
         // eslint-disable-next-line react/no-direct-mutation-state
        this.state.datex = parseInt(dt.xPosition)
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.datey = parseInt(dt.yPosition)
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.sigx = parseInt(js.xPosition)
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.sigy = parseInt(js.yPosition)

    }
    initSig = () => {
        this.setState({
            sigx: 0,
            sigy: 0,
            datex: 0,
            datey: 0,
            clippath: 'rect(0px, 0px, 200px, 600px)',
            sigindex: 0,
        })
    }
    onAccept = (cvs) => {
        if (cvs.height <= 1 || cvs.width <= 1)
            return
        this.setState({ showplacementpad: true })
        this.doSig()
        cvs.id = "signaturecanvas"
        let sigel = document.getElementById(cvs.id)
        if (sigel) {
            sigel.remove()
        }
        this.cvs = cvs
        this.ref.current.appendChild(cvs)

        this.dateCanvas.style.visibility = 'visible'

        this.rethinkSigGeometry()
    }
    sigAccepted = () => {
        this.fields.signature.pngBase64 = this.cvs.toDataURL()

        let x = 0 // 50 * 96 / 150
        this.fields.signature.xPosition = Math.round(parseInt(this.fields.signature.xPosition) + x).toString()

        this.fields.signature.width = Math.round(this.cvs.offsetWidth * this.cvs.style.zoom * 96.0 / 150).toString()
        this.fields.signature.height = Math.round(50 * 96.0 / 150).toString()

        this.fields.date.pngBase64 = this.dateCanvas.toDataURL()
        let dx = parseInt(this.fields.date.xPosition) // + 50 * 96.0 / 150
        this.fields.date.xPosition = Math.round(dx).toString()

        this.fields.date.width = Math.round(this.dateCanvas.offsetWidth * 96.0 / 150).toString()
        this.fields.date.height = Math.round(this.dateCanvas.offsetHeight * 96.0 / 150).toString()

        this.moveSig()
    }
    render = () => {

        return (
            <div className="text-center ml-0 pl-0 mt-3 ">

                <Modal dialogClassName="modal-50w" show={this.state.show} onHide={this.handleClose} onShow={this.onShow}>
                    <Modal.Header closeButton>
                        <Modal.Title className="capitalize">{this.state.name}, please sign the document</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5 hidden={this.state.sending} className="ml-3">Draw your signature on the pad, and click Accept</h5>
                        <Signature hidden={this.state.sending} ref={this.sigRef} width={600} height={200} onAccept={this.onAccept}></Signature>
                        <Row className="text-center" hidden={this.state.sending}>
                            <div ref={this.ref} className="mx-3 container clearfix" style={{
                                width: '100%', height: '200px', minHeight: '200px', overflow: 'hidden',
                                border: this.state.showplacementpad ? '1px solid steelblue' : '0px',

                            }}>
                                <div className="left">
                                    &nbsp;
                                </div>
                                <div className="right">
                                    &nbsp;
                                </div>
                                {this.state.preview ? <img
                                    hidden={!this.state.showplacementpad}
                                    style={{
                                        position: 'absolute', overflow: 'hidden',
                                        top: -this.state.sigy * 150 / 96.0 + 100, left: -this.state.sigx * 150 / 96.0 + 50,
                                        clipPath: this.state.clippath,
                                        zoom: this.signzoom,

                                    }} alt="view" src={prefix + this.state.preview[this.state.sigpage]} /> : ""}
                                <Button hidden={!this.state.showplacementpad} onClick={this.sigAccepted} variant='link'><img src={SignHere} alt="SignHere" style={{ position: 'absolute', zIndex: 20000, top: '100px', left: '70px' }} /></Button>
                            </div>
                        </Row>
                        <Row hidden={!this.state.sending}>
                            <Col className="text-center mb-5">
                                <h3 className="mb-3">Sending for cryptographic signing...</h3>
                                {this.state.spinner}
                            </Col>
                        </Row>

                    </Modal.Body>

                </Modal>

                {this.state.renderer}

            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Form1003)