import React, {Component, useState, useEffect} from "react";
import * as com from "../Common.js";
import Form from "react-bootstrap/Form";
import {Col, Row, Select} from "antd";
import {InputGroup} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
var parser = require("parse-address");

export default class AddressAutocomplete extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      value: props.defaultValue,
    };
  }

  onValidate = (adr) => {
    if (!this.props.onCompletion) {
      this.ref.current.setCustomValidity("");
      let parsed = parser.parseLocation(adr);
      if (parsed != null) {
        let busted = false;
        if (parsed.number === undefined) {
          busted = true;
          this.ref.current.setCustomValidity("Need to specify house number");
        }
        if (parsed.zip === undefined) {
          busted = true;
          this.ref.current.setCustomValidity("Need to specify zip code");
        }
        if (!busted) this.ref.current.setCustomValidity("");
        console.log("parsed addr", JSON.stringify(parsed));
      }
    }
  };
  onChange = (e) => {
    e.preventDefault();
    let adr = e.target.value;
    this.onValidate(adr);

    this.props.onChange(e);
  };
  onBlur = (e) => {
    let adr = e.target.value;
    this.onValidate(adr);
    if (this.props.onBlur != null) {
      this.props.onBlur(e);
    }
  };
  componentDidMount() {
    if (window.google == null) return;
    this.autocomplete = new window.google.maps.places.Autocomplete(document.getElementById(this.props.id), {
      types: ["address"],
      componentRestrictions: {country: "us"},
    });

    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
    this.onValidate(this.props.defaultValue);
  }
  handlePlaceSelect = () => {
    let addressObject = this.autocomplete.getPlace();
    let address = addressObject.address_components;

    if ("undefined" === typeof address) return;

    let num = com.getSubaddress(address, "street_number", "long_name");
    let str = com.getSubaddress(address, "route", "long_name");

    if (this.props.onCompletion) {
      let straddr = `${num} ${str}`;
      this.ref.current.value = straddr;
      this.onValidate(address);
      //this.props.onChange({target: {value: straddr}})
      this.props.onCompletion(address);
    } else {
      let city = com.getSubaddress(address, "locality", "long_name");
      let state = com.getSubaddress(address, "administrative_area_level_1", "short_name");
      let zip = com.getSubaddress(address, "postal_code", "long_name");
      let zips = com.getSubaddress(address, "postal_code_suffix", "short_name");
      //let country = com.getSubaddress(address, "country", "long_name")

      if (zips !== "") zip = zip + "-" + zips;

      let straddr = `${num} ${str}, ${city}, ${state} ${zip}`;
      this.onValidate(straddr);
      this.props.onChange({target: {value: straddr}});
    }
  };
  render = () => {
    return (
      <Form.Group
        controlId={this.props.id}
        className="text-left input-groups">
        {this.props.label !== null && this.props.label !== "" ? (
          <Form.Label className="text-left">{this.props.label}</Form.Label>
        ) : (
          ""
        )}
        <Form.Control
          isValid={false}
          size={this.props.size}
          ref={this.ref}
          required={this.props.required}
          name={this.props.name}
          onBlur={this.onBlur}
          autoComplete={this.props.autocomplete}
          type={this.props.type}
          value={this.props.defaultValue}
          onChange={this.onChange}
          pattern={this.props.pattern}
          placeholder={this.props.placeholder}
          style={this.props.style}
        />
        <Form.Control.Feedback>{this.props.goodfeedback}</Form.Control.Feedback>
        <Form.Control.Feedback type="invalid">{this.props.badfeedback}</Form.Control.Feedback>
      </Form.Group>
    );
  };
}

export const AddressComponent = ({id, label, address, state, county, city, zip, onAddressLineChange, onStateChange, onCountyChange, onCityChange, onZipChange, placeholder, cityPlaceholder, zipPlaceholder}) => {
  const [loanLimits, setLoanLimits] = useState([]);
  const [addressLine, setAddressLine] = useState(address);
  const [addressState, setAddressState] = useState(state);
  const [addressCounty, setAddressCounty] = useState(county);
  const [addressCity, setAddressCity] = useState(city);
  const [addressZip, setAddressZip] = useState(zip);

  useEffect(() => {
    fetchLoanLimits();
  }, []);

  const fetchLoanLimits = () => {
    fetch("/data/loanlimits")
      .then((res) => {
        if (!res.ok) {
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        setLoanLimits(res);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getStates = () => {
    let options = [];
    let keys = Object.keys(loanLimits);

    keys.forEach((x) => {
      options.push(<option key={x} value={com.capitalize(x)}>{com.capitalize(x)}</option>);
    });
    return options;
  };

  const getCounties = () => {
    let options = [];

    try {
      if (loanLimits[addressState.toUpperCase()]) {
        let keys = Object.keys(loanLimits[addressState.toUpperCase()]);
        keys.forEach((x) => {
          options.push(<option key={x} value={com.capitalize(x)}>{com.capitalize(x)}</option>);
        });
      }
    } catch (error) {
      console.log(error);
    }
    return options;
  };

  const onStreetAddressCompletion = (address) => {
    let num = com.getSubaddress(address, "street_number", "long_name");
    let city = com.getSubaddress(address, "locality", "long_name");
    let state = com.getSubaddress(address, "administrative_area_level_1", "short_name");
    let zip = com.getSubaddress(address, "postal_code", "long_name");
    let county = com.getSubaddress(address, "administrative_area_level_2", "long_name");

    let longstate = com.getSubaddress(address, "administrative_area_level_1", "long_name");
    let short = com.getSubaddress(address, "route", "short_name");
    let stradd = num + " " + short;

    setAddressLine(stradd);
    setAddressState(longstate);
    setAddressCounty(county);
    setAddressCity(city);
    setAddressZip(zip);

    onAddressLineChange(stradd);
    onStateChange(longstate);
    onCountyChange(county);
    onCityChange(city);
    onZipChange(zip);
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <AddressAutocomplete
            id={id}
            label={label}

            name="addressline"
            defaultValue={addressLine}
            onChange={(e) => {
              setAddressLine(e.target.value);
              onAddressLineChange(e.target.value);
            }}
            placeholder={placeholder ? placeholder : "Enter the address"}
            goodfeedback=""
            badfeedback="Please provide your street address."
            onCompletion={(e) => onStreetAddressCompletion(e)}
            size="md"
          />
        </Col>
      </Row>
      <Row justify="space-between" className="mt-3"  gutter={16}>
        <Col span={6}>
            <Select
              className="w-100"
              placeholder="State"
              value={addressState || "State"}
              onChange={(e) => {
                setAddressState(e);
                onStateChange(e);
              }}>
              {getStates()}
            </Select>
        </Col>
        <Col span={6}>
            <Select
                className="w-100"
                placeholder="County"
              onChange={(e) => {
                setAddressCounty(e);
                onCountyChange(e);
              }}
              value={addressCounty || "County"}>
              {getCounties()}
            </Select>
        </Col>
        <Col span={6}>
          <Form.Group>
            <Form.Control
              required
              onChange={(e) => {
                setAddressCity(e.target.value);
                onCityChange(e.target.value);
              }}
              value={addressCity}
              placeholder={cityPlaceholder ? cityPlaceholder : "City"}
            />
          </Form.Group>
        </Col>
        <Col span={6}>
          <Form.Group>
            <Form.Control
              required
              onChange={(e) => {
                setAddressZip(e.target.value);
                onZipChange(e.target.value);
              }}
              value={addressZip}
              placeholder={zipPlaceholder ? zipPlaceholder : "Zip code"}
            />
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};
