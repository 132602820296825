import React, { Component } from "react";
import Chart from "react-apexcharts";
import * as com from "../Common.js"
function dateRange(startDate, endDate) {
  var start = startDate.split("-");
  var end = endDate.split("-");
  var startYear = parseInt(start[0]);
  var endYear = parseInt(end[0]);
  var dates = [];

  for (var i = startYear; i <= endYear; i++) {
    var endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
    var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
    for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      var month = j + 1;
      var displayMonth = month < 10 ? "0" + month : month;
      dates.push([i, displayMonth, "01"].join("-"));
    }
  }
  return dates;
}
class AmortizationChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="app " style={{ color: "black" }}>
        <Chart
          options={{
            chart: {
              width: 350,
              type: "line",
              toolbar: {
                show: false,
              },
              dropShadow: {
                enabled: true,
                color: "#000",
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2,
              },
            },
            tooltip: {
              y: {
                formatter: (value) => {
                  return "$" + com.commaizeFloat(Math.round(value));
                },
              },
              x: {
                formatter: function (value) {
                  let d = new Date(value)
                  return d.toLocaleDateString('en-us', {year: 'numeric', month: 'long'});
                },                
              }
            },
            legend: {
              show: true,
              position: "top",
            },
            stroke: {
              curve: "smooth",
            },
            xaxis: {
              type: "datetime",
              categories: dateRange("2021-04-01", "2051-03-01").slice(
                0,
                this.props.n
              ),
              tickAmount: 10,
              labels: {
                formatter: function (value, timestamp, opts) {
                  return opts.dateFormatter(new Date(timestamp), "yyyy");
                },
              },
            },
            yaxis: {
              labels: {
                formatter: (value) => {
                  return  "$" + com.commaizeFloat(Math.round(value));
                },
              },
            },
          }}
          series={[
            {
              name: "Remaining",
              type: "line",
              data: this.props.remaining,
            },
            {
              name: "Principal Paid",
              type: "line",
              data: this.props.monthlyPrinciple,
            },
            {
              name: "Interest Paid",
              type: "line",
              data: this.props.monthlyInterest,
            },
          ]}
          //   width="700"
        />
      </div>
    );
  }
}

export default AmortizationChart;
