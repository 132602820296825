import React, { useEffect, useState } from "react";
import { Button, Input, message, Modal, Select } from "antd";
import { isEmailValid } from "./utils";
import "./SharePreQualLetterModal.css";
import { useSelector } from "react-redux";

interface ShareModalProps {
  visible: boolean;
  onClose: () => void;
}

export const SharePreQualLetterModal = (props: ShareModalProps) => {
  const { visible, onClose } = props;
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [emailInput, setEmailInput] = useState("");
  const [emailSuggestions, setEmailSuggestions] = useState([]);
  const application = useSelector((state) => (state as any).application);
  const state = useSelector((state) => state);
  console.log("application", application);
  console.log("state", state);

  useEffect(() => {
    if (application?.brokerinfo?.email) {
      const suggestions = [
        {
          label: application.brokerinfo.loanofficername,
          value: application.brokerinfo.email,
        },
      ];
      setEmailSuggestions(suggestions);
    }
  }, []);

  const handleEmailChange = (value) => {
    console.log("value", value);
    setSelectedEmails(value);
  };

  const handleSend = () => {
    if (selectedEmails.length === 0) {
      message.warning("Please select at least one email.");
      return;
    }
    console.log("Emails to send:", selectedEmails);
    // TODO: Send emails

    message.success("Emails sent successfully!");
    setSelectedEmails([]);
    onClose();
  };

  return (
    <Modal
      title="Share your pre-qual letter"
      open={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="send"
          type="primary"
          onClick={handleSend}
          disabled={selectedEmails.length === 0}
        >
          Send
        </Button>,
      ]}
    >
      <div style={{ width: "100%" }}>
        <Select
          mode="multiple"
          placeholder="Enter email address"
          value={selectedEmails}
          onChange={handleEmailChange}
          style={{ width: "100%", height: "auto !important" }}
          popupMatchSelectWidth={true}
          showSearch
          filterOption={(input, option) =>
            (option.value + option.label)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          options={emailSuggestions}
          optionRender={(option) => (
            <div>{`${option.label} (${option.value})`}</div>
          )}
          dropdownRender={(menu) => (
            <>
              {menu}
              <div style={{ display: "flex", padding: 8 }}>
                <Input
                  style={{ flex: "auto" }}
                  placeholder="Add new email"
                  value={emailInput}
                  onChange={(e) => {
                    // stop propagation to prevent the email addresses input from being cleared
                    console.log("event", e);
                    e.stopPropagation();
                    setEmailInput(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Backspace") {
                      // Prevent backspace from deleting the last selected email
                      e.stopPropagation();
                    }
                  }}
                />
                <Button
                  type="text"
                  onClick={() => {
                    if (
                      emailInput &&
                      isEmailValid(emailInput) &&
                      !selectedEmails.includes(emailInput)
                    ) {
                      setSelectedEmails([...selectedEmails, emailInput]);
                      setEmailInput("");
                    } else {
                      message.warning("Please enter a valid and unique email.");
                    }
                  }}
                >
                  Add Email
                </Button>
              </div>
            </>
          )}
        />
      </div>
    </Modal>
  );
};
