import React, {useState, useEffect} from "react";
import {Form, Input, Row, Col, Radio, InputNumber, Button, Steps, message, Spin, Space} from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import {useParams} from "react-router-dom";
import {AddressComponent} from "../../Common/AddressAutocomplete.js";
import {DollarInput} from "../../Common/components/DollarInput.js";
import {connect} from "react-redux";
import * as act from "../../Store/actions.js";
import * as com from "../../Common.js";
import { State } from '../../State'
import { getCurrentState, loadState, updateState, mergeState } from '../../Store'

const mapStateToProps = (state) => {
  return {
    application: state.application,
    progress: state.progress,
    property: state.application.property,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateProduct: (v, verb) => {
    dispatch(act.UpdateProduct(v, verb));
  },
  updateSelectField: (input, verb) => {
    dispatch(act.UpdateSelectField(input, verb));
  },
  changeMainPropertyAttribute: (t, verb) => {
    dispatch(act.ChangeMainPropertyAttribute(t, verb));
  },
  changeBorrowerInfo: (event, who, verb) => {
    dispatch(act.ChangeBorrowerInfo(event, who, verb));
  },
  updateSelectField: (input, verb) => {
    dispatch(act.UpdateSelectField(input, verb));
  },
  changeOccupationInfo: (event, verb) => {
    dispatch(act.ChangeOccupationInfo(event, "borrower", verb));
  },
  updateApplicationAttribute: (input, verb) => {
    dispatch(act.UpdateApplicationAttribute(input, verb));
  },
  changeDeclaration: (val, verb) => {
    dispatch(act.ChangeDeclaration(val, "borrower", verb));
  },
});

const ContactInformation = (props) => {
  const {customerid} = useParams();
  const [verificationCode, setVerificationCode] = useState("");
  const [showCode, setShowCode] = useState(false);
  const [sendingCode, setSendingCode] = useState(false);


  const [verifyingCode, setVerifyingCode] = useState(false);
  const [showPastLoansModal, setShowPastLoansModal] = useState(false);
  const [currentLoanId, setCurrentLoanId] = useState(null);
 

  const sendcode = () => {
    setVerificationCode("")
    setSendingCode(true);
    props.setVerified(null)
    fetch("/auth/generateonetimepasscode", {
        cache: 'no-cache',
        method: 'POST',
        body: JSON.stringify({
            email: props.application.borrower.email.toLowerCase(),
            customerID: sessionStorage.getItem("cid"),
            loID: sessionStorage.getItem("loid") === null ? "" : sessionStorage.getItem("loid"),
        }),
        headers: {
            'Content-Type': 'application/json',
            Cache: "no-cache"
        },
    }).then(response => {
        if (!response.ok) {
            console.log(response)
        } else {
            setShowCode(true)
        }
        setSendingCode(false)
    }).catch(error => {
        console.log(error)
    })
}

  const verifycodeAndCheckPastLoans = (code) => {
    setVerifyingCode(true)
    fetch("/auth/verifycodeandcheckpastloans", {
        cache: 'no-cache',
        method: 'POST',
        body: JSON.stringify({
            email: props.application.borrower.email.toLowerCase(),
            firstName: props.application.borrower.firstname,
            lastName: props.application.borrower.lastname,
            code: code,
            customerID: sessionStorage.getItem("cid"),
            loID: sessionStorage.getItem("loid") === null ? "" : sessionStorage.getItem("loid"),
            State: JSON.stringify(loadState()), // change to com.ascii2hex(sessionStorage.getItem("state")) ?
        }),
        headers: {
            Cache: "no-cache"
        },
    }).then(response => {
        if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' + response.status)
            props.setVerified(false)
            setVerifyingCode(false)
            setVerificationCode("")
            return
        }
        response.json().then(js => {
          props.setAlreadySignedUp(js.AlreadySignedUp)  
            if (js.LoanStates && js.LoanStates.loans) {
                js.LoanStates.loans.forEach(loan => {
                    loan.state = JSON.parse(loan.state)
                    loan.lostate = JSON.parse(loan.lostate)
                })
                props.setPastLoans(js.LoanStates.loans)
                setCurrentLoanId(js.LoanStates.loanid)
                
              }
            props.setVerified(true)
            setVerifyingCode(false)
        })

    }).catch(error => {
        console.log(error)
        setVerificationCode("")
    })
}

  let b = props.application.borrower;
  let updateBorrower = (key, value) => {
    props.changeBorrowerInfo(value, "borrower", key);
  };
  return (
    <div className="email-verificaition-simple">
      <Row gutter={[16, 24]}>
        <Col
          className="gutter-row"
          xs={24}
          md={12}>
          <div className="formLabel mt-4">First name</div>
          <Input
            onChange={(e) => updateBorrower("firstname", e.target.value)}
            value={b.firstname}
          />
        </Col>
      </Row>
      <Row gutter={[16, 24]}>
        <Col
          className="gutter-row"
          xs={24}
          md={12}>
          <div className="formLabel mt-4">Last name</div>
          <Input
            onChange={(e) => updateBorrower("lastname", e.target.value)}
            value={b.lastname}
          />
        </Col>
      </Row>
      <Row gutter={[16, 24]}>
        <Col
          className="gutter-row"
          xs={24}
          md={12}>
          <div className="formLabel mt-4">Email</div>
          <Space.Compact style={{width: "100%"}}>
            <Input
              className="email-input"
              onChange={(e) => {
                const email = e.target.value;
                updateBorrower("email", email);
              }}
              value={b.email}
            />
            <Button
              disabled={b.email === "" || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(b.email)}
              type="suffix"
              onClick={() => sendcode()}>
              {props.verified === true ? ""  : sendingCode ? <Spin /> : showCode ? "Resend" : "Send code"}
            </Button>
          </Space.Compact>
          {b.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(b.email) && (
            <div style={{color: "#CB8600", fontSize: "12px", marginTop: "5px"}}>
              Please enter a valid email address
            </div>
          )}
        </Col>
        {showCode && (props.verified === null || props.verified === false) && (
          <Col>
            <div className="formLabel mt-4">Verification code</div>
            <Input.OTP
              status={props.verified === false ? "error" : ""}
              length={4}
              level={8}
              value={verificationCode}
              onChange={(e) => {
                setVerificationCode(e);
                if (e.length === 4) {
                  verifycodeAndCheckPastLoans(e);
                }
              }}
            />
            {props.verified === false && <div style={{color: "#C21818", fontSize: "12px", marginTop: "3px"}}>
              Incorrect code. Please check your email and try again.
            </div>}
          </Col>
        )}
        {
          props.verified === true && <Col>
            <CheckCircleFilled  style={{fontSize:19, color:"#325CEB", marginTop:66}}/>
          </Col>
        }
      </Row>
      <Row gutter={[16, 24]}>
        <Col
          className="gutter-row"
          xs={24}
          md={12}>
          <div className="formLabel mt-4">Phone</div>
          <Input
            onChange={(e) => updateBorrower("homephone", com.fixPhoneInput(e.target.value))}
            value={b.homephone}
          />
        </Col>
      </Row>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactInformation);
