import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import LandingMenu from "./Landing/LandingMenu.js"

import Copyright from "./Copyright.js"
export default function Error404() {
  // THIS IS A HACK. I DON'T KNOW HOW TO SOLVE THIS RIGHT!!!
  // something to do with the wrong nesting
  if ( (window.location.pathname === "/" && window.location.hash.length > 0) || window.location.pathname === "/resources/faq") {
    window.location = window.location.href
    return (
      <div></div>
    )
  } else {
    return (
      <div className=" py-0 my-0">
        <LandingMenu />
        <div id="landing1" className="container my-5" >
          <div className="row">
            <div className="col-sm mt-5  text-center">
              <h1 className="mt-5 ">Error 404.</h1>
              <h1>Page Not Found.</h1>
              <div className="pt-5 punch">
                The application is still under construction. Please be patient!
          </div>
              <div className="mt-5  text-center" >


              </div>
            </div>
            <div className="col-sm ml-5">
              <img id="homepic" className="pl-5" alt="Your future home" src="/home.svg" />
            </div>
          </div>
          <div className="mt-5">&nbsp;</div>
          <div className="mt-5">&nbsp;</div>

        </div>

        <Copyright />

      </div>
    )
  }
}
