import React, { Component } from 'react'
import './App.css'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { LinkContainer } from 'react-router-bootstrap'

import Button from 'react-bootstrap/Button'

/* const circle = <svg style={{ zIndex: 102, position: 'absolute', top: '-23px', right: '-9px' }}  width="90" height="90" version="1.1" viewBox="0 0 90 90" >

<defs>
 <filter id="a" x="0" y="0" width="109" height="109" filterUnits="userSpaceOnUse">
  <feOffset dy="3" input="SourceAlpha"/>
  <feGaussianBlur result="b" stdDeviation="10"/>
  <feFlood flood-opacity=".204"/>
  <feComposite in2="b" operator="in" result="result1"/>
  <feComposite in="SourceGraphic" in2="result1"/>
 </filter>
</defs>
<g transform="matrix(.85218 0 0 .82563 25.565 22.292)">
 <g transform="translate(-30,-27)" filter="url(#a)">
  <g transform="translate(30,27)" fill="#fff" stroke="#fff">
   <circle cx="24.5" cy="24.5" r="24.5" stroke="none"/>
   <circle cx="24.5" cy="24.5" r="24" fill="none"/>
  </g>
 </g>
 <circle transform="translate(14,13)" cx="11" cy="11" r="11" fill="#1665d0"/>
</g>
</svg>
*/
const circle = <img src="/circle.png" alt="circle" style={{width: '82px', marginTop:'-100px', marginRight:'-10px'}}/>

class How extends Component {
    constructor(props) {
        super(props)
        this.index = 0
        this.timer = 0
        this.state = {
            hidden: false,

        }
        this.tagRef = React.createRef()
    }

    render = () => {
        return (

            <div className="w-100 text-center deepestblue">
                <Row id="how" className="w-100">
                    <Col className="pt-5 pb-2 mt-2 ">
                        <h3>HOW</h3>
                        <h2 className="heavyspaced mb-3"
                        >ZEITRO WORKS</h2>
                    </Col>
                </Row>
                &nbsp;<br />

                <Row className="padrow text-center w-100" >
             
                    <Col xs="5" className="bimagex pl-0 pr-1">

                        <div className="m-0 p-0  text-right">
                            <h4 style={{marginTop: '-2px'}} className="pb-3 pt-0 steptop">STEP 1</h4>
                            {circle}
                        </div>
                    </Col>
                    <Col xs="6" className="text-left">
                        <h5 className="pb-3 mt-0 pt-0 steptop1"><div className="capitalize">GET PRE-QUALIFIED</div></h5>
                        <div className="mb-5">Pre-qualification provides you with a general idea of the loan amount you will likely qualify for. This process will only take a few minutes!
                            </div>
                    </Col>

                </Row>
                <Row className="padrow text-center w-100" >
                    <Col  xs="5" className="bimagex pl-0 pr-1">

                        <div className="m-0 p-0  text-right">
                            <h4 className="pb-3 steptop">STEP 2</h4>
                            {circle}
                        </div>
                    </Col>
                    <Col  xs="6" className="text-left">
                        <h5 className="pb-3 steptop1"><div className="capitalize">COMPLETE APPLICATION</div></h5>
                        <div className="mb-5">Zeitro will walk you through the whole application process online. Once you upload the necessary documents and provide relevant financial information, Zeitro will examine your options with our AI technology. We will then present you with a list of recommended loans according to your financial situation. 
                            </div>
                    </Col>
                </Row>   
                <Row className="padrow text-center w-100" >
                    <Col  xs="5" className="bimagex pl-0 pr-1">

                        <div className="m-0 p-0  text-right">
                            <h4 className="pb-3 steptop">STEP 3</h4>
                            {circle}
                        </div>
                    </Col>
                    <Col  xs="6" className="text-left">
                        <h5 className="pb-3 steptop1"><div className="capitalize">ANALYZE OPTIONS</div></h5>
                        <div className="mb-5">Zeitro evaluates numerous mortgages on the market from a variety of lenders and will provide customers an optimization tool to select the best one for your needs. 
                            </div>
                    </Col>
                </Row>     
                <Row className="padrow text-center w-100" >
                    <Col  xs="5" className="bimagex pl-0 pr-1">

                        <div className="m-0 p-0  text-right">
                            <h4 className="pb-3 steptop">STEP 4</h4>
                            {circle}
                        </div>
                    </Col>
                    <Col  xs="6" className="text-left">
                        <h5 className="pb-3 steptop1"><div className="capitalize">CLOSE THE MORTGAGE!</div></h5>
                        <div className="mb-5">Once you have chosen the option that best suits you, Zeitro will finish the process with the chosen lender to secure your mortgage.  
                            </div>
                            <LinkContainer to="/services/signup">
                    <Button vsize="lg" ariant="primary" className="landingbutton lpbutton mb-5" onClick>
                        Apply Now
                </Button>
                </LinkContainer>

                    </Col>
                </Row>                                                

                <div className="my-5">&nbsp;</div>
            </div>
        )
    }
}


export default class Howitworks extends Component {

    render = () => {
        return (
            <div className="mb-5" style={{ minHeight: '1200px' }}>

                <How />
             
            </div>
        )
    }
}