import React, { Component } from 'react'
import 'awesome-steps/dist/style.css'
import './App.css'

import Steps from 'awesome-steps'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { LinkContainer } from 'react-router-bootstrap'
import { HashLink } from "react-router-hash-link"
import LandingMenu from './Landing/LandingMenu'
import LandingCopyright from './Landing/LandingCopyright'
import Button from 'react-bootstrap/Button'
import HomeButton from "./Landing/HomeButton.js"
import { Link } from 'react-router-dom'
const tagLines = [

    "Digital mortgage broker that shops on your behalf",
    "Save thousands of dollars on your mortgage. Let us find you the best deal!",
    "We'll take care of your mortgage loan from the beginning to the end.",
    "Mortgage loan made simple. On your timeline. On your terms. For you!",
    "Taking mortgage loans into the 21st century with modern technology."
]

class ArrowDown extends Component {
    constructor(props) {
        super(props);

        this.state = {

            redirect: "",
            hidden: true,
        };
    }

    click = () => {
        // this does not reload the page
        window.location.hash = ""
        window.scrollTo(0, 0);
    }

    listenScrollEvent = e => {

        if (window.pageYOffset > 10) {
            if (!this.state.hidden)
                this.setState({ hidden: true })
        } else {
            if (this.state.hidden)
                this.setState({ hidden: false })
        }
    }
    componentDidMount() {
        window.addEventListener('scroll', this.listenScrollEvent)
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenScrollEvent)
    }
    render() {
        return (
            <HashLink hidden={this.state.hidden} className="arrowdown" to="/#how"><i className="fas fa-arrow-circle-down fa-2x"></i></HashLink>
        )
    }
}

class FirstPage extends Component {
    constructor(props) {
        super(props)
        this.index = 0
        this.timer = 0
        this.state = {
            hidden: false,
            tagline: tagLines[this.index]
        }
        this.tagRef = React.createRef()
        this.towait = 20000
    }
    listenResizeEvent = e => {
        if (window.innerWidth < 300) {
            if (!this.state.hidden) {
                this.setState({ hidden: true })

            }
        } else {
            if (this.state.hidden) {
                this.setState({ hidden: false })

            }
        }
    }
    componentDidMount() {
        window.addEventListener('resize', this.listenResizeEvent)
        this.listenResizeEvent()
        //this.wait()
    }
    componentWillUnmount() {
        clearTimeout(this.timer)
        clearInterval(this.timer)
        this.timer = 0
    }

    wait = () => {
        this.timer = setTimeout(this.fadeOut, this.towait)
        this.towait = 6000
    }
    fadeIn = () => {
        let elem = this.tagRef.current
        if (!elem)
            return;

        elem.style.opacity = 0;
        elem.style.filter = "alpha(opacity=0)";

        let ms = 2000
        if (ms) {
            var opacity = 0;
            this.timer = setInterval(() => {
                opacity += 50 / ms;
                if (opacity >= 1) {
                    clearInterval(this.timer);
                    opacity = 1;
                    this.wait()
                }
                elem.style.opacity = opacity;
                elem.style.filter = "alpha(opacity=" + opacity * 100 + ")";
            }, 50);
        }
    }

    fadeOut = () => {
        let elem = this.tagRef.current
        if (!elem)
            return;
        let ms = 2000
        if (ms) {
            var opacity = 1;
            this.timer = setInterval(() => {
                opacity -= 50 / ms;
                if (opacity <= 0) {
                    clearInterval(this.timer);
                    opacity = 0;
                    this.index = (this.index + 1) % tagLines.length
                    this.setState({ tagline: tagLines[this.index] })
                    this.fadeIn()
                }
                elem.style.opacity = opacity;
                elem.style.filter = "alpha(opacity=" + opacity * 100 + ")";
            }, 50);
        }
    }

    renderButton = (variant) => {

        return (
            <LinkContainer to="/services/signup">
                <Button variant={undefined === variant ? "primary" : variant} className={undefined === variant ? "landingbutton lpbutton" : ""}>
                    Start Your Loan Application
                </Button>
            </LinkContainer>
        )

    }


    render = () => {

        return (

            <div className="w-100 text-center mx-0 " >

                <div className="mx-5 mt-0 pt-0 text-center myrow" style={{ alignItemr: 'center', position: 'relative', minHeight: '700px' }} >
                    <div hidden={this.state.hidden} style={{ width: '5.6em' }}>
                        &nbsp;
                    </div>
                    <div className="mr-0  mt-1 mpt-2 mr-4   text-center">
                        <div className=" mpt-4">
                            <h1 className="tagline   text-left" ref={this.tagRef} >
                                {this.state.tagline}
                            </h1>
                            <div style={{ fontWeight: "bold" }} className="text-left mt-5 deepestblue">
                                Whether you need to buy or refinance a home, we’ll help you through the process,
                                from finding the best deal to closing.
                                <br /> <br />Fully online, hassle and spam-free.
                            </div>
                            <div className="mt-5 pt-3  text-left">
                                {this.renderButton()}
                            </div>
                            <div className=" mt-3 text-left deepestblue" style={{ fontSize: "0.9em" }}>
                                Currently serving California.
                        </div>
                        </div>

                    </div>
                    <div hidden={this.state.hidden} className=" mt-5  pt-5 align-center happycouple">
                        <img src="/hobbit.png" hidden={this.state.hidden} alt="Your future home" className="frontimage"></img>
                    </div>
                    <div hidden={this.state.hidden} style={{ width: '6em' }}>
                        &nbsp;
                    </div>

                </div>

                <div className="backimage mobilewide mx-0 mt-0 pt-0 text-center " style={{ alignItemr: 'center', position: 'relative', minHeight: '700px', backgroundColor: '#F7F9FC' }} >
                    <Row id="how" className="px-5">
                        <Col >
                            <h1 className="tagline  text-left" >You get the best deal from thousands of options</h1>
                        </Col>

                    </Row>
                    <Row className="pt-3 pb-3 px-5 ">
                        <Col >
                            <div style={{ fontSize: '1.1em' }} className="text-left mt-4">Straightforward experience with you in the driver seat.<br />
                    No sales pressure. Your timeline. Your choices. Your savings. </div>
                        </Col>
                    </Row>
                    <div  id="explainsteps" className="text-left mx-5 mt-5 explainsteps" >
                        <Steps  direction="vertical" current={0} className="text-left">
                            <Steps.Step title="Tell us about your needs" description="Take 5 minutes to answer some simple questions. We evaluate your credit and income, and show you the real rates that you qualify for." />
                            <Steps.Step title="Compare rates, costs and fees" description="Get best personalized rates out of 1,000+ programs with transparent fees. Make an informed choice that is best for you." />
                            <Steps.Step title="Submit application, get the funds" description="We will help you to finalize the application and get a loan with minimal efforts." />
                            <Steps.Step title="Continue saving" description="We monitor the market and let you know when rates are low to refinance your loan." />


                        </Steps>
                    </div>
                </div>


                {/*
                <div className=" px-5 mx-0 mt-0 pt-0 text-center pb-5" style={{ alignItemr: 'center', position: 'relative' }} >
                    <Row id="how"  >
                        <Col >
                            <h1 className="tagline  text-left" >You’re in control</h1>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Row className="pt-3 pb-3">
                        <Col >
                            <div className="text-left">Choose the right balance between monthly payments, upfront cost, and total cost over the life time of your loan</div>
                        </Col>
                    </Row>
                    {item("Real rates, not teasers", "Don't waste your time on appointments and phone calls. Get real rates online from 1,000+ loan programs.")}

                    {item("Transparent fees", "We disclose estimates of all costs and fees of the loan products offered to you right away. You compare apples to apples. No surprises at the end of the process.")}

                    {item("Your loan officer works for you", "Whenever you have a question, or need help, a dedicated loan officer is there for you. Use online chat anytime, or schedule a call at your convenience.")}

                    <Row className="pt-3 pb-5">
                        <Col >

                        </Col>
                    </Row>
                </div>
*/}
                <div className=" mobilewide mx-0 mt-0 pt-0 text-center pb-5" style={{ alignItem: 'center', position: 'relative' }} >
                    <Row id="how"  >
                        <Col >
                            <h1 className="tagline  text-center" >You’re in control</h1>
                        </Col>

                    </Row>
                </div>
                <div className="tablecontainer   pb-5 mb-5  text-center " >

                    <div className="tablerow gray-shadow deepestblue">
                        <h4 className="mb-4 littleheading" style={{ color: '#215ef4' }}> Real rates, not teasers</h4>
    Don't waste your time on appointments and phone calls. Get real rates online from 1,000+ of loan programs.
</div>
                    <div className="tablerow gray-shadow deepestblue">
                        <h4 className="mb-4 littleheading" style={{ color: '#215ef4' }}>Transparent fees</h4>

We disclose estimates of costs and fees of the loan products offered to you immediately. You compare apples to apples. No surprises at the end of the process.
        </div>
                    <div className="tablerow gray-shadow deepestblue">
                        <h4 className="mb-4 littleheading" style={{ color: '#215ef4' }}>Your loan officer works for you</h4>

Whenever you have a question, or need help, a dedicated loan officer is there for you. Use online chat anytime, or schedule a call at your convenience.
</div>

                </div>
                <div className="backimagecalc mobilewide mx-0 mt-0 pt-0 text-center pb-5 pt-2" style={{ alignItemr: 'center', position: 'relative', backgroundColor: '#F7F9FC' }} >
                    <Row className="py-3" >
                        <Col id="landingabout" className="py-2 mt-2 text-center"  >
                            <h1 className="mt-4 tagline">Use Zeitro Mortgage Tools</h1>

                            <div className="w-100 mt-5 text-center deepestblue">
                                <div style={{ marginLeft: 'auto', marginRight: 'auto', fontSize: '1.0em', maxWidth: '600px' }}>Whether you're buying or refinancing, we provide you with the set of tools to research your next loan before your commit to applying.
                            <Row className=" text-center mt-5" style={{color: '#294B87'}}>
                                <Col classNamme="mr-5"> <Link to="/resources/tools/calculator"><i className="fas fa-3x mr-1 fa-calculator"></i>Mortgage Calculator</Link></Col>
                                <Col classNamme="ml-5"><Link to="/resources/tools/historic"><i className="fas fa-3x mr-1 fa-chart-area"></i>Historical mortgage rates</Link></Col>
                            </Row> 
                            </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="mobilewide mx-0 mt-0 pt-0 text-center pb-5 pt-2" style={{ alignItemr: 'center', position: 'relative', backgroundColor: '#FFFFFF' }} >

                    <Row className="py-3" >
                        <Col id="landingabout" className="py-2 mt-2 text-center"  >
                            <h1 className="mt-4 tagline">We care about your privacy</h1>

                            <div className="w-100 mt-5 text-center deepestblue">
                                <div style={{ marginLeft: 'auto', marginRight: 'auto', fontSize: '1.0em', maxWidth: '600px' }}>Zeitro is dedicated to securing your data from the moment we collect it. Once your information reaches our platform, we will shield it from any potential threats. Most importantly, your data will never be sold.
                            </div>
                            </div>
                        </Col>
                    </Row>
                    <Row >
                        <Col className="pb-1  text-center"  >
                            <img alt="cloud" className="mb-3" style={{ width: '72px' }} src='/cloud.png'></img>
                        </Col>
                    </Row>
                </div>

                <div className="px-5 mx-0 mt-0 pt-0 text-center pb-5" style={{ alignItemr: 'center', position: 'relative', backgroundColor: '#294B87' }} >
                    <Row  >
                        <Col >
                            <h1 className="tagline text-center"><div style={{ color: '#FFFFFF' }}>Pay less, be happy!</div></h1>
                        </Col>

                    </Row>
                    <Row className="mt-5">
                        <Col >
                            {this.renderButton('landing')}
                        </Col>

                    </Row>

                </div>

            </div>
        )
    }
}

export default class LandingPage extends Component {

    render = () => {
        return (
            <div id="firstpage" style={{ minHeight: '700px' }}>
                <LandingMenu />
                <ArrowDown />
                <FirstPage />
                <LandingCopyright />
                <HomeButton />
            </div>
        )
    }
}