import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const trackingID = 'UA-159921911-2';

const usePageTracking = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        //For production
        if (!window.location.href.includes("localhost")) {
        //For testing
        // if (1) {
            ReactGA.initialize(trackingID);
            setInitialized(true);
        }
    }, []);

    useEffect(() => {
        if (initialized) {
            ReactGA.ga('require', 'displayfeatures');
            ReactGA.pageview(location.pathname);
        }
    }, [initialized, location]);
};

export default usePageTracking;