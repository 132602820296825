import React from "react";

interface PDFViewerProps {
  src: string;
  fileName: string;
}

const PDFViewer: React.FC<PDFViewerProps> = ({ src, fileName }) => {
  return (
    <div style={{ textAlign: "center", border: "gray", height: "100%" }}>
      <iframe
        src={src}
        title={fileName}
        style={{
          border: "gray 2px solid",
          height: "100%",
          width: "100%",
        }}
      />
    </div>
  );
};

export default PDFViewer;
