import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

export default function Privacy(){

    return (
        <div className=" py-0 my-0">
            <h3 className="mb-4">PRIVACY POLICY</h3>
            <div id="landing1" className="container my-3 text-left" >
                <h4 className="text-left ">Privacy Statement</h4>

                <div className="my-3">
                Zeitro is committed to protecting your privacy and developing technology that gives you the most powerful and safe online experience. This Privacy Policy applies to the Zeitro Web site and governs data collection and usage. By using the Zeitro website, you consent to the data practices described in this statement.                
                </div>

                <h4 className="text-left ">Collection of your Personal Information</h4>
                <div className="my-3">
                Zeitro collects personally identifiable information, such as your e-mail address, name, home or work address or telephone number. Zeitro also collects anonymous demographic information, which is not unique to you, such as your ZIP code, age, gender, preferences, interests and favorites. We also collect the supporting documents necessary to apply for your mortgage loan, such as bank statements, tax returns, etc. with your explicit permission.

                There is also information about your computer hardware and software that is automatically collected by Zeitro. This information can include: your IP address, browser type, domain names, access times and referring Web site addresses. This information is used by Zeitro for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the Zeitro Web site.
                </div>

                <h4 className="text-left ">Use of your Personal Information</h4>
                <div className="my-3">                
                Zeitro collects and uses your personal information to operate the Zeitro Web site and deliver the services you have requested.
                <br/>
                <b>Zeitro does not sell, rent or lease its customer lists to third parties.</b> 
                <br/>
                Your personal information which is necessary to apply for a mortgage loan will only be supplied to an underwriter with your explicit permission.
                </div>

                <h4 className="text-left ">Use of Cookies</h4>
                <div className="my-3">                  
                The Zeitro Web site itself does not use cookies. However, some 3rd party services, such as Google Analytics, can use cookies.
                </div>

                <h4 className="text-left ">Security of your Personal Information</h4>
                <div className="my-3">              
                Zeitro secures your personal information from unauthorized access, use or disclosure. Zeitro secures the personally identifiable information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use or disclosure. When personal information (such as a credit card number) is transmitted to other Web sites, it is protected through the use of encryption, such as the Secure Socket Layer (SSL) protocol.
                </div>

                <h4 className="text-left ">Changes to this Statement</h4>
                <div className="my-3">                 
                Zeitro will occasionally update this Statement of Privacy to reflect company and customer feedback. Zeitro encourages you to periodically review this Statement to be informed of how Zeitro is protecting your information.
                </div>
                <h4 className="text-left ">Contact Information</h4>
                <div className="my-3">                 
                Zeitro welcomes your comments regarding this Statement of Privacy. If you have any questions or concerns about this notice, or about the privacy of your nonpublic personal information that you have provided via use of this website, or would like to opt out from future contact by Zeitro, please contact Zeitro at <a href="mailto:contact@zeitro.com">contact@zeitro.com</a>. If you believe that Zeitro has not adhered to this Statement, please contact Zeitro. We will use commercially reasonable efforts to promptly determine and remedy the problem.
                </div>
            </div>
        </div>
    );
}
