import "./SampleRates.css";
import { BrowserRouter as Router } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { useBreakPoints, useViewport } from "../HistoricalChart/Hooks";
import { Col, Row, Card } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { tooltip, tooltipFlat } from "../Common/Tooltip";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import * as com from "../Common";

const Container = styled.span`
  display: flex;
  width: 100%;
  height: calc(0.625 * 100vw);
  align-items: center;
  justify-content: center;
`;

const calculateDimensions = (breakPoints, viewport) => {
  let w, h;
  if (breakPoints.xl) {
    w = 1000;
    h = 800;
  } else if (breakPoints.lg) {
    w = 800;
    h = 600;
  } else if (breakPoints.md) {
    w = 600;
    h = 400;
  } else if (breakPoints.sm || breakPoints.xs) {
    w = Math.round(viewport.width * 0.95);
    h = 300;
  }
  return { width: w, height: h };
};

function SampleRates(props) {
  window.drift.hide();
  const breakPoints = useBreakPoints();
  const viewport = useViewport();
  const { width, height } = calculateDimensions(breakPoints, viewport);
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState({});
  const [sampleRates, setSampleRates] = useState({});
  let customerid = props.match.params["customerid"];

  const ref = useRef();
  useEffect(() => {
    const myObserver = new ResizeObserver((entries) => {
      if (entries.length > 0) {
        let height = entries[0].contentRect.height;
        let width = entries[0].contentRect.width;
        window.parent.postMessage(
          { source: "tools", payload: ["100%", height + 39] },
          "*"
        );
        console.log("width:" + width + ", height: " + height);
      }
    });
    if (ref.current) myObserver.observe(ref.current);
  });

  const getSampleRates = () => {
    setLoading(true);
    fetch("/data/samplerates/" + customerid, {
      method: "GET",
      headers: {
        Cache: "no-cache",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          alert(
            "Looks like there was a problem. Status Code: " + response.status
          );
          setLoading(false);
          return;
        }
        response.json().then((js) => {
          let c = JSON.parse(js.Config);
          let r = JSON.parse(js.SampleRates);
          setConfig(c);
          setSampleRates(r);
          setLoading(false);
        });
      })
      .catch(function (err) {
        alert("Fetch Error :-S", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getSampleRates();
  }, []);

  const showRates = () => {
    let out = []
    let ordered = []
    for (let prd in sampleRates) {
      let order = 0
      if (prd==="Conforming") order = 1
      if (prd==="High Balance") order = 2
      if (prd==="Jumbo") order = 3
      if (prd==="FHA") order = 4
      ordered.push({prd: prd, order: order, data: sampleRates[prd]})
    }
    ordered.sort((a,b)=>(a.order>b.order?1:-1))
    
    for (let p of ordered) {
        let ratesCards = []
        for (let termtype in p.data) {
            let d = p.data[termtype]
            ratesCards.push(
                <div className="ratesCard">
                    <div className="termType"><span>{termtype}</span></div>
                    <div className="rateApr">{d.BaseRate.toFixed(3)}/{d.APR}</div>
                    <div className="rateAprTitle">Rate/APR</div>
                    <div className="costsRow">
                        <div className="costsCol rightBorder">
                            <div className="costsColTitle" >Monthly Payment</div>
                            <div className="costs" >${com.commaizeFloat(d.MonthlyPayment)}</div>
                        </div>
                        <div className="costsCol">
                            <div className="costsColTitle" >Points</div>
                            <div className="costs" >${com.commaizeFloat(d.Points>0?Math.round(d.Points):0)}</div>
                        </div>
                    </div>
                </div>
            )
        }
        if (config[p.prd]!==undefined) {
          out.push(
            <div className="product">
                <div className="productTitle">{p.prd}</div>
                <div className="productAssumption">Assumption: loan amount ${com.commaizeFloat(config[p.prd].loanAmount)} | FICO {config[p.prd].fico} | LTV {config[p.prd].ltv}%</div>
                <div className="productRates">{ratesCards}</div>
                
            </div>
        )
      }
    }
    return out
  }

  return (
    <Router>
      {loading ? (
        <Container ref={ref}>
          <Spinner animation="border" variant="primary" />
        </Container>
      ) : (
        <div
          ref={ref}
          className="App py-0 my-0 mx-0 px-0 pb-5"
          style={{ textAlign: "left" }}
        >
            <div id="contain" className="sampleRates" width={width} height={height} >
            {showRates()}
            </div>

        </div>
      )}
    </Router>
  );
}

export default withRouter(SampleRates);
