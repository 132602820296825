import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

export default function JoinTeam() {

    return (
        <div className=" py-0 my-0">
            <h3 className="mb-4">JOIN THE ZEITRO TEAM!</h3>
            <div id="landing1" className="container my-3 text-left" >


                <div className="my-3">
                Zeitro is always on the lookout for good people. If you are interested in changing the world applying AI to the mortgage broker space, drop us a resume at <a href="mailto:contact@zeitro.com">contact@zeitro.com</a> 
                Here are some of the skillsets we're looking for:             
                </div>

                <h4 className="text-left ">Data Scientist</h4>
                <div className="my-3">
                You'll be applying modern machine learning techniques to analyzing documents, customer datasets, mining of the open sources of data. 
                Knowledge of Python is expected. Experience with AWS is a plus.
                </div>

                <h4 className="text-left ">Full Stack Developer</h4>
                <div className="my-3">
                    You'll be working on the user interface using React, Redux, JavaScript and TypeScript, as well as implementing the backend in Go.<br/>
                    Knowledge of AWS, Docker technology is a plus. Good UI taste, and appreciation of useability is very helpful.
                </div>

                <div className="my-3">
                    &nbsp;
                </div>

            </div>


        </div>


    );
}
