import React, { ref, useState, useEffect, useRef, useMemo } from "react";
import PurchaseCalc from "./PurchaseCalc";
import { CustomerHeader } from "../Landing/LandingMenu";


const PurchaseCalcPage = () => {
    let searchparams = new URLSearchParams(window.location.search);
    const customerid = searchparams.get("customerid")
    const loid = searchparams.get("loid")

    console.log(customerid, loid)
    return (
        <div>
            <CustomerHeader  />
            <PurchaseCalc customerid={customerid} loid={loid}/>
        </div>
    )
}

export default PurchaseCalcPage;
