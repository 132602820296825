import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import LandingMenu from './Landing/LandingMenu'
import LandingCopyright from './Landing/LandingCopyright'

import { Link } from 'react-router-dom'


import Helmet from 'react-helmet'

export default class CoborrowerCreated extends Component {

    componentDidMount() {
    }
    componentWillUnmount() {
    }    

    render = () => {

        return (
            <div>
                <LandingMenu />
                <div id="landing1" className="text-center my-4 pb-5" >
                    <div className="text-center pt-5">
                        <div className="loginbox mt-5 pt-3 pb-5 text-center"  >
                            <div className="pt-3 mb-3 sectionquestion">Your account has been successfully created.</div>
                            <div className="pt-3"> <Link to="/services/signin">Please click here to sign in, and proceed with your application!</Link></div>

                            <Helmet>
                                <title>Account Created!</title>
                            </Helmet>
                        </div>
                    </div>
                </div>
                <LandingCopyright />
            </div>
        )
    }
}



