import { useState, useEffect, lazy, Suspense, useRef } from 'react'
import styled, { withTheme } from 'styled-components'
import Button from './Button'
import ButtonGroup from './ButtonGroup'
import Loading from './Loading'
import Rates from './Rates'
import { withRouter } from "react-router-dom";

const LineChart = lazy(() => import('./LineChart'))

enum SelectionType {
  TimeFrame,
  Loan,
  Product,
}

const TimeFrame = ['1M', '3M', '6M', '1Y'] as const
const Loan = ['Conforming', 'Super', 'Jumbo'] as const
const Product = [
  'Fixed 15 Year',
  'Fixed 30 Year',
  'ARM 10 Year',
  'ARM 7 Year',
  'ARM 5 Year',
] as const

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: '#F7F9FC';
`

const ChartContainer = styled.div`
  min-height: 168.75px;
  height: calc(0.5625 * 100vw);
  width: 100vw;
  padding-top: 10px;
  padding-bottom: 25px;
`

let rates = new Rates()

const HistoricalChart = ( props) => {
  
  let theme = props.theme
  const { colors } = theme.chart
  const [loaded, setLoaded] = useState(false)
  const [selection, setSelection] = useState({
    TimeFrame: '3M',
    Loan: 'Conforming',
    Products: new Set(['Fixed 15 Year', 'Fixed 30 Year']),
  })

  let customerid = props.match.params["customerid"]

  const ref = useRef()

  useEffect(() => {
    const myObserver = new ResizeObserver((entries) => {
      if (entries.length > 0) {
        let height = entries[0].contentRect.height
        let width = entries[0].contentRect.width
        window.parent.postMessage(
          { source: 'tools', payload: [width, height] },
          '*'
        )
        // console.log('width:' + width + ', height: ' + height)
      }
    })    
    if (ref.current) myObserver.observe(ref.current)

    if (null != window['drift']) {
      window['drift'].hide()
      window['drift'] = null
    }

    const fetchData = () =>
      fetch('/data/historicalrates/'+customerid)
        .then((res) => {
          if (!res.ok) throw Error(res.statusText)
          return res.json()
        })
        .then((res) => {
          let arr = []
          for (const property in res) {
            arr.push({...res[property], Timestamp: property});
          }
          rates.push(arr)
          setLoaded(true)
        })
        .catch((err) => {
          console.error(err)
          setLoaded(false)
        })
    fetchData()

  }, []);


  const handleSelection = (type: SelectionType, value: string) => {
    switch (type) {
      case SelectionType.TimeFrame:
        setSelection({ ...selection, TimeFrame: value })
        break
      case SelectionType.Loan:
        setSelection({
          ...selection,
          Loan: value,
        })
        break
      case SelectionType.Product:
        const isCurrentlySelected = selection.Products.has(value)
        if (!(selection.Products.size === 1 && isCurrentlySelected)) {
          let newProductSelection = new Set(selection.Products)
          if (isCurrentlySelected) newProductSelection.delete(value)
          else newProductSelection.add(value)
          setSelection({
            ...selection,
            Products: newProductSelection,
          })
        }
        break
      default:
        break
    }
  }

  return (
    <div ref={ref}>
      {!loaded ? (
        <Loading />
      ) : (
        <div  className="px-3"> 
          <Container>

            <ButtonGroup>
              {TimeFrame.map((key, index) => (
                <Button
                  key={index}
                  className={'timeframe'}
                  selected={key === selection.TimeFrame}
                  onClick={() => handleSelection(SelectionType.TimeFrame, key)}
                >
                  {key}
                </Button>
              ))}
            </ButtonGroup>
            <ButtonGroup>
              {Loan.map((key, index) => (
                <Button
                  key={index}
                  className={'loan'}
                  selected={key === selection.Loan}
                  onClick={() => handleSelection(SelectionType.Loan, key)}
                >
                  {key}
                </Button>
              ))}
            </ButtonGroup>
            <ButtonGroup>
              {Product.map((key, index) => (
                <Button
                  key={index}
                  color={colors[key]}
                  className={'product'}
                  selected={selection.Products.has(key)}
                  onClick={() => handleSelection(SelectionType.Product, key)}
                >
                  {key}
                </Button>
              ))}
            </ButtonGroup>
          </Container>
          <div id="chartcontainer" className="mx-4 px-2 mt-2 pb-5">
          <Suspense fallback={<Loading />}>
            <ChartContainer>
              <LineChart data={rates.filter(selection)} />
            </ChartContainer>
          </Suspense>
          </div>
        </div>
      )}
    </div>
  )
}

export default withTheme(withRouter(HistoricalChart))
