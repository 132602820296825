export const stepMap = {
  borrower: 1,
  property: 2,
  experience: 3,
  declarations: 4,
}
export const stepNumMap = {
  1: 'borrower',
  2: 'property',
  3: 'experience',
  4: 'declarations',
}
